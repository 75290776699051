import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson24/step13-1.png";
import img2 from "../../../image/lessons/lesson24/step13-2.png";

export default class step13 extends React.Component {

    render() {
        return (
            <div className="step13">
                <div className="head">
                    Sheba<sup>®</sup> Цельные кусочки
                </div>
                <TextBlock
                    text={`Что может быть приятнее для кошек, чем возможность насладиться цельными мясными кусочками? «Цельные кусочки» подарит вашей любимице эту незабываемую возможность. Ведь кусочки любимого мяса в изысканном фирменном соусе — это настоящее событие для любой кошки. Соблазнительный вкус индейки или нежнейшего ягненка в кусочках? Выберите для своей кошки то, что она пожелает сегодня. Когда кошка хочет Sheba<sup>®</sup> , сопротивление бесполезно.
                    
                    <span class="text_sheba">Ключевые особенности:</span>`}
                />
                <div className="advantages">
                    <div className="advantages_el">
                        Сочетание цельных мясных
                        кусочков и классических
                        ломтиков Sheba<sup>®</sup>
                    </div>
                    <div className="advantages_plus">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.22939 13.425V8.925H0.704395V5.475H5.22939V0.975H8.7794V5.475H13.3044V8.925H8.7794V13.425H5.22939Z" fill="#AC8748"/>
                        </svg>
                    </div>
                    <div className="advantages_el">
                        Особая подлива, содержащая
                        натуральный бульон, получаемый
                        при варке цельных мясных кусочков
                    </div>
                </div>
                <div className="flex">
                    <img src={img1} alt="" className="flex_img"/>
                    <img src={img2} alt="" className="flex_img"/>
                </div>
            </div>
        )
    }
}
