import React from 'react'
import Resume from "../../../Components/LessonAndTestComponents/Resume";

import img1 from "../../../image/lessons/lesson7/step22-1.png";

export default class step22 extends React.Component {
    state = {
        resumeData: [
            `Кошкам и собакам необходимо около <b>40 питательных веществ</b>, каждое из которых должно содержаться <b>в правильном количестве и правильной форме.</b>`,
            `В отличие от полнорационного корма, мясо в чистом виде не является <b>сбалансированной пищей.</b>`,
            `При производстве кормов <b>важен баланс питательных веществ</b>, который отвечает уникальным пищевым потребностям кошек и собак.`,
        ]
    }

    render() {
        return (
            <div className="step22">
                <Resume
                    resumeData={this.state.resumeData}
                    img={img1}
                />
            </div>
        )
    }
}
