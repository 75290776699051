import React from 'react'
import Resume from "../../../Components/LessonAndTestComponents/Resume";

import img1 from "../../../image/lessons/lesson7/step28-1.png";

export default class step28 extends React.Component {
    state = {
        resumeData: [
            `Сухой и влажный корма имеют свои преимущества <b>для хозяина и для питомца.</b>`,
            `Сухой корм содержит примерно <b>в 4 раза больше калорий</b>, чем влажный корм.`,
            `Замена части сухого рациона влажным позволяет поддерживать <b>оптимальный вес</b> животных.`,
            `<b>Сочетание</b> сухого и влажного корма в рационе имеет много преимуществ, например, <b>разнообразие вкусов и текстур еды.</b>`,
        ]
    }

    render() {
        return (
            <div className="step28">
                <Resume
                    resumeData={this.state.resumeData}
                    img={img1}
                />
            </div>
        )
    }
}
