import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson18/step14-1.jpg'

export default class step14 extends React.Component{

    render(){
        return (
            <div className="step14">
                <TextBlock
                    text={`<span class="text_dark-blue">Достаточно ли давать собаке PEDIGREE<sup>®</sup> DENTASTIX™ для здоровья её зубов?</span>
                    
                    Продукт PEDIGREE<sup>®</sup> DENTASTIX™ снижает риск возникновения заболеваний зубов и десен, он <b>является профилактическим, но не лечебным средством</b>. Вам <b>необходимо регулярно посещать ветеринарного врача</b> для осмотра зубов собаки. Также ветеринарный врач может посоветовать, как чистить зубы собаке.

                    <span class="text_dark-blue">Я кормлю свою собаку сухим кормом. Нужно ли мне делать что-то ещё для ухода за её зубами? Или этого достаточно?</span>

                    Одного сухого корма <b>недостаточно</b> для снижения уровня образования зубного налета и зубного камня. Чтобы уменьшить риск возникновения заболеваний, необходимо <b>ежедневно чистить зубы</b> собаки <b>или давать</b> ей лакомство <b>PEDIGREE<sup>®</sup> DENTASTIX™</b>. Оно снижает уровень образования зубного камня до 80%, предотвращая риск возникновения заболеваний ротовой полости.`}
                />
                <img src={img1} className="imgCenter" alt=""/>
            </div>
        )
    }

}
