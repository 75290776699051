import React from 'react'
import './style.sass'
import star from '../../image/CourseStat/star.png'
import starPyaterochka from '../../image/CourseStat/starPyaterochka.png'
import status from '../../image/CourseStat/status.png'
import statusPyaterochka from '../../image/CourseStat/statusPyaterochka.png'
import progress from '../../image/CourseStat/progress.png'
import progressPyaterochka from '../../image/CourseStat/progressPyaterochka.png'

export default class CourseStat extends React.Component{

    _renderStatus = () =>{
        const {courses} = this.props
        let rank = ''
        if(courses.rank){
            switch (courses.rank) {
                case 0:
                    rank = 'НОВИЧОК';
                    break;
                case 1:
                    rank = 'НОВИЧОК';
                    break;
                case 2:
                    rank = 'СПЕЦИАЛИСТ';
                    break;
                case 3:
                    rank = 'ПРОФЕССИОНАЛ';
                    break;
                case 5:
                    rank = 'МАСТЕР';
                    break;
                case 6:
                    rank = 'ЗНАТОК';
                    break;
                case 7:
                    rank = 'ГУРУ';
                    break;
                default :
                    rank = 'НОВИЧОК';
                    break;
            }
        }
        return rank
    }

    render() {
        const {courses} = this.props
        return (
            <div className={`CourseStat ${this.props.status} ` + (courses.progressTest !== undefined && 'CourseStatFull')}>
                <div className={`head ${this.props.status}`}>
                    Статистика курса
                </div>
                <div className="balls">
                    <div className={`icon ${this.props.status}`}>
                        <img src={this.props.status === 'pyaterochka' || this.props.status === 'perekrestok' ? starPyaterochka : star} alt=""/>
                    </div>
                    <div className={`balls__box ${this.props.status}`}>
                        Набрано баллов{courses.progressTest !== undefined && ' по урокам'}:&#8194;<b className={this.props.status}>{courses.points} из {this.props.pointTotal}</b>
                    </div>
                </div>
                {courses.pointsTest !== undefined ?
                    <div className="status">
                        <div className="icon">
                            <img src={star} alt=""/>
                        </div>
                        <div className={this.props.status ? "balls__box blue" : "balls__box"}>
                            Набрано баллов{courses.progressTest !== undefined && ' по тестам'}:&#8194;<b>{courses.pointsTest} из {this.props.pointTotalTest}</b>
                        </div>
                    </div>
                    :
                    <div className="status">
                        <div className={`icon ${this.props.status}`}>
                            <img src={this.props.status === 'pyaterochka' || this.props.status === 'perekrestok' ? statusPyaterochka : status} alt=""/>
                        </div>
                        <div className={`balls__box ${this.props.status}`}>
                            Статус: &nbsp; <b className={this.props.status}>{this._renderStatus()}</b>
                        </div>
                    </div>
                }
                <div className={'progress ' + (courses.progressTest !== undefined && 'progressHulf')}>
                    <div className={`icon ${this.props.status}`}>
                        <img src={this.props.status === 'pyaterochka' || this.props.status === 'perekrestok' ? progressPyaterochka : progress} alt=""/>
                    </div>
                    <div className={`desc ${this.props.status}`}>
                        Общий прогресс{courses.progressTest !== undefined && ' по уроку'}:&#8194;<div className="textProgress textProgress--mobile" style={{left: courses.progress + '%'}}>{Math.round(courses.progress)}%</div>
                        <div className="line">
                            <div className={`lineProgress`} style={{width: Math.round(courses.progress) + '%'}}/>
                        </div>
                        <div className="textProgress" style={{left: courses.progress + '%'}}>{Math.round(courses.progress)}%</div>
                    </div>
                </div>
                {courses.progressTest !== undefined &&
                    <div className="progress progressHulf">
                        <div className="icon">
                            <img src={progress} alt=""/>
                        </div>
                        <div className="desc">
                            Общий прогресс по тесту:&#8194;<div className="textProgress textProgress--mobile" style={{left: courses.progressTest + '%'}}>{Math.round(courses.progressTest)}%</div>
                            <div className="line">
                                <div className="lineProgress" style={{width: Math.round(courses.progressTest) + '%'}}/>
                            </div>
                            <div className="textProgress" style={{left: courses.progressTest + '%'}}>{Math.round(courses.progressTest)}%</div>
                        </div>
                    </div>
                }
            </div>
        )
    }

}
