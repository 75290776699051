import React from 'react'
import Resume from "../../../Components/LessonAndTestComponents/Resume";

export default class step15 extends React.Component {

    state = {
        resumeData: [
            `<b>Обменная энергия</b> — это энергия, которая тратится на обмен веществ в организме.`,
            `<b>Обменная энергия рассчитывается</b> как валовая энергия минус энергия, которая выходит с фекалиями, мочой и газами.`,
            `<b>Энергетическая плотность</b> — главный фактор, который учитывается при расчете размера порции.`,
            `Белки и углеводы обеспечивают <b>почти одинаковое количество энергии на 1 грамм.</b>`,
            `Энергетическая ценность жиров <b>в два раза выше,</b> чем белков и углеводов.`,
            `Для подсчета общей энергетической ценности корма <b>используются математические формулы.</b>`,
            `Энергетические потребности взрослых кошек и собак рассчитываются <b>исходя из массы их тела.</b>`,
        ]
    }

    render() {
        return (
            <div className="step7">
                <Resume
                    resumeData={this.state.resumeData}
                />
            </div>
        )
    }
}
