import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson15/step14-1.png';
import img1xs from '../../../image/lessons/lesson15/step14-1xs.png';


export default class step14 extends React.Component {

    render() {
        return (
            <div className="step14">
                <TextBlock
                    text={`Говоря про особенности рынка, легко заметить, что в России очень любят кошек.`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} alt="" className="imgCenter"/>
                </picture>
            </div>
        )
    }
}
