import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';

export default class step1 extends React.Component {

    planData = [
        `Для чего нужны специальные лакомства для кошек?`,
        `Почему нельзя угощать кошек едой со стола?`,
        `Почему кошкам так нравится Dreamies™?`,
        `Полезные советы по угощению кошек лакомствами Dreamies™`,
    ]

    render() {
        return (
            <div className="step1">
                <div className="head">План урока</div>

                <TextBlock
                    text={`В этом уроке рассматриваются следующие вопросы:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'light-blue'}
                />

            </div>
        )
    }
}
