import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson18/step15-1.jpg'

export default class step1 extends React.Component{

    render(){
        return (
            <div className="step15">
                <TextBlock
                    text={`<span class="text_dark-blue">Моя собака постоянно грызёт кости и палки. Это сохраняет её зубы здоровыми?</span>
                    
                    Сырые кости и палки <b>не могут обеспечить необходимую профилактику заболеваний зубов и десен</b> собаки, ведь для этого важен не только сам процесс жевания, но и текстура, форма, размер разгрызаемого предмета. Кроме того, <b>сырые кости и палки не перевариваются в желудочно-кишечном тракте собаки</b>. Они могут стать причиной нарушения пищеварения и даже травмировать кишечник острыми краями.

                    <span class="text_dark-blue">Правда ли, что PEDIGREE<sup>®</sup> DENTASTIX™ очень калорийный продукт?</span>

                    PEDIGREE<sup>®</sup> DENTASTIX™ не содержит сахара, а <b>энергетическая ценность одной палочки лакомства для собак среднего размера составляет 78 ккал</b>. Чтобы снизить вероятность набора собакой веса, <b>сократите калорийность рациона</b> питомца на количество калорий в PEDIGREE<sup>®</sup> DENTASTIX™.`}
                />
                <img src={img1} className="imgCenter" alt=""/>
            </div>
        )
    }

}
