import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from "../../../image/lessons/lesson3/step6-1.png";



export default class step6 extends React.Component {

    render() {
        return (
            <div className="step6">
               <div className="flex">
                   <div className="flex_el">
                       <div className="flex_el_head">
                           Пример нарушения баланса:
                       </div>
                       <TextBlock
                           text={`Отсутствие хотя бы одной незаменимой аминокислоты в пище препятствует синтезу необходимых белков, что ставит под угрозу здоровье питомца.`}
                       />
                       <div className="flex_el_blue">
                           ПОСЛЕДСТВИЯ ДЕФИЦИТА АМИНОКИСЛОТ:
                       </div>
                       <ul className="list">
                           <li className="list_item">разрушение тканей;</li>
                           <li className="list_item">замедление роста;</li>
                           <li className="list_item">невозможность синтеза белка.</li>
                       </ul>
                   </div>
                   <div className="flex_el">
                       <div className="flex_el_imgBox">
                           <img src={img1} alt="" className="flex_el_img"/>
                       </div>
                   </div>
               </div>
            </div>
        )
    }
}
