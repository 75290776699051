import React from 'react'

import img1 from '../../../image/lessons/lesson21/step16-1.png'
import img2 from '../../../image/lessons/lesson21/step16-2.png'
import img3 from '../../../image/lessons/lesson21/step16-3.png'
import img4 from '../../../image/lessons/lesson21/step16-4.png'


export default class step16 extends React.Component {


    render() {
        return (
            <div className="step16">
                <div className="info">
                    <div className="info_el">
                        <div className="info_el_number">
                            <img src={img1} alt="" className="info_el_img"/>
                        </div>
                        <div className="info_el_text">
                            Центр по изучению питания домашних животных находится в Ульяновске.
                        </div>
                    </div>
                    <div className="info_el">
                        <div className="info_el_number">
                            <img src={img2} alt="" className="info_el_img"/>
                        </div>
                        <div className="info_el_text">
                            Центр изучения питания домашних животных в Ульяновской области стал 11-м для Mars в мире. За 4 года работы он вошел в топ-3 по количеству проживающих животных и проводимых исследований.
                        </div>
                    </div>
                    <div className="info_el">
                        <div className="info_el_number">
                            <img src={img3} alt="" className="info_el_img"/>
                        </div>
                        <div className="info_el_text">
                            Научно-исследовательский институт Waltham находится на втором месте по количеству животных среди других центров, но именно с этого научного центра и началась история изучения питания домашних животных. Первый центр по исследованию потребности животных в питательных веществах был основан в 60-х годах.
                        </div>
                    </div>
                </div>
                <img src={img4} alt="" className="imgCenter"/>
            </div>
        )
    }
}
