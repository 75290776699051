import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson16/step12-1.png';
import img1xs from "../../../image/lessons/lesson16/step12-1xs.png";

export default class step12 extends React.Component {

    render() {
        return (
            <div className="step12">
                <TextBlock
                    text={`Большая доля покупателей совершает покупку кормов в канале современной торговли. Покупка кормов только в Зооканале более распространена среди владельцев собак.`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} className="imgCenter" alt=""/>
                </picture>
            </div>
        )
    }
}
