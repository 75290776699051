import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson32/step51-1.png'
import img1xs from '../../../image/lessons/lesson32/step51-1xs.png'

export default class step51 extends React.Component {

    render() {
        return (
            <div className="step51">
                <TextBlock
                    text={`Практикующие ветеринарные врачи в России подтверждают эффективность PEDIGREE<sup>®</sup> на основании клинических исследований.`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img className="img" src={img1} alt=""/>
                </picture>
            </div>
        )
    }
}
