import React from 'react'
// import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson32/step35-1.png'
import img2 from '../../../image/lessons/lesson32/step35-2.png'
import img1xs from "../../../image/lessons/lesson32/step35-1xs.png";
import img2xs from "../../../image/lessons/lesson32/step35-2xs.png";

export default class step35 extends React.Component {

    render() {
        return (
            <div className="step35">
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Интенсивный рост и развитие
                        </div>
                        <div className="text">
                            Чтобы избежать повышенной нагрузки на ЖКТ щенка в период роста, необходимо использовать в кормлении высококалорийный и хорошо усвояемый корм
                        </div>
                        <ul>
                            <li>С 2-х месяцев щенок интенсивно растет и развивается, у него сохраняются высокие потребности в энергии</li>
                            <li>Желудочно-кишечный тракт щенка в этот момент очень уязвим и пищеварение характеризуется повышенной чувствительностью</li>
                            <li>Перекармливание растущих щенков может вызвать ожирение, а у щенков крупных пород - ещё и отклонение в развитии скелета</li>
                            <li>Оптимальная скорость роста наблюдается у щенков, которые потребляют примерно 85% от того количества, которое они поедали бы при свободном доступе к нему</li>
                        </ul>
                    </div>
                    <div className="flex_el">
                        <picture>
                            <source srcSet={img1xs} media="(max-width: 767px)"/>
                            <img src={img1} alt="" className="flex_img"/>
                        </picture>
                    </div>
                </div>
                <div className="text">
                    Пищевые потребности щенков
                </div>
                <ul>
                    <li>Щенки, вес которых составляет 80% от веса взрослой собаки, нуждаются в 1,2 раза большем количестве калорий</li>
                    <li>Потребность в белке у щенков в возрасте 12 недель и старше составляет порядка 9 г/кг массы тела, в то время, как у взрослых собак 4,5 г/кг массы тела</li>
                    <li>Щенки нуждаются в 5,8 раз большем количестве кальция и в 6,4 раз большем количестве фосфора, чем взрослые собаки</li>
                    <li>Потребность в цинке у щенков в 4,5 раза выше, чем у взрослых собак</li>
                </ul>
                <picture className="img-box">
                    <source srcSet={img2xs} media="(max-width: 767px)"/>
                    <img src={img2} alt="" className="imgCenter"/>
                </picture>
            </div>
        )
    }
}
