import React from 'react'

import img1 from '../../../image/lessons/lesson32/step22-1.png'
import img1xs from '../../../image/lessons/lesson32/step22-1xs.png'



export default class step22 extends React.Component {

    render() {
        return (
            <div className="step22">
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} alt="" className="imgCenter"/>
                </picture>
            </div>
        )
    }
}
