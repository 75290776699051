import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import DefinitionBlock from "../../../Components/LessonAndTestComponents/DefinitionBlock";

import img1 from '../../../image/lessons/lesson6/step2-1.png'


export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <DefinitionBlock
                    text={`<span class="text_blue">Минеральные вещества</span> — это неорганические компоненты пищи.`}
                />
                <TextBlock
                    text={`<b>Как понять, сколько минеральных веществ содержится в корме?</b>
                    
                    Возьмем упаковку и найдем в составе слово <b>«зола» или «зольность»</b> - это и есть количество всех минералов, содержащихся в корме.
                    <b>Но почему общее количество минеральных веществ называется золой?</b> В лаборатории при сжигании образца корма сгорают все питательные (органические) вещества, при этом остается минеральный (неорганический) остаток, который и называют золой.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <TextBlock
                            text={`При добавлении минеральных веществ в готовые рационы учитываются следующие факторы:`}
                        />
                        <ul className="list">
                            <li className="list_item">содержание минеральных веществ в исходных ингредиентах;</li>
                            <li className="list_item">взаимное влияние минеральных веществ друг на друга (избыток одного минерального вещества может привести к недостатку другого);</li>
                            <li className="list_item">биологическая доступность (степень усвоения минеральных веществ из пищи);</li>
                            <li className="list_item">потребности животных (с учётом их возрастных особенностей).</li>
                        </ul>
                    </div>
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                </div>
            </div>
        )
    }
}
