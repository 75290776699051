import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from "../../../Components/LessonAndTestComponents/OnClickBlock";

import img1 from '../../../image/lessons/lesson4/step7-1.png';
import img2 from "../../../image/lessons/lesson4/step7-2.png";
import img3 from "../../../image/lessons/lesson4/step7-3.png";

export default class step7 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит и избыток'],
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step7">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Омега-3 жирные кислоты — противовоспалительное действие, насыщение клеток кислородом.</b>
                           
                            Эйкозапентаеновая кислота (ЭПК), докозагексаеновая кислота (ДГК) и альфа-линоленовая кислота (АЛК) вместе образуют семейство омега-3 ПНЖК (полиненасыщенные жирные кислоты).
                            
                            ПНЖК омега-3 не являются незаменимыми, но улучшают некоторые процессы в организме.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Функции омега-3 ПНЖК:`}
                        />
                        <ul className="list">
                            <li className="list_item">улучшение состояния кожи и шерсти;</li>
                            <li className="list_item">противовоспалительное действие;</li>
                            <li className="list_item">предотвращение снижения мозговой активности благодаря насыщению головного мозга кислородом;</li>
                            <li className="list_item">способствуют нормальному росту и развитию плода у беременных животных;</li>
                            <li className="list_item">ДГК добавляют в корма для котят и щенков для развития мозговой деятельности.</li>
                        </ul>
                        <TextBlock
                            text={`Котятам и щенкам особенно важно правильное развитие мозговой деятельности. Поэтому во многих рационах, например, PERFECT FIT™ для котят и для щенков, добавляют докозагексаеновую кислоту (ДГК).
                            
                            ДГК способствует снабжению клеток мозга кислородом, и тем самым помогает развитию памяти и обучению у щенков и котят. Данный эффект доказан рядом клинических исследований.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img2} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Источниками жирных кислот омега-3 являются:`}
                        />
                        <div className="imgDiv_wrap_flex">
                            <ul className="list">
                                <li className="list_item">льняное масло (содержит АЛК);</li>
                                <li className="list_item">рыбий жир (ЭПК и ДГК);</li>
                                <li className="list_item">фитопланктон (ЭПК и ДГК);</li>
                                <li className="list_item">одноклеточные водоросли (ЭПК и ДГК).</li>
                            </ul>
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img3} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Избыток омега-3 ПНЖК может привести к снижению иммунитета у собак.`}
                        />
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
