import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from "../../../image/lessons/lesson10/step19-1.jpg";

export default class step19 extends React.Component {

    render() {
        return (
            <div className="step9">
                <TextBlock
                    text={`Каждая жизненная стадия кошки требует особого ухода за ней.
                    
                    <b>В период роста</b> котенку требуется много энергии, поэтому корм для котят должен быть калорийным и содержать большое количество белка, таурина и кальция для здорового роста.
                    
                    <b>Во взрослом периоде</b> жизни рацион кошки должен поддерживать ее состояние здоровья и хорошую физическую форму.
                    
                    Когда <b>кошка беременна</b>, ее энергетические потребности увеличиваются, но при этом она не может есть большое количество пищи. Рекомендуется кормить беременных и кормящих кошек рационом для котят.
                    
                    <b>В пожилом возрасте</b> у кошек могут возникнуть проблемы с пищеварением. Чтобы предотвратить это, кошкам нужен специализированный рацион, который будет хорошо усваиваться и удовлетворять их энергетические потребности.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
