import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson24/step11_1.png";
import img2 from "../../../image/lessons/lesson24/step11_2.png";
import img3 from "../../../image/lessons/lesson24/step11_3.png";
import img4 from "../../../image/lessons/lesson24/step11_4.png";
import img5 from "../../../image/lessons/lesson24/step11_5.png";
import img6 from "../../../image/lessons/lesson24/step11_6.png";
import img7 from "../../../image/lessons/lesson24/step11_7.png";
import img8 from "../../../image/lessons/lesson24/step11_8.png";
import img9 from "../../../image/lessons/lesson24/step11_9.png";

export default class step11 extends React.Component {

    render() {
        return (
            <div className="step11">
                <div className="head">
                    Sheba<sup>®</sup> Ломтики в соусе
                </div>
                <TextBlock
                    text={`Sheba<sup>®</sup> — это завораживающий вкус сочных мясных ломтиков и фирменный соус. Такое аппетитное сочетание станет истинным наслаждением даже для самых притязательных кошек. Нежные курица и индейка или изысканные креветки и форель? А может быть, пленительное сочетание телятины и языка? Что выберет ваша кошка сегодня? Порадуйте вашу любимицу большим выбором изысканной коллекции блюд линейки Sheba<sup>®</sup> «Ломтики в соусе».
                    
                    <span class="text_sheba">Ключевые особенности:</span>`}
                />
                <div className="advantages">
                    <div className="advantages_el">
                        Классические мясные
                        ломтики Sheba<sup>®</sup>
                    </div>
                    <div className="advantages_plus">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.22939 13.425V8.925H0.704395V5.475H5.22939V0.975H8.7794V5.475H13.3044V8.925H8.7794V13.425H5.22939Z" fill="#AC8748"/>
                        </svg>
                    </div>
                    <div className="advantages_el">
                        Густой аппетитный соус
                    </div>
                </div>
                <TextBlock
                    text={`<span class="text_sheba">Вкусы Sheba<sup>®</sup> Ломтики в соусе™</span>`}
                />
                <div className="flex">
                    <img src={img1} alt="" className="flex_img"/>
                    <img src={img2} alt="" className="flex_img"/>
                    <img src={img3} alt="" className="flex_img"/>
                    <img src={img4} alt="" className="flex_img"/>
                    <img src={img5} alt="" className="flex_img"/>
                    <img src={img6} alt="" className="flex_img"/>
                    <img src={img7} alt="" className="flex_img"/>
                    <img src={img8} alt="" className="flex_img"/>
                    <img src={img9} alt="" className="flex_img"/>
                </div>
            </div>
        )
    }
}
