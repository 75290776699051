import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson32/step39-1.png'
import img1xs from "../../../image/lessons/lesson32/step39-1xs.png";


export default class step39 extends React.Component {


    render() {
        return (
            <div className="step39">
                <div className="head">
                    2. Склонность к заболеваниям мочевыделительной системы
                </div>
                <TextBlock
                    text={`<b>Включение в рацион 25% ежедневных калории в виде влажного рациона достаточно, чтобы уменьшить риск формирования оксалата кальция у собак мелких пород.</b>`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} alt="" className="imgCenter"/>
                </picture>
                <div className="info">
                    Такой рацион способствует увеличению объема вырабатываемой мочи, растворению кристаллов и увеличенной частоты мочеиспусканий, по сравнению с кормлением только сухим рационом
                </div>
            </div>
        )
    }
}
