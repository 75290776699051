import React from 'react'
import './style.sass'

import arrow from '../../image/arrow.png'

export default function Element(props) {
    return (
        <div className={'guru_el '  + (props.active && 'active')}>
            {/*<div className={'img'}>*/}
            {/*    <img src={props.img} alt="" className="img_img"/>*/}
            {/*</div>*/}
            <p className="name">{props.name}</p>
            <div className="arrow">
                {/*<svg className="up" width="49" height="20" viewBox="0 0 49 20" fill="none">*/}
                {/*    <path*/}
                {/*        d="M48.8876 0.8C48.8876 0.8 48.8876 0.685714 48.8876 0.8C48.8876 0.685714 48.8876 0.685714 48.8876 0.685714V0.571429C48.8876 0.571429 48.8876 0.571429 48.8876 0.457143V0.342857C48.8876 0.342857 48.8876 0.228571 48.7752 0.228571C48.7752 0.228571 48.7752 0.228571 48.6628 0.114286H48.5505C48.5505 0.114286 48.5505 0.114286 48.4381 0.114286H48.3257C48.2133 0 48.2133 0 48.2133 0C47.539 0.114286 46.9771 0.342857 46.3028 0.571429C44.617 1.14286 42.4817 1.82857 38.9977 1.37143C38.6606 1.37143 38.3234 1.6 38.3234 1.94286C38.3234 2.28571 38.5482 2.62857 38.8853 2.62857C39.7844 2.74286 40.5711 2.85714 41.3578 2.85714C43.7179 2.85714 45.4037 2.28571 46.7523 1.82857C33.4908 17.0286 14.3853 20.2286 0.674312 18.0571C0.337156 18.0571 0 18.2857 0 18.6286C0 18.9714 0.224771 19.3143 0.561927 19.3143C3.14679 19.6571 5.84404 19.8857 8.54128 19.8857C21.2408 19.8857 36.0757 15.5429 46.9771 3.2C46.5275 4.91429 46.078 6.85714 45.8532 8.11429C45.7408 8.45714 46.078 8.8 46.4151 8.91429H46.5275C46.8647 8.91429 47.0895 8.68571 47.2018 8.34286C47.4266 6.4 48.4381 1.94286 48.8876 0.8Z"*/}
                {/*        fill="#005EAD"/>*/}
                {/*</svg>*/}
                {/*<svg className="down" width="49" height="20" viewBox="0 0 49 20" fill="none">*/}
                {/*    <path*/}
                {/*        d="M48.8876 19.2C48.8876 19.2 48.8876 19.3143 48.8876 19.2C48.8876 19.3143 48.8876 19.3143 48.8876 19.3143V19.4286C48.8876 19.4286 48.8876 19.4286 48.8876 19.5429V19.6571C48.8876 19.6571 48.8876 19.7714 48.7752 19.7714C48.7752 19.7714 48.7752 19.7714 48.6628 19.8857H48.5505C48.5505 19.8857 48.5505 19.8857 48.4381 19.8857H48.3257C48.2133 20 48.2133 20 48.2133 20C47.539 19.8857 46.9771 19.6571 46.3028 19.4286C44.617 18.8571 42.4817 18.1714 38.9977 18.6286C38.6606 18.6286 38.3234 18.4 38.3234 18.0571C38.3234 17.7143 38.5482 17.3714 38.8853 17.3714C39.7844 17.2571 40.5711 17.1429 41.3578 17.1429C43.7179 17.1429 45.4037 17.7143 46.7523 18.1714C33.4908 2.97143 14.3853 -0.228571 0.674312 1.94286C0.337156 1.94286 0 1.71429 0 1.37143C0 1.02857 0.224771 0.685717 0.561927 0.685717C3.14679 0.342859 5.84404 0.114286 8.54128 0.114286C21.2408 0.114286 36.0757 4.45714 46.9771 16.8C46.5275 15.0857 46.078 13.1429 45.8532 11.8857C45.7408 11.5429 46.078 11.2 46.4151 11.0857H46.5275C46.8647 11.0857 47.0895 11.3143 47.2018 11.6571C47.4266 13.6 48.4381 18.0571 48.8876 19.2Z"*/}
                {/*        fill="#005EAD"/>*/}
                {/*</svg>*/}
                <svg className="arrow_img" width="39" height="16" viewBox="0 0 39 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M38.1934 8.70711C38.584 8.31658 38.584 7.68342 38.1934 7.29289L31.8295 0.928932C31.4389 0.538408 30.8058 0.538408 30.4153 0.928932C30.0247 1.31946 30.0247 1.95262 30.4153 2.34315L36.0721 8L30.4153 13.6569C30.0247 14.0474 30.0247 14.6805 30.4153 15.0711C30.8058 15.4616 31.4389 15.4616 31.8295 15.0711L38.1934 8.70711ZM0.979492 9H37.4863V7H0.979492V9Z"/>
                </svg>
                {/*<img src={arrow} alt="" className="arrow_img"/>*/}
            </div>
        </div>
    )
}