import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson15/step22-1.png'


export default class step22 extends React.Component {

    render() {
        return (
            <div className="step22">
                <TextBlock
                    text={`Рост текущего потребления позволяет потенциально увеличить категорию почти в 7 раз.`}
                />
                <img src={img1} className="imgCenter" alt=""/>
            </div>
        )
    }
}
