import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson5/step12-1.png";
import img2 from "../../../image/lessons/lesson5/step12-2.png";

export default class step12 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит и избыток'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step12">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Витамин В5 (пантотеновая кислота) — важен для здоровья.</b>
                           
                            За открытие ключевой роли витамина B5 в энергообразовании клеток Фриц Липман получил в 1953 году Нобелевскую премию в области химии.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`В качестве элемента в коферменте A пантотеновая кислота участвует почти во всех метаболических процессах.
                            
                            Вместе с другими витаминами группы B (ниацином и холином) она помогает защищать кожу, способствуя синтезу кожных жиров.`}
                        />
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Больше всего витамина В5 содержат:`}
                        />
                        <div className="imgDiv_wrap_flex">
                            <ul className="list">
                                <li className="list_item">мясо;</li>
                                <li className="list_item">субпродукты;</li>
                                <li className="list_item">яйца.</li>
                            </ul>
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img2} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Пантотеновая кислота содержится во многих пищевых продуктах, поэтому дефицит ее наблюдается крайне редко и симптомы общие.`}
                        />
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
