import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson25/step12-1.png'

export default class step12 extends React.Component{

    render() {
        return (
            <div className="step12">
                <TextBlock
                    text={`<span class="text_blue">Я не могу найти корм, который обычно ест мой питомец. Могу ли я переключить его на другой рацион?</span>`}
                />
                <TextBlock
                    text={`Да. В идеале возьмите тот же тип корма (сухой или влажный) и постепенно, в течение недели добавляйте малыми порциями новый рацион в привычное питомцу питание. Это необходимо, чтобы избежать пищевого расстройства у питомца.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
