import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson13/step10-1.jpg'
import img2 from "../../../image/lessons/lesson13/step10-2.jpg";
import img3 from "../../../image/lessons/lesson13/step9-3.png";


export default class step10 extends React.Component {

    render() {
        return (
            <div className="step10">
                <TextBlock
                    text={`Если в сухом корме используется предварительно высушенное сырье, то во влажном корме — сырье в его естественном состоянии. Сырьевые источники влажного корма представлены в таблице ниже.`}
                />
                <div className="flex flex_header">
                    <div className="flex_el_header">
                        <div className="attention">
                            Тип сырья
                        </div>
                    </div>
                    <div className="flex_el_header">
                        <div className="attention">
                            Сырой материал
                        </div>
                    </div>
                    <div className="flex_el_header">
                        <div className="attention">
                            Функция
                        </div>
                    </div>
                </div>
                <div className="flex">
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Тип сырья:
                        </h3>
                        <div className="flex_wrp">
                            <TextBlock
                                text={`Мясные субпродукты`}
                            />
                            <img className="img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Сырой материал:
                        </h3>
                        <div className="flex_wrp">
                            <TextBlock
                                text={`Печень говяжья
                            Печень куриная
                            Селезенка
                            Почки
                            Сердце куриное
                            `}
                            />
                        </div>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Функция:
                        </h3>
                        <div className="flex_wrp">
                            <TextBlock
                                text={`Источник животного белка, незаменимых аминокислот, витамина А`}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex">
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Тип сырья:
                        </h3>
                        <div className="flex_wrp">
                            <TextBlock
                                text={`Мясо`}
                            />
                            <img className="img" src={img2} alt=""/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Сырой материал:
                        </h3>
                        <div className="flex_wrp">
                            <TextBlock
                                text={`Говядина
                            Ягненок
                            Кролик
                            Креветки
                            Тунец
                            Форель
                            Лосось
                            Утка`}
                            />
                        </div>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Функция:
                        </h3>
                        <div className="flex_wrp">
                            <TextBlock
                                text={`Источник животного белка и жира, незаменимых аминокислот, витаминов`}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex">
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Тип сырья:
                        </h3>
                        <div className="flex_wrp">
                            <TextBlock
                                text={`Жиры и масла`}
                            />
                            <img className="img" src={img3} alt=""/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Сырой материал:
                        </h3>
                        <div className="flex_wrp">
                            <TextBlock
                                text={`Подсолнечное масло
                            Оливковое масло
                            Рыбий жир
                            Животный жир`}
                            />
                        </div>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Функция:
                        </h3>
                        <div className="flex_wrp">
                            <TextBlock
                                text={`Источник энергии, жирных кислот, жирорастворимых витаминов`}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
