import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from "../../../image/lessons/lesson8/step23-1.png";

export default class step23 extends React.Component {

    render() {
        return (
            <div className="step23">
                <TextBlock
                    text={`Каждый день через пищеварительный тракт проходит большое количество жидкости, состоящей из питьевой воды и воды, содержащейся в корме. Примерно 95% воды всасывается в организм в тонком кишечнике.`}
                />
                <div className="flex">
                    <TextBlock
                        text={`Даже незначительное <b>уменьшение всасываемой</b> жидкости приводит к увеличению количества воды в тонкой кишке.
                        
                        Фактически, <b>небольшие изменения содержания воды в кале от 70%</b> (норма) до 80% (низкая плотность) становятся причиной возникновения диареи.`}
                    />
                    <img src={img1} alt="" className="flex_img"/>
                </div>
            </div>
        )
    }
}
