import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson15/step27-1.png'
import img1xs from '../../../image/lessons/lesson15/step27-1xs.png'


export default class step27 extends React.Component {


    render() {
        return (
            <div className="step27">
                <TextBlock
                    text={`Реализация источников роста позволит увеличить размер категории к 2020 году до 242 млрд рублей.`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} className="imgCenter" alt=""/>
                </picture>
            </div>
        )
    }
}
