import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson18/step12-1.png'
import img2 from '../../../image/lessons/lesson18/step12-2.png'

export default class step12 extends React.Component{

    render() {
        return (
            <div className="step12">
                <TextBlock
                    text={`Практикующие ветеринарные врачи в России подтверждают эффективность PEDIGREE<sup>®</sup> DENTASTIX™ на основании клинических исследований.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                    <div className="flex_el">
                        <img src={img2} alt="" className="flex_el_img"/>
                    </div>
                </div>
                <TextBlock
                    text={`<span class="text_dark-blue">95% врачей, принявших участие в исследовании, высоко оценили эффективность лакомства PEDIGREE<sup>®</sup> DENTASTIX™ и указали, что будут рекомендовать данный продукт в своей практике.</span>`}
                />
            </div>
        )
    }
}
