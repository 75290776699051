import React from 'react'

import img1 from '../../../image/lessons/lesson17/step3-1.png'

export default class step3 extends React.Component {


    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step3">
                <div className="block">
                    Популяция домашних животных в России огромна! У каждой 2й семьи есть домашний питомец...
                </div>
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
