import React from 'react'
import OnClickTest from "../../../Components/LessonAndTestComponents/OnClickTest";
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

export default class step12 extends React.Component {

    state = {
        answersData: [
            `35%`,
            `72%`,
            `98%`,
        ],
    }

    showNextBtn = () => {
        this.props.showNextBtn()
        this.setState({showImg: true})
    }

    render() {
        return (
            <div className="step12">
                <TextBlock
                    text={`Для россиян домашнее животное – это не просто питомец дома. Как Вы думаете, сколько процентов владельцев домашних животных называют себя «родителями» по отношению к питомцам?`}
                />
                <OnClickTest
                    ansewrsData={this.state.answersData}
                    type={'line'}
                    rightAnswers={[2]}
                    showNextBtn={this.showNextBtn}
                    correctText={`Кроме того, 72% владельцев называют себя «родителями» по отношению к питомцу, а 1 из 12 респондентов празднует день рождения питомца.`}
                />
            </div>
        )
    }
}
