import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson18/step10-1.png'
import img2 from '../../../image/lessons/lesson18/step10-2.png'

export default class step10 extends React.Component{

    render() {
        return (
            <div className="step10">
                <TextBlock
                    text={`Эффективность механической чистки зубов заключается <b>в особой форме и текстуре PEDIGREE<sup>®</sup> DENTASTIX™</b>, которая способствует чистке зубов и массажу дёсен, а также стимулирует выделение слюны и удаление с её помощью остатков пищи.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                    <div className="flex_el">
                        <img src={img2} alt="" className="flex_el_img"/>
                    </div>
                </div>
                <TextBlock
                    text={`<span class="text_dark-blue">Чтобы разгрызть косточку, собака должна приложить усилия, что помогает удалить налёт с зубов.</span>`}
                />
            </div>
        )
    }
}
