import React from 'react'

import img1 from '../../../image/lessons/lesson15/step11-1.png'
import img1xs from '../../../image/lessons/lesson15/step11-1xs.png'
import img2 from '../../../image/lessons/lesson15/step11-2.png'
import img2xs from '../../../image/lessons/lesson15/step11-2xs.png'
import img3 from '../../../image/lessons/lesson15/step11-3.png'
import img4 from '../../../image/lessons/lesson15/step7-4.png'


export default class step11 extends React.Component {

    render() {
        return (
            <div className="step11">
                <div className="block">
                    <div className="block_head">
                        1. Увеличивается количество собак <b>старше 7 лет.</b>
                    </div>
                    <div className="block_box">
                        <div className="block_box_el">
                            <picture>
                                <source srcSet={img1xs} media="(max-width: 767px)"/>
                                <img src={img1} alt="" className="block_box_el_img"/>
                            </picture>
                        </div>
                        <div className="block_box_el">
                            <div className="block_box_el_description">
                                <div className="block_box_el_point dark-blue"/>
                                <div className="block_box_el_text">возраст старше 9 лет</div>
                            </div>
                            <div className="block_box_el_description">
                                <div className="block_box_el_point blue"/>
                                <div className="block_box_el_text">возраст моложе 9 лет</div>
                            </div>
                            <div className="block_box_el_description">
                                <div className="block_box_el_point green">
                                    <img src={img4} alt="" className="block_box_el_point_img"/>
                                </div>
                                <div className="block_box_el_text">самый активный рост популяции</div>
                            </div>
                            <img src={img3} alt="" className="img"/>
                        </div>
                    </div>
                </div>
                <div className="block">
                    <div className="block_head">
                        2. Увеличивается количество <b>породистых/с сертификатами</b> собак.
                    </div>
                    <div className="block_box">
                        <div className="block_box_el">
                            <picture>
                                <source srcSet={img2xs} media="(max-width: 767px)"/>
                                <img src={img2} alt="" className="block_box_el_img"/>
                            </picture>
                        </div>
                        <div className="block_box_el">
                            <div className="block_box_el_description">
                                <div className="block_box_el_point dark-blue"/>
                                <div className="block_box_el_text">породист./с сертификатами</div>
                            </div>
                            <div className="block_box_el_description">
                                <div className="block_box_el_point blue"/>
                                <div className="block_box_el_text">другие животные</div>
                            </div>
                            <div className="block_box_el_description">
                                <div className="block_box_el_point green">
                                    <img src={img4} alt="" className="block_box_el_point_img"/>
                                </div>
                                <div className="block_box_el_text">самый активный рост популяции</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
