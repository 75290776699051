import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson9/step22-1.jpg'


export default class step22 extends React.Component {
    state = {
    };

    render() {
        return (
            <div className="step22">
                <div className="flex">
                    <div className="ul">
                        <div className="li">
                            <div className="number">1</div>
                            <div className="text">
                                Идеальный период для того, чтобы отучить щенка от материнского молока — <b>с 6 до 8 недели</b> жизни.
                            </div>
                        </div>
                        <div className="li">
                            <div className="number">2</div>
                            <div className="text">
                                Щенки нуждаются в <b>особом балансе питательных веществ</b>, отличном от взрослой собаки.
                            </div>
                        </div>
                        <div className="li">
                            <div className="number">3</div>
                            <div className="text">
                                Переводить щенка на другой рацион следует постепенно, <b>в течение 4–7 дней.</b>
                            </div>
                        </div>
                        <div className="li">
                            <div className="number">4</div>
                            <div className="text">
                                Для каждого периода жизни щенка требуется <b>особый режим кормления.</b>
                            </div>
                        </div>
                        <div className="li">
                            <div className="number">5</div>
                            <div className="text">
                                <b>Свободный доступ к корму не рекомендуется</b> для щенков во избежание переедания.
                            </div>
                        </div>
                    </div>
                    <img src={img1} alt=""/>
                </div>
                <TextBlock
                    text={`Далее вы узнаете о питании взрослых и стареющих собак.`}
                />
            </div>
        )
    }
}
