import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson17/step6-1.png'
import img2 from '../../../image/lessons/lesson17/step6-2.png'

export default class step6 extends React.Component {


    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step6">
                <TextBlock
                    text={`Бренды компании MARS <b>— лидеры на рынке России по объему продаж!</b>`}
                />
                <img src={img1} alt="" className="imgCenter"/>
                <TextBlock
                    text={`Другие бренды компании MARS также являются очень сильными и <b>занимают ведущие роли в своих сегментах рынка:</b>`}
                />
                <img src={img2} alt="" className="imgCenter"/>
                <div className="note">
                    Источник: Nielsen Retail Audit, Ноябрь 2021, Россия, Объём продаж в денежном выражении.
                </div>
            </div>
        )
    }
}
