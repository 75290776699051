import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson7/step32-1.png'
import img2 from '../../../image/lessons/lesson7/step32-2.png'


export default class step32 extends React.Component {


    render() {
        return (
            <div className="step32">
                <TextBlock
                    text={`Маленькие породы собак подвержены риску развития заболеваний мочевыводящих путей, так как их моча является достаточно концентрированной. Чтобы снизить уровень риска, необходимо соблюдать водный баланс.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                    <div className="flex_el">
                        <TextBlock
                            text={`Чем больше воды употребляет собака, тем больше объем мочи и тем ниже её концентрация.`}
                        />
                        <img src={img2} alt="" className="flex_el_img second"/>
                    </div>
                </div>
            </div>
        )
    }
}
