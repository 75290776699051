import React from 'react'
import Resume from "../../../Components/LessonAndTestComponents/Resume";

import img1 from "../../../image/lessons/lesson3/step8-1.png";

export default class step8 extends React.Component {
    state = {
        resumeData: [
            `После расщепления употребленного белка аминокислоты <b>соединяются в другом порядке</b> для формирования белков, необходимых организму.`,
            `Последовательность аминокислот в белке определяет его функции и специфические качества.`,
            `Избыточные аминокислоты <b>не откладываются</b> в организме для дальнейшего использования.`,
        ]
    }

    render() {
        return (
            <div className="step8">
                <Resume
                    resumeData={this.state.resumeData}
                    img={img1}
                />
            </div>
        )
    }
}
