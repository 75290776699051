import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';

export default class step1 extends React.Component {

    planData = [
        `Изменения пищевых потребностей кошек в период беременности и лактации`,
        `Кормление котят на различных стадиях роста`,
        `Особенности питания взрослой кошки`,
        `Особенности питания пожилой кошки`,
    ]

    render() {
        return (
            <div className="step1">
                <div className="head">План урока</div>

                <TextBlock
                    text={`Потребности кошек на разных стадиях жизни отличаются, поэтому им нужно питание, соответствующее их возрасту.
                    
                    В этом уроке изучаются следующие темы:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'blue'}
                />

            </div>
        )
    }
}
