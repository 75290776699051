import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson5/step5-1.png";
import img2 from "../../../image/lessons/lesson5/step5-2.png";

export default class step5 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит и избыток'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step5">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Витамин D (холекальциферол) — рост и минерализация костей.</b>
                           
                            У людей и травоядных животных витамин D синтезируется в коже из холестерина под действием солнечного света. Он накапливается в печени и постепенно расходуется.
                            
                            Этот процесс отсутствует у кошек и собак, поэтому получать витамин D они могут только из пищи.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Витамин D играет важную роль в регулировании метаболизма кальция и фосфора: он увеличивает кишечное всасывание обоих минералов, оптимизируя включение кальция в костную ткань и сокращая потерю кальция и фосфора с мочой.`}
                        />
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`В мясе и овощах практически нет витамина D. Основными источниками являются:`}
                        />
                        <div className="imgDiv_wrap_flex">
                            <ul className="list">
                                <li className="list_item">жирная рыба (сардины, тунец);</li>
                                <li className="list_item">печень.</li>
                            </ul>
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img2} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Дефицит витамина D может вызывать:`}
                        />
                        <ul className="list">
                            <li className="list_item">рахит (редко);</li>
                            <li className="list_item">потерю веса;</li>
                            <li className="list_item">размягчение костей.</li>
                        </ul>
                        <TextBlock
                            text={`Избыток имеет следующие последствия:`}
                        />
                        <ul className="list">
                            <li className="list_item">замедление формирования костной ткани;</li>
                            <li className="list_item">чрезмерная минерализация костей;</li>
                            <li className="list_item">у кошек — минеральные отложения в мягких тканях, гиперкальцемия, депрессия, заторможенность, рвота.</li>
                        </ul>
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
