import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson6/step8-1.png";
import img2 from "../../../image/lessons/lesson6/step8-2.png";
import img3 from "../../../image/lessons/lesson6/step8-3.png";

export default class step8 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит и избыток'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step8">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Магний (Mg) — здоровье костей, нормальная работа нервной системы.</b>
                           
                            В организме он является 2-м по распространенности внутриклеточным катионом (положительно заряженный ион) и участвует более чем в 300 процессах обмена веществ.
                            
                            Магний формирует около 2% земной коры.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_flex">
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img2} alt=""/>
                            </div>
                            <div className="imgDiv_wrap_flex_box">
                                <TextBlock
                                    text={`Магний участвует в:`}
                                />
                                <ul className="list">
                                    <li className="list_item">энергетическом обмене;</li>
                                    <li className="list_item">метаболизме ДНК и РНК;</li>
                                    <li className="list_item">синтезе протеина;</li>
                                    <li className="list_item">работе мышц и мембран нервных клеток;</li>
                                    <li className="list_item">наряду с кальцием является важным компонентом костей и зубов.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Магний содержится в костях:`}
                        />
                        <div className="imgDiv_wrap_flex">
                            <ul className="list">
                                <li className="list_item">млекопитающих;</li>
                                <li className="list_item">птиц;</li>
                                <li className="list_item">рыб.</li>
                            </ul>
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img3} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Дефицит</b> магния может привести к возникновению нервных патологий:`}
                        />
                        <ul className="list">
                            <li className="list_item">гиперэкстензии (чрезмерная растяжимость) суставов;</li>
                            <li className="list_item">параличу;</li>
                            <li className="list_item">гипертонии;</li>
                            <li className="list_item">потере аппетита.</li>
                        </ul>
                        <TextBlock
                            text={`<b>Избыток</b> магния в рационе кошек является причиной развития мочекаменной болезни.`}
                        />
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
