import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import StatisticsBlock from '../../../Components/LessonAndTestComponents/StatisticsBlock';

import img1 from "../../../image/lessons/lesson1/step12-1.png";
import img2 from "../../../image/lessons/lesson1/step12-2.png";
import img3 from "../../../image/lessons/lesson1/step12-3.png";
import img4 from "../../../image/lessons/lesson1/step12-4.png";
import img5 from "../../../image/lessons/lesson1/step12-5.png";
import img6 from "../../../image/lessons/lesson1/step12-6.png";

export default class step12 extends React.Component {


    render() {
        return (
            <div className="step12">
                <TextBlock
                    text={`В больших городах более популярны миниатюрные породы собак, в то время как в сельской местности распространены большие собаки.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <img className="img" src={img1} alt=""/>
                        <p className="flex_el_text">Миниатюрные</p>
                        <p className="flex_el_text">{'(<5 кг)'}</p>
                        <p className="flex_el_percent">15%</p>
                    </div>
                    <div className="flex_el">
                        <img className="img" src={img2} alt=""/>
                        <p className="flex_el_text">Маленькие</p>
                        <p className="flex_el_text">{'(5-10 кг)'}</p>
                        <p className="flex_el_percent">26%</p>
                    </div>
                    <div className="flex_el">
                        <img className="img" src={img3} alt=""/>
                        <p className="flex_el_text">Средние</p>
                        <p className="flex_el_text">{'(11-25 кг)'}</p>
                        <p className="flex_el_percent">32%</p>
                    </div>
                    <div className="flex_el">
                        <img className="img" src={img4} alt=""/>
                        <p className="flex_el_text">Большие</p>
                        <p className="flex_el_text">{'(25-40 кг)'}</p>
                        <p className="flex_el_percent">18%</p>
                    </div>
                    <div className="flex_el">
                        <img className="img" src={img5} alt=""/>
                        <p className="flex_el_text">Гигантские</p>
                        <p className="flex_el_text">{'(>40 кг)'}</p>
                        <p className="flex_el_percent">9%</p>
                    </div>
                </div>
                <StatisticsBlock
                    img={img6}
                    text={'Популяция маленьких и миниатюрных собак составляет <b>41%</b> от всего количества собак в России. В городах с населением 1 млн+ их число уже превышает 50%.'}
                />
            </div>
        )
    }
}
