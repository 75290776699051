import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'
import Answer from './dnd/Answer';
import Questions from './dnd/Questions';

export default class step18 extends React.Component {

    state = {
        // Ресайз
        windowWidth: window.innerWidth,

        // Работа десктопной версии
        answers: ['1', '6', '8', '3', '5', '7', '2', '4', '9'], //Массив правильных ответов
        correct: '',
        answerCorrect: [],
        answersSelectedDesktop: ['?', '?', '?', '?', '?', '?', '?', '?', '?'], // Массив выбираемых значений

        //Работа мобильной версии
        answerActive: 0,
        questionActive: 0,
        answersMobile: [],
        questionsMobile: [],
        answersSelected: ['?', '?', '?', '?', '?', '?', '?'],
        correctMobile: '',
        answerCorrectMobile: [],
        showPopup: false
    }

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    //Работа десктопной версии
    setAnswer = (answer_el, question_el) => {
        const {answersSelectedDesktop} = this.state

        answersSelectedDesktop.splice(answer_el - 1, 1, question_el)
        this.setState({answersSelectedDesktop})
    };

    checkAnswer = () => {
        const {answerCorrect, answersSelectedDesktop, answers} = this.state
        // eslint-disable-next-line
        answersSelectedDesktop.map((answer, index) => {
            if (index < 3) {
                if (answer === answers[0] || answer === answers[1] || answer === answers[2]) {
                    answerCorrect.push('correct')
                } else {
                    answerCorrect.push('inCorrect')
                }
            } else if (index < 6) {
                if (answer === answers[3] || answer === answers[4] || answer === answers[5]) {
                    answerCorrect.push('correct')
                } else {
                    answerCorrect.push('inCorrect')
                }
            } else if (index < 9) {
                if (answer === answers[6] || answer === answers[7] || answer === answers[8]) {
                    answerCorrect.push('correct')
                } else {
                    answerCorrect.push('inCorrect')
                }
            }
        })
        this.setState({
            answerCorrect: answerCorrect,
        })
        if (answersSelectedDesktop.indexOf('?') === -1 && answerCorrect.indexOf('inCorrect') === -1) {
            this.setState({correct: '1'})
        } else {
            this.setState({correct: '0'})
        }
        this.props.showNextBtn()
    };

    _renderTestDesktop = () => {
        const {answerCorrect, correct} = this.state
        return (
            <div className="test_desktop">
                <div className="flex">
                    <div className="flex_el">
                        <div className="answers">
                            <div className="answers_item green">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'1'} text={'?'} count={'?'} answerCorrect={answerCorrect[0]}/>
                            </div>
                            <div className="answers_item blue">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'2'} text={'1'} count={'?'} answerCorrect={answerCorrect[1]}/>
                            </div>
                            <div className="answers_item violet">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'3'} text={'2'} count={'?'} answerCorrect={answerCorrect[2]}/>
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="answers">
                            <div className="answers_item green">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'4'} text={'?'} count={'?'} answerCorrect={answerCorrect[3]}/>
                            </div>
                            <div className="answers_item blue">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'5'} text={'1'} count={'?'} answerCorrect={answerCorrect[4]}/>
                            </div>
                            <div className="answers_item violet">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'6'} text={'2'} count={'?'} answerCorrect={answerCorrect[5]}/>
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="answers">
                            <div className="answers_item green">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'7'} text={'?'} count={'?'} answerCorrect={answerCorrect[6]}/>
                            </div>
                            <div className="answers_item blue">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'8'} text={'1'} count={'?'} answerCorrect={answerCorrect[7]}/>
                            </div>
                            <div className="answers_item violet">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'9'} text={'2'} count={'?'} answerCorrect={answerCorrect[8]}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="questions">
                    <div className={'question_el'}>
                        <Questions targetKey="1" question_el={'1'} label={'Поддержка здорового роста и правильного развития'} text={'Поддержка здорового роста и правильного развития'}/>
                    </div>
                    <div className={'question_el pink'}>
                        <Questions targetKey="1" question_el={'2'} label={'Поддержка долгой и здоровой жизни'} text={'Поддержка долгой и здоровой жизни'}/>
                    </div>
                    <div className={'question_el purple'}>
                        <Questions targetKey="1" question_el={'3'} label={'Поддержка разнообразия вкусов и текстур'} text={'Поддержка разнообразия вкусов и текстур'}/>
                    </div>
                    <div className={'question_el pink'}>
                        <Questions targetKey="1" question_el={'4'} label={'Поддержка жизненной энергии'} text={'Поддержка жизненной энергии'}/>
                    </div>
                    <div className={'question_el purple'}>
                        <Questions targetKey="1" question_el={'5'} label={'Обеспечение оптимального баланса питательных веществ'} text={'Обеспечение оптимального баланса питательных веществ'}/>
                    </div>
                    <div className={'question_el'}>
                        <Questions targetKey="1" question_el={'6'} label={'Поддержка иммунной системы'} text={'Поддержка иммунной системы'}/>
                    </div>
                    <div className={'question_el purple'}>
                        <Questions targetKey="1" question_el={'7'} label={'Поддержка здоровья'} text={'Поддержка здоровья'}/>
                    </div>
                    <div className={'question_el'}>
                        <Questions targetKey="1" question_el={'8'} label={'Удобный размер и текстура корма'} text={'Удобный размер и текстура корма'}/>
                    </div>
                    <div className={'question_el pink'}>
                        <Questions targetKey="1" question_el={'9'} label={'Поддержка аппетита'} text={'Поддержка аппетита'}/>
                    </div>
                </div>
                {correct !== '' ?
                    <div className={'info ' + (correct === '0' && 'error')}>
                        <div className="info_head">
                            {correct === '1' ? 'все верно!' : 'К сожалению, ваш ответ не верен.'}
                        </div>
                        <div className="info_el">
                            <div className="info_el_number">1</div>
                            <div className="info_el_text">
                                Котятам до одного года важно поддерживать здоровый рост и иммунную систему, а также предоставлять корм удобного размера и текстуры.
                            </div>
                        </div>
                        <div className="info_el">
                            <div className="info_el_number">2</div>
                            <div className="info_el_text">
                                Взрослым кошкам необходимо поддерживать здоровье и обеспечивать оптимальный баланс питательных веществ, а также поддерживать разнообразие вкусов и текстур.
                            </div>
                        </div>
                        <div className="info_el">
                            <div className="info_el_number">3</div>
                            <div className="info_el_text">
                                Кошкам старше 7 лет важно поддержание жизненной энергии и аппетита для долгой здоровой жизни.
                            </div>
                        </div>
                    </div>
                    :
                    <button className="btn btn_blue" onClick={() => this.checkAnswer()}>
                        Узнать правильный ответ
                    </button>
                }
            </div>
        )
    }

    //Работа мобильной версии
    questions = [
        `Кальций`,
        `Таурин и L-карнитин`,
        `L-картинин`,
        `Витамин А и таурин`,
        `Омега-3, 6 жирные кислоты глюкозамин и хондроитин`,
        `Баланс минералов (Mg, Ca, P, Na, K)`,
        `Юкка Шидигера`,
    ]

    selectAnswer = (answerActive) => {
        document.body.scrollIntoView( true);
        document.body.style.overflow = "hidden";
        this.setState({
            showPopup: true,
            answerActive: answerActive
        })
    }

    selectQuestion = (questionActive) => {
        const {answerActive, answersMobile, questionsMobile, answersSelected} = this.state

        if (answersMobile.indexOf(answerActive) === -1) {
            answersMobile.push(answerActive)
        }
        this.setState({answersMobile})

        if (questionsMobile.indexOf(questionsMobile) === -1) {
            questionsMobile.push(questionActive)
        }
        this.setState({questionsMobile})

        answersSelected.splice(answerActive - 1, 1, this.questions[questionActive - 1])

        document.body.style.overflow = "";
        this.setState({
            showPopup: false,
            questionActive: questionActive,
            answersSelected: answersSelected
        })
    }

    checkAnswerMobile = () => {
        const {answerCorrectMobile, answersSelected} = this.state
        // eslint-disable-next-line
        answersSelected.map((answer, index) => {
            if (answer === this.questions[index]) {
                answerCorrectMobile.push('correct')
            } else {
                answerCorrectMobile.push('inCorrect')
            }
        })
        this.setState({
            answerCorrect: answerCorrectMobile,
        })
        if (answerCorrectMobile.indexOf('?') === -1 && answerCorrectMobile.indexOf('inCorrect') === -1) {
            this.setState({correctMobile: '1'})
        } else {
            this.setState({correctMobile: '0'})
        }
        this.props.showNextBtn()
    }

    closePopup = () => {
        document.body.style.overflow = "";
        this.setState({showPopup: false})
    }

    _renderTestMobile = () => {
        const {showPopup, answersMobile, questionsMobile, answersSelected, correctMobile, answerCorrectMobile} = this.state
        return (
            <div className="test_mobile">
                {correctMobile !== '' ?
                    <div className={'info ' + (correctMobile === '0' && 'error')}>
                        <div className="info_head">
                            {correctMobile === '1' ? 'все верно!' : 'К сожалению, ваш ответ не верен.'}
                        </div>
                    </div>
                    :
                    <button className="btn btn_blue" onClick={() => this.checkAnswerMobile()}>
                        Узнать правильный ответ
                    </button>
                }
                <div className="answers">
                    <div className="answers_box">
                        <div className="property">
                            Здоровые кости
                        </div>
                        <div
                            className={'answers_item ' + (answersMobile.indexOf(1) >=0 ? 'selected ' : '') + answerCorrectMobile[0]}
                            onClick={() => {this.selectAnswer(1)}}
                        >
                            {answersSelected[0]}
                        </div>
                    </div>
                    <div className="answers_box">
                        <div className="property">
                            Здоровое сердце
                        </div>
                        <div
                            className={'answers_item ' + (answersMobile.indexOf(2) >=0 ? 'selected ' : '') + answerCorrectMobile[1]}
                            onClick={() => {this.selectAnswer(2)}}
                        >
                            {answersSelected[1]}
                        </div>
                    </div>
                    <div className="answers_box">
                        <div className="property">
                            Контроль веса
                        </div>
                        <div
                            className={'answers_item ' + (answersMobile.indexOf(3) >=0 ? 'selected ' : '') + answerCorrectMobile[2]}
                            onClick={() => {this.selectAnswer(3)}}
                        >
                            {answersSelected[2]}
                        </div>
                    </div>
                    <div className="answers_box">
                        <div className="property">
                            Здоровое зрение
                        </div>
                        <div
                            className={'answers_item ' + (answersMobile.indexOf(4) >=0 ? 'selected ' : '') + answerCorrectMobile[3]}
                            onClick={() => {this.selectAnswer(4)}}
                        >
                            {answersSelected[3]}
                        </div>
                        <div className="answers_box">
                            <div className="property">
                                Здоровые суставы
                            </div>
                            <div
                                className={'answers_item ' + (answersMobile.indexOf(5) >=0 ? 'selected ' : '') + answerCorrectMobile[4]}
                                onClick={() => {this.selectAnswer(5)}}
                            >
                                {answersSelected[4]}
                            </div>
                        </div>
                        <div className="answers_box">
                            <div className="property">
                                Здоровье мочевыделительной системы
                            </div>
                            <div
                                className={'answers_item ' + (answersMobile.indexOf(6) >=0 ? 'selected ' : '') + answerCorrectMobile[5]}
                                onClick={() => {this.selectAnswer(6)}}
                            >
                                {answersSelected[5]}
                            </div>
                        </div>
                        <div className="answers_box">
                            <div className="property">
                                Устранение запаха туалета
                            </div>
                            <div
                                className={'answers_item ' + (answersMobile.indexOf(7) >=0 ? 'selected ' : '') + answerCorrectMobile[6]}
                                onClick={() => {this.selectAnswer(7)}}
                            >
                                {answersSelected[6]}
                            </div>
                        </div>
                    </div>
                    {/*{this.questions.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className={'answers_item ' + (answersMobile.indexOf(index + 1) >=0 ? 'selected ' : '') + answerCorrectMobile[index]}
                                onClick={() => {this.selectAnswer(index + 1)}}
                            >
                                {answersSelected[index]}
                            </div>
                        )
                    })

                    }*/}
                </div>
                {showPopup &&
                <div className="popup">
                    <div className="popup_wrp">
                        <div
                            className="popup_closed"
                            onClick={() => {this.closePopup()}}
                        />
                        <div className="popup_flex">
                            <div className="questions">
                                {this.questions.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={'questions_item ' + (questionsMobile.indexOf(index + 1) >=0 ? 'selected ' : '')}
                                            onClick={() => {this.selectQuestion(index + 1)}}
                                        >
                                            {item}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }

    render() {
        const {windowWidth} = this.state
        return (
            <div className="step18">
                <InfoBlock
                    text={`Вы изучили основные потребности питания кошек на разных стадиях жизни! Теперь Вам предстоит вспомнить изученный материал и перетащить соответствующие потребности в пустые ячейки.`}
                />
                {windowWidth >= 768 ?
                    <div className="test">
                        {this._renderTestDesktop()}
                    </div>
                    :
                    <div className="test">
                        {this._renderTestMobile()}
                    </div>
                }
            </div>
        )
    }
}
