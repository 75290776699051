import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step10 extends React.Component{

    state = {
        pointActive: 0,
        points: [],
    }

    showAnswer = (pointActive) => {
        const {points} = this.state;
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        this.setState({points: points, pointActive: pointActive})

        if (points.length === 8) {
            this.props.showNextBtn();
        }
    }

    render() {
        const {points} = this.state
        return (
            <div className="step10">
                <TextBlock
                    text={`Отвечаем на самые важные вопросы`}
                />
               <div className="block">
                   <div className={'block_el ' + (points.indexOf(1) >= 0 && 'active')}>
                       <div className="block_el_headline">
                           <div className="block_el_icon">?</div>
                           <div className="block_el_question">
                               Может ли мой питомец заразиться коронавирусом?
                           </div>
                           <button
                               className="block_el_btn"
                               onClick={() => {this.showAnswer(1)}}
                           />
                       </div>
                       <div className="block_el_description">
                           Исходя из текущей информации, это очень маловероятно. Исследования показывают, что собаки и кошки не так легко заражаются этим вирусом, и нет никаких доказательств того, что они могут передавать вирус людям или другим питомцам в домашних условиях.
                       </div>
                   </div>
                   <div className={'block_el ' + (points.indexOf(2) >= 0 && 'active')}>
                       <div className="block_el_headline">
                           <div className="block_el_icon">?</div>
                           <div className="block_el_question">
                               Стоит ли моему питомцу носить маску?
                           </div>
                           <button
                               className="block_el_btn"
                               onClick={() => {this.showAnswer(2)}}
                           />
                       </div>
                       <div className="block_el_description">
                           Нет никаких научных данных о том, что маски защищают домашних животных от инфекционных заболеваний или веществ, загрязняющих воздух. Кошачьи и собачьи морды различаются значительно сильнее, чем лица людей – маска для одного животного вряд ли подойдет остальным. Кроме того, домашним животным невозможно объяснить, зачем им на морду надевают то, что может их напугать или причинить неудобство.
                       </div>
                   </div>
                   <div className={'block_el ' + (points.indexOf(3) >= 0 && 'active')}>
                       <div className="block_el_headline">
                           <div className="block_el_icon">?</div>
                           <div className="block_el_question">
                               Существует ли риск для моего питомца заразиться через корма компании MARS?
                           </div>
                           <button
                               className="block_el_btn"
                               onClick={() => {this.showAnswer(3)}}
                           />
                       </div>
                       <div className="block_el_description">
                           Будьте уверены, что нынешняя вспышка коронавируса не представляет опасности для пищевых продуктов или питания для домашних животных – коронавирус человека COVID-19 не может передаваться ни через продукцию, ни через её упаковку. Кроме того, методы обработки, применяемые на производстве компании MARS, достаточны для уничтожения вируса.
                       </div>
                   </div>
                   <div className={'block_el ' + (points.indexOf(4) >= 0 && 'active')}>
                       <div className="block_el_headline">
                           <div className="block_el_icon">?</div>
                           <div className="block_el_question">
                               Может ли мой домашний питомец переносить вирус в своей шерсти?
                           </div>
                           <button
                               className="block_el_btn"
                               onClick={() => {this.showAnswer(4)}}
                           />
                       </div>
                       <div className="block_el_description">
                           Шерсть вашего питомца – такая же поверхность, как и остальное окружение человека. Поэтому важно соблюдать правила гигиены, тщательно мыть руки, ограничить общение животного с заболевшими и придерживаться карантина, если такой контакт уже произошел.
                       </div>
                   </div>
                   <div className={'block_el ' + (points.indexOf(5) >= 0 && 'active')}>
                       <div className="block_el_headline">
                           <div className="block_el_icon">?</div>
                           <div className="block_el_question">
                               Какой лучший способ защитить моего питомца от коронавируса?
                           </div>
                           <button
                               className="block_el_btn"
                               onClick={() => {this.showAnswer(5)}}
                           />
                       </div>
                       <div className="block_el_description">
                           В настоящее время исследования показывают, что очень маловероятно, что ваш питомец может заразиться COVID-19, и нет никаких доказательств того, что питомцы могут заражать других в домашних условиях. Мы продолжаем рекомендовать:<br/>
                           - тщательно мыть руки<br/>
                           - ограничить контакт животных с зараженными<br/>
                           - если домашний питомец уже находился в контакте с инфицированными людьми, необходимо выдерживать карантин
                       </div>
                   </div>
                   <div className={'block_el ' + (points.indexOf(6) >= 0 && 'active')}>
                       <div className="block_el_headline">
                           <div className="block_el_icon">?</div>
                           <div className="block_el_question">
                               Являются ли регионы, из которых привозят ингредиенты для кормов компании MARS, безопасными?
                           </div>
                           <button
                               className="block_el_btn"
                               onClick={() => {this.showAnswer(6)}}
                           />
                       </div>
                       <div className="block_el_description">
                           Мы поставляем ингредиенты из многих регионов мира и выбираем только высококачественную продукцию наших поставщиков. Такую, которая соответствуют требованиям местного законодательства и нашим высоким стандартам качества. Кроме того, заметим, что методы обработки, применяемые на производстве компании, достаточны для уничтожения вируса.
                       </div>
                   </div>
                   <div className={'block_el ' + (points.indexOf(7) >= 0 && 'active')}>
                       <div className="block_el_headline">
                           <div className="block_el_icon">?</div>
                           <div className="block_el_question">
                               Могу ли я продолжать выгуливать собаку?
                           </div>
                           <button
                               className="block_el_btn"
                               onClick={() => {this.showAnswer(7)}}
                           />
                       </div>
                       <div className="block_el_description">
                           Да. Выгуливайте любимца на поводке или шлейке, желательно без взаимодействия с другими людьми или домашними животными. Исключение составляют случаи, когда владелец находится в режиме самоизоляции/карантина - в этом случае следуйте предписаниям местных органов власти.
                       </div>
                   </div>
                   <div className={'block_el ' + (points.indexOf(8) >= 0 && 'active')}>
                       <div className="block_el_headline">
                           <div className="block_el_icon">?</div>
                           <div className="block_el_question">
                               Могу ли я продолжать заботиться о своём питомце, если я заболел?
                           </div>
                           <button
                               className="block_el_btn"
                               onClick={() => {this.showAnswer(8)}}
                           />
                       </div>
                       <div className="block_el_description">
                           По возможности попросите другого члена вашей семьи заботиться о ваших домашних питомцах, пока вы больны. Если вы должны ухаживать за своим питомцем самостоятельно или находиться рядом с животными во время болезни, мойте руки до и после контакта с ними.
                       </div>
                   </div>
               </div>
            </div>
        )
    }
}
