import React from 'react'

import img1 from '../../../image/lessons/lesson32/step40-1.png'
import img2 from '../../../image/lessons/lesson32/step40-2.png'
import img1xs from "../../../image/lessons/lesson32/step40-1xs.png";


export default class step6 extends React.Component {


    render() {
        return (
            <div className="step40">
                <div className="head">
                    3. Склонность к ожирению
                </div>
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} alt="" className="imgCenter"/>
                </picture>
                <div className="flex">
                    <img src={img2} alt="" className="left"/>
                    <ul>
                        <li>Влажные рационы содержат в 4-5 раз меньше калорий, чем сухие рационы.</li>
                        <li>Риск перекормить собаку влажным кормом значительно меньше благодаря его низкой энергетической плотности.</li>
                    </ul>
                </div>
            </div>
        )
    }
}
