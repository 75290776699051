import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson5/step15-1.png";
import img2 from "../../../image/lessons/lesson5/step15-2.png";

export default class step15 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит и избыток'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step15">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Витамин В9 (фолиевая кислота) — участвует в формировании тканей нервной системы.</b>
                           
                            Фолиевая кислота выполняет ряд важнейших функций, связанных с работой нервной системы.
                            
                            У собак незначительное количество фолиевой кислоты вырабатывается кишечными бактериями. Однако фолиевая кислота должна поступать и с пищей. Это же относится к кошкам.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Биотин участвует в следующих процессах:`}
                        />
                        <ul className="list">
                            <li className="list_item">формирование тканей нервной системы;</li>
                            <li className="list_item">синтез ДНК;</li>
                            <li className="list_item">предотвращение развития анемии;</li>
                            <li className="list_item">важен для быстрого размножения клеток;</li>
                            <li className="list_item">у беременных собак сокращает риск рождения щенков с волчьей пастью.</li>
                        </ul>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Главные источники фолиевой кислоты:`}
                        />
                        <div className="imgDiv_wrap_flex">
                            <ul className="list">
                                <li className="list_item">дрожжи;</li>
                                <li className="list_item">печень;</li>
                                <li className="list_item">зеленые овощи (например, шпинат).</li>
                            </ul>
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img2} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Дефицит фолиевой кислоты может вызывать врожденные пороки у плода, а также развитие анемии (малокровия).`}
                        />
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
