import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson21/step8-1.png";
import img2 from "../../../image/lessons/lesson21/step8-2.png";
import img3 from "../../../image/lessons/lesson21/step8-3.png";
import img4 from "../../../image/lessons/lesson21/step8-4.png";

export default class step8 extends React.Component {

    state = {
        flexData: [
            'Горький',
            'Соленый',
            'Кислый',
            'Сладкий',
        ],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step8">
                <TextBlock
                    text={`Посмотрите степень восприятия каждого вкуса кошкой.`}
                />
                <InfoBlock
                    text={`Нажмите на вкусы, чтобы узнать более подробную информацию.`}
                />
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_flex">
                            <img src={img1} alt="" className="imgDiv_wrap_flex_img"/>
                            <div className="imgDiv_wrap_flex_box">
                                <div className="imgDiv_wrap_flex_head">
                                    <b>Кошки чрезвычайно чувствительны к горькому вкусу</b>
                                </div>
                                <TextBlock
                                    text={`Присутствие в корме горьких компонентов быстро вызывает у кошек отвращение к еде. Кошки более чувствительны к горькому вкусу, чем собаки, и определяют его в более низкой концентрации. Такая способность помогает кошкам избежать отравления различными ядовитыми веществами, которые часто очень горькие на вкус.`}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_flex">
                            <img src={img2} alt="" className="imgDiv_wrap_flex_img"/>
                            <div className="imgDiv_wrap_flex_box">
                                <div className="imgDiv_wrap_flex_head">
                                    <b>Кошкам нравится солёный вкус.</b>
                                </div>
                                <TextBlock
                                    text={`Пища животного происхождения содержит оптимальный уровень хлорида натрия.
                                    
                                    Поэтому чувствительность к соли у кошек развита хуже, чем у всеядных животных. При этом к солёному вкусу кошки относятся положительно. Добавление соли в пищу способствует более активному потреблению, как самого корма, так и воды.`}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_flex">
                            <img src={img3} alt="" className="imgDiv_wrap_flex_img"/>
                            <div className="imgDiv_wrap_flex_box">
                                <div className="imgDiv_wrap_flex_head">
                                    <b>Кислый вкус особенно привлекателен для кошек.</b>
                                </div>
                                <TextBlock
                                    text={`Ярко выраженный кислый вкус очень привлекателен для кошек. Однако потребление корма с высоким содержанием кислот может привести к нарушению работы почек. И это обязательно необходимо учитывать.`}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_flex">
                            <img src={img4} alt="" className="imgDiv_wrap_flex_img"/>
                            <div className="imgDiv_wrap_flex_box">
                                <div className="imgDiv_wrap_flex_head">
                                    <b>Кошки не распознают сладкий вкус.</b>
                                </div>
                                <TextBlock
                                    text={`У кошек отсутствуют рецепторы сладкого вкуса. Они не реагируют не только на сахар, но и на заменители сахара. Однако низкая чувствительность к сладкому не является, по-видимому, проблемой для кошек, так как в природе они не нуждаются в потреблении фруктов в своём ежедневном рационе.`}
                                />
                            </div>
                        </div>
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
