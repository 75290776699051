import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson19/step3-1.png';

export default class step3 extends React.Component {
    state = {
    };

    render() {
        return (
            <div className="step3">
                <div className="box">
                    <div className="flex">
                        <div className="flex_el">
                            <div className="flex_el_box-img">
                                <img src={img1} alt="" className="flex_el_img"/>
                            </div>
                        </div>
                        <div className="flex_el">
                            <TextBlock
                                text={`<span class="text_dark-blue">WALTHAM™</span> изучает вопросы питания домашних животных <span class="text_dark-blue">с 1963 года.</span>
                            
                            <span class="text_dark-blue">Научный центр WALTHAM™</span> – мировой лидер в области ухода за домашними животными и их питания.
                            
                            Обширные научные знания <span class="text_dark-blue">WALTHAM™</span> легли в основу разработки рецептур <span class="text_dark-blue">PERFECT FIT™.</span>`}
                            />
                        </div>
                    </div>
                    <TextBlock
                        text={`За это время WALTHAM™ совершил ряд важных научных открытий, ценность которых очевидна и сегодня:`}
                    />
                    <div className="pin-box">
                        <div className="pin_el">
                            <div className="pin"><span className="pin_number">1</span></div>
                            <TextBlock
                                text={`В 1997 г. специалисты WALTHAM™ определили количество цинка и линолевой кислоты, необходимое для поддержания хорошего состояния кожи и шерсти собак`}
                            />
                        </div>
                        <div className="pin_el">
                            <div className="pin"><span className="pin_number">2</span></div>
                            <TextBlock
                                text={`Другой пример – в WALTHAM™ был открыт идеальный баланс макронутриентов в пищи для собак: около 60% суточной нормы калорий должно приходиться на жиры, 30% – на белки и 10% – на углеводы.`}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
