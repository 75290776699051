import React from 'react'
import './style.sass'

import logo from '../../image/entrance-logo.png'
import Inputs from '../../Components/Inputs'
import Checkbox from '../../Components/Checkbox'
import SiteFooter from '../../Components/SiteFooter'
import Api from '../../Service/Api'
import {withRouter} from 'react-router-dom';

class StartPage extends React.Component {

    state = {
        email: '',
        emailError: false,
        emailErrorText: false,
        password: '',
        passwordError: false,
        passwordErrorText: false,
        // passwordRepeat: '',
        // passwordRepeatError: false,
        agreement: false,
        agreementDoc: '',
        link: this.props.match.params.link,
        promotion: false,
    }

    Api = new Api()

    componentDidMount() {
        const {link} = this.state
        this.Api.getSignUpFree(link)
            .then(res => {
                this.setState({agreementDoc: res.agreement})
            })
    }

    updateState = (type, value, index) => {
        // eslint-disable-next-line
        switch (type) {
            case 'email' : {
                this.setState({
                    email: value,
                    emailError: false,
                    emailErrorText: false
                });
                break;
            }
            case 'password' : {
                this.setState({
                    password: value,
                    passwordError: false,
                    passwordErrorText: false
                })
                break;
            }
            /*case 'passwordRepeat' : {
                this.setState({
                    password: value,
                    passwordRepeatError: false,
                })
                break;
            }*/
        }
    }

    sendDate = () => {
        const {
            email,
            password,
            link
        } = this.state
        if (!this.valid_email(email)) {
            this.setState({emailError: true})
        } else if (!password.length) {
            this.setState({passwordError: true})
        } else {
            this.Api.signUpFree(email, password, link/*, passwordRepeat*/)
                .then((res) => {
                    if (res === true) {
                        this.props.changeType();
                    } else {
                        Object.keys(res.errors).map(key => {
                            // eslint-disable-next-line
                            this.state[key + 'Error'] = true;
                            // eslint-disable-next-line
                            this.state[key + 'ErrorText'] = res.errors[key];
                            this.setState({errors: new Date()})
                            return false
                        })
                    }
                })
        }
    }

    checkAgreement = () => {
        const {agreement} = this.state
        this.setState({
            agreement: !agreement
        })
    }

    checkPromotion = () => {
        const {promotion} = this.state
        this.setState({
            promotion: !promotion
        })
    }

    valid_email = (el) => {
        let reg = /^[a-z-._0-9]+@[a-z-_0-9]+\.[a-z0-9]{2,10}$/i;
        return reg.test(el)
    }

    render() {
        const {
            emailError,
            emailErrorText,
            passwordError,
            passwordErrorText,
            // passwordRepeatError,
            agreement,
            promotion,
            agreementDoc
        } = this.state
        return (
            <div className="Entrance">
                <div className="wrp">
                    <form action="" className="form">
                        <div className="logo">
                            <img src={logo} alt=""/>
                        </div>
                        <div className="head">
                            Приветствуем Вас! <br/>
                            Для регистрации в обучающей программе заполните, пожалуйста, поля:
                        </div>
                        <Inputs
                            name={'email'}
                            placeholder={'E-mail'}
                            onChange={this.updateState}
                            type={'email'}
                            className={emailError && 'error'}
                            errorText={emailErrorText}
                        />
                        <Inputs
                            name={'password'}
                            placeholder={'Придумайте пароль'}
                            onChange={this.updateState}
                            className={passwordError && 'error'}
                            type={'password'}
                            errorText={passwordErrorText}

                        />
                        {/*<Inputs
                            name={'passwordRepeat'}
                            placeholder={'Повторите пароль'}
                            onChange={this.updateState}
                            className={passwordRepeatError && 'error'}
                            type={'password'}
                            errorText={passwordErrorText}

                        />*/}
                        <Checkbox
                            active={agreement}
                            text={`Я подтверждаю, что мне исполнилось 18 лет, я ознакомился/ознакомилась с <a class="agreement_link" href=${agreementDoc} target="_blank" rel="noopener">пользовательским соглашением</a>, политикой конфиденциальности и политикой обработки персональных данных.`}
                            onClick={this.checkAgreement}
                        />
                        <Checkbox
                            active={promotion}
                            text={`Я согласен на получение рекламной рассылки (в т.ч. в виде смс, электронных писем и/или через месенджеры).`}
                            onClick={this.checkPromotion}
                        />
                        <div className="buttons">
                            <button
                                type='button'
                                className="btn btn_white"
                                onClick={() => {
                                    this.sendDate()
                                }}
                                disabled={agreement ? '' : 'disabled'}
                            >
                                Зарегистрироваться
                            </button>
                        </div>
                    </form>
                </div>
                <SiteFooter/>
            </div>
        )
    }

}

export default withRouter(StartPage);
