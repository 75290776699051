import React from 'react'
import './style.sass'

export default props => {
    return (
        <a href={props.link} className="LinkPreview" target="_blank">
            <img src={props.img} alt={props.alt} className="LinkPreview_img"/>
        </a>
    )
}
