import React from 'react'
import './style.sass'

import logo from '../../image/entrance-logo.png'
import Inputs from '../../Components/Inputs'
import SiteFooter from '../../Components/SiteFooter'
import {Link} from "react-router-dom";
import Api from '../../Service/Api'
import {withRouter} from 'react-router-dom';

class Auth extends React.Component {

    state = {
        email: '',
        emailError: false,
        emailErrorText: false,
        password: '',
        passwordError: false,
        passwordErrorText: false,
    }

    Api = new Api()

    componentDidMount() {
    }

    updateState = (type, value, index) => {
        // eslint-disable-next-line
        switch (type) {
            case 'email' : {
                this.setState({
                    email: value,
                    emailError: false,
                    emailErrorText: false
                });
                break;
            }
            case 'password' : {
                this.setState({
                    password: value,
                    passwordError: false,
                    passwordErrorText: false
                })
                break;
            }
        }
    }

    sendDate = () => {
        const {
            email,
            password,
        } = this.state
        if (!this.valid_email(email)) {
            this.setState({emailError: true})
        } else if (!password.length) {
            this.setState({passwordError: true})
        } else {
            this.Api.login(email, password)
                .then((res) => {
                    if (res.status === 200) {
                        this.props.changeType();
                    } else {
                        Object.keys(res.error).map(key => {
                            // eslint-disable-next-line
                            this.state[key + 'Error'] = true;
                            // eslint-disable-next-line
                            this.state[key + 'ErrorText'] = res.error[key];
                            this.setState({errors: new Date()})
                            // if (key === 'password') {
                            //     this.setState({
                            //         passwordError: true,
                            //         passwordErrorText: res.error[key],
                            //     })
                            // }
                            return false
                        })
                    }
                })
        }
    }

    valid_email = (el) => {
        let reg = /^[a-z-._0-9]+@[a-z-_0-9]+\.[a-z0-9]{2,10}$/i;
        return reg.test(el)
    }

    render() {
        const {
            emailError,
            emailErrorText,
            passwordError,
            passwordErrorText,
        } = this.state
        return (
            <div className="Entrance">
                <div className="wrp">
                    <form action="" className="form">
                        <div className="logo">
                            <img src={logo} alt=""/>
                        </div>
                        <div className="head">
                            Приветствуем Вас! <br/>
                            Для входа в обучающую программу заполните, пожалуйста, поля:
                        </div>
                        <Inputs
                            name={'email'}
                            placeholder={'E-mail'}
                            onChange={this.updateState}
                            type={'email'}
                            className={emailError && 'error'}
                            errorText={emailErrorText}
                        />
                        <Inputs
                            name={'password'}
                            placeholder={'Пароль'}
                            onChange={this.updateState}
                            className={passwordError && 'error'}
                            type={'password'}
                            errorText={passwordErrorText}

                        />
                        <div className="forgot">
                            <Link
                                to={'/startPage/forgot'}
                                onClick={this.props.changePage}
                            >
                                Забыли пароль?
                            </Link>
                        </div>
                        <div className="buttons">
                            <button
                                type='button'
                                className="btn btn_white"
                                onClick={() => {
                                    this.sendDate()
                                }}
                            >
                                Войти
                            </button>
                        </div>
                    </form>
                </div>
                <SiteFooter/>
            </div>
        )
    }

}

export default withRouter(Auth);
