import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson18/step3-1.png'
import img2 from '../../../image/lessons/lesson18/step3-2.png'
import img3 from '../../../image/lessons/lesson18/step3-3.png'


export default class step3 extends React.Component {

    render() {
        return (
            <div className="step3">
                <TextBlock
                    text={`Категория лакомств для собак представлена <b>различными видами</b> лакомств, предназначенных <b>для решения различных задач</b>.

                    Так, существуют следующие <span class="text_dark-blue">виды лакомств PEDIGREE<sup>®</sup>:</span>`}
                />
                <div className="flex">
                    <div className="flex_el left">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                    <div className="flex_el right">
                        <img src={img2} alt="" className="flex_el_img"/>
                    </div>
                    <div className="flex_el bottom">
                        <img src={img3} alt="" className="flex_el_img"/>
                    </div>
                </div>
            </div>
        )
    }
}
