import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson7/step30-1.png";
import img2 from "../../../image/lessons/lesson7/step30-2.png";
import img3 from "../../../image/lessons/lesson7/step30-3.png";
import img4 from "../../../image/lessons/lesson7/step30-4.png";

export default class step30 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит и избыток', 'Потребление'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step30">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Вода — самый жизненно необходимый элемент для организма.</b>
                           
                            Вода является одним из основных элементов организма животного (75% при рождении и 60% в зрелом возрасте). Она является самым важным компонентом питания и играет ключевую роль во всех основных физиологических процессах.

                            Считается, что кошки мало пьют, потому что они произошли от животных, обитавших в пустыне, и что они умеют концентрировать свою мочу. Однако, если концентрация мочи становится слишком высокой, повышается риск образования кристаллов в мочевыводящих путях и развития мочекаменной болезни.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                        <div className="imgDiv_wrap_text-blue">
                            У кошек и собак должен быть постоянный свободный доступ к питьевой воде.
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_text-blue">
                            Вода выполняет ряд жизненно важных функций в организме животных:
                        </div>
                        <ul className="list">
                            <li className="list_item">образует среду для транспортировки компонентов питания и выведения отходов жизнедеятельности из организма;</li>
                            <li className="list_item">необходима для всех процессов обмена веществ в организме;</li>
                            <li className="list_item">отвечает за регуляцию температуры тела;</li>
                            <li className="list_item">является смазкой суставных поверхностей, слизистой оболочки глаз и внутреннего уха.</li>
                        </ul>
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img2} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_text-blue">
                            Существует три источника воды:
                        </div>
                        <ul className="list">
                            <li className="list_item">жидкости (например, вода в миске);</li>
                            <li className="list_item">пища (в сухих кормах содержится до 10% воды, а во влажных — около 80%);</li>
                            <li className="list_item">побочный продукт обмена веществ (производство воды внутри организма).</li>
                        </ul>
                        <TextBlock
                            text={`Собаки и кошки, питающиеся влажным кормом, получают часть воды из корма, а потому пьют меньше, чем животные, питающиеся только сухим кормом.`}
                        />
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Свободный доступ к чистой питьевой воде помогает избежать обезвоживания, признаками которого являются:`}
                        />
                        <ul className="list">
                            <li className="list_item">сухая и неэластичная кожа;</li>
                            <li className="list_item">учащенное сердцебиение;</li>
                            <li className="list_item">повышенная температура тела.</li>
                        </ul>
                        <TextBlock
                            text={`Потеря организмом более 10% воды может привести к серьезным последствиям для здоровья.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img3} alt=""/>
                        </div>
                        <TextBlock
                            text={`<b>Повышенное потребление воды может быть признаком сахарного диабета или заболевания почек.</b>`}
                        />
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Потребность животного в воде зависит от его веса:`}
                        />
                        <ul className="list">
                            <li className="list_item">суточная норма <span className="imgDiv_wrap_text-blue">кошки — 50 мл/кг;</span></li>
                            <li className="list_item">суточная норма <span className="imgDiv_wrap_text-blue">собаки — 60 мл/кг.</span></li>
                        </ul>
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img4} alt=""/>
                        </div>
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
