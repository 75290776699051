import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson1/step17-1.png';
import img2 from '../../../image/lessons/lesson1/step17-2.png';
import img3 from '../../../image/lessons/lesson1/step17-3.png';
import img4 from '../../../image/lessons/lesson1/step17-4.png';
import img5 from '../../../image/lessons/lesson1/step17-5.png';
import img6 from '../../../image/lessons/lesson1/step17-6.png';

export default class step17 extends React.Component {

    render() {
        return (
            <div className="step17">
                <TextBlock
                    text={`Среди 10 наиболее популярных пород в России встречаются и большие, и миниатюрные собаки. Большие собаки в основном служат помощниками человека в охране дома, сельскохозяйственных работах и даже в службах безопасности.`}
                />
                <div className="head">
                    Топ-5 самых популярных пород собак
                </div>
                <div className="wrap">
                    <div className="rating height">
                        <img className="rating_img" src={img1} alt=""/>
                        <p className="rating_text">
                            Немецкая овчарка
                        </p>
                        {/*<p className="rating_percent">*/}
                        {/*    6%*/}
                        {/*</p>*/}
                    </div>
                </div>
                <div className="flex">
                    <div className="flex_el">
                        <div className="rating middle">
                            <img className="rating_img" src={img3} alt=""/>
                            <p className="rating_text">
                                Йоркширский терьер
                            </p>
                            {/*<p className="rating_percent">*/}
                            {/*    3%*/}
                            {/*</p>*/}
                        </div>
                        <div className="rating middle">
                            <img className="rating_img" src={img4} alt=""/>
                            <p className="rating_text">
                                Чихуахуа
                            </p>
                            {/*<p className="rating_percent">*/}
                            {/*    2%*/}
                            {/*</p>*/}
                        </div>
                        <div className="rating low">
                            <img className="rating_img" src={img2} alt=""/>
                            <p className="rating_text">
                                Той-терьер
                            </p>
                            {/*<p className="rating_percent">*/}
                            {/*    3%*/}
                            {/*</p>*/}
                        </div>
                        <div className="rating low">
                            <img className="rating_img" src={img5} alt=""/>
                            <p className="rating_text">
                                Среднеазиатская овчарка (алабай)
                            </p>
                            {/*<p className="rating_percent">*/}
                            {/*    2%*/}
                            {/*</p>*/}
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_block">
                            <p className="flex_el_text">
                                <b>46% всех собак в России составляют беспородные собаки.</b>
                            </p>
                            <p className="flex_el_text">
                                Они очень умные и жизнеспособные, и, как и остальные собаки, являются хорошими друзьями людей.
                            </p>
                            <img src={img6} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
