import React from 'react'
import './style.sass'

export default class PlanInFirstStep extends React.Component {

    render() {
        return (
            <div className="PlanInFirstStep">
                {this.props.planData.map((item, index) => {
                    return (
                        <div
                            key={index}
                            className={'PlanInFirstStep_el ' + this.props.type}
                            dangerouslySetInnerHTML={{ __html: item}}
                        />
                    )
                })}
            </div>
        )
    }
}
