import React from 'react'
import Resume from "../../../Components/LessonAndTestComponents/Resume";

import img1 from "../../../image/lessons/lesson1/step9-1.png";

export default class step5 extends React.Component {

    state = {
        resumeData: [
            `Численность кошек в России составляет <b>40,8 млн.</b>`,
            `<b>В городах около половины кошек стерилизованы,</b> а в сельской местности их количество невелико и составляет лишь 16%.`,
            `<b>В городах домашние кошки не гуляют самостоятельно</b> и ведут малоактивный образ жизни.`,
            `Породы кошек классифицированы <b>в 4 большие группы.</b>`,
        ]
    }

    render() {
        return (
            <div className="step9">
                <Resume
                    resumeData={this.state.resumeData}
                    img={img1}
                />
            </div>
        )
    }
}
