import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson6/step16-1.png";
import img2 from "../../../image/lessons/lesson6/step16-2.png";
import img3 from "../../../image/lessons/lesson6/step16-3.png";

export default class step16 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит и избыток'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step16">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Селен — сокращение клеточных повреждений, иммунная реакция.</b>
                           
                            Является жизненно важным микроэлементом. Впервые он был классифицирован в 1817 году.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_flex">
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img2} alt=""/>
                            </div>
                            <div className="imgDiv_wrap_flex_box">
                                <TextBlock
                                    text={`<b>Функции селена:</b>`}
                                />
                                <ul className="list">
                                    <li className="list_item">играет жизненно важную роль в сокращении клеточных повреждений, вызванных воздействием свободных радикалов;</li>
                                    <li className="list_item">выполняет вспомогательную функцию в иммунных реакциях.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Селен содержится в неорганических минеральных солях и рыбе. В небольших количествах содержится в мясе и субпродуктах (печени и почках).`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img3} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Дефицит</b> селена может привести к:`}
                        />
                        <ul className="list">
                            <li className="list_item">отказу от пищи;</li>
                            <li className="list_item">угнетенному состоянию;</li>
                            <li className="list_item">затрудненному дыханию;</li>
                            <li className="list_item">коме.</li>
                        </ul>
                        <TextBlock
                            text={`<b>Избыток</b> селена может наблюдаться при использовании рациона с повышенным содержанием рыбы. К симптомам относятся отказ от пищи и снижение веса.`}
                        />
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
