import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";
import OnClickTest from "../../../Components/LessonAndTestComponents/OnClickTest";

import img1 from "../../../image/lessons/lesson1/step6-1.png";
import img2 from "../../../image/lessons/lesson1/step6-2.png";
import img3 from "../../../image/lessons/lesson1/step6-3.png";



export default class step6 extends React.Component {

    state = {
        answersData: {
            1: {
                img: img1,
                text: `Среднеевропейская лесная кошка`
            },
            2: {
                img: img2,
                text: `Азиатская дикая кошка`
            },
            3: {
                img: img3,
                text: `Африканская степная кошка`
            },
        }

    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step6">
                <TextBlock
                    text={`Как и собаки, кошки имеют общего предка в дикой природе. Знаете ли вы, от какой дикой кошки произошла современная домашняя кошка?`}
                />
                <InfoBlock
                    text={`Выберите вариант ответа и нажмите «Подтвердить».`}
                />
                <OnClickTest
                    ansewrsData={this.state.answersData}
                    type={'img'}
                    rightAnswers={[3]}
                    showNextBtn={this.showNextBtn}
                    correctText={`Домашние кошки произошли от африканской степной кошки и были одомашнены около 9500 лет назад.`}
                />
            </div>
        )
    }
}
