import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson21/step15-1.png";
import img2 from "../../../image/lessons/lesson21/step15-2.png";
import img3 from "../../../image/lessons/lesson21/step15-3.png";
import img4 from "../../../image/lessons/lesson21/step15-4.png";
import img5 from "../../../image/lessons/lesson21/step15-5.png";
import img6 from "../../../image/lessons/lesson21/step15-6.png";
import img7 from "../../../image/lessons/lesson21/step15-7.png";

export default class step15 extends React.Component {

    state = {
        flexData: [
            'Белки',
            'Углеводы',
            'Аргинин',
            'Витамин А',
            'Таурин',
            'Витамин В3',
            'Жиры',
        ],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step15">
                <TextBlock
                    text={`В отличие от других животных, в том числе и собак, целый ряд жизненно важных веществ кошки могут получать исключительно из продуктов животного происхождения.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <OnClickBlock
                            flexData={flexData}
                            hideElement={this.hideElement}
                            showNextBtn={this.showNextBtn}
                        >
                            <div className="imgDiv_wrap">
                                <div className="info">
                                    <TextBlock
                                        text={`Потребности кошки в белке выше, чем у собаки или человека. Это связано с неспособностью к регулированию процесса распада белков даже при потреблении низкобелкового рациона. Поэтому кошки нуждаются в рационе с высоким общим объёмом белка.`}
                                    />
                                    <div className="info_flex">
                                        <div className="info_flex_el">
                                            <img src={img2} alt="" className="info_flex_img"/>
                                        </div>
                                        <div className="info_flex_el">
                                            <div className="info_head">Источники:</div>
                                            <div className="info_flex_text">
                                                Продукты животного происхождения (мясо, субпродукты, рыба).
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="imgDiv_wrap">
                                <div className="info">
                                    <TextBlock
                                        text={`Кошки обладают меньшей по сравнению с собаками способностью переваривать углеводы вследствие низкой активности амилазы в тонком кишечнике. Однако это не означает, что кошка может обходиться совсем без углеводов.`}
                                    />
                                    <div className="info_head">Функции:</div>
                                    <TextBlock
                                        text={`Важным компонентом рациона кошки является клетчатка, необходимая для обеспечения нормального процесса пищеварения.`}
                                    />
                                    <div className="info_head">Дефицит и избыток:</div>
                                    <TextBlock
                                        text={`Использование исключительно мясного рациона чревато для кошки нарушением работы пищеварительного тракта.`}
                                    />
                                </div>
                            </div>
                            <div className="imgDiv_wrap">
                                <div className="info">
                                    <TextBlock
                                        text={`Аргинин — аминокислота, наличие которой чрезвычайно важно в рационе кошек. Кошки зависят от наличия в рационе аминокислоты аргинина.`}
                                    />
                                    <div className="info_head">Функции:</div>
                                    <TextBlock
                                        text={`Аммиак образуется в результате распада белков, поэтому чем выше содержание белка в рационе, тем выше потребность кошки в аргинине, ведь он необходим дли синтеза мочевины из аммиака, а также способствует регулированию тонуса кровеносных сосудов и выработке некоторых гормонов.`}
                                    />
                                    <div className="info_flex">
                                        <div className="info_flex_el">
                                            <img src={img3} alt="" className="info_flex_img"/>
                                        </div>
                                        <div className="info_flex_el">
                                            <div className="info_head">Источники:</div>
                                            <div className="info_flex_text">
                                                Содержится аргинин в продуктах животного происхождения: мясе и субпродуктах.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="info_head">Дефицит и избыток:</div>
                                    <TextBlock
                                        text={`Недостаток аргинина в их организме очень скоро приводит к появлению клинических симптомов интоксикации аммиаком: рвоты, повышенного слюноотделения и нарушения со стороны нервной системы. Дефицит аргинина может привести к летальному исходу в течение нескольких часов.`}
                                    />
                                </div>
                            </div>
                            <div className="imgDiv_wrap">
                                <div className="info">
                                    <TextBlock
                                        text={`Провитамином А является бета-каротин, который содержится в растительных продуктах. Его молекула состоит по существу из двух соединённых вместе витамин-А-подобных молекул, которые организм животных способен превращать в две молекулы активного витамина А.`}
                                    />
                                    <div className="info_definition">
                                        Доказано, что организм кошки не может превращать бета-каротин в витамин А, поэтому кошки должны получать с пищей готовые источники витамина А, которыми являются только продукты животного происхождения, в растениях готового витамина А нет.
                                    </div>
                                    <div className="info_flex">
                                        <div className="info_flex_el">
                                            <img src={img4} alt="" className="info_flex_img"/>
                                        </div>
                                        <div className="info_flex_el">
                                            <div className="info_head">Источники:</div>
                                            <div className="info_flex_text">
                                                Хорошими источниками витамина А являются печень, рыба и яйца.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="info_head">Дефицит и избыток:</div>
                                    <TextBlock
                                        text={`Дефицит витамина А может привести к нарушению зрения, сухости кожи, репродуктивным нарушениям, лёгочной патологии и повышенной восприимчивости к инфекциям. Но помните: опасен не только недостаток витамина А в рационе кошки, но и его избыток, поэтому количество этого вещества в рационе должно строго контролироваться.`}
                                    />
                                </div>
                            </div>
                            <div className="imgDiv_wrap">
                                <div className="info">
                                    <TextBlock
                                        text={`Таурин — аминокислота, содержащаяся в большинстве животных тканей и являющаяся ещё одним жизненно необходимым элементом рациона кошек. В их организме, в отличие от собак, таурин не синтезируется, поэтому они нуждаются в постоянном поступлении этой аминокислоты с пищей.`}
                                    />
                                    <div className="info_head">Функции:</div>
                                    <TextBlock
                                        text={`Таурин необходим кошкам для нормальной работы сердца, хорошего зрения и слуха, кроме того, он является важным антиоксидантом и обеспечивает защиту организма от негативных факторов внешней среды.`}
                                    />
                                    <div className="info_flex">
                                        <div className="info_flex_el">
                                            <img src={img5} alt="" className="info_flex_img"/>
                                        </div>
                                        <div className="info_flex_el">
                                            <div className="info_head">Источники:</div>
                                            <div className="info_flex_text">
                                                Таурин содержится лишь в продуктах животного происхождения. Основными его источниками являются субпродукты (например, сердце, почки, печень).
                                            </div>
                                        </div>
                                    </div>
                                    <div className="info_head">Дефицит и избыток:</div>
                                    <TextBlock
                                        text={`Дефицит таурина может привести к дегенерации сетчатки у кошек и последующей слепоте, развитию заболеваний сердца, неадекватной иммунной реакции, замедленному росту и нарушению репродуктивной функции, включая рождение нежизнеспособного потомства или врождённые пороки развития`}
                                    />
                                </div>
                            </div>
                            <div className="imgDiv_wrap">
                                <div className="info">
                                    <TextBlock
                                        text={`Кошки способны синтезировать ниацин очень ограниченно, потому его также необходимо включать в их рацион.`}
                                    />
                                    <div className="info_head">Функции:</div>
                                    <TextBlock
                                        text={`Наряду с другими витаминами группы В ниацин помогает защищать кожу, способствуя синтезу кожного жира, особенно церамидов, чтобы ограничить обезвоживание кожи.`}
                                    />
                                    <div className="info_flex">
                                        <div className="info_flex_el">
                                            <img src={img6} alt="" className="info_flex_img"/>
                                        </div>
                                        <div className="info_flex_el">
                                            <div className="info_head">Источники:</div>
                                            <div className="info_flex_text">
                                                Ниацин содержится во многих продуктах, его много в мясе, рыбе и злаках.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="imgDiv_wrap">
                                <div className="info">
                                    <TextBlock
                                        text={`Особое значение для кошек играет наличие в рационе животных жиров, которые содержат арахидоновую жирную кислоту, относящуюся к группе омега-6 жирных кислот. Кошки не способны её синтезировать и должны получать с рационом.`}
                                    />
                                    <div className="info_flex">
                                        <div className="info_flex_el">
                                            <img src={img7} alt="" className="info_flex_img"/>
                                        </div>
                                        <div className="info_flex_el">
                                            <div className="info_head">Источники:</div>
                                            <div className="info_flex_text">
                                                Арахидоновая кислота содержится в животных жирах, например, в говяжьем жире и подкожном жире домашней птицы.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="info_head">Дефицит и избыток:</div>
                                    <TextBlock
                                        text={`Важно придерживаться разумного сочетания животных и растительных жиров в её рационе. Дефицит омега-6 жирных кислот может привести к нарушению репродуктивной функции, ухудшению состояния кожи и шерсти — сухости, раздражению и шелушению кожи, тусклости шёрстного покрова.`}
                                    />
                                </div>
                            </div>
                        </OnClickBlock>
                    </div>
                    <div className="flex_el">
                        <InfoBlock
                            text={`Нажмите на ключевые элементы, чтобы больше узнать о них.`}
                        />
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                </div>
            </div>
        )
    }
}
