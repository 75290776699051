import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import SwipeBlock from '../../../Components/LessonAndTestComponents/SwipeBlock';
import Slider from "react-slick";
import img1 from '../../../image/lessons/lesson9/step20-1.jpg';
import img2 from '../../../image/lessons/lesson9/step20-2.png';
import img3 from '../../../image/lessons/lesson9/step20-3.png';
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";

export default class step20 extends React.Component {
    state = {
        popupOpen: false
    };


    changeSlide = (next) => {
        if(next === 2){
            this.props.showNextBtn();
        }
    }

    openPopup = () => {
        const {popupOpen} = this.state
        this.setState({popupOpen: !popupOpen})
    }

    slider = {
        1: {
            head: `Шаг 1 из 3`,
            text: `На <b>3-й неделе</b>  жизни щенок начинает интересоваться едой своей матери. А после <b>4-й недели</b> питательные свойства материнского молока уже не удовлетворяют потребности щенка в полном объеме. В этот период нужно начинать давать щенку <b>специальный корм в небольших количествах,</b> чтобы организм адаптировался к твердой пище.
            
            Пример специализированного корма: PEDIGREE<sup>®</sup> «Первый прикорм», для щенков от 3-х недель.`,
            img: img1,
            popup: 'Данный рацион доступен только для профессиональных заводчиков.'
        },
        2: {
            head: `Шаг 2 из 3`,
            text: `<b>В двухмесячном возрасте</b> щенков следует переводить на новый рацион и кормить <b>каждые 4 часа небольшими порциями,</b> которые должны быть обязательно рассчитаны, исходя из размеров щенка. Также <b>рекомендуется сочетать сухой и влажный корм</b> в течение дня, чтобы щенок привык к разным текстурам и вкусам
            
            Примером рациона для данного периода является Pedigree<sup>®</sup> для щенков.`,
            img: img2
        },
        3: {
            head: `Шаг 3 из 3`,
            text: `После <b>4-х месяцев</b> щенка стоит постепенно переводить на стандартный режим кормления. До 6 месяцев рекомендуется кормить его два раза в день, а затем <b>полностью</b> перевести на режим питания взрослых собак (1-2 раза в день). 
            
            Щенки крупных пород растут дольше, чем щенки мелких пород. Кроме того, они имеют ряд особенностей роста и развития. Поэтому с <b>6 месяцев и до 2 лет</b> их рекомендуется кормить специальным кормом. Для этого подходит Pedigree<sup>®</sup> для щенков всех пород.`,
            img: img3
        },
    }

    render() {

        const {popupOpen} = this.state

        let settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: false,
            beforeChange: (current, next) => {
                this.changeSlide(next)
            },
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        dots: true,
                        arrows: false,
                        adaptiveHeight: true,
                    }
                },
            ]
        };
        return (
            <div className="step20">
                <TextBlock
                    text={`Щенки нуждаются в большом количестве питательных веществ, поэтому на каждой стадии их жизни им необходим специализированный корм.`}
                />
                <InfoBlock
                    text={
                        `Изучите рекомендации по подбору рациона для щенка. Используйте стрелки для перемещения по шагам.`
                    }
                />
                <SwipeBlock
                    text={
                        `Используйте свайп для перемещения по шагам.`
                    }
                />

                <div className={"slider slider_noArrow show"}>
                    <Slider {...settings}>
                        {Object.values(this.slider).map(item => {
                            return (
                                <div
                                    key={item.img}
                                    className="slider_el"

                                >
                                    <div className="imgMobile">
                                        <img src={item.img} alt=""/>
                                    </div>
                                    <div className="head">
                                        {item.head}
                                    </div>
                                    <div className="slider_flex">
                                        <TextBlock text={item.text}/>
                                        <img src={item.img} alt=""/>
                                        {item.popup &&
                                        <span className="slider_popup" onClick={this.openPopup}>?</span>
                                        }
                                    </div>
                                    {item.text2 &&
                                    <TextBlock text={item.text2}/>
                                    }
                                </div>
                            )
                        })}
                    </Slider>
                </div>
                {popupOpen &&
                    <PopupInStep
                        popupText={`Данный рацион доступен только для профессиональных заводчиков.`}
                        closePopup={this.openPopup}
                        changePoint={this.openPopup}
                    />
                }
            </div>
        )
    }
}
