import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import DefinitionBlock from '../../../Components/LessonAndTestComponents/DefinitionBlock';

import img1 from "../../../image/lessons/lesson20/step14-1.png";
import img2 from "../../../image/lessons/lesson20/step14-2.png";
import img3 from "../../../image/lessons/lesson20/step14-3.png";
import img4 from "../../../image/lessons/lesson20/step14-4.png";
import img5 from "../../../image/lessons/lesson20/step14-5.png";
import img6 from "../../../image/lessons/lesson20/step14-6.png";


export default class step14 extends React.Component {

    render() {
        return (
            <div className="step14">
                <div className="block">
                    <DefinitionBlock
                        text={`<span class="text_pink">Стерилизация</span> — один из элементов ответственного содержания домашних кошек.`}
                    />
                    <img src={img5} alt="" className="block_img"/>
                </div>
                <div className="flex">
                    <div className="flex_el">
                        <TextBlock
                            text={`<b>Стерилизация несет в себе ряд преимуществ для здоровья кошки:</b>`}
                        />
                        <div className="flex_el_li">
                            <img src={img1} alt="" className="flex_el_li_img"/>
                            <div className="flex_el_li_text">
                                Стерилизованные кошки становятся <span className="flex_el_li_pink">более спокойными и дружелюбными</span>;
                            </div>
                        </div>
                        <div className="flex_el_li">
                            <img src={img2} alt="" className="flex_el_li_img"/>
                            <div className="flex_el_li_text">
                                Стерилизованные кошки живут дольше, у них значительно <span className="flex_el_li_pink">снижается риск развития ряда заболеваний</span>.
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <TextBlock
                            text={`<b>Особенности здоровья стерилизованных кошек:</b>`}
                        />
                        <div className="flex_el_li">
                            <img src={img3} alt="" className="flex_el_li_img"/>
                            <div className="flex_el_li_text">
                                Кошки становятся менее подвижными, больше спят, у них появляется <span className="flex_el_li_pink">склонность к набору лишнего веса</span>;
                            </div>
                        </div>
                        <div className="flex_el_li">
                            <img src={img4} alt="" className="flex_el_li_img"/>
                            <div className="flex_el_li_text">
                                Снижается частота мочеиспусканий, что приводит к застою мочи и <span className="flex_el_li_pink">повышению риска развития мочекаменной болезни</span>.
                            </div>
                        </div>
                    </div>
                </div>
                <img src={img6} alt="" className="imgCenter"/>
            </div>
        )
    }
}
