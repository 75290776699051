import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson7/step11-1.png";

export default class step11 extends React.Component {

    render() {
        return (
            <div className="step11">
                <TextBlock
                    text={`Собаки и волки в дикой природе питаются растительноядными животными и получают необходимые питательные вещества из растительных и животных источников.
                    
                    Питательный состав влажных кормов очень близок к составу рациона собак в природе. Для примера рассмотрим самый популярный корм для собак — <b>влажный корм PEDIGREE<sup>®</sup>.</b>`}
                />
                <img src={img1} alt="" className="img"/>
                <div className="advice">
                    Из сравнения видно, что готовый влажный корм для собак также похож на состав их пищи в диких условиях и отвечает энергетическим потребностям собак.
                </div>
            </div>
        )
    }
}
