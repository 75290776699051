import React from 'react'
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";

export default class step4 extends React.Component {

    render() {
        return (
            <div className="step4">
                <div className="box">
                    {/*<VideoBlock*/}
                    {/*    src={'https://www.youtube.com/embed/0bV9ITPM8Ic'}*/}
                    {/*    title={'https://www.youtube.com/watch?time_continue=2&v=0bV9ITPM8Ic&feature=emb_logo'}*/}
                    {/*/>*/}
                    <VideoBlock
                        src={'https://www.youtube.com/embed/66pkAL5GrwU'}
                        title={'https://www.youtube.com/watch?time_continue=2&v=66pkAL5GrwU&feature=emb_logo'}
                    />
                </div>
            </div>
        )
    }
}
