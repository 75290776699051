import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

export default class step16 extends React.Component {

    state = {
        flexActive: 0,
    };

    changeFlex = flexActive => {
        this.setState({flexActive})
        this.props.showNextBtn()
    }

    render() {
        const {flexActive} = this.state
        return (
            <div className="step16">
                <TextBlock
                    text={`Для того, чтобы понять, что такое «покрытие калорий», необходимо прибегнуть к нескольким математическим вычислениям. Их результат наглядно продемонстрирует смысл и важность данного показателя.`}
                />
                <div className="head">
                    Шаг №1: Рассчитать, сколько калорий необходимо всей популяции животных в год
                </div>
                <InfoBlock
                    text={`Нажмите на ячейку, чтобы узнать ответ.`}
                />
                <div className={'flex'}>
                    <div className="flex_el flex_el_inactive">
                        Численность животных в стране (с учетом веса и возраста)
                    </div>
                    <div className="flex_el_sign">x</div>
                    <div className={'flex_el flex_el_inactive'}>
                        Объем калорий, необходимый животному в день (с учетом веса и возраста)
                        <p className={'flex_el_text'}>
                            Умножить на 365 дней для расчета годового объема
                        </p>
                    </div>
                    <div className="flex_el_sign">=</div>
                    <div className={'flex_el total ' + (flexActive === 1 && 'hidden')} onClick={() => {this.changeFlex(1)}}>
                        Объем калорий, необходимый всей популяции животных в день
                        <p className={'flex_el_click left'}>
                            Нажмите
                        </p>
                        <p className={'flex_el_click right'}>
                            на ячейку
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}
