import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import Answer from './dnd/Answer';
import Questions from './dnd/Questions';

export default class step4 extends React.Component {

    state = {
        // Ресайз
        windowWidth: window.innerWidth,

        // Работа десктопной версии
        answers: ['1', '2', '4'], //Массив правильных ответов
        correct: '',
        answerCorrect: [],
        answersSelectedDesktop: ['?', '?', '?'], // Массив выбираемых значений

        //Работа мобильной версии
        answerActive: 0,
        questionActive: 0,
        answersMobile: [],
        questionsMobile: [],
        answersSelected: ['?', '?', '?'], // Массив выбираемых значений
        correctMobile: '',
        answerCorrectMobile: [],
        showPopup: false
    }

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    //Работа десктопной версии
    setAnswer = (answer_el, question_el) => {
        const {answersSelectedDesktop} = this.state

        answersSelectedDesktop.splice(answer_el - 1, 1, question_el)
        this.setState({answersSelectedDesktop})
    };

    checkAnswer = () => {
        const {answerCorrect, answersSelectedDesktop, answers} = this.state
        // eslint-disable-next-line
        answersSelectedDesktop.map((answer, index) => {
            if (answers.indexOf(answer) >= 0) {
                answerCorrect.push('correct')
            } else {
                answerCorrect.push('inCorrect')
            }
        })
        this.setState({
            answerCorrect: answerCorrect,
        })
        if (answersSelectedDesktop.indexOf('?') === -1 && answerCorrect.indexOf('inCorrect') === -1) {
            this.setState({correct: '1'})
        } else {
            this.setState({correct: '0'})
        }
        this.props.showNextBtn()
    };

    _renderTestDesktop = () => {
        const {answerCorrect, correct} = this.state
        return (
            <div className="test_desktop">
                <div className="energy">
                    <div className="energy_text">
                        ЭНЕРГИЯ
                    </div>
                    <div className="energy_equally">
                        =
                    </div>
                </div>
                <div className="test_wrp">
                    <div className="questions">
                        <div className={'question_el'}>
                            <Questions targetKey="1" question_el={'1'} label={'Белки'} text={'Белки'}/>
                        </div>
                        <div className={'question_el'}>
                            <Questions targetKey="1" question_el={'2'} label={'Углеводы'} text={'Углеводы'}/>
                        </div>
                        <div className={'question_el'}>
                            <Questions targetKey="1" question_el={'3'} label={'Минералы'} text={'Минералы'}/>
                        </div>
                        <div className={'question_el'}>
                            <Questions targetKey="1" question_el={'4'} label={'Жиры'} text={'Жиры'}/>
                        </div>
                        <div className={'question_el'}>
                            <Questions targetKey="1" question_el={'5'} label={'Витамины'} text={'Витамины'}/>
                        </div>
                        <div className={'question_el'}>
                            <Questions targetKey="1" question_el={'6'} label={'Вода'} text={'Вода'}/>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="answers">
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'1'} count={'?'} answerCorrect={answerCorrect[0]}/>
                                <span className="answers_plus">+</span>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'2'} count={'?'} answerCorrect={answerCorrect[1]}/>
                                <span className="answers_plus">+</span>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'3'} count={'?'} answerCorrect={answerCorrect[2]}/>
                            </div>
                        </div>
                    </div>
                </div>
                {correct !== '' ?
                    <div className={'info ' + (correct === '0' && 'error')}>
                        <div className="info_head">
                            {correct === '1' ? 'все верно!' : 'К сожалению, ваш ответ не верен.'}
                        </div>
                        <div className="infoText">
                            Три главных составляющих энергии — белки, жиры и углеводы. Далее вы получите более подробную информацию об этих элементах.
                        </div>
                    </div>
                    :
                    <button className="btn btn_blue" onClick={() => this.checkAnswer()}>
                        Подтвердить
                    </button>
                }
            </div>
        )
    }

    //Работа мобильной версии
    questions = [
        `Белки`,
        `Углеводы`,
        `Минералы`,
        `Жиры`,
        `Витамины`,
        `Вода`
    ]

    selectAnswer = (answerActive) => {
        document.body.scrollIntoView( true);
        document.body.style.overflow = "hidden";
        this.setState({
            showPopup: true,
            answerActive: answerActive
        })
    }

    selectQuestion = (questionActive) => {
        const {answerActive, answersMobile, questionsMobile, answersSelected} = this.state

        if (answersMobile.indexOf(answerActive) === -1) {
            answersMobile.push(answerActive)
        }
        this.setState({answersMobile})

        if (questionsMobile.indexOf(questionsMobile) === -1) {
            questionsMobile.push(questionActive)
        }
        this.setState({questionsMobile})

        answersSelected.splice(answerActive - 1, 1, this.questions[questionActive - 1])

        document.body.style.overflow = "";
        this.setState({
            showPopup: false,
            questionActive: questionActive,
            answersSelected: answersSelected
        })
        console.log(111, answersSelected)
    }

    checkAnswerMobile = () => {
        const {answerCorrectMobile, answersSelected} = this.state
        // eslint-disable-next-line
        answersSelected.map((answer, index) => {
            if (answer === this.questions[0] || answer === this.questions[1] || answer === this.questions[3]) {
                answerCorrectMobile.push('correct')
            } else {
                answerCorrectMobile.push('inCorrect')
            }
        })
        this.setState({
            answerCorrect: answerCorrectMobile,
        })
        if (answerCorrectMobile.indexOf('?') === -1 && answerCorrectMobile.indexOf('inCorrect') === -1) {
            this.setState({correctMobile: '1'})
        } else {
            this.setState({correctMobile: '0'})
        }
        this.props.showNextBtn()
    }

    closePopup = () => {
        document.body.style.overflow = "";
        this.setState({showPopup: false})
    }

    _renderTestMobile = () => {
        const {showPopup, answerActive, answersMobile, answersSelected, correctMobile, answerCorrectMobile} = this.state
        return (
            <div className="test_mobile">
                {correctMobile !== '' ?
                    <div className={'info ' + (correctMobile === '0' && 'error')}>
                        <div className="info_head">
                            {correctMobile === '1' ? 'все верно!' : 'К сожалению, ваш ответ не верен.'}
                        </div>
                        <div className="infoText">
                            Три главных составляющих энергии — белки, жиры и углеводы. Далее вы получите более подробную информацию об этих элементах.
                        </div>
                    </div>
                    :
                    <button className="btn btn_blue" onClick={() => this.checkAnswerMobile()}>
                        Подтвердить
                    </button>
                }
                <div className="answers">
                    <div className="energy">
                        <div className="energy_text">
                            ЭНЕРГИЯ
                        </div>
                        <div className="energy_equally">
                            =
                        </div>
                    </div>
                    <div className="flex">
                        <div className={'answers_item ' + (answersMobile.indexOf(1) >=0 ? 'selected ' : '') + answerCorrectMobile[0]} onClick={() => {this.selectAnswer(1)}}>
                            {answersSelected[0]}
                            <span className="answers_plus">+</span>
                        </div>
                        <div className={'answers_item ' + (answersMobile.indexOf(2) >=0 ? 'selected ' : '') + answerCorrectMobile[1]} onClick={() => {this.selectAnswer(2)}}>
                            {answersSelected[1]}
                            <span className="answers_plus">+</span>
                        </div>
                        <div className={'answers_item ' + (answersMobile.indexOf(3) >=0 ? 'selected ' : '') + answerCorrectMobile[2]} onClick={() => {this.selectAnswer(3)}}>
                            {answersSelected[2]}
                        </div>
                    </div>
                </div>
                {showPopup &&
                <div className="popup">
                    <div className="popup_wrp">
                        <div
                            className="popup_closed"
                            onClick={() => {this.closePopup()}}
                        />
                        <div className="popup_flex">
                            <div className="point">
                                {answerActive}
                            </div>
                            <p className="popup_text">
                                Выберите вариант ответа:
                            </p>
                            <div className="questions">
                                {this.questions.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={'questions_item'}
                                            onClick={() => {this.selectQuestion(index + 1)}}
                                        >
                                            {item}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }

    render() {
        const {windowWidth} = this.state
        return (
            <div className="step4">
                <TextBlock
                    text={`Как вы думаете, из каких трех составляющих пищи вырабатывается энергия?`}
                />
                <InfoBlock
                    text={`Расположите элементы в формулу, а затем нажмите "подтвердить", чтобы проверить себя.`}
                />
                {windowWidth >= 768 ?
                    <div className="test">
                        {this._renderTestDesktop()}
                    </div>
                    :
                    <div className="test">
                        {this._renderTestMobile()}
                    </div>
                }
            </div>
        )
    }
}
