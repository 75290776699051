import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import ArrowBack from '../../../Components/LessonAndTestComponents/ArrowBack';

import img1 from '../../../image/lessons/lesson2/step5-1.png';
import img2 from '../../../image/lessons/lesson2/step5-2.png';
import img3 from '../../../image/lessons/lesson2/step5-3.png';


export default class step5 extends React.Component {
    state = {
        flexActive: 0,
        flexs: [1],
        flexHidden: false,
    };

    changeFlex = flexActive => {
        const {flexs} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }
        this.setState({flexActive, flexs, flexHidden: true})
        if (flexs.length === 3) {
            this.props.showNextBtn()
        }
    }

    closeImgDiv = () => {this.setState({flexHidden: false, flexActive: 0})}


    render() {
        const {flexActive, flexHidden} = this.state
        return (
            <div className="step5">
                <TextBlock
                    text={`Узнайте больше о каждом компоненте энергии.`}
                    hidden={flexHidden === true ? ' hidden' : ''}
                />
                <InfoBlock
                    text={`Для продолжения, пожалуйста, ознакомьтесь со всей представленной информацией в этом шаге урока.`}
                    hidden={flexHidden === true ? ' hidden' : ''}
                />

                <div className={'flex ' + (flexHidden === true && 'hidden')}>
                    <div className="flex_el flex_el_inactive">
                        Энергия
                    </div>
                    <div className="flex_el_sign">=</div>
                    <div className={'flex_el ' + (flexActive === 1 && 'active')} onClick={() => {
                        this.changeFlex(1)
                    }}>Белки
                    </div>
                    <div className="flex_el_sign">+</div>
                    <div className={'flex_el ' + (flexActive === 2 && 'active')} onClick={() => {
                        this.changeFlex(2)
                    }}>Углеводы
                    </div>
                    <div className="flex_el_sign">+</div>
                    <div className={'flex_el ' + (flexActive === 3 && 'active')} onClick={() => {
                        this.changeFlex(3)
                    }}>Жиры
                    </div>
                </div>
                {flexActive !== 0 &&
                <div className={'imgDiv ' + (flexActive === 2 ? 'center' : flexActive === 3 ? 'right' : '')}>
                    <ArrowBack
                        returnMenu={this.closeImgDiv}
                    />
                    {flexActive === 1 &&
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Белок — это конструктивный элемент всего живого. В различных формах белки принимают участие в важнейших процессах обмена веществ, благодаря которым поддерживается жизнедеятельность организма.`}
                        />
                        <img src={img1} alt=""/>
                    </div>
                    }
                    {flexActive === 2 &&
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Углеводы — источник энергии, которую животные могут быстро получать в процессе пищеварения. Часть углеводов представляет собой пищевые волокна, которые хорошо влияют на работу пищеварительной системы, обеспечивая необходимую моторику желудочно-кишечного тракта. Таким образом, пищевые волокна позволяют предупредить ожирение, диабет, запоры и диареи.`}
                        />
                        <img src={img2} alt=""/>
                    </div>
                    }
                    {flexActive === 3 &&
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Жиры — это концентрированный источник энергии, они повышают привлекательность пищи и способствуют усвоению витаминов A, D, Е и К. Хорошо усваиваются организмом собак и кошек.`}
                        />
                        <img src={img3} alt=""/>
                    </div>
                    }
                </div>
                }
            </div>
        )
    }
}
