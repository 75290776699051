import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';

export default class step1 extends React.Component {

    planData = [
        `Дела домашние`,
        `COVID-19 и Ваш питомец`,
        `Питание и медицина`,
    ]

    render() {
        return (
            <div className="step1">
                <TextBlock
                    text={`<b>Узнайте, как с пользой провести время дома и стать еще ближе со своим питомцем.</b>`}
                />
                <div className="info"
                     dangerouslySetInnerHTML={{ __html: `Мы понимаем, что ради всеобщей безопасности вам приходиться оставаться дома. Однако это прекрасный шанс стать еще ближе с теми, кто нам бесконечно дорог и любим. В том числе, с нашими <b>любимыми питомцами.</b>

                    Чтобы вы не беспокоились о своих домашних питомцах, мы собрали для вас советы по главным темам. В них вы узнаете, что и как делать дома, чтобы они оставались здоровыми и счастливыми.

                    Разделите это время вместе и продолжайте радовать друг друга!` }}
                />
                <div className="head">План урока</div>

                <TextBlock
                    text={`В этом уроке Вы изучите следующие темы:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'blue'}
                />

            </div>
        )
    }
}
