import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import img1 from '../../../image/lessons/lesson32/step28-1.png'
import img2 from '../../../image/lessons/lesson32/step28-2.png'
import img3 from '../../../image/lessons/lesson32/step28-3.png'
import img4 from '../../../image/lessons/lesson32/step28-4.png'
import img5 from '../../../image/lessons/lesson32/step28-5.png'
import Popup from "../../../Components/Popup";


export default class step30 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        pointActive: 0,
        points: [0],
        showPopup: false,
    };

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    clickPoint = pointActive => {
        const {points, windowWidth} = this.state
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        if (windowWidth < 767) {

            if (pointActive > 2) {
                this.setState({
                    pointActive: 0,
                    showPopup: false,
                })
            } else {
                this.setState({
                    pointActive: pointActive,
                    showPopup: true,
                    points: points
                })
            }

        } else {
            this.setState({pointActive, points})
        }

        if (points.length === 3) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({pointActive: 0, showPopup: false})
    }

    render() {
        const {showPopup, pointActive} = this.state;
        return (
            <div className="step28">
                <TextBlock
                    text={`<b>Обогащение рациона собаки антиоксидантами способствует укреплению ее иммунной системы.</b>`}
                />

                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_text">
                            Компоненты рациона, способствующие поддержанию иммунитета собак
                        </div>
                        <div className="flex_el_box">
                            <ul>
                                <li>цинк</li>
                                <li>витамин Е</li>
                                <li>таурин</li>
                                <li>витамин С</li>
                                <li>каратиноиды</li>
                                <li>полифенолы</li>
                                <li>селен</li>
                            </ul>
                            <img src={img3} alt="" className="flex_el_box_img"/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_text mobile">
                            Компоненты рациона, способствующие поддержанию иммунитета собак
                        </div>
                        <div className="flex_el_wrp">
                            <div className="flex_el_container">
                                <img src={img1} alt="" className="flex_el_img" onClick={() => {this.clickPoint(1)}}/>
                                <img src={img2} alt="" className="flex_el_img" onClick={() => {this.clickPoint(2)}}/>
                            </div>
                        </div>
                        <div className="flex_el_box">
                            <div className={'info ' + (pointActive === 1 && 'active')}>
                                <ul>
                                    <li>ко-фактор ферментативной системы антиоксидантной защиты организма (супероксиддисмутазы)</li>
                                    <li>ускоряют реакцию дисмутации анион-радикала О2</li>
                                    <li>обрывают цепь свободнорадикальных превращений кислорода:</li>
                                </ul>
                                <img src={img4} alt="" className="imgCenter"/>
                            </div>
                            <div className={'info ' + (pointActive === 2 && 'active')}>
                                <ul>
                                    <li>входит в состав неферментативной системы антиоксидантной защиты организма</li>
                                    <li>в рационах использована его активная форма – альфа-токоферол:</li>
                                </ul>
                                <img src={img5} alt="" className="imgCenter"/>
                                <ul>
                                    <li>взаимодействует с активными формами кислорода</li>
                                    <li>повышает продукцию иммуноглобулинов (IgA, IgG, IgM)</li>
                                    <li>защищает фосфолипиды клеточных мембран от повреждающего действия свободных радикалов</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {showPopup &&
                <Popup
                    closePopup={this.closePopup}
                >
                    {pointActive === 1 ?
                        <div className="Popup_box">
                            <img src={img1} alt="" className="Popup_img"/>
                            <ul>
                                <li>входит в состав неферментативной системы антиоксидантной защиты организма</li>
                                <li>ускоряют реакцию дисмутации анион-радикала О2</li>
                                <li>обрывают цепь свободнорадикальных превращений кислорода:</li>
                            </ul>
                            <img src={img4} alt="" className="imgCenter"/>
                        </div>
                        :
                        <div className="Popup_box">
                            <img src={img2} alt="" className="Popup_img"/>
                            <ul>
                                <li>ко-фактор ферментативной системы антиоксидантной защиты организма (супероксиддисмутазы)</li>
                                <li>в рационах использована его активная форма – альфа-токоферол:</li>
                            </ul>
                            <img src={img5} alt="" className="imgCenter"/>
                            <ul>
                                <li>взаимодействует с активными формами кислорода</li>
                                <li>повышает продукцию иммуноглобулинов (IgA, IgG, IgM)</li>
                                <li>защищает фосфолипиды клеточных мембран от повреждающего действия свободных радикалов</li>
                            </ul>
                        </div>
                    }
                    <button
                        className="btn btn_blue"
                        onClick={() => {this.clickPoint(pointActive + 1)}}
                    >
                        продолжить
                    </button>
                </Popup>
                }
            </div>
        )
    }
}
