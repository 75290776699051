import React from 'react'

import img1 from '../../../image/lessons/lesson22/step7-1.png';

export default class step7 extends React.Component {

    render() {
        return (
            <div className="step7">
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_question red">
                            Как часто можно предлагать DREAMIES™ кошке?
                        </div>
                        <div className="flex_el_answer">
                            Лакомство DREAMIES™ можно давать по несколько подушечек в течение дня каждый раз, когда хотите побаловать вашу кошку.
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_question blue">
                            Сколько подушечек в день можно давать кошке?
                        </div>
                        <div className="flex_el_answer">
                            Рекомендуемая дневная норма - 4 подушечки на 1 килограмм веса кошки.
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_question green">
                            Можно ли давать DREAMIES™ котёнку?
                        </div>
                        <div className="flex_el_answer">
                            Лакомство DREAMIES™ можно давать котятам от 2-х месяцев, однако важно соблюдать рекомендации по нормам кормления, указанные на упаковке
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_question orange">
                            Где нужно хранить открытую упаковку DREAMIES™?
                        </div>
                        <div className="flex_el_answer">
                            Открытое лакомство нужно хранить в сухом и прохладном месте. Пакет лучше плотно закрыть, тогда лакомство дольше сохранит свой вкус и аромат.
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_question red">
                            Может ли DREAMIES™ заменить обычный корм?
                        </div>
                        <div className="flex_el_answer">
                            В состав DREAMIES™ входит большое количество важных для кошки питательных веществ. Но, тем не менее, мы всегда рекомендуем давать это лакомство в качестве дополнения к полноценному, сбалансированному корму.
                        </div>
                    </div>
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                </div>
            </div>
        )
    }
}
