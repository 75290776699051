import React from 'react'
import Resume from "../../../Components/LessonAndTestComponents/Resume";

import img1 from "../../../image/lessons/lesson7/step35-1.png";

export default class step35 extends React.Component {
    state = {
        resumeData: [
            `Вода жизненно важна для животных и необходима <b>для всех процессов</b> в организме.`,
            `Пища с высоким содержанием жидкости способствует <b>профилактике</b> заболевания мочевыводящих путей.`,
            `Польза влажного корма для животных <b>научно доказана.</b>`,
        ]
    }

    render() {
        return (
            <div className="step35">
                <Resume
                    resumeData={this.state.resumeData}
                    img={img1}
                />
            </div>
        )
    }
}
