import React from 'react'

import img1 from "../../../image/lessons/lesson21/step12-1.png";

export default class step12 extends React.Component {

    render() {
        return (
            <div className="step12">
                <div className="flex">
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                    <div className="flex_el_img">
                        <div className="info">
                            <div className="info_el">
                                <div className="info_el_number">1</div>
                                <div className="info_el_text">
                                    Хищники имеют сильные челюсти и острые клыки для того, чтобы убивать и раздирать свою добычу.
                                </div>
                            </div>
                            <div className="info_el">
                                <div className="info_el_number">2</div>
                                <div className="info_el_text">
                                    Им свойственна хорошо развитая и гибкая мускулатура передних конечностей для того, чтобы бросаться на добычу.
                                </div>
                            </div>
                            <div className="info_el">
                                <div className="info_el_number">3</div>
                                <div className="info_el_text">
                                    Хищников объединяет то, что они питаются пищей <b>животного</b> происхождения.
                                </div>
                            </div>
                            <div className="info_el">
                                <div className="info_el_number">4</div>
                                <div className="info_el_text">
                                    Характерны для хищников и мощные когти.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
