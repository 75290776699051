import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson32/step33-1.png'
import img1xs from '../../../image/lessons/lesson32/step33-1xs.png'
import img2 from '../../../image/lessons/lesson32/step33-2.png'
import img3 from '../../../image/lessons/lesson32/step33-3.png'

export default class step33 extends React.Component {

    render() {
        return (
            <div className="step33">
                <div className="flex">
                    <div className="block">
                        <TextBlock
                            text={`<b>Энергетические потребности щенка в период отъема от матери в 2-2,5 раза больше, чем у взрослой собаки такого же веса</b>
                        
                        Начиная с 3 недель щенку уже недостаточно питания материнским молоком. Необходимо начинать первый прикорм, помогая пищеварительной системе щенка постепенно адаптироваться к усвоению новых источников питательных веществ.
                        
                        <b>PEDIGREE<sup>®</sup> Первый прикорм</b> специально создан для перевода щенков с материнского молока на специальные рационы для щенков.
                        
                        <b>При смешивании с теплой водой</b> гранулы быстро впитывают воду и становятся мягкими.
                        
                        PEDIGREE<sup>®</sup> Первый прикорм необходимо предлагать щенку <b>перед кормлением материнским молоком.</b>`}
                        />
                        <picture>
                            <source srcSet={img1xs} media="(max-width: 767px)"/>
                            <img src={img1} alt="" className="flex_img"/>
                        </picture>
                    </div>
                    <h2>Особенности роста щенков крупных пород</h2>
                    <TextBlock text={'С 6 месяцев для щенков крупных пород характерно интенсивное развитие мускулатуры и увеличение массы тела, опережающее рост и созревание скелета.<br>Поэтому в этот период важно следить, чтобы щенок не набирал вес слишком быстро. Избыток веса, оказывая давление на суставы, повышает риск развития костно-суставных патологий, а также может вызвать раннее созревание скелета, что приведет к отставанию в росте и развитии щенка.'}/>
                    <img className="image" src={img2} alt="grafik"/>
                    <img className="image" src={img3} alt="shema"/>
                </div>
            </div>
        )
    }
}
