import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickTest from '../../../Components/LessonAndTestComponents/OnClickTest';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

import img1 from '../../../image/lessons/lesson1/step3-1.png'
import img2 from '../../../image/lessons/lesson1/step3-2.png'
import img3 from '../../../image/lessons/lesson1/step3-3.png'

export default class step3 extends React.Component {

    state = {
        answersData: {
            1: {
                img: img1,
                text: `Азиатский волк`
            },
            2: {
                img: img2,
                text: `Евразийский волк`
            },
            3: {
                img: img3,
                text: `Эфиопский шакал`
            },
        }

    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step3">
                <TextBlock
                    text={`Чтобы узнать больше о современных собаках, надо понять, как они появились. Знаете ли вы, от какого волка предположительно произошли домашние собаки?`}
                />
                <InfoBlock
                    text={`Выберите вариант ответа и нажмите «Подтвердить».`}
                />
                <OnClickTest
                    ansewrsData={this.state.answersData}
                    type={'img'}
                    rightAnswers={[1]}
                    showNextBtn={this.showNextBtn}
                    correctText={`По последним данным собака появилась около 12 000 лет назад в результате одомашнивания азиатского волка.`}
                />
            </div>
        )
    }
}
