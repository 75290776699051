import React from 'react'
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";

import img1 from '../../../image/lessons/lesson24/step9-1.png';

export default class step9 extends React.Component {

    state = {
        pointActive: 0,
        points: [],
    }

    showInfo = (pointActive) => {
        const {points} = this.state;
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        this.setState({points: points, pointActive: pointActive})

        if (points.length === 5) {
            this.props.showNextBtn();
        }
    }

    render() {
        const {points} = this.state
        return (
            <div className="step9">
                <InfoBlock
                    text={`Нажмите на активные элементы, чтобы познакомиться с ключевыми особенностями блюда SHEBA`}
                />
                <div className="block">
                        <div className="block_box">
                        <div
                            className={'point ' + (points.indexOf(1) >= 0 && 'active')}
                            onClick={() => {this.showInfo(1)}}
                        >
                            <div className="point_number">1</div>
                            <div className={'point_info'}>
                                Высокая вкусовая привлекательность
                            </div>
                        </div>
                        <div
                            className={'point ' + (points.indexOf(2) >= 0 && 'active')}
                            onClick={() => {this.showInfo(2)}}
                        >
                            <div className="point_number">2</div>
                            <div className={'point_info'}>
                                Оптимальное соотношение ломтиков и соуса
                            </div>
                        </div>
                        <div
                            className={'point ' + (points.indexOf(3) >= 0 && 'active')}
                            onClick={() => {this.showInfo(3)}}
                        >
                            <div className="point_number">3</div>
                            <div className={'point_info'}>
                                Высокая оценка в исследовании потребителей
                            </div>
                        </div>
                        <div
                            className={'point ' + (points.indexOf(4) >= 0 && 'active')}
                            onClick={() => {this.showInfo(4)}}
                        >
                            <div className="point_number">4</div>
                            <div className={'point_info'}>
                                Полностью сбалансированное питание
                            </div>
                        </div>
                        <div
                            className={'point ' + (points.indexOf(5) >= 0 && 'active')}
                            onClick={() => {this.showInfo(5)}}
                        >
                            <div className="point_number">5</div>
                            <div className={'point_info'}>
                                Высокое содержание мясных ингредиентов
                            </div>
                        </div>
                        <img className="imgCenter" src={img1} alt=""/>
                    </div>
                </div>
            </div>
        )
    }
}
