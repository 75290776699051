import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson24/step4-1.png'

export default class step4 extends React.Component {

    render() {
        return (
            <div className="step4">
                <TextBlock
                    text={`Блюда Sheba<sup>®</sup> обладают высокой вкусовой привлекательностью`}
                />
                <img src={img1} alt="" className="imgCenter"/>
                <div className="info">
                    Поедаемость обновленной Sheba<sup>®</sup> выше, чем предыдущей версии рецептуры*
                </div>
                <TextBlock
                    text={`* Усовершенствование рецептуры произошло в 2019 году.`}
                />
            </div>
        )
    }
}
