import React from 'react';
import './style.sass'
import {Link} from "react-router-dom";

/*
props.className - класс для кнопки
props.type - либо ее тип. btn - кнопа, '' - ссылка
props.bgc - бекграунд кнопки
props.to - ссылка
props.svg - картинка
props.name - название
 */

export default function MenuBtn(props) {
    return (
        <Link
            className={props.className ? 'menuBtn ' + props.className : props.type === 'btn' ? 'menuBtn' : 'menuBtn menuBtn_link'}
            style={{backgroundColor: props.bgc}}
            to={props.to}
            onClick={props.closePopup}
        >
            {props.svg && props.svg}
            {props.name}
        </Link>

);
}
