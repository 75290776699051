import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson24/step3-1.png'
export default class step3 extends React.Component {

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step3">
                <TextBlock
                    text={`<span class="text_sheba">Кошки по своей природе – взыскательные гурманы</span> и обладают высокими требованиями к вкусовой привлекательности корма и требуют разнообразия в питании

                    <span class="text_sheba">Sheba<sup>®</sup> предлагает кошкам:</span>`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="block">
                            <div className="block_point">1</div>
                            Соблазнительные сочетания вкусов
                        </div>
                        <div className="block">
                            <div className="block_point">2</div>
                            Богатый выбор текстур
                        </div>
                        <div className="block">
                            <div className="block_point">3</div>
                            Высокую вкусовую привлекательность блюд
                        </div>
                    </div>
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                </div>
            </div>
        )
    }
}
