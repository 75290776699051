import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson16/step25-1.png'

export default class step25 extends React.Component {

    render() {
        return (
            <div className="step25">
                <TextBlock
                    text={`Покупатель принимает решение в соответствии с его приоритетами в покупке, иначе говоря, на основе <span class="text_blue">дерева принятия решений.</span>`}
                />
                <img className="imgCenter" src={img1} alt=""/>
                <div className="block">
                    Давайте сыграем в игру,
                    чтобы разобраться.
                </div>
            </div>
        )
    }
}
