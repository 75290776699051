import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';

export default class step1 extends React.Component {

    planData = [
        `Какие особенности есть у категории кормов для животных`,
        `Сколько собак и кошек в России, и чем они питаются`,
        `Какие бренды являются лидерами в категории`,
    ]

    render() {
        return (
            <div className="step1">
                <div className="head">План урока</div>

                <TextBlock
                    text={`Вы изучите:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'blue'}
                />
                <div className="block">
                    Для чего на полке в магазине присутствует большое разнообразие кормов:
                    <ul className="list">
                        <li className="list_item">для собак и для кошек;</li>
                        <li className="list_item">для животных разных возрастов;</li>
                        <li className="list_item">для собак разных размеров;</li>
                        <li className="list_item">сухие и влажные корма;</li>
                        <li className="list_item">корма разных вкусов и текстур (кусочки в соусе, кусочки в желе, паштеты и другие варианты).</li>
                    </ul>
                </div>
            </div>
        )
    }
}
