import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson7/step3-1.png";

export default class step3 extends React.Component {

    render() {
        return (
            <div className="step3">
                <TextBlock
                    text={`Кошки относятся к отряду <b>хищников.</b> По типу питания они являются <b>облигатными плотоядными</b>, то есть животная пища необходима им для нормальной жизнедеятельности. Поэтому в их рационе обязательно должны присутствовать продукты животного происхождения.`}
                />
                <div className="text_blue">
                    Пищевое поведение кошек имеет следующие особенности:
                </div>
                <ul className="list">
                    <li className="list_item">Кошки любят охотиться, даже когда не голодны. Это их природный инстинкт.</li>
                    <li className="list_item">Предпочитают есть только что убитую жертву и никогда не запасают еду.</li>
                    <li className="list_item">Поймав жертву, кошка первым делом съедает печень и другие внутренние органы, именно поэтому субпродукты (внутренние органы) широко используются при производстве готовых кормов и необходимы в рационе.</li>
                    <li className="list_item">Кошки не жуют пищу, они привыкли разрывать жертву и проглатывать небольшие ее куски.</li>
                    <li className="list_item">Кошки не чувствуют сладкий вкус (открытие сделано научно-исследовательским институтом Waltham).</li>
                </ul>
                <img src={img1} alt="" className="img"/>
            </div>
        )
    }
}
