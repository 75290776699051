import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";


export default class step8 extends React.Component {

    state = {
        flexActive: 0,
        flexs: [0],
    };

    changeFlex = flexActive => {
        const {flexs} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }
        this.setState({flexActive, flexs, flexHidden: true})
        if (flexs.length === 6) {
            this.props.showNextBtn()
        }
    }

    flexData = {
        1: {
            flexText: 'Период неолита',
            divImgText: 'Предполагается, что кошки впервые были одомашнены на Ближнем Востоке. Это произошло приблизительно в то же время, когда в этом регионе начало развиваться сельское хозяйство.'
        },
        2: {
            flexText: '7500 лет до н. э.',
            divImgText: 'На Кипре было найдено тело котенка, похороненного рядом с его товарищем-человеком. Это открытие заставило историков полагать, что кошки стали важны для людей гораздо раньше, чем принято считать.'
        },
        3: {
            flexText: '2000 лет до н. э.',
            divImgText: 'До обнаружения захоронения на Кипре долгое время считалось, что кошки впервые были одомашнены древними египтянами, поскольку в их культуре уделялось много внимания кошкам.'
        },
        4: {
            flexText: '900 лет до н. э.',
            divImgText: 'Считается, что приблизительно в X веке домашние кошки из Египта через Рим и Грецию распространились по Европе и большей части Азии.'
        },
        5: {
            flexText: '1400-1700 гг.',
            divImgText: 'Кошки получили распространение в Северной Америке, Австралии и Новой Зеландии, попав туда на кораблях.'
        },
    }

    render() {
        const {flexActive} = this.state
        return (
            <div className="step8">
                <TextBlock
                    text={`Вопрос о том, когда именно кошки впервые были одомашнены, вызывает множество дискуссий. История одомашнивания кошек начинается задолго до нашей эры. Ознакомьтесь с процессом одомашнивания кошек, рассмотрев эту временную последовательность.`}
                />
                <InfoBlock
                    text={
                        `Для продолжения, пожалуйста, ознакомьтесь со всей представленной информацией в этом шаге урока`
                    }
                />
                <div className={'flex'}>
                    {Object.values(this.flexData).map((item, index) => {
                        return (
                            <div className="flex_wrp">
                                <div
                                    key={index}
                                    className={'flex_el ' + (flexActive === (index + 1) && 'active')}
                                    onClick={() => {this.changeFlex(index + 1)}}
                                >
                                    {item.flexText}
                                </div>
                                <div
                                    className={'flex_content ' + (flexActive === (index + 1) && 'active')}
                                >
                                    {item.divImgText}
                                </div>
                            </div>
                        )
                    })}
                </div>
                {flexActive !== 0 &&
                <div className="imgDiv">
                    {Object.values(this.flexData).map((item, index) => {
                        return (
                            <div
                                key={index}
                                className={'imgDiv_wrap ' + (flexActive === (index + 1) && 'active')}
                            >
                                <TextBlock
                                    text={item.divImgText}
                                />
                            </div>
                        )
                    })}
                </div>
                }
            </div>
        )
    }
}
