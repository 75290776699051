import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'

import img1 from "../../../image/lessons/lesson8/step7-1.png";
import img2 from "../../../image/lessons/lesson8/step7-2.png";


export default class step7 extends React.Component{

    render(){
        return (
            <div className="step7">
                <TextBlock
                    text={`Рекомендации по ежедневному рациону питания тщательно рассчитываются, но, как следует из названия, это всего лишь <b>рекомендации.</b> Они могут потребовать корректировки в соответствии с индивидуальными особенностями животного и его потребностью в энергии.`}
                />
                <InfoBlock
                    text={`На этих таблицах представлены рекомендуемые для кошек и собак схемы кормления сухими рационами, а также схемы сочетанного кормления.`}
                />
                <div className="box">
                    <img src={img1} alt="" className="img"/>
                    <img src={img2} alt="" className="img"/>
                </div>
            </div>
        )
    }

}
