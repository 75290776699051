import React from 'react'

import img1 from '../../../image/lessons/lesson20/step2-1.png'

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <div className="flex">
                    <div className="flex_el">
                        <ul className="list">
                            <li className="list_item">
                                <b>Кошачье любопытство не знает границ</b>. Кошки все время пытаются
                                что-то выяснить, куда-то забраться, все разнюхать, попробовать и изучить.
                            </li>
                            <li className="list_item">
                                Такое поведение лежит <b>в основе кошачьей природы</b>. Каждый любящий
                                хозяин знает, что только здоровая и счастливая кошка будет проявлять любопытство. И для
                                этого ей нужны забота и полноценный сбалансированный рацион.
                            </li>
                            <li className="list_item">
                                WHISKAS<sup>®</sup> помогает миллионам хозяев кошек правильно заботиться о них, поскольку рационы
                                WHISKAS<sup>®</sup> разработаны <b>с учетом особых потребностей кошки на каждом этапе ее жизни</b>.
                            </li>
                        </ul>
                    </div>
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                </div>
            </div>
        )
    }
}
