import React from 'react'
import OnClickTest from "../../../Components/LessonAndTestComponents/OnClickTest";
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

export default class step14 extends React.Component {

    state = {
        answersData: [
            `Никак не отличается`,
            `В больших городах репертуар меньше`,
            `В больших городах репертуар больше`,
        ],
        showImg: false
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step14">
                <TextBlock
                    text={`Как вы думаете, как отличается этот показатель в разных городах?`}
                />
                <OnClickTest
                    ansewrsData={this.state.answersData}
                    type={'line'}
                    rightAnswers={[3]}
                    showNextBtn={this.showNextBtn}
                    correctText={`В больших городах репертуар больше.`}
                />
            </div>
        )
    }
}
