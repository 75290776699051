import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'


export default class step43 extends React.Component {


    render() {
        return (
            <div className="step43">
                <div className="head">
                    5. Большая продолжительность жизни
                </div>
                <TextBlock
                    text={`<b>Нередко стареющие собаки отказываются от привычной еды, либо едят неохотно и с трудом.</b>`}
                />
                <div className="text-red">
                    Признаки старения:
                </div>
                <ul>
                    <li>Ухудшение качества зрения и слуха, потеря обоняния</li>
                    <li>Ухудшение качества шерстного покрова</li>
                    <li>Снижение активности</li>
                    <li>Ухудшение состояния/потеря зубов</li>
                    <li>Лишний вес</li>
                    <li>Снижение пищеварительной функции </li>
                </ul>
                <div className="info">
                    Обоняние у собак притупляется первым из органов чувств, и это может сопровождаться снижением аппетита
                </div>
            </div>
        )
    }
}
