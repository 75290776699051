import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson32/step42-1.png'
import img1xs from "../../../image/lessons/lesson32/step42-1xs.png";


export default class step6 extends React.Component {


    render() {
        return (
            <div className="step42">
                <div className="head">
                    4. Высокая частота заболеваний ротовой полости
                </div>
                <TextBlock
                    text={`<b>Кормление сухим кормом и ежедневное использование PEDIGREE<sup>®</sup> DENTASTIX™ обеспечивает максимальную эффективную профилактику заболеваний ротовой полости.</b>`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} alt="" className="imgCenter"/>
                </picture>
            </div>
        )
    }
}
