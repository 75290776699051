import React from "react";
import InputMask from "react-input-mask";
import "./style.scss"

const TextInput = (props) => {
    const validation = (e) => {
        if(e.target.value.length === 0){
            e.target.closest('.input__body').querySelector('span').classList.add('error')
            e.target.closest('.input__body').querySelector('input').classList.add('error')
        }
        else{
            e.target.closest('.input__body').querySelector('span').classList.remove('error')
            e.target.closest('.input__body').querySelector('input').classList.remove('error')
        }
    }

    const changePlaceholder = (e) => {
        e.target.closest('.input__body').querySelector('span').classList.add('active')
    }
    const changePlaceholder2 = (e) => {
        if(e.target.value.length === 0){
            e.target.closest('.input__body').querySelector('span').classList.remove('active')
            e.target.closest('.input__body').querySelector('span').classList.add('error')
        }
        else{
            e.target.closest('.input__body').querySelector('span').classList.remove('error')
        }
        if(e.target.type === 'email'){
            if(!e.target.value.includes('@')){
                e.target.closest('.input__body').querySelector('span').classList.add('error')
                e.target.classList.add('error')
            }
            else{
                e.target.closest('.input__body').querySelector('span').classList.remove('error')
                e.target.classList.remove('error')
            }
        }
    }

    return(
        <div className="input__body">
            <InputMask
                className="input"
                mask={props.mask}
                data-required={props.required}
                onKeyUp = {e => {validation(e)}}
                onFocus = {e => {changePlaceholder(e)}}
                onBlur = {e => {changePlaceholder2(e)}}
                name={props.name}
                id={props.id}
                type = {props.type}
                
            />
            <span>{props.placeholder}</span>
        </div>
    )
}

export default TextInput