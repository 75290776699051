import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import Answer from './dnd/Answer';
import Questions from './dnd/Questions';

import img1 from '../../../image/lessons/lesson32/step9-1.png'

export default class step9 extends React.Component {

    state = {
        // Ресайз
        windowWidth: window.innerWidth,

        // Работа десктопной версии
        answers: [],
        trueAnswers: ['1', '2', '3', '4', '5', '6', '7', '8',],
        questions: [],
        correct: '',
        answerCorrect: [],

        //Работа мобильной версии
        answerActive: 0,
        questionActive: 0,
        answersMobile: [],
        questionsMobile: [],
        answersSelected: ['?', '?', '?', '?', '?', '?', '?', '?'],
        correctMobile: '',
        answerCorrectMobile: [],
        showPopup: false
    }

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    //Работа десктопной версии
    setAnswer = (answer_el, question_el) => {
        const {answers} = this.state

        if (answers.indexOf(question_el) === -1) {
            let answer = []
            answers[answer_el] = question_el
            answers.push(answer)
        }
        this.setState({answers})

        let questions = [];
        // eslint-disable-next-line
        answers.map(answer => {

            if (answer.length) {
                questions.push(answer)
            }

        });
        this.setState({questions})
    };

    checkAnswer = () => {
        const {answerCorrect, questions, trueAnswers} = this.state
        // eslint-disable-next-line
        questions.map((answer, index) => {
            if (index < 4) {
                if (trueAnswers.slice(0, 4).indexOf(answer) >=0) {
                    answerCorrect.push('correct')
                } else {
                    answerCorrect.push('inCorrect')
                }
            } else if (index >= 4) {
                if (trueAnswers.slice(4, 9).indexOf(answer) >=0) {
                    answerCorrect.push('correct')
                } else {
                    answerCorrect.push('inCorrect')
                }
            }
            /*if (answer === String(index + 1)) {
                answerCorrect.push('correct')
            } else {
                answerCorrect.push('inCorrect')
            }*/
        })
        this.setState({
            answerCorrect: answerCorrect,
        })
        if (questions.length === 8 && answerCorrect.indexOf('inCorrect') === -1) {
            this.setState({correct: '1'})
        } else {
            this.setState({correct: '0'})
        }
        this.props.showNextBtn()
    };

    _renderTestDesktop = () => {
        const {answerCorrect, correct, questions} = this.state
        return (
            <div className="test_desktop">
                <div className="questions">
                    <div className={'question_el ' + (questions.indexOf('1') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'1'} label={'Служит субстратом для роста бифидобактерий'} text={'Служит субстратом для роста бифидобактерий'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('2') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'2'} label={'Снижает кислотность кишечника, препятствуя росту патогенных микроорганизмов'} text={'Снижает кислотность кишечника, препятствуя росту патогенных микроорганизмов'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('3') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'3'} label={'Снижает бродильные процессы'} text={'Снижает бродильные процессы'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('4') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'4'} label={'Является источником бутирата, питательного вещества для колоноцитов (клеток кишечника)'} text={'Является источником бутирата, питательного вещества для колоноцитов (клеток кишечника)'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('5') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'5'} label={'Оптимизирует время кишечного транзита, обеспечивая всасывание питательных веществ и предупреждая запоры'} text={'Оптимизирует время кишечного транзита, обеспечивая всасывание питательных веществ и предупреждая запоры'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('6') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'6'} label={'Стимулирует перистальтику (естественную моторику ЖКТ)'} text={'Стимулирует перистальтику (естественную моторику ЖКТ)'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('7') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'7'} label={'Поглощает избыток воды'} text={'Поглощает избыток воды'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('8') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'8'} label={'Обеспечивает оптимальный объем фекальных масс'} text={'Обеспечивает оптимальный объем фекальных масс'}/>
                    </div>
                </div>
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Ферментируемая клетчатка - Пектин
                        </div>
                        <div className="answers">
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'1'} text={'?'} count={'?'} answerCorrect={answerCorrect[0]}/>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'2'} text={'1'} count={'?'} answerCorrect={answerCorrect[1]}/>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'3'} text={'2'} count={'?'} answerCorrect={answerCorrect[2]}/>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'4'} text={'3'} count={'?'} answerCorrect={answerCorrect[3]}/>
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Неферментируемая клетчатка - Целлюлоза
                        </div>
                        <div className="answers">
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'5'} text={'?'} count={'?'} answerCorrect={answerCorrect[4]}/>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'6'} text={'?'} count={'?'} answerCorrect={answerCorrect[5]}/>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'7'} text={'?'} count={'?'} answerCorrect={answerCorrect[6]}/>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'8'} text={'?'} count={'?'} answerCorrect={answerCorrect[7]}/>
                            </div>
                        </div>
                    </div>
                    <img className="flex_img" src={img1} alt=""/>
                </div>
                {correct !== '' ?
                    <div className={'info ' + (correct === '0' && 'error')}>
                        <div className="info_head">
                            {correct === '1' ? 'все верно!' : 'К сожалению, ваш ответ не верен.'}
                        </div>
                    </div>
                    :
                    <button className="btn btn_blue" onClick={() => this.checkAnswer()}>
                        ПРОВЕРИТЬ
                    </button>
                }
            </div>
        )
    }

    //Работа мобильной версии
    questions = [
        `Служит субстратом для роста бифидобактерий`,
        `Является источником бутирата, питательного вещества для колоноцитов (клеток кишечника)`,
        `Снижает бродильные процессы`,
        `Снижает кислотность кишечника, препятствуя росту патогенных микроорганизмов`,
        `Поглощает избыток воды`,
        `Стимулирует перистальтику (естественную моторику ЖКТ)`,
        `Обеспечивает оптимальный объем фекальных масс`,
        `Оптимизирует время кишечного транзита, обеспечивая всасывание питательных веществ и предупреждая запоры`,
    ]

    selectAnswer = (answerActive) => {
        document.body.scrollIntoView( true);
        document.body.style.overflow = "hidden";
        this.setState({
            showPopup: true,
            answerActive: answerActive
        })
    }

    selectQuestion = (questionActive) => {
        const {answerActive, answersMobile, questionsMobile, answersSelected} = this.state

        if (answersMobile.indexOf(answerActive) === -1) {
            answersMobile.push(answerActive)
        }
        this.setState({answersMobile})

        if (questionsMobile.indexOf(questionsMobile) === -1) {
            questionsMobile.push(questionActive)
        }
        this.setState({questionsMobile})

        answersSelected.splice(answerActive - 1, 1, this.questions[questionActive - 1])

        document.body.style.overflow = "";
        this.setState({
            showPopup: false,
            questionActive: questionActive,
            answersSelected: answersSelected
        })
    }

    checkAnswerMobile = () => {
        const {answerCorrectMobile, answersSelected} = this.state
        // eslint-disable-next-line
        answersSelected.map((answer, index) => {
            if (index < 4) {
                if (this.questions.slice(0, 4).indexOf(answer) >=0) {
                    answerCorrectMobile.push('correct')
                } else {
                    answerCorrectMobile.push('inCorrect')
                }
            } else if (index >= 4) {
                if (this.questions.slice(4, 9).indexOf(answer) >=0) {
                    answerCorrectMobile.push('correct')
                } else {
                    answerCorrectMobile.push('inCorrect')
                }
            }
        })
        this.setState({
            answerCorrect: answerCorrectMobile,
        })
        if (answerCorrectMobile.indexOf('?') === -1 && answerCorrectMobile.indexOf('inCorrect') === -1) {
            this.setState({correctMobile: '1'})
        } else {
            this.setState({correctMobile: '0'})
        }
        this.props.showNextBtn()
    }

    closePopup = () => {
        document.body.style.overflow = "";
        this.setState({showPopup: false})
    }

    _renderTestMobile = () => {
        const {showPopup, answersMobile, questionsMobile, answersSelected, correctMobile, answerCorrectMobile} = this.state
        return (
            <div className="test_mobile">
                {correctMobile !== '' ?
                    <div className={'info ' + (correctMobile === '0' && 'error')}>
                        <div className="info_head">
                            {correctMobile === '1' ? 'все верно!' : 'К сожалению, ваш ответ не верен.'}
                        </div>
                    </div>
                    :
                    <button className="btn btn_blue" onClick={() => this.checkAnswerMobile()}>
                        ПРОВЕРИТЬ
                    </button>
                }
                <div className="flex">
                    <div className="flex_img">
                        <img className="img" src={img1} alt=""/>
                    </div>
                </div>
                <div className="answers">
                    <div className="answers_box top">
                        <p className="answers_head">
                            Ферментируемая клетчатка - Пектин
                        </p>
                        {this.questions.slice(0, 4).map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={'answers_item ' + (answersMobile.indexOf(index + 1) >=0 ? 'selected ' : '') + answerCorrectMobile[index]}
                                    onClick={() => {this.selectAnswer(index + 1)}}
                                >
                                    {answersSelected[index]}
                                </div>
                            )
                        })

                        }
                    </div>
                    <div className="answers_box bottom">
                        <p className="answers_head">
                            Неферментируемая клетчатка - Целлюлоза
                        </p>
                        {this.questions.slice(4, 9).map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={'answers_item ' + (answersMobile.indexOf(index + 5) >=0 ? 'selected ' : '') + answerCorrectMobile[index + 4]}
                                    onClick={() => {this.selectAnswer(index + 5)}}
                                >
                                    {answersSelected[index + 4]}
                                </div>
                            )
                        })

                        }
                    </div>
                </div>
                {showPopup &&
                <div className="popup">
                    <div className="popup_wrp">
                        <div
                            className="popup_closed"
                            onClick={() => {this.closePopup()}}
                        />
                        <div className="popup_flex">
                            <p className="popup_text">
                                Выберите вариант ответа:
                            </p>
                            <div className="questions">
                                {this.questions.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={'questions_item ' + (questionsMobile.indexOf(index + 1) >=0 ? 'selected ' : '')}
                                            onClick={() => {this.selectQuestion(index + 1)}}
                                        >
                                            {item}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }

    render() {
        const {windowWidth} = this.state
        return (
            <div className="step9">
                <InfoBlock
                    text={`Перетащите слагаемые элементы ферментируемой и неферментируемой клетчатки, затем нажмите кнопку «Продолжить» чтобы узнать ответ.`}
                />
                <TextBlock
                    text={`В состав рационов PEDIGREE<sup>®</sup> входит клетчатка, способствующая правильной работе пищеварительной системы. Используется ферментируемая и неферментируемая клетчатка. Каждая из них играет свою роль в поддержании процесса пищеварения. <span class="text_dark-blue">Источником клетчатки в рационах PEDIGREE<sup>®</sup> является пульпа сахарной свеклы.</span>`}
                />
                {windowWidth >= 768 ?
                    <div className="test">
                        {this._renderTestDesktop()}
                    </div>
                    :
                    <div className="test">
                        {this._renderTestMobile()}
                    </div>
                }
            </div>
        )
    }
}
