import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson32/step27-1.png'
import img1xs from '../../../image/lessons/lesson32/step27-1xs.png'


export default class step6 extends React.Component {


    render() {
        return (
            <div className="step27">
                <TextBlock
                    text={`<b>Организм собаки каждый день подвергается воздействию свободных радикалов, которым противостоит антиоксидантная система организма.</b>`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} alt="" className="imgCenter"/>
                </picture>
                <div className="info">
                    Когда активность антиоксидантной системы недостаточна, создаются благоприятные условия для развития целого ряда заболеваний, а также преждевременного старения организма.
                </div>
            </div>
        )
    }
}
