import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson1/step11-1.png";
import img2 from "../../../image/lessons/lesson1/step11-2.png";
import img3 from "../../../image/lessons/lesson1/step11-3.png";
import img4 from "../../../image/lessons/lesson1/step21-1.png";
import img5 from "../../../image/lessons/lesson1/step21-2.png";
import img6 from "../../../image/lessons/lesson1/step21-3.png";
import img7 from "../../../image/lessons/lesson1/step21-4.png";



export default class step21 extends React.Component {

    render() {
        return (
            <div className="step21">
                <TextBlock
                    text={`В больших городах количество стерилизованных кошек и кастрированных котов возрастает, в то время как в сельской местности преобладают нестерилизованные или некастрированные кошки и коты.`}
                />
                <div className="table">
                    <div className="table_flex">
                        <div className="table_flex_el head">
                            <img className="table_flex_head_img" src={img1} alt=""/>
                            <p className="table_flex_head_text">Общая</p>
                        </div>
                        <div className="table_flex_el data">
                            <img className="table_flex_data_img" src={img4} alt=""/>
                        </div>
                    </div>
                    <div className="table_flex">
                        <div className="table_flex_el head">
                            <img className="table_flex_head_img" src={img2} alt=""/>
                            <p className="table_flex_head_text">Городская</p>
                        </div>
                        <div className="table_flex_el data">
                            <img className="table_flex_data_img" src={img5} alt=""/>
                        </div>
                    </div>
                    <div className="table_flex">
                        <div className="table_flex_el head">
                            <img className="table_flex_head_img" src={img3} alt=""/>
                            <p className="table_flex_head_text">Деревенская</p>
                        </div>
                        <div className="table_flex_el data">
                            <img className="table_flex_data_img" src={img6} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="footnote">
                    <div className="footnote_el">
                        <span className="footnote_point"></span><span className="footnote_text">Не стерилизованы/не кастрированы</span>
                    </div>
                    <div className="footnote_el">
                        <span className="footnote_point"></span><span className="footnote_text">Стерилизованы/кастрированы</span>
                    </div>
                </div>
                <div className="flex">
                    <div className="flex_el">
                        <img className="flex_el_img" src={img7} alt=""/>
                    </div>
                    <div className="flex_el">
                        <TextBlock
                            text={`<b>У стерилизованных кошек и кастрированных котов часто возникают проблемы с весом и мочевыделительной системой.</b>
                            
                            Существуют специализированные корма, позволяющие сохранять здоровье мочевыделительной системы и поддерживать оптимальный вес малоактивных кошек. Например, эти функции выполняет корм <span class="text_green">PERFECT FIT™ Sterile.</span>`}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
