import React from 'react'
import Resume from "../../../Components/LessonAndTestComponents/Resume";

import img1 from "../../../image/lessons/lesson4/step9-1.png";

export default class step9 extends React.Component {
    state = {
        resumeData: [
            `Жиры различаются <b>комбинациями жирных кислот,</b> из которых они состоят.`,
            `Главный источник Омега-3 жирных кислот — <b>рыбий жир.</b>`,
            `Переваривание жиров в кишечнике осуществляется под воздействием <b>желчных кислот и фермента липазы.</b>`,
            `Группы жирных кислот <b>Омега-3 и Омега-6</b> — главные незаменимые жирные кислоты в организме.`,
            `Главные источники Омега-6 жирных кислот — <b>говяжий и птичий жир, подсолнечное масло.</b>`,
        ]
    }

    render() {
        return (
            <div className="step9">
                <Resume
                    resumeData={this.state.resumeData}
                    img={img1}
                />
            </div>
        )
    }
}
