import React from 'react'
import Resume from "../../../Components/LessonAndTestComponents/Resume";

import img1 from "../../../image/lessons/lesson1/step19-1.png";


export default class step19 extends React.Component {

    state = {
        resumeData: [
            `Домашние собаки – <b>самый разнообразный вид</b> млекопитающих в мире.`,
            `Численность собак в России составляет <b>22,6 млн.</b>`,
            `Популяция маленьких и миниатюрных собак составляет <b>41% от общей численности собак.</b>`,
            `Породы собак классифицированы <b>в 10 породных групп.</b>`,
        ]
    }

    render() {
        return (
            <div className="step9">
                <Resume
                    resumeData={this.state.resumeData}
                    img={img1}
                />
            </div>
        )
    }
}
