import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson15/step2-1.png';
import img2 from '../../../image/lessons/lesson15/step2-2.png';
import img3 from '../../../image/lessons/lesson15/step2-3.png';
import img4 from '../../../image/lessons/lesson15/step2-4.png';
import img5 from '../../../image/lessons/lesson15/step2-5.png';
import img6 from '../../../image/lessons/lesson15/step2-6.png';
import img7 from '../../../image/lessons/lesson15/step2-7.png';
import img8 from '../../../image/lessons/lesson15/step2-8.png';

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <TextBlock
                    text={`Давайте узнаем, какие животные являются самыми распространёнными домашними питомцами по всему миру. Как Вы видите, в основном это собаки, кошки и рыбы.`}
                />
                <div className="wrap">
                    <div className="rating dog">
                        <img className="rating_img" src={img1} alt=""/>
                        <p className="rating_text">
                            Собаки
                        </p>
                        <p className="rating_percent">
                            33%
                        </p>
                    </div>
                    <div className="rating cat">
                        <img className="rating_img" src={img2} alt=""/>
                        <p className="rating_text">
                            Кошки
                        </p>
                        <p className="rating_percent">
                            23%
                        </p>
                    </div>
                </div>
                <div className="flex">
                    <div className="flex_el">
                        <div className="rating fish">
                            <img className="rating_img" src={img3} alt=""/>
                            <p className="rating_text">
                                Рыбы
                            </p>
                            <p className="rating_percent">
                                12%
                            </p>
                        </div>
                        <div className="rating other">
                            <img className="rating_img" src={img4} alt=""/>
                            <p className="rating_text">
                                Птицы
                            </p>
                            <p className="rating_percent">
                                6%
                            </p>
                        </div>
                        <div className="rating other">
                            <img className="rating_img" src={img5} alt=""/>
                            <p className="rating_text">
                                Другие
                            </p>
                            <p className="rating_percent">
                                6%
                            </p>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_block desktop">
                            <div className="flex_el_box">
                                <img src={img7} alt="" className="flex_el_img"/>
                                <p className="flex_el_text">
                                    <b>% домохозяйств, живущих с разными домашними животными.</b>
                                </p>
                            </div>
                            <div className="flex_el_box">
                                <img src={img8} alt="" className="flex_el_img"/>
                                <p className="flex_el_text">
                                    Исследование GFK в 22 странах мира, 2016 г
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrap">
                    <div className="rating nobody">
                        <img className="rating_img" src={img6} alt=""/>
                        <p className="rating_text">
                            Нет никого
                        </p>
                        <p className="rating_percent">
                            43%
                        </p>
                    </div>
                </div>
                <div className="flex mobile">
                    <div className="flex_el">
                        <div className="flex_el_block">
                            <div className="flex_el_box">
                                <img src={img7} alt="" className="flex_el_img"/>
                                <p className="flex_el_text">
                                    <b>% домохозяйств, живущих с разными домашними животными.</b>
                                </p>
                            </div>
                            <div className="flex_el_box">
                                <img src={img8} alt="" className="flex_el_img"/>
                                <p className="flex_el_text">
                                    Исследование GFK в 22 странах мира, 2016 г
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
