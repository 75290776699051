import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson16/step15-1.png';
import img1xs from "../../../image/lessons/lesson16/step15-1xs.png";

export default class step15 extends React.Component {

    render() {
        return (
            <div className="step15">
                <TextBlock
                    text={`Поскольку в больших городах конкуренция между торговыми сетями выше, то покупатель имеет больший выбор.`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} className="imgCenter" alt=""/>
                </picture>
            </div>
        )
    }
}
