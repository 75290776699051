import React from 'react'
import Resume from "../../../Components/LessonAndTestComponents/Resume";

import img1 from "../../../image/lessons/lesson7/step7-1.png";

export default class step7 extends React.Component {
    state = {
        resumeData: [
            `Кошки являются <b>облигатными плотоядными</b>, то есть <b>безусловными хищниками.</b>`,
            `В диких условиях кошки охотятся в одиночку и <b>едят только что убитую жертву.</b>`,
            `Кошки <b>едят с частотой от 7 до 20 раз за сутки</b>, так как их желудок плохо растягивается.`,
            `В рационе кошек должны преобладать <b>белки.</b>`,
        ]
    }

    render() {
        return (
            <div className="step7">
                <Resume
                    resumeData={this.state.resumeData}
                    img={img1}
                />
            </div>
        )
    }
}
