import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import ArrowBack from '../../../Components/LessonAndTestComponents/ArrowBack';

import img1 from '../../../image/lessons/lesson1/step23-1.png';
import img2 from '../../../image/lessons/lesson1/step23-2.png';
import img3 from '../../../image/lessons/lesson1/step23-3.png';
import img4 from '../../../image/lessons/lesson1/step23-4.png';
import img5 from '../../../image/lessons/lesson1/step23-5.png';


export default class step23 extends React.Component {
    state = {
        flexActive: 0,
        flexs: [1],
        flexHidden: false,
    };

    changeFlex = flexActive => {
        const {flexs} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }
        this.setState({flexActive, flexs, flexHidden: true})
        if (flexs.length === 5) {
            this.props.showNextBtn()
        }
    }

    closeImgDiv = () => {this.setState({flexHidden: false, flexActive: 0})}

    breedData = {
        0: {
            img: '',
            breed: '',
            divText: ``,
        },
        1: {
            img: img1,
            breed: 'Длинношерстные',
            divText: `Многие длинношерстные кошки имеют безмятежный, спокойный характер, любвеобильны и являются идеальными домашними питомцами.
            
            Их длинная шерсть может отрастать на 10 см длиннее, чем у короткошерстных кошек. При правильном и регулярном уходе кошка с длинной шерстью выглядит роскошно.`,
        },
        2: {
            img: img2,
            breed: 'Полудлинношерстные',
            divText: `Эти кошки примечательны своей красивой мягкой шерстью и необычными окрасами. Характерные черты полудлинношерстных кошек – кисточки на ушах, как у рыси, внутренняя шерсть в ушах и шерсть в форме воротничка на груди.
            
            При регулярном уходе и стрижках шерсть таких кошек будет сохранятся в идеальном состоянии.`,
        },
        3: {
            img: img3,
            breed: 'Короткошерстные',
            divText: `Большинство кошек, как чистопородных, так и беспородных, принадлежат к группе короткошерстных. Такие кошки очень популярны, так как они не требуют тщательного ухода за шерстью и могут адаптироваться к любой среде.`,
        },
        4: {
            img: img4,
            breed: 'Сиамо-ориентальные короткошерстные',
            divText: `Эта группа включает в себя две подгруппы: сиамская и ориентальная. Идеальный представитель этой группы – стройная, элегантная, средней величины кошка с длинными утонченными линиями, гибкая и мускулистая.`,
        },
    }

    render() {
        const {flexActive, flexHidden} = this.state
        return (
            <div className={'step23 ' + (flexActive !== 0 && 'padding-none')}>
                <TextBlock
                    text={`Внешние различия между кошками гораздо менее разнообразны, чем у собак. Все кошки имеют схожий вес, рост и телосложение. Основные физические отличия между разными породами кошек – это окрас и длина шерсти.`}
                    hidden={flexHidden === true ? 'hidden' : ''}
                />
                <InfoBlock
                    text={`Нажмите на название породы, чтобы узнать о ней подробнее.
                    
                    Для продолжения, пожалуйста, ознакомьтесь со всей представленной информацией в этом шаге урока.`}
                    hidden={flexHidden === true ? ' hidden' : ''}
                />
                <div className="head">
                    {flexActive !== 0 ? this.breedData[flexActive].breed : 'Все кошки делятся на 4 породные группы:'}
                </div>
                <div className={'flex ' + (flexActive !== 0 && 'active ') +  (flexHidden === true && ' hidden')}>
                    {Object.keys(this.breedData).filter(id => id > 0).map((item) => {
                        return (
                            <div
                                key={item}
                                className={'flex_el ' + (flexActive === (item) && 'active')}
                                onClick={() => {this.changeFlex(item)}}
                            >
                                <img className="flex_el_img" src={this.breedData[item].img} alt=""/>
                                <p className="flex_el_text">
                                    {this.breedData[item].breed}
                                </p>
                            </div>
                        )
                    })}
                </div>
                <div className={'imgDiv ' + (flexActive !== 0 && 'active')}>
                    <ArrowBack
                        className={flexActive === 0 ? 'hidden' : ''}
                        returnMenu={this.closeImgDiv}
                    />
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_flex">
                            <img className="imgDiv_img-tablet" src={this.breedData[flexActive].img} alt=""/>
                            {this.breedData[flexActive].divText}
                        </div>
                    </div>
                </div>
                <div className={'popular ' + (flexActive !== 0 && 'hidden')}>
                    <img src={img5} alt="" className="popular_img"/>
                    <p className="popular_text">
                        <b>Беспородные кошки – самые распространенные в России.</b>
                    </p>
                    <p className="popular_text">
                        По данным исследования, беспородные кошки составляют 74% от всей популяции кошек в России, но в больших городах эта цифра постепенно уменьшается.
                    </p>
                    <p className="popular_text">
                        Окрас беспородных кошек может быть очень разнообразным. Такие кошки привязываются к своим хозяевам и легко приспосабливаются к любым условиям.
                    </p>
                </div>
            </div>
        )
    }
}
