import React, {useEffect} from 'react'
import StartPage from '../Page/Login'
// import Admin from '../Page/Entrance/Admin'
// import Owner from '../Page/Entrance/Registration'
// import Auth from '../Page/Entrance/Auth'
// import Forgot from '../Page/Entrance/Forgot'
// import ResetPassword from '../Page/Entrance/ResetPassword'
import Dostupnost from '../Page/dostupnost'
import CoocieForm from '../Page/CoocieForm'
import { Switch, Route, Redirect } from 'react-router-dom'
import PPClub from '../Page/PPClub'
import Pyaterochka from '../Page/Pyaterochka'
import Perekrestok from '../Page/Perekrestok'
import Restore from "../Page/Restore";


export default function Entrance(props) {

    useEffect(() => {
        props.hidePreloader();
    },[]);

    return(
        <entrance>
            <Switch>
                {/*<Route exact path='/startPage' component={StartPage}/>*/}
                <Route exact path='/startPage/dostupnost' component={Dostupnost}/>
                <Route exact path='/startPage/coociesform' component={CoocieForm}/>
                <Route
                    exact path='/startPage/ppclub'
                    children={<PPClub {...props} changeType={props.changeType}/>}
                />
                <Route
                    exact path='/startPage/Pyaterochka'
                    children={<Pyaterochka {...props} changeType={props.changeType}/>}
                />
                <Route
                    exact path='/startPage/Perekrestok'
                    children={<Perekrestok {...props} changeType={props.changeType}/>}
                />
                
                <Route
                       path='/startPage/:link'
                       children={<StartPage {...props} changeType={props.changeType}/>}
                />
                <Route exact path='/restore' children={<Restore {...props} changeType={props.changeType}/>}/>
                <Route
                    path="/*"
                    render={() => {
                        return (
                            <Redirect to="/startPage/auth" />
                        )
                    }}
                />

                {/*<Route exact path='/startPage/admin' children={<Admin changeType={props.changeType}/>}/>*/}
                {/*<Route exact path='/startPage/registration' children={<Owner changeType={props.changeType}/>}/>*/}
                {/*<Route exact path='/startPage/auth' children={<Auth changeType={props.changeType}/>}/>*/}
                {/*<Route exact path='/startPage/forgot' children={<Forgot changeType={props.changeType}/>}/>*/}
                {/*<Route exact path='/startPage/reset-password' children={<ResetPassword changeType={props.changeType}/>}/>*/}
            </Switch>
        </entrance>
    )
}
