import React, {useState, useEffect} from 'react';
import './style.sass'
import Api from "../../Service/Api";
const CoocieForm = () => {
    const THISApi = new Api();
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [text, setText] = useState('')
    const [checkOne, setCheckOne] = useState(false);
    const [checkTwo, setCheckTwo] = useState(false);
    const [completed, setCompleted] = useState(false);

    const ChangecheckOne = ({target: {checked}}) => {
        setCheckOne(checked);
    };
    const ChangecheckTwo = ({target: {checked}}) => {
        setCheckTwo(checked);
    };

    const Send = () =>{
        THISApi.sendFormCoocie(email,name,text)
            .then(res => {
                setEmail('')
                setName('')
                setText('')
                setCompleted(true)
                setTimeout(function (){
                    setCompleted(false)
                }, 3000)
            })
            .catch(res => console.log(res))
    }

    const rout = window.location.href
    const start = rout.indexOf('/startPage')

    return (
        <div className="coociesForm">
            <div className="coociesForm__container">
                <div className="coociesForm__title">
                    Связаться с нами
                </div>
                <div className="coociesForm__subtitle">
                    Мы рады слышать Вас
                </div>
                <div className="coociesForm__desc">
                    <p>Ваш голос важен для нас. Если у вас есть какие-либо вопросы или комментарии к нам, используйте
                        форму ниже, чтобы отправить их нам. Спасибо!</p>

                    <p> В случае возникновения каких-либо затруднений при использовании наших сайтов, пожалуйста,
                        сообщите нам об этом по адресу электронной почты <a
                            href="mailto:ksenia.shumitskaya@effem.com">ksenia.shumitskaya@effem.com</a></p>
                </div>
                <form action="" className="coociesForm__form">
                    {start >=0 &&
                    <input type="email" className="coociesForm__form__input" placeholder="E-mail"
                           onChange={(e) => setEmail(e.target.value)} value={email}/>
                    }
                    <input type="text" className="coociesForm__form__input" placeholder="Тема сообщения"
                           onChange={(e) => setName(e.target.value)} value={name}/>
                    <textarea className="coociesForm__form__textarea" placeholder="Введите текст вашего сообщения"
                              onChange={(e) => setText(e.target.value)} value={text}></textarea>
                    <div className="coociesForm__form__check">
                        <div className="coociesForm__form__check__item">
                            <input name='a' type="checkbox" checked={checkOne} onChange={ChangecheckOne}/>
                            <span></span>
                        </div>
                        <span>Персональные данные, отправленные через эту форму, будут сохранены только для ответа на ваш вопрос или беспокойство и не будут использоваться в маркетинговых целях.</span>
                    </div>
                    <div className="coociesForm__form__check">
                        <div className="coociesForm__form__check__item">
                            <input name='b' type="checkbox" onChange={ChangecheckTwo}/>
                            <span></span>
                        </div>
                        <span>Чтобы отправить форму, вам должно быть не менее 18 лет.</span>
                    </div>
                    <button disabled={checkOne && checkTwo ? false : true} className="coociesForm__form__btn" type='button'
                            onClick={() => Send()}>Отправить
                    </button>

                         <div style={completed ? {opacity:1} : {opacity:0}} className="completed">Ваше сообщение отправлено!</div>


                </form>
            </div>
        </div>
    )
}
export default CoocieForm
