import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson32/step29-1.png'
import img1xs from '../../../image/lessons/lesson32/step29-1xs.png'


export default class step29 extends React.Component {


    render() {
        return (
            <div className="step29">
                <TextBlock
                    text={`<b>Кормление щенков рационом, обогащенным антиоксидантами, способствует более адекватному иммунному ответу на вакцинацию*</b>`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} alt="" className="imgCenter"/>
                </picture>
            </div>
        )
    }
}
