import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson18/step4-1.jpg'

export default class step4 extends React.Component {

    render() {
        return (
            <div className="step4">
                <div className="flex">
                    <div className="flex_el">
                        <TextBlock
                            text={`У собаки <b>42 зуба</b>, предназначенных для того, чтобы:`}
                        />
                        <ul className="list">
                            <li className="list_item">разгрызать, раскусывать и употреблять пищу;</li>
                            <li className="list_item">играть;</li>
                            <li className="list_item">грызть;</li>
                            <li className="list_item">дергать, таскать, двигать.</li>
                        </ul>
                    </div>
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_img"/>
                    </div>
                </div>
            </div>
        )
    }
}
