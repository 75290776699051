import React from 'react';
import './style.sass'
import {Link} from "react-router-dom";

import logo from '../../image/LeftSideBar/logo.png'
import logo2 from '../../image/LeftSideBar/logo-pet-parents-club.png'
import logoPyaterochka from '../../image/LeftSideBar/logo-pyaterochka.png'
import logoPerekrestok from '../../image/LeftSideBar/logo-perekrestok.png'
import Profile from './Profile.js'
import MenuBtn from './MenuBtn.js'

import Api from '../../Service/Api'


export default class LeftSideBar extends React.Component{
    Api = new Api();

    state = {
        user: undefined,
        menuActive: true,
        vipStatus: undefined
    }

    componentDidMount() {
        this.setState({status: this.props.status})
        this.Api.getUserData()
            .then((res) => {
                this.setState(
                    {vipStatus: res.user.is_vip}
                )
            })
        console.log('init')
    }

    menuOpen = () => {
        this.setState({
            menuActive: !this.state.menuActive
        })
    }

    render() {
        const {menuActive, vipStatus} = this.state
        const {user, status} = this.props
        const buttonColor1 = status === 'Blue' ? '#02D7B9' : status === 'pyaterochka' ? '#BC1110' : status === 'perekrestok' ? '#1F6A2D' : '#146BB3'
        const buttonColor2 = status === 'Blue' ? '#02D7B9' : status === 'pyaterochka' ? '#A91615' : status === 'perekrestok' ? '#1B5025' : '#1F72B7'

        return (
            <div className={`LeftSideBar ${status}`}>

                {status === 'Blue' ?
                <Link className="logo logo--Blue" to={'/'}>
                    <img className="logo--Image" src={logo2} alt=""/>
                </Link> :
                status === 'pyaterochka' ?
                <Link className="logo logo--pyaterochka" to={'/'}>
                    <img src={logoPyaterochka} alt=""/>
                </Link> :
                status === 'perekrestok' ?
                    <Link className="logo logo--perekrestok" to={'/'}>
                        <img src={logoPerekrestok} alt=""/>
                    </Link> :
                <Link className="logo" to={'/'}>
                    <img src={logo} alt=""/>
                </Link>
                }


                <div className={`top ${status} ` + (menuActive ? "closed" : "")}>
                    <Profile user={user} status={status} closePopup={this.menuOpen}/>

                    {status === 'Blue' &&
                    <MenuBtn status={status} color="#02D7B9" to={'/PPClubVideo'} name={'Pet Parents Club'} type={'btn'} bgc={buttonColor2} closePopup={this.menuOpen}
                             svg={<svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                 <path d="M6.32879 2.22612e-08C5.49623 -6.87248e-05 4.67192 0.159092 3.90352 0.468278C3.13512 0.777464 2.43789 1.23054 1.85215 1.8013C-0.618845 4.19865 -0.615922 8.07484 1.85215 10.4717L11.4111 19.7673C11.4871 19.8411 11.5775 19.8996 11.677 19.9395C11.7765 19.9794 11.8832 20 11.991 20C12.0988 20 12.2055 19.9794 12.305 19.9395C12.4045 19.8996 12.4949 19.8411 12.5708 19.7673C15.7622 16.6733 18.9542 13.5803 22.1469 10.4882C24.6179 8.09317 24.6179 4.20476 22.1469 1.80976C21.5593 1.24044 20.861 0.788687 20.092 0.480432C19.3229 0.172176 18.4984 0.0134929 17.6656 0.0134929C16.8328 0.0134929 16.0083 0.172176 15.2392 0.480432C14.4702 0.788687 13.7719 1.24044 13.1843 1.80976L12.0076 2.96143L10.8137 1.80177C10.2266 1.23099 9.52829 0.777928 8.75889 0.468694C7.98948 0.159461 7.16425 0.000169011 6.33074 2.22612e-08H6.32879ZM6.32879 1.56298C6.94805 1.56462 7.56083 1.6847 8.13167 1.91627C8.70252 2.14784 9.2201 2.4863 9.65448 2.91207L11.4282 4.63111C11.5042 4.70474 11.5946 4.76315 11.6941 4.80301C11.7936 4.84287 11.9003 4.86339 12.008 4.86339C12.1158 4.86339 12.2225 4.84287 12.322 4.80301C12.4215 4.76315 12.5118 4.70474 12.5879 4.63111L14.3445 2.92053C16.1962 1.12581 19.1365 1.12581 20.9871 2.92053C21.4271 3.34348 21.7761 3.84605 22.0143 4.39941C22.2525 4.95277 22.3751 5.54604 22.3751 6.1452C22.3751 6.74437 22.2525 7.33764 22.0143 7.891C21.7761 8.44436 21.4271 8.94692 20.9871 9.36987C17.9884 12.2768 14.9897 15.186 11.991 18.0976L3.01188 9.36141C2.572 8.93843 2.22299 8.43586 1.98485 7.8825C1.74671 7.32915 1.62413 6.73589 1.62413 6.13674C1.62413 5.5376 1.74671 4.94434 1.98485 4.39098C2.22299 3.83763 2.572 3.33506 3.01188 2.91207C3.44465 2.48662 3.96071 2.14832 4.53011 1.91681C5.0995 1.6853 5.71089 1.56519 6.32879 1.56345V1.56298Z" fill="white"/>
                             </svg>}/>
                    }
                    <MenuBtn status={status} color="#02D7B9" to={'/myTraining'} name={'Мое обучение'} type={'btn'} bgc={buttonColor1} closePopup={this.menuOpen}
                             svg={<svg width="22" height="29" viewBox="0 0 22 29" fill="none">
                                 <path
                                     d="M5.38776 28.05H5.43776V28.0297H19.9796C20.5776 28.0297 21.05 27.5628 21.05 26.9872V7.68417C21.05 7.10932 20.5579 6.62142 19.9796 6.62142H5.36735H4.16327C3.12964 6.62142 2.27449 5.77282 2.27449 4.72693V4.13953C2.27449 3.11458 3.12896 2.2653 4.18367 2.2653H19.898C20.1497 2.2653 20.3561 2.06098 20.3561 1.8102V1.4051C20.3561 1.15433 20.1497 0.95 19.898 0.95H4.18367C2.40131 0.95 0.95 2.38989 0.95 4.15979V4.74719V24.8402C0.95 26.6101 2.40131 28.05 4.18367 28.05H5.38776ZM4.09286 7.9363V26.7331C3.08199 26.6876 2.27449 25.8687 2.27449 24.8402V7.31754C2.78697 7.68551 3.42054 7.92495 4.09286 7.9363ZM19.7255 26.8157H5.45816V7.93672H19.7255V26.8157Z"
                                     fill="white" stroke="white" strokeWidth="0.1"/>
                                 <path
                                     d="M4.46944 3.7249C4.21769 3.7249 4.01128 3.92923 4.01128 4.18V4.5851C4.01128 4.83588 4.21769 5.04021 4.46944 5.04021H19.8368C20.0885 5.04021 20.295 4.83588 20.295 4.5851V4.18C20.295 3.92923 20.0885 3.7249 19.8368 3.7249H4.46944Z"
                                     fill="white" stroke="white" strokeWidth="0.1"/>
                                 <path
                                     d="M17.1316 13.6798V13.6298H17.1112V13.2747C17.1112 13.024 16.9048 12.8196 16.653 12.8196H9.87752C9.62578 12.8196 9.41936 13.024 9.41936 13.2747V13.6798C9.41936 13.9306 9.62578 14.1349 9.87752 14.1349H16.6734C16.9252 14.1349 17.1316 13.9306 17.1316 13.6798Z"
                                     fill="white" stroke="white" strokeWidth="0.1"/>
                                 <path
                                     d="M17.1316 16.6168V16.5668H17.1112V16.2117C17.1112 15.961 16.9048 15.7566 16.6531 15.7566H11.5102C11.2585 15.7566 11.0521 15.961 11.0521 16.2117V16.6168C11.0521 16.8676 11.2585 17.0719 11.5102 17.0719H16.6735C16.9252 17.0719 17.1316 16.8676 17.1316 16.6168Z"
                                     fill="white" stroke="white" strokeWidth="0.1"/>
                                 <path
                                     d="M17.1317 19.5539V19.5039H17.1113V19.1488C17.1113 18.898 16.9049 18.6937 16.6531 18.6937H10.6939C10.4422 18.6937 10.2358 18.898 10.2358 19.1488V19.5539C10.2358 19.8046 10.4422 20.009 10.6939 20.009H16.6735C16.9253 20.009 17.1317 19.8046 17.1317 19.5539Z"
                                     fill="white" stroke="white" strokeWidth="0.1"/>
                             </svg>}/>
                    <MenuBtn status={status} color="#02D7B9" to={'/myTesting'} name={'Мои тесты'} type={'btn'} bgc={buttonColor2} closePopup={this.menuOpen}
                             svg={<svg width="26" height="28" viewBox="0 0 26 28" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                 <path
                                     d="M20.8805 27.0157C20.8017 27.0157 20.7229 27.0157 20.5654 26.9369C17.3361 25.6767 14.8157 24.0227 13.1617 21.8961H13.0042C9.53859 21.8961 6.46683 20.8722 3.94642 18.7456C1.34724 16.6977 0.0870361 14.0986 0.0870361 11.0268C0.0870361 8.82145 0.795902 6.77361 2.13487 5.04083C2.60745 4.41072 3.23755 3.78062 3.86766 3.22928C6.46683 1.10268 9.53859 0 13.0042 0C14.2644 0 15.5246 0.157526 16.706 0.393815C16.8635 0.393815 16.9423 0.472578 17.0211 0.55134C18.9114 1.02392 20.6442 1.96907 22.1407 3.22928C24.6611 5.27712 26 7.95506 26 11.0268C26 14.0986 24.7398 16.6977 22.1407 18.8243C21.353 19.4544 20.4866 20.0058 19.6202 20.5571C19.7778 22.2111 20.4866 24.0227 21.6681 25.8342C21.8256 26.1493 21.8256 26.4643 21.5893 26.7794C21.353 26.9369 21.1167 27.0157 20.8805 27.0157ZM13.0042 1.57526C9.85364 1.57526 7.1757 2.52041 4.89157 4.41072C4.26147 4.8833 3.78889 5.43464 3.31632 6.06475C2.21364 7.48248 1.66229 9.1365 1.66229 11.0268C1.66229 13.626 2.76498 15.7526 4.89157 17.6429C7.1757 19.4544 9.85364 20.3996 13.0042 20.3996H13.5555C13.7918 20.3996 14.0281 20.4784 14.1856 20.7147C15.367 22.2111 17.0211 23.5501 19.1477 24.574C18.4388 22.9988 18.045 21.5023 17.9662 20.0845C17.9662 19.7695 18.1237 19.4544 18.36 19.3757C19.3052 18.9031 20.2503 18.273 21.1167 17.6429C23.3221 15.8313 24.4248 13.626 24.4248 11.1056C24.4248 8.42763 23.3221 6.30103 21.1167 4.48949C19.7778 3.30804 18.2025 2.52041 16.5485 2.1266C16.4697 2.1266 16.3122 2.04784 16.2334 1.96907C15.2095 1.65402 14.1068 1.57526 13.0042 1.57526Z"
                                     fill="white"/>
                                 <path
                                     d="M12.9945 16.2951C13.2623 16.2951 13.5301 16.1612 13.7309 15.9603C13.9318 15.7595 14.0657 15.4917 14.0657 15.2239C14.0657 14.9561 13.9318 14.6883 13.7309 14.5545C13.5301 14.3536 13.2623 14.2197 12.9945 14.2197C12.7267 14.2197 12.4589 14.3536 12.2581 14.5545C12.0573 14.7553 11.9903 14.9561 11.9903 15.2239C11.9903 15.4917 12.0573 15.7595 12.2581 15.9603C12.4589 16.2281 12.7267 16.2951 12.9945 16.2951ZM13.0615 6.98939C13.3293 6.98939 13.597 7.05634 13.8648 7.12329C14.0657 7.19023 14.2665 7.32413 14.4674 7.45802C14.6682 7.59192 14.8021 7.79276 14.869 7.9936C14.936 8.19445 15.0029 8.39529 15.0029 8.66308C15.0029 8.93087 14.936 9.13171 14.869 9.33255C14.7351 9.60034 14.6013 9.80118 14.3335 9.93508C14.1326 10.1359 13.8648 10.2698 13.4631 10.4037C13.1284 10.5376 12.7267 10.6715 12.2581 10.7384L12.5259 13.2825H13.3962L13.5301 11.6088C13.9987 11.4749 14.4674 11.341 14.8021 11.1401C15.1368 10.9393 15.4716 10.7384 15.7394 10.4707C16.0071 10.2029 16.141 9.93508 16.2749 9.60034C16.4088 9.2656 16.4758 8.93087 16.4758 8.52918C16.4758 8.1275 16.4088 7.79276 16.2749 7.39108C16.141 7.05634 15.8733 6.7216 15.6055 6.45381C15.3377 6.18602 14.936 5.98518 14.5343 5.85129C14.1326 5.71739 13.664 5.5835 13.1954 5.5835L12.325 5.71739L11.5217 6.11908C11.053 6.31992 10.7183 6.58771 10.4505 6.98939C10.1827 7.32413 10.0488 7.72581 10.0488 8.1275C10.0488 8.46224 10.1827 8.73003 10.3836 8.93087C10.5844 9.13171 10.7853 9.19866 11.12 9.19866C11.3878 9.19866 11.5886 9.13171 11.7225 8.99781C11.8564 8.86392 11.9234 8.59613 11.9234 8.39529C11.9234 8.06055 11.7895 7.79276 11.5217 7.72581C11.5217 7.72581 11.5217 7.72581 11.5886 7.65887C11.7225 7.45802 11.9234 7.32413 12.1911 7.19023C12.392 7.05634 12.7267 6.98939 13.0615 6.98939Z"
                                     fill="white"/>
                             </svg>}/>
                    {user && user.finalTest &&
                    <MenuBtn status={status} color="#02D7B9" to={'/finalTest'} name={'Финальный тест'} type={'btn'} bgc={buttonColor1} closePopup={this.menuOpen}
                             svg={<svg width="26" height="28" viewBox="0 0 26 28" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                 <path
                                     d="M20.8805 27.0157C20.8017 27.0157 20.7229 27.0157 20.5654 26.9369C17.3361 25.6767 14.8157 24.0227 13.1617 21.8961H13.0042C9.53859 21.8961 6.46683 20.8722 3.94642 18.7456C1.34724 16.6977 0.0870361 14.0986 0.0870361 11.0268C0.0870361 8.82145 0.795902 6.77361 2.13487 5.04083C2.60745 4.41072 3.23755 3.78062 3.86766 3.22928C6.46683 1.10268 9.53859 0 13.0042 0C14.2644 0 15.5246 0.157526 16.706 0.393815C16.8635 0.393815 16.9423 0.472578 17.0211 0.55134C18.9114 1.02392 20.6442 1.96907 22.1407 3.22928C24.6611 5.27712 26 7.95506 26 11.0268C26 14.0986 24.7398 16.6977 22.1407 18.8243C21.353 19.4544 20.4866 20.0058 19.6202 20.5571C19.7778 22.2111 20.4866 24.0227 21.6681 25.8342C21.8256 26.1493 21.8256 26.4643 21.5893 26.7794C21.353 26.9369 21.1167 27.0157 20.8805 27.0157ZM13.0042 1.57526C9.85364 1.57526 7.1757 2.52041 4.89157 4.41072C4.26147 4.8833 3.78889 5.43464 3.31632 6.06475C2.21364 7.48248 1.66229 9.1365 1.66229 11.0268C1.66229 13.626 2.76498 15.7526 4.89157 17.6429C7.1757 19.4544 9.85364 20.3996 13.0042 20.3996H13.5555C13.7918 20.3996 14.0281 20.4784 14.1856 20.7147C15.367 22.2111 17.0211 23.5501 19.1477 24.574C18.4388 22.9988 18.045 21.5023 17.9662 20.0845C17.9662 19.7695 18.1237 19.4544 18.36 19.3757C19.3052 18.9031 20.2503 18.273 21.1167 17.6429C23.3221 15.8313 24.4248 13.626 24.4248 11.1056C24.4248 8.42763 23.3221 6.30103 21.1167 4.48949C19.7778 3.30804 18.2025 2.52041 16.5485 2.1266C16.4697 2.1266 16.3122 2.04784 16.2334 1.96907C15.2095 1.65402 14.1068 1.57526 13.0042 1.57526Z"
                                     fill="white"/>
                                 <path
                                     d="M12.9945 16.2951C13.2623 16.2951 13.5301 16.1612 13.7309 15.9603C13.9318 15.7595 14.0657 15.4917 14.0657 15.2239C14.0657 14.9561 13.9318 14.6883 13.7309 14.5545C13.5301 14.3536 13.2623 14.2197 12.9945 14.2197C12.7267 14.2197 12.4589 14.3536 12.2581 14.5545C12.0573 14.7553 11.9903 14.9561 11.9903 15.2239C11.9903 15.4917 12.0573 15.7595 12.2581 15.9603C12.4589 16.2281 12.7267 16.2951 12.9945 16.2951ZM13.0615 6.98939C13.3293 6.98939 13.597 7.05634 13.8648 7.12329C14.0657 7.19023 14.2665 7.32413 14.4674 7.45802C14.6682 7.59192 14.8021 7.79276 14.869 7.9936C14.936 8.19445 15.0029 8.39529 15.0029 8.66308C15.0029 8.93087 14.936 9.13171 14.869 9.33255C14.7351 9.60034 14.6013 9.80118 14.3335 9.93508C14.1326 10.1359 13.8648 10.2698 13.4631 10.4037C13.1284 10.5376 12.7267 10.6715 12.2581 10.7384L12.5259 13.2825H13.3962L13.5301 11.6088C13.9987 11.4749 14.4674 11.341 14.8021 11.1401C15.1368 10.9393 15.4716 10.7384 15.7394 10.4707C16.0071 10.2029 16.141 9.93508 16.2749 9.60034C16.4088 9.2656 16.4758 8.93087 16.4758 8.52918C16.4758 8.1275 16.4088 7.79276 16.2749 7.39108C16.141 7.05634 15.8733 6.7216 15.6055 6.45381C15.3377 6.18602 14.936 5.98518 14.5343 5.85129C14.1326 5.71739 13.664 5.5835 13.1954 5.5835L12.325 5.71739L11.5217 6.11908C11.053 6.31992 10.7183 6.58771 10.4505 6.98939C10.1827 7.32413 10.0488 7.72581 10.0488 8.1275C10.0488 8.46224 10.1827 8.73003 10.3836 8.93087C10.5844 9.13171 10.7853 9.19866 11.12 9.19866C11.3878 9.19866 11.5886 9.13171 11.7225 8.99781C11.8564 8.86392 11.9234 8.59613 11.9234 8.39529C11.9234 8.06055 11.7895 7.79276 11.5217 7.72581C11.5217 7.72581 11.5217 7.72581 11.5886 7.65887C11.7225 7.45802 11.9234 7.32413 12.1911 7.19023C12.392 7.05634 12.7267 6.98939 13.0615 6.98939Z"
                                     fill="white"/>
                             </svg>}/>
                    }
                    {vipStatus === 'Blue' ? <MenuBtn status={status} color="#02D7B9" to={'/PPCVideo'} name={'Видеолекции'} type={'btn'} bgc={buttonColor1} closePopup={this.menuOpen}/> : null}
                    <MenuBtn status={status} color="#001299" to={'/faq'} name={'Вопросы и ответы'} closePopup={this.menuOpen}/>


                    <button
                        className="menuBtn btn-border"
                        onClick={() => {
                            this.Api.logout()
                                .then((res) => {
                                    if (res === true){
                                        //Если новый пользователь из вип сообщества, отправляем на соответвующую страницу
                                        this.props.changeType(status)
                                    }
                                })
                        }}
                    >
                        Выйти из системы
                    </button>
                </div>

                <div className={`openMenu ${status} ` + (menuActive ? "" : "opened")}  onClick={()=>{this.menuOpen()}}>
                    <p></p>
                    <p></p>
                </div>


            </div>
        )
    }
}

