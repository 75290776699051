import React from 'react'
import './style.sass'

export default class List extends React.Component {

    render() {
        const {ListData} = this.props
        return (
            <ul className="List">
                {ListData.map((item, index) => {
                    return (
                        <li
                            key={index}
                            dangerouslySetInnerHTML={{ __html: item }}
                        >
                        </li>
                    )
                })}
            </ul>
        )
    }
}