import React from 'react'

import img1 from '../../../image/lessons/lesson15/step10-1.png'
import img2 from '../../../image/lessons/lesson15/step10-2.png'


export default class step6 extends React.Component {

    render() {
        return (
            <div className="step10">
                <div className="flex">
                    <img src={img1} alt="" className="img"/>
                    <img src={img2} alt="" className="img"/>
                </div>
            </div>
        )
    }
}
