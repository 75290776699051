import React from 'react';
import DragDropContainer  from './src/DragDropContainer';

/*
    Food is a draggable element (in a DragDropContainer)
*/

export default class Questions extends React.Component {
    landedOn(e) {
      console.log('I was dropped on ' + e.dropData.name)
      console.log({'onDrop event passed back to Food': e});
    }

    render() {
      // note use of render prop below, rather than child element
      return (
        <DragDropContainer
          targetKey={this.props.targetKey}
          dragClone={this.props.dragClone || false}
          dragData={{label: this.props.label, question_el: this.props.question_el, img: this.props.img}}
          customDragElement={this.props.customDragElement}
          onDrop={this.landedOn}
          render = {() => {
            return (
                <div className={this.props.className}>
                    {this.props.img &&
                    <span className={'icon'}><img src={this.props.img} alt=""/></span>
                    }
                    {this.props.text}
                </div>
            )
          }}
        />

      );
    }
  }
