import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson13/step2-1.png'
import img2 from '../../../image/lessons/lesson13/step2-2.png'
import img3 from '../../../image/lessons/lesson13/step2-3.png'
import img4 from '../../../image/lessons/lesson13/step2-4.png'

export default class step2 extends React.Component {

    state = {
    }

    render() {
        return (
            <div className="step2">
                <TextBlock
                    text={`Создание кормов делится на несколько этапов. Каждый этап имеет важное значение в производственном процессе.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_wrp">
                            <img src={img1} alt=""/>
                            <p className="flex_text">
                                научная разработка продукта
                            </p>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_wrp">
                            <img src={img2} alt=""/>
                            <p className="flex_text">
                                отбор и&nbsp;анализ сырья
                            </p>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_wrp">
                            <img src={img3} alt=""/>
                            <p className="flex_text">
                                производство продукта
                            </p>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_wrp">
                            <img src={img4} alt=""/>
                            <p className="flex_text">
                                хранение готовых кормов
                            </p>
                        </div>
                    </div>
                </div>
                <TextBlock
                    text={`Далее вы рассмотрите каждый из этапов подробнее, чтобы лучше понимать процесс создания рационов для домашних животных.`}
                />
            </div>
        )
    }
}
