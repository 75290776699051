import React, {useState, useEffect} from 'react';
import './style.sass'
import Api from "../../Service/Api";
const Dostupnost = () => {
    const THISApi = new Api();
    const [name, setName] = useState('')
    const [text, setText] = useState('')
    const [checkOne, setCheckOne] = useState(false);
    const [checkTwo, setCheckTwo] = useState(false);

    const ChangecheckOne = ({target: {checked}}) => {
        setCheckOne(checked);
    };
    const ChangecheckTwo = ({target: {checked}}) => {
        setCheckTwo(checked);
    };

    const Send = () =>{
        THISApi.sendFormCoocie(name,text)
            .then(res => {
                setName('')
                setText('')
            })
            .catch(res => console.log(res))
    }


    return (
        <div className="dostupnost">
            <div className="dostupnost__container">
                <div className="dostupnost__title">
                    Доступность
                </div>
                <div className="dostupnost__desc">
                    <p>Компания Mars, Incorporated стремится к обеспечению доступности информации, размещенной на ее сайтах, и удобства навигации для всех посетителей.
                    </p>
                    <p>В случае возникновения каких-либо затруднений при использовании наших сайтов, пожалуйста, сообщите нам об этом по адресу электронной почты <a
                        href="mailto:tatiana.pridchenko@effem.com"> tatiana.pridchenko@effem.com</a>
                    </p>
                    <p>Мы будем рады оказать Вам содействие в целях обеспечении полного доступа
                    к информации, размещенной на наших сайтах.
                    </p>
                </div>
            </div>
        </div>
    )
}
export default Dostupnost
