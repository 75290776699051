import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickTest from "../../../Components/LessonAndTestComponents/OnClickTest";

import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";


export default class step13 extends React.Component {

    state = {
        answersData: [`Лизин`, `Таурин`, `Аргинин`, `Фенилаланин и тирозин`, `Метионин и цистеин`]
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step13">
                <TextBlock
                    text={`Есть несколько аминокислот, без которых жизнь животного невозможна. Однако не для всех животных они являются незаменимыми.
                    
                    Знаете ли вы, какая аминокислота является незаменимой для кошек, но заменимой для собак?`}
                />
                <InfoBlock
                    text={`Выберите и нажмите на соответствующую аминокислоту.`}
                />
                <OnClickTest
                    ansewrsData={this.state.answersData}
                    type={'block'}
                    rightAnswers={[2]}
                    showNextBtn={this.showNextBtn}
                    correctText={`Таурин является незаменимой аминокислотой для кошки, но не для собаки.
                    
                    Остальные перечисленные здесь аминокислоты являются незаменимыми и для кошки, и для собаки.`}
                />
            </div>
        )
    }
}
