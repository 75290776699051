import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson5/step14-1.png";

export default class step14 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит и избыток'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step14">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Витамин В7 (биотин) — здоровье кожи, шерсти, нервной системы.</b>
                           
                            Биотин, также известный как витамин Н — один из самых важных витаминов для здоровой кожи и блестящей шерсти животных. Кроме того, он способствует нормальному функционированию нервной системы.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Биотин участвует в следующих процессах:`}
                        />
                        <ul className="list">
                            <li className="list_item">расщепление глюкозы;</li>
                            <li className="list_item">расщепление и синтез жирных кислот;</li>
                            <li className="list_item">расщепление некоторых аминокислот.</li>
                        </ul>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Биотин содержится в печени и почках.
                            
                            У собак биотин вырабатывается кишечными бактериями, поэтому с пищей он должен поступать только при использовании антибиотиков.
                            
                            Кошкам необходимо поступление биотина с пищей.`}
                        />
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Биотин был обнаружен в конце прошлого столетия во время исследования «болезни яичного белка». При этом заболевании употребление большого количества сырого яичного белка вызывало повреждения кожи, потерю волос и нейромышечные расстройства. Связано это с тем, что сырой яичный белок содержит авидин, образующий прочный неактивный комплекс с биотином. Кроме того, авидин «нейтрализует» биотин, образуемый бактериями.`}
                        />
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
