import React from 'react'

import img1 from '../../../image/lessons/lesson32/step13-1.png'
import img2 from '../../../image/lessons/lesson32/step13-2.png'
import img3 from "../../../image/lessons/lesson32/step12-2.png";
import img4 from "../../../image/lessons/lesson32/step13-3.png";
import img5 from "../../../image/lessons/lesson32/step13-4.png";
import Popup from "../../../Components/Popup";


export default class step6 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        pointActive: 0,
        points: [0],
        showPopup: false,
    };

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    clickPoint = pointActive => {
        const {points, windowWidth} = this.state
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        if (windowWidth < 767) {

            if (pointActive > 2) {
                this.setState({
                    pointActive: 0,
                    showPopup: false,
                })
            } else {
                this.setState({
                    pointActive: pointActive,
                    showPopup: true,
                    points: points
                })
            }

        } else {
            this.setState({pointActive, points, showPopup: true})
        }

        if (points.length === 3) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    popupData = {
        1: img4,
        2: img5
    }

    render() {
        const {showPopup, pointActive, windowWidth} = this.state;
        return (
            <div className="step13">
                <div className="confirmation">
                    <img src={img3} alt="" className="confirmation_img"/>
                    <p className="confirmation_text">
                        Ассоциация практикующих ветеринарных врачей подтверждает, что применение сухих рационов Pedigree для регулярного кормления собак в течение 14 дней гарантирует отличное пищеварение
                    </p>
                </div>
                <div className="flex">
                    <div className="flex_el">
                        <img src={img2} alt="" className="flex_el_img"/>
                    </div>
                    <div className="flex_el recommend">
                        <div className="flex_el_click doctor" onClick={() => {this.clickPoint(1)}}/>
                        <div className="flex_el_click logo" onClick={() => {this.clickPoint(2)}}/>
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                </div>
                {showPopup &&
                    <Popup
                        closePopup={this.closePopup}
                    >
                        <img src={this.popupData[pointActive]} alt="" className="Popup_img"/>
                        {windowWidth < 767 &&
                        <button
                            className="btn btn_blue"
                            onClick={() => {this.clickPoint(pointActive + 1)}}
                        >
                            продолжить
                        </button>
                        }
                    </Popup>
                }
            </div>
        )
    }
}
