import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";
import Popup from "../../../Components/Popup";

export default class step34 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        pointActive: 0,
        points: [0],
        showPopup: false,
    };

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    changePoint = pointActive => {
        const {points, windowWidth} = this.state
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }
        if (windowWidth < 767) {

            if (pointActive > 4) {
                this.setState({
                    pointActive: 0,
                    showPopup: false,
                })
            } else {
                this.setState({
                    pointActive: pointActive,
                    showPopup: true,
                    points: points
                })
            }

        } else {
            this.setState({pointActive, points})
        }

        if (points.length === 5) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    infoData = {
        1: {
            text: `Щенки уже не нуждаются в стимулировании мочеиспускания и дефекации`,
            week: `16-21 день`,
        },
        2: {
            text: `Начинается процесс прорезания зубов`,
            week: `20 день`,
        },
        3: {
            text: `Потребности в питательных веществах и энергии уже, не полностью удовлетворяются за счёт молока, хотя оно остается важным источником питательных веществ
            
            В этом возрасте необходимо начинать первый прикорм, помогая пищеварительной системе щенка постепенно адаптироваться к усвоению новых источников питательных веществ
            
            Щенку необходимо давать 3-4 раза в день немного гранул сухого корма, размоченного в воде.`,
            week: `(21-28 дней) 3-4 недели`,
        },
        4: {
            text: `Щенок полностью контролирует температуру тела`,
            week: `28 дней`,
        },
    }

    render() {
        const {points, pointActive, showPopup} = this.state
        return (
            <div className="step34">
                <div className="head">
                    График перевода щенков на корм
                </div>
                <TextBlock
                    text={`<b>Наиболее подходящим для первого прикорма кормом является корм с хорошими вкусовыми качествами и высокой питательной ценностью.</b>`}
                />
                <InfoBlock
                    text={`Познакомьтесь с графиком перевода щенков на корм и введение прикорма, а также правильный отъем от матери.`}
                />
                <div className="progress">
                    <div className="progress_big">
                        {Object.keys(this.infoData).slice(1).map(item => {
                            return (
                                <div key={item} className={'progress_big_item ' + (points.indexOf(Number(item)) >= 0 && 'active')}/>
                            )
                        })}
                    </div>
                    <div className="progress_small">
                        {Object.keys(this.infoData).map(item => {
                            return (
                                <div
                                    key={item}
                                    className={'progress_small_item ' + (points.indexOf(Number(item)) >= 0 && item === '1' ? 'active-first' : points.indexOf(Number(item)) >= 0 ? 'active' : '')}
                                />
                            )
                        })}
                    </div>
                    <div className="progress_points">
                        {Object.keys(this.infoData).map(item => {
                            return (
                                <div
                                    key={item}
                                    className={'progress_points_item ' + (points.indexOf(Number(item)) >= 0 && 'active')}
                                    onClick={() => {this.changePoint(Number(item))}}
                                >
                                    <div
                                        className={'progress_points_item_red ' + (points.indexOf(Number(item)) >= 0 && 'active')}
                                        onClick={() => {this.changePoint(Number(item))}}
                                    >
                                        {item}
                                    </div>
                                    <div className="week">
                                        {this.infoData[item].week}
                                    </div>
                                    <div
                                        className={'info_item ' + (pointActive === Number(item) && 'info_item_show')}
                                    >
                                        <div className="info_item_box">
                                            <TextBlock
                                                text={this.infoData[item].text}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {showPopup &&
                <Popup
                    closePopup={this.closePopup}
                >
                    <div className="Popup_number">
                        {pointActive}
                    </div>
                    <TextBlock
                        text={this.infoData[pointActive].text}
                    />
                    <button
                        className="btn btn_blue"
                        onClick={() => {this.changePoint(pointActive + 1)}}
                    >
                        продолжить
                    </button>
                </Popup>
                }
                {/*<div className="info">
                    {Object.keys(this.infoData).map(item => {
                        return (
                            <div
                                key={item}
                                className={'info_item ' + (pointActive === Number(item) && 'info_item_show')}
                            >
                                <TextBlock
                                    text={this.infoData[item].text}
                                />
                            </div>
                        )
                    })}
                </div>*/}
            </div>
        )
    }
}
