import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson16/step17-1.png';
import img1xs from "../../../image/lessons/lesson16/step17-1xs.png";

export default class step17 extends React.Component {

    render() {
        return (
            <div className="step17">
                <TextBlock
                    text={`Несмотря на широкий выбор магазинов, у большинства покупателей все еще отсутствует привычка регулярного кормления. Большая доля покупателей приобретает корма реже 1 раза в месяц.`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} className="imgCenter" alt=""/>
                </picture>
                <div className="note">
                    * Среди покупателей, совершивших покупку. Данный график не учитывает тех, кто не планировал или не купил. Источник: данные опроса покупателей кормов для животных в каналах торговли, Nielsen, 2016.
                </div>
            </div>
        )
    }
}
