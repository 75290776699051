import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson17/step7-1.png';

export default class step7 extends React.Component {

    render() {
        return (
            <div className="step7">
                <div className="block">
                    Категория кормов для животных в России уже сейчас входит в ТОП 10 FMCG категорий и в будущем будет только расти!
                </div>
                <div className="flex">
                    <div className="flex_el">
                        <img src={img1} alt="" className="imgCenter"/>
                    </div>
                    <div className="flex_el">
                        <TextBlock
                            text={`<b>WALTHAM<sup>®</sup></b> — это:`}
                        />
                        <ul className="list">
                            <li className="list_item">Центр Waltham проводит современные научные исследования и изучает потребности собак и кошек в питании.</li>
                            <li className="list_item">За более чем 50-летнюю историю, центр Waltham издал более 1 700 научных статей о правильном питании питомцев.</li>
                            <li className="list_item">Компания Mars создает готовые рационы для домашних животных, используя научные данные центра Waltham, чтобы гарантировать их пользу, качество и безопасность для кошек и собак!</li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
