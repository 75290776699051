import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

export default class step16 extends React.Component {

    render() {
        return (
            <div className="step16">
                <TextBlock
                    text={`Вы изучили свойства и функции таких важных групп питательных веществ, как жиры и углеводы.
                    
                    Жиры — основной источник энергии. Они содержат незаменимые жирные кислоты и обеспечивают условия для всасывания жирорастворимых витаминов в кишечнике. К незаменимым жирным кислотам относятся кислоты групп Омега-3 и Омега-6.
                    
                    Углеводы — это вещества, молекулы которых состоят из углерода, кислорода и водорода. Главная функция углеводов — источник энергии и пищевых волокон. К углеводам относятся сахара, крахмал и клетчатка, которая бывает ферментируемой и неферментируемой. Клетчатка улучшает работу кишечника, а некоторые ее виды способствуют росту полезных бактерий в кишечнике.`}
                />
            </div>
        )
    }
}
