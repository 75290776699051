import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import DefinitionBlock from '../../../Components/LessonAndTestComponents/DefinitionBlock';

import img1 from "../../../image/lessons/lesson1/step14-1.png";
import img1xs from "../../../image/lessons/lesson1/step14-1xs.png";


export default class step14 extends React.Component {

    render() {
        return (
            <div className="step14">
                <TextBlock
                    text={`Домашняя собака – самый распространенный вид млекопитающих в мире.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <picture className="flex_el_box">
                            <source srcSet={img1xs} media="(max-width: 767px)"/>
                            <img className="flex_el_img" src={img1} alt=""/>
                        </picture>
                    </div>
                    <div className="flex_el">
                        <TextBlock
                            text={`В течение длительного времени потребности человека постоянно менялись, и это привело к распространению множества различных пород собак, выполняющих определенные функции.
                            
                            Разные породы отличаются:`}
                        />
                        <ul className="list">
                            <li className="list_item">внешним видом</li>
                            <li className="list_item">телосложением</li>
                            <li className="list_item">поведением</li>
                            <li className="list_item">характером</li>
                        </ul>
                    </div>
                </div>
                <DefinitionBlock
                    head={'Определения'}
                    text={`<span class="text_blue">Порода</span> – это совокупность домашних животных одного вида, созданная человеком искусственно (путем скрещивания) и имеющая общие физические характеристики. Для каждой породы существует свой стандарт, разработанный породными клубами стран происхождения пород и утвержденный национальными федерациями и ассоциациями.`}
                />
            </div>
        )
    }
}
