import React from 'react'
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";

export default class step1 extends React.Component {

    render() {
        return (
            <div className="step1">
                <VideoBlock
                    src={'https://www.youtube.com/embed/xMSD933vB0E'}
                    title={'https://www.youtube.com/watch?v=xMSD933vB0E'}
                />
            </div>
        )
    }
}
