import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import StatisticsBlock from '../../../Components/LessonAndTestComponents/StatisticsBlock';

import img1 from "../../../image/lessons/lesson1/step11-1.png";
import img2 from "../../../image/lessons/lesson1/step11-2.png";
import img3 from "../../../image/lessons/lesson1/step11-3.png";
import img4 from "../../../image/lessons/lesson1/step11-4.png";


export default class step11 extends React.Component {

    render() {
        return (
            <div className="step11">
                <TextBlock
                    text={`Численность собак в России значительно меньше, чем численность кошек. В сельской местности процент домохозяйств, где есть собака, больше, чем в городах. Это связано с тем, что в деревнях собаки помогают людям охранять дом.`}
                />
                <div className="table">
                    <div className="table_flex rows">
                        <div className="table_flex_row hidden"></div>
                        <div className="table_flex_row">Процент домохозяйств, в которых есть собака</div>
                        <div className="table_flex_row">Популяция собак (в млн.)</div>
                    </div>
                    <div className="table_flex">
                        <div className="table_flex_row head">
                            <img className="table_flex_head_img" src={img1} alt=""/>
                            <p className="table_flex_head_text">Общая</p>
                        </div>
                        <div className="table_flex_row data">
                            <p className="table_flex_row_text">31%</p>
                            <div className="table_flex_mobile">
                                <div className="table_flex_mobile_row">
                                    <p className="table_flex_mobile_name">Процент домохозяйств, в которых есть собака</p>
                                    <p className="table_flex_mobile_data">31%</p>
                                </div>
                                <div className="table_flex_mobile_row">
                                    <p className="table_flex_mobile_name">Популяция собак (в млн.)</p>
                                    <p className="table_flex_mobile_data">22,6</p>
                                </div>
                            </div>
                        </div>
                        <div className="table_flex_row block">
                            22,6
                        </div>
                    </div>
                    <div className="table_flex">
                        <div className="table_flex_row head">
                            <img className="table_flex_head_img" src={img2} alt=""/>
                            <p className="table_flex_head_text">Городская</p>
                        </div>
                        <div className="table_flex_row data">
                            <p className="table_flex_row_text">24%</p>
                            <div className="table_flex_mobile">
                                <div className="table_flex_mobile_row">
                                    <p className="table_flex_mobile_name">Процент домохозяйств, в которых есть собака</p>
                                    <p className="table_flex_mobile_data">24%</p>
                                </div>
                                <div className="table_flex_mobile_row">
                                    <p className="table_flex_mobile_name">Популяция собак (в млн.)</p>
                                    <p className="table_flex_mobile_data">12,7</p>
                                </div>
                            </div>
                        </div>
                        <div className="table_flex_row block">
                            12,7
                        </div>
                    </div>
                    <div className="table_flex">
                        <div className="table_flex_row head">
                            <img className="table_flex_head_img" src={img3} alt=""/>
                            <p className="table_flex_head_text">Деревенская</p>
                        </div>
                        <div className="table_flex_row data">
                            <p className="table_flex_row_text">53%</p>
                            <div className="table_flex_mobile">
                                <div className="table_flex_mobile_row">
                                    <p className="table_flex_mobile_name">Процент домохозяйств, в которых есть собака</p>
                                    <p className="table_flex_mobile_data">53%</p>
                                </div>
                                <div className="table_flex_mobile_row">
                                    <p className="table_flex_mobile_name">Популяция собак (в млн.)</p>
                                    <p className="table_flex_mobile_data">9,9</p>
                                </div>
                            </div>
                        </div>
                        <div className="table_flex_row block">
                            9,9
                        </div>
                    </div>
                </div>
                <StatisticsBlock
                    img={img4}
                    text={'Собаки мужского пола более распространены в России, чем собаки женского пола.'}
                />
            </div>
        )
    }
}
