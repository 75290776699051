import React from 'react'
import Resume from "../../../Components/LessonAndTestComponents/Resume";

import img1 from '../../../image/lessons/lesson2/step18-1.png';

export default class step18 extends React.Component {

    state = {
        resumeData: [
            `Кошки и собаки питаются с целью удовлетворения своих потребностей <b>в питательных веществах и энергии.</b>`,
            `Все питательные вещества и энергия должны употребляться <b>в соответствии с суточной нормой.</b>`,
            `Энергетический баланс соблюдается, когда количество потребляемой энергии <b>равняется</b> количеству затрачиваемой.`,
        ]
    }

    render() {
        return (
            <div className="step18">
                <Resume
                    resumeData={this.state.resumeData}
                    img={img1}
                />
            </div>
        )
    }
}
