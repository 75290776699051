import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson24/step8-1.png'

export default class step8 extends React.Component {

    render() {
        return (
            <div className="step8">
                <TextBlock
                    text={`Блюдо Sheba<sup>®</sup> – полноценное сбалансированное питание для взрослых кошек, разработанное экспертами:`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
