export default class Api {

    baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8000/ajax/' : '/ajax/';
    // baseUrl = 'http://rkf.loc/ajax/';
    // http://mars:7888/ajax/

    getResource = async (url, body) => {
        const res = await fetch(`${this.baseUrl}${url}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/javascript, */*; q=0.01',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            // body: JSON.stringify(body)
            body: body !== undefined ? Object.keys(body).map(key => key + '=' + encodeURIComponent(body[key])).join('&') : ''

        })
        return res.json(); // Возвращение промиса
    }

    /*getClubs = async (test) => {
        return await this.getResource('get-clubs')
    }*/
    getData = async (url) => {
        const res = await fetch(`${this.baseUrl}${url}`, {
            method: 'GET',

        })
        return res.json(); // Возвращение промиса
    }

    getTypeUser = async (typeUser) => {
        return await this.getResource('get-type-user')
    }

    // Список курсов с уроками
    getLessonCourses = async () => {
        return await this.getResource('get-lesson-courses')
    }

    // Подсчет статистики для уроков.
    setLessonStats = async (id, step) => {
        return await this.getResource('set-lesson-stats',{
            id: id,
            step: step,
        })
    }

    // Список курсов с тестами
    getTestCourses = async () => {
        return await this.getResource('get-test-courses')
    }

    startTest = async (lId) => {
        return await this.getResource(`test-step?lId=${lId}`)
    }

    checkAnswer = async (lId, qId, answers) => {
        return await this.getResource(`test-step?lId=${lId}`, {
            qId: qId,
            answers: [answers]
        })
    }

    updateUserData = async (email, city, club, breed, surname, name, phone, status_id, quantity, nursery, federation) => {
        let body = {
            email: email,
            city: city,
            club: club,
            breed: breed,
            surname: surname,
            name: name,
            phone: phone,
            type: status_id,
            countDog: quantity,
            nursery: nursery,
            federation: federation,
        }
        return await this.getResource('update-user-data', body)
    };

    login = async (email, password) => {
        return await this.getResource('login', {
            email: email,
            password: password,
        })
    }

    requestPasswordReset = async (email) => {
        return await this.getResource('request-password-reset', {
            email: email
        })
    }

    logout = async () => {
        return await this.getResource('logout')
    }

    isGuest = async () => {
        return await this.getResource('is-guest')
    }

    getUserData = async () => {
        return await this.getResource('get-user-data')
    }

    getCourses = async () => {
        return await this.getResource('get-courses')
    }
    getCourse = async (id) => {
        return await this.getResource('get-course',{
            id: id
        })
    }

    getTestStepForLessonStep = async (lId, lStep) => {
        return await this.getResource(`test-step-for-lesson-step?lId=${lId}&lStep=${lStep}`)
    }
    getTestStepForLessonStepCheck = async (lId, lStep,qId, answers) => {
        return await this.getResource(`test-step-for-lesson-step?lId=${lId}&lStep=${lStep}`, {
            qId: qId,
            answers: [answers]
        })
    }

    getFinalTest = async () => {
        return await this.getResource('final')
    }

    getFinalTestStep = async (order) => {
        return await this.getResource('final', {
            order: order
        })
    }

    compareFinalTestQuestion = async (qId, answers) => {
        return await this.getResource(`compare-final-test-question`, {
            qId: qId,
            answers: [answers]
        })
    }

    getSignUpFree = async (link) => {
        return await this.getResource(`sign-up-free?link=${link}`)
    }

    signUpFree = async (email, password, link) => {
        let body = {
            email: email,
            password: password
        }
        return await this.getResource(`sign-up-free?link=${link}`, body)
    }


    sendFormCoocie = async (email, name, text) =>{
        let body = {
            email: email,
            title: name,
            message: text
        }
        return await this.getResource(`send-cookie-mail`, body)
    }

    ppClubRegistration = async (info, link) => {
        let body = info
        return await this.getResource(`ppclubregistration?link=${link}`, body)
    }

    getSignUpLink = async (link) => {
        return await this.getData(`sign-up-link?link=${link}`)
    }

    setUserPhoto = async (photo, extension) => {
        return await this.getResource('set-photo', {
            data: photo,
            ext: extension
        })
    }

    setShowSertificate = async () => {
        return await this.getResource('set-show-sertificate?flag=1', )
    }
}

