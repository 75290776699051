import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import ArrowBack from '../../../Components/LessonAndTestComponents/ArrowBack';

import img1 from '../../../image/lessons/lesson13/step6-1.jpg';
import img2 from '../../../image/lessons/lesson13/step6-2.png';
import img3 from '../../../image/lessons/lesson13/step6-3.jpg';
import img4 from '../../../image/lessons/lesson13/step6-4.jpg';
import img5 from '../../../image/lessons/lesson13/step6-5.jpg';
import List from "../../../Components/LessonAndTestComponents/List";


export default class step6 extends React.Component {
    state = {
        flexActive: 0,
        flexs: [1],
        flexHidden: false,
    };

    changeFlex = flexActive => {
        const {flexs} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }
        this.setState({flexActive, flexs, flexHidden: true})
        if (flexs.length === 3) {
            this.props.showNextBtn()
        }
    }

    closeImgDiv = () => {this.setState({flexHidden: false, flexActive: 0})}

    ListData = [
        'усвояемость продукта;',
        'вкусовая привлекательность;',
        'отдельно у кошек — способность продукта помогать профилактике мочекаменной болезни.'
    ]

    render() {
        const {flexActive, flexHidden} = this.state
        return (
            <div className="step6">
                <TextBlock
                    text={`В данной теме мы расскажем вам, как происходит процесс производства кормов на примере компании Mars. Первый этап — <b>это научная разработка кормов,</b> которая <b>проводится в несколько этапов.</b> Без них невозможно создание гарантированно полезного и безопасного продукта.`}
                    hidden={flexHidden === true ? ' hidden' : ''}
                />
                <InfoBlock
                    text={`Нажмите на кнопки, чтобы узнать подробнее об этапах.`}
                    hidden={flexHidden === true ? ' hidden' : ''}
                />

                <div className={'flex ' + (flexHidden === true && 'hidden')}>
                    <div className={'flex_el ' + (flexActive === 1 && 'active')} onClick={() => {
                        this.changeFlex(1)
                    }}>создание рецептуры на основании научных данных
                        <span className="flex_arrow">
                            <img src={img2} alt=""/>
                        </span>
                    </div>
                    <div className={'flex_el ' + (flexActive === 2 && 'active')} onClick={() => {
                        this.changeFlex(2)
                    }}>проверка рецептуры
                        <span className="flex_arrow">
                            <img src={img2} alt=""/>
                        </span>
                    </div>
                    <div className={'flex_el ' + (flexActive === 3 && 'active')} onClick={() => {
                        this.changeFlex(3)
                    }}>исследование предпочтений владельцев домашних животных
                    </div>
                </div>
                {flexActive !== 0 &&
                <div className={'imgDiv ' + (flexActive === 2 ? 'center' : flexActive === 3 ? 'right' : '')}>
                    <ArrowBack
                        returnMenu={this.closeImgDiv}
                    />
                    {flexActive === 1 &&
                    <div className="imgDiv_wrap">
                        <div className="imgPhone">
                            <img className="imgPhoneBg" src={img3} alt=""/>
                        </div>
                        <div className="imgDiv_flex">
                            <img src={img3} alt=""/>
                            <TextBlock
                                text={`На основании научных данных создаётся рецептура, учитывающая потребности животных, а также спецификацию нового корма.`}
                            />
                        </div>
                    </div>
                    }
                    {flexActive === 2 &&
                    <div className="imgDiv_wrap">
                        <div className="imgPhone">
                            <img className="imgPhoneBg" src={img4} alt=""/>
                        </div>
                        <div className="imgDiv_flex">
                            <div>
                                <TextBlock
                                    text={`После создания рецептуры производится <b>пробная</b> партия продукта.
                                
                                <b>До запуска</b> массового производства необходимо убедиться, что продукт <b>благотворно влияет на здоровье</b> животного.
                                
                                Для этого исследуются следующие характеристики:`}
                                />
                                <List
                                    ListData={this.ListData}
                                />
                            </div>
                            <img src={img4} alt=""/>
                        </div>
                    </div>
                    }
                    {flexActive === 3 &&
                    <div className="imgDiv_wrap">
                        <div className="imgPhone">
                            <img className="imgPhoneBg imgWidth" src={img5} alt=""/>
                        </div>
                        <div className="imgDiv_flex">
                            <TextBlock
                                text={`Чтобы продукт пользовался успехом на рынке, важно изучить ожидания владельцев домашних животных.
                                
                                Перед массовым запуском производства проводится большая работа по изучению мнения владельцев домашних животных. Оцениваются такие показатели <b>как привлекательность внешнего вида продукта, его запах, цвет, удобство использования упаковки, привлекательность её дизайна</b> и другие показатели.`}
                            />
                        </div>
                        <img className="img imgDesktop" src={img5} alt=""/>
                    </div>
                    }
                </div>
                }
                <img className={'img img_bottom ' +  (flexHidden === true ? ' hidden' : '')} src={img1} alt=""/>
            </div>
        )
    }
}
