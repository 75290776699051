import React from 'react'
import OnClickBlock from "../../../Components/LessonAndTestComponents/OnClickBlock";
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from "../../../image/lessons/lesson3/step15-1.png";
import img2 from "../../../image/lessons/lesson3/step15-2.png";

export default class step15 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step15">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Метионин и цистеин — сульфоаминокислоты. Участвуют в синтезе рогового вещества — кератина.</b>
                           
                            Синтез кератина необходим организму для сохранения здоровья кожи и шерсти и предусматривает до 30% суточной потребности взрослой собаки в белке.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Метионин должен обязательно входить в рацион животных.
                            
                            Цистеин синтезируется из метионина.
                            
                            Если цистеин поступает в достаточном количестве, он помогает высвобождать метионин для выполнения других функций в организме.`}
                        />
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Метионином и цистеином богаты:`}
                        />
                        <div className="imgDiv_wrap_flex">
                            <ul className="list">
                                <li className="list_item">яичный белок;</li>
                                <li className="list_item">казеин (молочный белок);</li>
                                <li className="list_item">рыба;</li>
                                <li className="list_item">пшеничный глютен;</li>
                                <li className="list_item">кукурузный глютен.</li>
                            </ul>
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img2} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Дефицит метионина и цистеина вызывает проблемы с шерстью, такие как:`}
                        />
                        <ul className="list">
                            <li className="list_item">выпадение;</li>
                            <li className="list_item">медленный рост;</li>
                            <li className="list_item">ломкость.</li>
                        </ul>
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
