import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from "../../../image/lessons/lesson10/step11-1.jpg";


export default class step11 extends React.Component{

    render(){
        return (
            <div className="step11">
                <TextBlock
                    text={`Главная цель ухода за взрослой кошкой — поддержание хорошего состояния здоровья и оптимальной физической формы.
                    
                    Кошка по своей природе является хищником. Поэтому ей необходимо большое количество мясных ингредиентов в пище.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <TextBlock text={`<span class="text_blue">Характеристики оптимального корма для кошек:</span>`}/>
                        <ul className="list">
                            <li className="list_item">высокое содержание белка;</li>
                            <li className="list_item">низкое содержание углеводов;</li>
                            <li className="list_item">хорошая переваримость.</li>
                        </ul>
                    </div>
                    <div className="flex_el">
                        <img className="flex_el_img" src={img1} alt=""/>
                    </div>
                </div>

            </div>
        )
    }

}
