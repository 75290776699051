import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";
import OnClickTest from "../../../Components/LessonAndTestComponents/OnClickTest";

import img1 from "../../../image/lessons/lesson8/step10-1.png";
import img2 from "../../../image/lessons/lesson8/step10-2.png";
import img3 from "../../../image/lessons/lesson8/step10-3.png";

export default class step10 extends React.Component {

    state = {
        answersData: {
            1: {
                img: img1,
                text: `1 порция картофеля фри`
            },
            2: {
                img: img2,
                text: `2 порции картофеля фри`
            },
            3: {
                img: img3,
                text: `6 порций картофеля фри`
            },
        }

    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step10">
                <TextBlock
                    text={`Некоторые владельцы предлагают животным пищу со своего стола или лакомства, предназначенные для людей. Это может вызвать проблемы, так как лакомства для людей обычно более калорийны, чем специальные лакомства для животных.
                    
                    Допустим, тунец — это лакомство для кошки, а картофель фри — для человека. <span class="text_blue">Как вы думаете, <b>скольким порциям картофеля фри</b> для человека эквивалентна <b>одна банка</b> консервов тунца для кошки?</span>`}
                />
                <InfoBlock
                    text={`Выберите подходящий вариант ответа.`}
                />
                <OnClickTest
                    ansewrsData={this.state.answersData}
                    type={'imgText'}
                    rightAnswers={[3]}
                    showNextBtn={this.showNextBtn}
                    quantity={'33.25'}
                    correctText={`1 банка тунца эквивалентна для кошек целым 6 порциям картофеля фри для человека! Чтобы убедиться в том, что человеческая еда, как правило, излишне калорийна для животных, изучите таблицу далее.`}
                />
            </div>
        )
    }
}
