import React, {useEffect, useState} from "react";
import Wrapper from "../../Coomponents/Wrapper";
import LoginLogo from "../../Images/login__logo.png"
import "./style.scss"
import Select from "../../Coomponents/Select";
import TextInput from "../../Coomponents/TextInput";
import RadioGroup from "../../Coomponents/RadioGroup";
import CheckboxInput from "../../Coomponents/CheckboxInput";
import Question from "../../Coomponents/Question";
import Button from "../../Coomponents/Button";
import Api from "../../Service/Api";
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import ru from 'date-fns/locale/ru';
import DatePicker from 'react-datepicker'
import {Link} from "react-router-dom";

const Login = (props) => {

    const [isError, setIsError] = useState(false)
    const [isErrorRadioGroup, setIsErrorRadioGroup] = useState(false)
    const [showErrorRadioGroup, setShowErrorRadioGroup] = useState(false)
    const [errors, setErrors] = useState([])
    const [selectedDay, setSelectedDay] = useState({1: null, 2: null, 3: null, 4: null, 5: null});
    const [calendarDay, setCalendarDay] = useState({1: null, 2: null, 3: null, 4: null, 5: null});

    const [petWeaknessActive, setPetWeaknessActive] = useState({1: true, 2: true, 3: true, 4: true, 5: true});
    const [goodiesActive, setGoodiesActive] = useState({1: true, 2: true, 3: true, 4: true, 5: true});
    const [petClassActive, setPetClassActive] = useState({1: true, 2: true, 3: true, 4: true, 5: true});
                                              
    const [select1, setSelect1] = useState(true);
    const [select2, setSelect2] = useState(true);

    const [food1, setFood1] = useState(true);
    const [food2, setFood2] = useState(true);
    const [food3, setFood3] = useState(true);
    const [food4, setFood4] = useState(true);
    const [food5, setFood5] = useState(true);
 
    const api = new Api      

    const tabsHandler = (e) => {
        [...e.target.closest('.login__tabs-items').querySelectorAll('.login__tabs-item')].map(tab => {
            tab.classList.remove('active')
        })
        e.target.classList.add('active');
        let dataName = e.target.getAttribute('data-name');
        [...e.target.closest('.login__tabs').querySelectorAll('.login__tabs-body')].map(tabBody => {
            tabBody.classList.remove('active');
            let datTab = tabBody.getAttribute('data-tab')
            dataName === datTab ? tabBody.classList.add('active') : console.log(123)
        })
        setIsError(false)
    }

    const petQuestion = (e) => {
        if(e.target.id === "havePet"){
            setIsErrorRadioGroup(true)
            document.querySelector('.pet__information').classList.add('show')
        }
        else {
            document.querySelector('.pet__information').classList.remove('show')
            setIsErrorRadioGroup(false)

            document.querySelectorAll('.petSection').forEach(section => {
                section.querySelectorAll('input').forEach(el => {
                    el.dataset.required = 'false'
                })
            });
        }
    }

    let petCollection = ['1', "2", "3", "4", "5"]
    let data = {};
    const petSelect = (e) => {
        setIsErrorRadioGroup(false);
        setShowErrorRadioGroup(false);
        [...document.querySelectorAll('.petSection')].map((el, index) => {
            el.classList.remove('active')
        });
        let activeTab = +e.target.closest('.radio-group__item').querySelector('.radio__label').textContent - 1;
        // [...document.querySelectorAll('.petSection')][activeTab].classList.add('active');
        // [...document.querySelectorAll('.petSection')].forEach(section => section.classList.add('active'));
        // console.log(activeTab);
        document.querySelectorAll('.petSection').forEach((section, index) => {
            if (index <= activeTab) {
                section.classList.add('active')
                section.querySelectorAll('input').forEach(el => {
                    el.dataset.required = 'true'
                })
            } else {
                section.classList.remove('active')
                section.querySelectorAll('input').forEach(el => {
                    el.dataset.required = 'false'
                })
            }
        });

        // [...e.target.closest('.pet__information').querySelector('#petSection' + (activeTab + 1)).querySelectorAll('input')].map(el => {
        //     el.dataset.required = 'true'
        // });

        data['pets'] = e.target.closest('.radio-group__item').querySelector('label').textContent
    }


    const selSet = (e) => {
        const sel = e.target.closest('.select')
        const input = sel.querySelector('.select_hidden')
        console.log(input.getAttribute('id'))

        if (input.getAttribute('id') === 'foodType1'){
            input.classList.remove('error')
        }
        if (input.getAttribute('id') === 'foodType2'){
            input.classList.remove('error')
        }
        if (input.getAttribute('id') === 'foodType3'){
            input.classList.remove('error')
        }
        if (input.getAttribute('id') === 'foodType4'){
            input.classList.remove('error')
        }
        if (input.getAttribute('id') === 'foodType5'){
            input.classList.remove('error')
        }

    }


    const registration = () => {
        isErrorRadioGroup && setShowErrorRadioGroup(true);

        let inputCollection = [...document.querySelector('.login__tabs-body.active').querySelectorAll('.input')]
        inputCollection.map(el => {
            const spanEl = el.closest('.input__body').querySelector('span');

            if(el.value.length === 0 && el.getAttribute('data-required') !== 'false'){
                el.classList.add('error')
                spanEl.classList.add('error')
            }
            if(el.value.length !== 0 && el.getAttribute('data-required') !== 'false'){
                el.classList.remove('error');
                spanEl.classList.remove('error');
            }

        })

        let selCollection = [...document.querySelector('.login__tabs-body.active').querySelectorAll('.select')]

        selCollection.map((el, index) => {
            const selectEl = el.querySelector('.select__header')
            const selectHidden = el.querySelector('.select_hidden')
            if(selectHidden.getAttribute('id') === 'foodType1' && selectHidden.getAttribute('data-required') === 'true' && selectHidden.value.length === 0){
                selectEl.classList.add('error')
                selectHidden.classList.add('error')
            }
            if(selectHidden.getAttribute('id') === 'foodType2' && selectHidden.getAttribute('data-required') === 'true' && selectHidden.value.length === 0){
                selectEl.classList.add('error')
                selectHidden.classList.add('error')
            }
            if(selectHidden.getAttribute('id') === 'foodType3' && selectHidden.getAttribute('data-required') === 'true' && selectHidden.value.length === 0){
                selectEl.classList.add('error')
                selectHidden.classList.add('error')
            }
            if(selectHidden.getAttribute('id') === 'foodType4' && selectHidden.getAttribute('data-required') === 'true' && selectHidden.value.length === 0){
                selectEl.classList.add('error')
                selectHidden.classList.add('error')
            }
            if(selectHidden.getAttribute('id') === 'foodType5' && selectHidden.getAttribute('data-required') === 'true' && selectHidden.value.length === 0){
                selectEl.classList.add('error')
                selectHidden.classList.add('error')
            }
        })
        let selectCollection = [...document.querySelector('.login__tabs-body.active').querySelectorAll('.select_hidden')].slice(0,2)
        selectCollection.map ((el, index) => {
            const selectEl = el.closest('.select__header')
            const indexEl = index + 1
            if(el.getAttribute('data-required') === 'true' && el.value.length === 0 && indexEl === 1){
                selectEl.classList.add('error')
                setSelect1(true)
            }
            if(el.getAttribute('data-required') === 'true' && el.value.length === 0 && indexEl === 2){
                selectEl.classList.add('error')
                setSelect2(true)
            }
        })
        let checkboxCollection = [...document.querySelector('.login__tabs-body.active').querySelectorAll('input[type="checkbox"]')]
        checkboxCollection.map(checkbox => {
            if(!checkbox.checked && checkbox.getAttribute('data-required') !== 'false'){
                checkbox.classList.add('error')
            }
        })
        let errorInputCollection = [...document.querySelector('.login__tabs-body.active').querySelectorAll('input.error')]
        if(errorInputCollection.length === 0){

            [...document.querySelector('.login__tabs-body.active').querySelectorAll('input[type="text"]')].map(el => {
                data[el.getAttribute('name')] = el.value
            });
            [...document.querySelector('.login__tabs-body.active').querySelectorAll('input[type="email"]')].map(el => {
                data[el.getAttribute('name')] = el.value
            });
            [...document.querySelector('.login__tabs-body.active').querySelectorAll('input[type="password"]')].map(el => {
                data[el.getAttribute('name')] = el.value
            });
            [...document.querySelector('.login__tabs-body.active').querySelectorAll('input[type="radio"]')].map(el => {
                el.checked && (data[el.getAttribute('name')] = el.value)
            });
            [...document.querySelector('.login__tabs-body.active').querySelectorAll('input[type="checkbox"]')].map(el => {
                data[el.getAttribute('id')] = el.checked
            });
            data[document.querySelector('.login__tabs-body.active').querySelector('.select_hidden').getAttribute('name')] = document.querySelector('.login__tabs-body.active').querySelector('.select_hidden').value
            // api.getSignUpLink('ppclub')
            //     .then((res) => {

            //     })
            if (!isErrorRadioGroup && !select1 && !select2) {
                api.ppClubRegistration(data, 'ppclub')
                    .then((res) => {
                        if(res === true){
                            // setIsGuest(false);
                            props.changeType('Blue');
                        } else {
                            setIsError(true)
                            setErrors(Object.values(res))
                        }
                    });
            }
        }
    }

    const login = () => {
        let inputCollection = [...document.querySelector('.login__tabs-body.active').querySelectorAll('.input')]
        inputCollection.map(el => {
            if(el.value.length === 0 && el.getAttribute('data-required') === 'true'){
                el.classList.add('error')
            }
        })
        let errorInputCollection = [...document.querySelector('.login__tabs-body.active').querySelectorAll('input.error')]
        if(errorInputCollection.length === 0){
            let email = document.querySelector('.login__tabs-body.active').querySelector('input[name="email"]').value
            let password = document.querySelector('.login__tabs-body.active').querySelector('input[name="password"]').value
            api.login(email, password)
                .then((res) => {
                    if(res.status === 200){
                        props.changeType('Blue');
                    } else {
                        setIsError(true)
                        setErrors(Object.values(res.error))
                    }
                });
        }
    }

    const showPetWeakness = (number) => {
        setPetWeaknessActive({...petWeaknessActive, [number]: !petWeaknessActive[number]});
    }

    const showPetClass = (number) => {
        setPetClassActive({...petClassActive, [number]: !petClassActive[number]});
    }

    const showGoodies = (number) => {
        setGoodiesActive({...goodiesActive, [number]: !goodiesActive[number]});
    }

    const qwerty = (date, index) => {
        let options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        };
        let newDate = date.toLocaleString("ru", options);
        setSelectedDay({...selectedDay, [index]: newDate});
        setCalendarDay({...calendarDay, [index]: date});
    }
    return(
        <div className="login">
            <Wrapper>
                <div className="login__container">
                    <div className="login__logo">
                        <img className="login__image" src={LoginLogo} alt='Логотип'/>
                    </div>
                    <div className="login__title">Онлайн колледж</div>
                    <div className="login__form">
                        <div className="login__tabs">
                            <div className="login__tabs-items">
                                <div className="login__tabs-item active" onClick={(e) => {tabsHandler(e)}} data-name='login'>Вход</div>
                                <div className="login__tabs-item">|</div>
                                <div className="login__tabs-item" onClick={(e) => {tabsHandler(e)}} data-name='registration'>Регистрация</div>
                            </div>
                            <form className="login__tabs-body active" data-tab="login">
                                <TextInput
                                    placeholder = 'E-MAIL'
                                    required = 'true'
                                    name = 'email'
                                    type = "email"
                                />
                                <TextInput
                                    placeholder = 'ПАРОЛЬ'
                                    required = 'true'
                                    name = 'password'
                                    type = "password"
                                />
                                <Link className="forget__password" to="/restore">ЗАБЫЛИ ПАРОЛЬ?</Link>
                                <Button
                                    title = "войти"
                                    onClick={login}
                                />
                            </form>
                            <form className="login__tabs-body" data-tab="registration">
                                <div>
                                    <TextInput
                                        placeholder = 'Укажите Ваш рабочий электронный адрес'
                                        required = 'true'
                                        name = 'email'
                                        id = 'email'
                                        type = "email"
                                    />
                                    <TextInput
                                        placeholder = 'Укажите Ваше имя'
                                        required = 'true'
                                        name = 'firstName'
                                        id = 'firstName'
                                        type = "text"
                                    />
                                    <TextInput
                                        placeholder = 'Укажите Вашу фамилию'
                                        required = 'true'
                                        name = 'lastName'
                                        id = 'lastName'
                                        type = "text"
                                    />
                                    <TextInput
                                        placeholder = 'Придумайте пароль'
                                        required = 'true'
                                        name = 'password'
                                        id = 'password'
                                        type = "password"
                                    />
                                    {/*<RadioGroup*/}
                                    {/*    label = 'Укажите Ваш пол'*/}
                                    {/*    items = {["М", "Ж"]}*/}
                                    {/*    idNumber = {["gender1", "gender2"]}*/}
                                    {/*    name = 'gender'*/}
                                    {/*    indexChecked = '0'*/}
                                    {/*/>*/}
                                    <Select
                                        title = 'В каком сегменте Вы работаете?'
                                        options = {[
                                            'Corporate',
                                            'Mars Vet Health',
                                            'Mars Global Services',
                                            'Mars Wrigley',
                                            'Mars Pet Nutrition',
                                            'Royal Canin',
                                        ]}
                                        name = 'workSegment'
                                        id = 'workSegment'
                                        required = 'true'
                                        element = '1'
                                        setSelect1 = {setSelect1}

                                    />
                                    <Select
                                        title = 'В каком отделе Вы работаете?'
                                        options = {[
                                            'Business Integration',
                                            'Commercial',
                                            'Corporate Affairs',
                                            'Data, Analytics & Insights',
                                            'Digital Technologies',
                                            'Finance',
                                            'Leadership',
                                            'Legal',
                                            'Marketing',
                                            'P&O',
                                            'R&D',
                                            'Sales',
                                            'Supply',
                                            'Support Services',
                                            'Veterinary',
                                        ]}
                                        name = 'workDepartment'
                                        id = 'workDepartment'
                                        required = 'true'
                                        element = '2'
                                        setSelect2 = {setSelect2}

                                    />
                                    <TextInput
                                        placeholder = 'В каком городе Вы работаете?'
                                        required = 'true'
                                        name = 'workCity'
                                        id = 'workCity'
                                        type = "text"
                                    />
                                    <Question
                                        question = 'Есть ли у Вас домашние питомцы:'
                                        answers = {['Нет', 'Нет, но планирую завести', 'Есть']}
                                        answersId = {['haveNotPet', 'mayBePet', 'havePet']}
                                        name = 'pet'
                                        onClick = {petQuestion}
                                    />
                                    <div className="pet__information">
                                        <div className="radio-group">
                                            <p className="radio-group__label">Сколько у вас питомцев?</p>
                                            {petCollection.map((el, index) => {
                                                return(
                                                    <div className="radio-group__item" key={index}>
                                                        <input
                                                            className="radio__input"
                                                            type="radio"
                                                            name="item"
                                                            onClick = {(e) => {petSelect(e)}}
                                                        />
                                                        <label className="radio__label">{el}</label>
                                                    </div>
                                                )
                                            })}
                                            {showErrorRadioGroup && <div className="radio-group__error">Выберите значение</div>}
                                        </div>
                                        <p className="registration__text"><strong>Заполните,пожалуйста, анкету на каждого питомца</strong></p>

                                        {petCollection.map((el, index) => {
                                            return (
                                                <div key={index} className="petSection" id={"petSection" + el}>
                                                    <div className="registration__text"><strong>Питомец {index + 1}</strong></div>
                                                    <RadioGroup
                                                        label = 'Укажите вид вашего питомца'
                                                        items = {['кошка', "собака"]}
                                                        idNumber = {[`cat${el}`, `dog${el}`]}
                                                        name ={'class' + el}
                                                        indexChecked = '0'
                                                        values = {[1, 0]}
                                                    />
                                                    <TextInput
                                                        placeholder = 'Укажите кличку вашего питомца'
                                                        required = 'false'
                                                        name ={'petName' + el}
                                                        type = "text"
                                                    />
                                                    {/*<TextInput*/}
                                                    {/*    placeholder = 'Укажите примерную дату рождения вашего питомца'*/}
                                                    {/*    required = 'false'*/}
                                                    {/*    name ={'petBirthday' + el}*/}
                                                    {/*    type = "text"*/}
                                                    {/*    mask = "99-99-9999"*/}
                                                    {/*/>*/}
                                                    <p className="registration__text">Укажите примерную дату рождения вашего питомца</p>
                                                    <DayPicker mode="single" locale={ru} selected={calendarDay[index + 1]} onSelect={(date) => {qwerty(date, index + 1)}} fromYear={1998} toYear={2024} captionLayout="dropdown"/>
                                                    {/*<DatePicker*/}
                                                    {/*    selected={startDate}*/}
                                                    {/*    onChange={(date) => setStartDate(date)}*/}
                                                    {/*    peekNextMonth*/}
                                                    {/*    showMonthDropdown*/}
                                                    {/*    showYearDropdown*/}
                                                    {/*    dropdownMode="select"*/}
                                                    {/*/>*/}
                                                    <input className="select_hidden" type="text" name={"petBirthday"+ el} id={"petBirthday" + el} value={selectedDay[el]}/>
                                                    <RadioGroup
                                                        label = 'Укажите пол вашего питомца'
                                                        items = {['М', "Ж"]}
                                                        idNumber = {[`man${el}`, `women${el}`]}
                                                        name ={'petGender' + el}
                                                        indexChecked = '0'
                                                        values = {[0, 1]}
                                                    />
                                                    {/*<TextInput*/}
                                                    {/*    placeholder = 'Укажите породу вашего питомца, если он/она породистый'*/}
                                                    {/*    required = 'false'*/}
                                                    {/*    name ={'petClass' + el}*/}
                                                    {/*    type = "text"*/}
                                                    {/*/>*/}
                                                    <RadioGroup
                                                        label = 'у вас породистый питомец?'
                                                        items = {['Да', "Нет"]}
                                                        name ={'typePet' + el}
                                                        idNumber = {[`typePetYes${el}`, `typePetNo${el}`]}
                                                        indexChecked = '0'
                                                        onChange={() => showPetClass(Number(el))}
                                                        values = {[1, 0]}
                                                    />
                                                    {petClassActive[Number(el)] &&
                                                    <TextInput
                                                        placeholder = 'Укажите породу вашего питомца'
                                                        required = 'false'
                                                        name ={'petClass' + el}
                                                        type = "text"
                                                    />
                                                    }
                                                    <TextInput
                                                        placeholder = 'Укажите примерный вес вашего питомца'
                                                        required = 'false'
                                                        name ={'petWeight' + el}
                                                        type = "text"
                                                    />
                                                    <RadioGroup
                                                        label = 'Ваш питомец стерилизован/кастрирован?'
                                                        items = {['Да', "Нет"]}
                                                        name ={'sterilized' + el}
                                                        idNumber = {[`sterilizedYes${el}`, `sterilizedNo${el}`]}
                                                        indexChecked = '0'
                                                        values = {[1, 0]}
                                                    />
                                                    <RadioGroup
                                                        label = 'Есть ли у вашего питомца заболевания?'
                                                        items = {['Да', "Нет"]}
                                                        name ={'diseases' + el}
                                                        idNumber = {[`diseasesYes${el}`, `diseasesNo${el}`]}
                                                        indexChecked = '0'
                                                        onChange={() => showPetWeakness(Number(el))}
                                                        values = {[1, 0]}
                                                    />
                                                    {petWeaknessActive[Number(el)] &&
                                                        <TextInput
                                                            placeholder = 'Если да, то укажите их'
                                                            required = 'false'
                                                            name ={'petWeakness' + el}
                                                            type = "text"
                                                        />
                                                    }
                                                    <Select
                                                        title = 'Какие из следующих типов корма Вы обычно даете Вашей кошке/собаке?'
                                                        options = {[
                                                            'Влажный и сухой корм в равных пропорциях',
                                                            'Чаще влажный корм, но сухой корм тоже всегда есть в миске',
                                                            'Чаще влажный корм, редко сухой',
                                                            'Чаще сухой корм, иногда влажный',
                                                            'Только влажный корм',
                                                            'Только сухой корм',
                                                            'Домашнюю специально приготовленную еду'
                                                        ]}
                                                        name = {'foodType' + el}
                                                        id = {'foodType' + el}
                                                        required = 'true'
                                                        selSet = {selSet}
                                                    />
                                                    <TextInput
                                                        placeholder = 'Какую марку сухого корма Вы даете своему питомцу чаще всего?'
                                                        required = 'false'
                                                        name ={'petFood' + el + 'Mark1'}
                                                        type = "text"
                                                    />
                                                    <TextInput
                                                        placeholder = 'Какую марку влажного корма Вы даете своему питомцу чаще всего?'
                                                        required = 'false'
                                                        name ={'petFood' + el +'Mark2'}
                                                        type = "text"
                                                    />
                                                    <RadioGroup
                                                        label = 'Даете ли Вы Вашей кошке/собаке лакомства?'
                                                        items = {['Да', "Нет"]}
                                                        name ={'goodies' + el}
                                                        idNumber = {[`goodiesYes${el}`, `goodiesNo${el}`]}
                                                        indexChecked = '0'
                                                        onChange={() => showGoodies(Number(el))}
                                                        values = {[1, 0]}
                                                    />
                                                    <TextInput
                                                        placeholder = {goodiesActive[Number(el)] ? 'Укажите название продукта' : 'Напишите почему'}
                                                        required = 'false'
                                                        name ={'petFoodName' + el}
                                                        type = "text"
                                                    />
                                                    {/*{goodiesActive[Number(el)] &&*/}
                                                    {/*    <TextInput*/}
                                                    {/*        placeholder = {}'Укажите назавание продукта'*/}
                                                    {/*        required = 'false'*/}
                                                    {/*        name ={'petFoodName' + el}*/}
                                                    {/*        type = "text"*/}
                                                    {/*    />*/}
                                                    {/*}*/}
                                                    {/*{!goodiesActive[Number(el)] &&*/}
                                                    {/*    <TextInput*/}
                                                    {/*        placeholder = 'Напишите почему'*/}
                                                    {/*        required = 'false'*/}
                                                    {/*        name ={'petWhyNot' + el}*/}
                                                    {/*        type = "text"*/}
                                                    {/*    />*/}
                                                    {/*}*/}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="login__rules">
                                    <CheckboxInput
                                        star = "true"
                                        id = "siteRules"
                                        label = "Я ознакомился и согласен с <a class='login__rules--underline' href='https://www.mars.com/legal-russia' target='_blank'>Правилами использования сайта</a>, <a class='login__rules--underline' href='https://www.mars.com/privacy-policy-russian' target='_blank'>Положением о конфиденциальности</a> и даю свое согласие на обработку персональных данных."
                                        required = 'true'
                                        name = "siteRules"
                                    />
                                    <CheckboxInput
                                        star = "true"
                                        id = "eighteen"
                                        label = "Мне исполнилось 18 лет."
                                        required = 'true'
                                        name = "eighteen"
                                    />
                                    <CheckboxInput
                                        star = "false"
                                        id = "spamAccept"
                                        label = "Я согласен на получение информационных материалов."
                                        required = 'false'
                                        name = "spamAccept"
                                    />

                                </div>

                                <div className="login__info-block">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.86957 0.000713581C4.34823 0.0659263 -0.0644992 4.60908 0.000713581 10.1304C0.0659263 15.6518 4.60908 20.0645 10.1304 19.9993C15.6518 19.9341 20.0645 15.3909 19.9993 9.86957C19.9341 4.34823 15.3909 -0.0644991 9.86957 0.000713581ZM10.9782 3.32656C11.9999 3.32656 12.3042 3.91348 12.3042 4.58734C12.3042 5.43511 11.6303 6.21766 10.4782 6.21766C9.52177 6.21766 9.06528 5.73943 9.08702 4.93514C9.06528 4.26128 9.63046 3.32656 10.9782 3.32656ZM8.36968 16.2604C7.67408 16.2604 7.17412 15.8474 7.65234 13.978L8.45663 10.6956C8.58706 10.1739 8.6088 9.95652 8.45663 9.95652C8.23926 9.95652 7.34801 10.3261 6.80458 10.6739L6.45677 10.1087C8.15231 8.69574 10.087 7.86972 10.913 7.86972C11.6086 7.86972 11.7173 8.69574 11.3695 9.95652L10.4565 13.4128C10.3043 14.0214 10.3695 14.2388 10.5217 14.2388C10.7391 14.2388 11.4129 13.978 12.0868 13.4563L12.4781 13.978C10.8478 15.6083 9.06528 16.2604 8.36968 16.2604Z" fill="#C6C6C6"/>
                                    </svg>
                                    <p className="login__info-block_text">* поля отмеченные звездочкой обязательны для заполнения</p>
                                </div>
                                <Button
                                    title = "зарегистрироваться"
                                    onClick={registration}
                                />
                            </form>
                            {
                                isError &&
                                <div className="login__error-block">
                                    {errors.join('<br>')}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Wrapper>
        </div>
    )
}

export default Login