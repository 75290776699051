import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

import img1 from "../../../image/lessons/lesson7/step29-1.png";

export default class step29 extends React.Component {

    render() {
        return (
            <div className="step29">
                <div className="flex">
                    <div className="flex_el">
                        <TextBlock
                            text={`Животным жизненно необходимо получать достаточное количество воды, так как вода — ключевой элемент в организме, <b>способствующий правильному обмену веществ.</b>
                            
                            Вода выполняет ряд важнейших функций для организма. При нехватке воды наступает нарушение водного баланса, что приводит к проблемам со здоровьем.`}
                        />
                    </div>
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                </div>
                <InfoBlock
                    text={`Далее вы ознакомитесь со свойствами воды, суточными нормами потребления для кошек и собак, а также с понятием водного баланса.`}
                />
            </div>
        )
    }
}
