import React from 'react'

import img1 from '../../../image/lessons/lesson15/step21-1.png'


export default class step21 extends React.Component {


    render() {
        return (
            <div className="step21">
                <img className="imgCenter" src={img1} alt=""/>
            </div>
        )
    }
}
