import React from 'react'
import './style.sass'

export default class popapAfterTest extends React.Component {

    componentDidMount() {
        document.body.scrollIntoView( true);
        document.body.style.overflow = "hidden";
    }

    componentWillUnmount() {
        document.body.style.overflow = "";
    }

    render() {
        return (

            <div className="popapAfterTest">
                <div className="popapAfterTest_wrp">
                    <div
                        className="popapAfterTest_close"
                        onClick={() => {
                            this.props.closeEndPopap !== undefined ? this.props.closeEndPopap() : this.props.history.push('/MyTraining', {showPopapEnd: false})
                        }}
                    />
                    <div className="popapAfterTest_head">
                        Поздравляем! <br/>
                        Вы закончили урок.
                    </div>
                    <div className="popapAfterTest_text">
                        Вы можете ознакомиться с другими уроками, <br/>
                        просто нажмите кнопку.
                    </div>
                    <div className="popapAfterTest_btn">
                        <button
                            className="btn btn_blue"
                            onClick={() => {
                                this.props.closeEndPopap !== undefined ? this.props.closeEndPopap() : this.props.history.push('/myTraining', {showPopapEnd: false})
                            }}
                        >продолжить
                        </button>
                    </div>

                </div>
            </div>

        )
    }
}
