import React from 'react'

export default class step9 extends React.Component {

    render() {
        return (
            <div className="step9">
                <div className="advice">
                    <dl className="advice_list">
                        <dt className="advice_headline">
                            Растяжка и точка когтей после дремоты
                        </dt>
                        <dd className="advice_description">
                            Домашние кошки точат свои когти, чтобы сохранять их в хорошем состоянии и помечать территорию. Кошки любят растягиваться и царапаться обычно сразу после того, как они проснулись, поэтому попробуйте поместить столб для точки возле их места для сна.
                        </dd>
                        <dt className="advice_headline">
                            Метка запахом
                        </dt>
                        <dd className="advice_description">
                            Для того, чтобы почувствовать себя в безопасности, домашние кошки трутся о различные предметы в доме – придают им аромат. Современная уборка означает, что мы постоянно стремимся удалять этот запах, что может вызвать беспокойство и дискомфорт у вашего питомца. Советуем выбирать средства без резко выраженного запаха, чтобы личный аромат вашего питомца не становился сильно менее ощутимым.
                        </dd>
                        <dt className="advice_headline">
                            Собственное место
                        </dt>
                        <dd className="advice_description">
                            Очень важно, чтобы у Вашей кошки было свое личное пространство – удобное подходящее место, где можно спрятаться. Вы можете использовать даже такой простой предмет как картонная коробка: переверните ее на бок или обеспечьте отверстия для входа.
                        </dd>
                        <dt className="advice_headline">
                            Тихое время
                        </dt>
                        <dd className="advice_description">
                            Если Ваша кошка убежала, забилась в темном углу шкафа, спряталась или забралась подальше от людей – не переживайте, это нормально, ей нужно пространство. В такие моменты их важно не беспокоить, позвольте своему питомцу просто успокоиться. Советуем продумать такие места для того, чтобы Ваша кошка всегда чувствовала себя комфортно.
                        </dd>
                        <dt className="advice_headline">
                            Высокие места для отдыха
                        </dt>
                        <dd className="advice_description">
                            Кошки чувствуют себя в большей безопасности, если они могут осмотреть свою территорию с высоты. Поэтому важно обеспечить им такие места для отдыха, например, полки или верхнюю часть шкафа. Убедитесь, что Ваш питомец может легко до них добраться. Кошкам пожилого возраста такие места также необходимы – важно сделать так, чтобы для них это было доступным.
                        </dd>
                    </dl>
                </div>
            </div>
        )
    }
}
