import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson9/step24-1.png'
import img2 from '../../../image/lessons/lesson9/step24-2.png'
import img3 from '../../../image/lessons/lesson9/step24-3.png'
import img4 from '../../../image/lessons/lesson9/step24-4.png'
import img5 from '../../../image/lessons/lesson9/step24-5.png'


export default class step24 extends React.Component {
    state = {
    };

    render() {
        return (
            <div className="step24">
                <TextBlock
                    text={`Помните о том, что для каждой стадии жизни необходим свой рацион.
                    
                    Промышленные рационы составляются на основании научных исследований и содержат именно те элементы, которые необходимы животным в тот или иной период жизни.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_wrp">
                            <span className="flex_status">
                                <img src={img4} alt=""/>
                            </span>
                            <p className="flex_text">
                                <span className="flex_red">Нельзя</span> кормить собаку рационом для кошек!
                                <br/>
                                Потребности кошек и собак различаются.
                            </p>
                            <img className="img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_wrp">
                            <span className="flex_status">
                                <img src={img4} alt=""/>
                            </span>
                            <p className="flex_text">
                                <span className="flex_red">Нельзя</span> давать взрослой собаке рацион для щенков!
                                Его питательная ценность превышает потребности взрослой собаки. Это может привести к набору лишнего веса.
                            </p>
                            <img className="img" src={img2} alt=""/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_wrp flex_wrp--green">
                            <span className="flex_status">
                                <img src={img5} alt=""/>
                            </span>
                            <p className="flex_text">
                                <span className="flex_green">Рекомендуется</span> кормить взрослую собаку сбалансированным кормом, в зависимости от ее размера. В таких кормах учитываются все потребности взрослых собак.
                            </p>
                            <img className="img img_3" src={img3} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
