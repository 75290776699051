import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

import img1 from "../../../image/lessons/lesson7/step4-1.png";

export default class step4 extends React.Component {

    render() {
        return (
            <div className="step4">
                <InfoBlock
                    text={`Рассмотрите схему пищеварительного тракта кошки. Цифры на схеме соответствуют номеру факта.`}
                />
               <div className="flex">
                   <div className="flex_el">
                       <ol className="list-numeral">
                           <li className="list-numeral_item"><span className="list-numeral_text">У кошек 30 зубов, все острые и предназначены для разгрызания и разрывания. Эмаль кошачьих зубов в 10 раз тоньше эмали человека.</span></li>
                           <li className="list-numeral_item"><span className="list-numeral_text">Отсутствуют рецепторы сладкого вкуса.</span></li>
                           <li className="list-numeral_item"><span className="list-numeral_text">Отсутствует выработка слюнной амилазы (предварительная обработка углеводов).</span></li>
                           <li className="list-numeral_item"><span className="list-numeral_text">Желудок предназначен для приема небольших порций пищи несколько раз в день.</span></li>
                           <li className="list-numeral_item"><span className="list-numeral_text">Желудочный сок более кислый, чем у человека. Это способствует перевариванию костей и уничтожению патогенных бактерий.</span></li>
                           <li className="list-numeral_item"><span className="list-numeral_text">Время кишечного транзита составляет от 12 до 24 часов.</span></li>
                           <li className="list-numeral_item"><span className="list-numeral_text">Тонкий кишечник хорошо приспособлен к перевариванию белков и жиров.</span></li>
                           <li className="list-numeral_item"><span className="list-numeral_text">Бактериальная ферментация происходит в толстом кишечнике.</span></li>
                       </ol>
                   </div>
                   <div className="flex_el">
                       <img src={img1} alt="" className="img"/>
                   </div>
               </div>
            </div>
        )
    }
}
