import React from 'react'
import OnClickTest from "../../../Components/LessonAndTestComponents/OnClickTest";
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";
import img1 from "../../../image/lessons/lesson15/step8-1.png";


export default class step8 extends React.Component {

    state = {
        answersData: [
            `Растет так же, как и популяция кошек`,
            `Снижается`,
            `Первое`,
        ],
        showImg: false
    }

    showNextBtn = () => {
        this.props.showNextBtn()
        this.setState({showImg: true})
    }

    render() {
        const {showImg} = this.state
        return (
            <div className="step8">
                <TextBlock
                    text={`Как Вы думаете, что происходит с популяцией собак в России?`}
                />
                <OnClickTest
                    ansewrsData={this.state.answersData}
                    type={'line'}
                    rightAnswers={[1]}
                    showNextBtn={this.showNextBtn}
                    correctText={``}
                />
                <img src={img1} alt="" className={'imgCenter ' + (showImg && 'show')}/>
            </div>
        )
    }
}
