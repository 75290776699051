import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import List from '../../../Components/LessonAndTestComponents/List';
import Popup from "../../../Components/Popup";

import img1 from '../../../image/lessons/lesson16/step6-1.png'

export default class step6 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        pointActive: 0,
        showPopup: false,
        points: [],
    };

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    showPopup = (pointActive) => {
        const {points, showPopup, windowWidth} = this.state;
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        if (windowWidth < 768) {
            if (!showPopup) {
                this.setState({
                    pointActive: pointActive,
                    showPopup: true,
                })
            } else if (pointActive >= 2) {
                this.setState({showPopup: false, pointActive: 0})
            } else {
                this.setState({
                    pointActive: pointActive +1,
                    showPopup: true,
                })
            }
        } else {
            this.setState({points: points})
        }
        if (points.length === 2) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    popupData = {
        0: {
            head: '',
            list: [],
        },
        1: {
            head: `<b>В пользу покупки</b> говорят следующие факторы:`,
            list: [
                `кормить готовыми рационами быстро, <b>легко и удобно;</b>`,
                `корма — это <b>сбалансированное питание</b>, они полезны для животного;`,
                `производители кормов лучше <b>знают, что важно и полезно</b> для животного;`,
                `<b>опасно кормить со стола</b>, так как часто то, что полезно и вкусно человеку, вредно для животного.`,
            ],
            class: 'green',
        },
        2: {
            head: `<b>Барьерами</b> к покупке являются:`,
            list: [
                `<b>ненатуральные, вредные</b> для здоровья ингредиенты;`,
                `корма вызывают <b>привыкание</b> у животных;`,
                `<b>непонятные и / или неизвестные</b> ингредиенты.`,
            ],
            class: 'red',
        },
    }

    render() {
        const {showPopup, pointActive, points} = this.state
        return (
            <div className="step6">
                <TextBlock
                    text={`Для покупки готовых кормов есть определенные стимулы, но при этом существуют и барьеры, препятствующие покупке.`}
                />
                <InfoBlock
                    text={`Нажмите на знаки вопросов, чтобы узнать подробнее.`}
                />
                <div className="box">
                    <div className="box_img">
                        <div
                            className="box_point"
                            onClick={() => {this.showPopup(1)}}
                        />
                        <div
                            className="box_point"
                            onClick={() => {this.showPopup(2)}}
                        />
                        <img src={img1} className="imgCenter" alt=""/>
                        <div className={'block green ' + (points.indexOf(1) >=0 && 'active')}>
                            <div className="block_head"
                                 dangerouslySetInnerHTML={{ __html: this.popupData[1].head }}
                            />
                            <List
                                ListData={this.popupData[1].list}
                            />
                        </div>
                        <div className={'block red ' + (points.indexOf(2) >=0 && 'active')}>
                            <div className="block_head"
                                 dangerouslySetInnerHTML={{ __html: this.popupData[2].head }}
                            />
                            <List
                                ListData={this.popupData[2].list}
                            />
                        </div>
                    </div>
                </div>
                {showPopup &&
                <Popup
                    closePopup={this.closePopup}
                >
                    <div className={'info ' + (this.popupData[pointActive].class)}>
                        <div className="info_head"
                             dangerouslySetInnerHTML={{ __html: this.popupData[pointActive].head }}
                        />
                        <List
                            ListData={this.popupData[pointActive].list}
                        />
                    </div>
                    <button
                        className="btn btn_blue"
                        onClick={() => {this.showPopup(pointActive)}}
                    >
                        Продолжить
                    </button>
                </Popup>
                }
            </div>
        )
    }
}
