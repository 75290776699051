import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from "../../../image/lessons/lesson2/step8-1.png";

export default class step8 extends React.Component {

    render() {
        return (
            <div className="step8">
                <div className="flex">
                    <div className="flex_el">
                        <TextBlock
                            text={`Около <span class="text_blue">40%</span> кошек и собак страдают от лишнего веса. Чтобы этого избежать, нужно не перекармливать питомца и знать его индивидуальный размер порции.
                            
                            Главный фактор, определяющий энергетические потребности, — <span class="text_blue">масса тела.</span>
                            
                            Но есть и другие факторы:`}
                        />
                        <ul className="list">
                            <li className="list_item">уровень активности;</li>
                            <li className="list_item">стерилизация;</li>
                            <li className="list_item">упитанность;</li>
                            <li className="list_item">возраст;</li>
                            <li className="list_item">условия окружающей среды.</li>
                        </ul>
                    </div>
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                </div>
            </div>
        )
    }
}
