import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';


export default class step11 extends React.Component {


    render() {
        return (
            <div className="step11">
                <TextBlock
                    text={`Рассмотрите графики. На первом показано содержание белка в различных продуктах, а на втором — его усвояемость в тех же продуктах.
                    
                    Как вы видите, некоторые растительные белки усваиваются так же хорошо, как и животные (например, соя усваивается так же, как и рыба).`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Содержание белка
                        </div>
                        <div className="table">
                            <div className="table_row">
                            <div className="table_row_left">яйцо</div>
                            <div className="table_row_right">
                                <div className="table_row_right_percent eleven"><p className="low_percent">11%</p></div>
                            </div>
                        </div>
                            <div className="table_row">
                                <div className="table_row_left">рис</div>
                                <div className="table_row_right">
                                    <div className="table_row_right_percent seven"><p className="low_percent">7%</p></div>
                                </div>
                            </div>
                            <div className="table_row">
                                <div className="table_row_left">мясо (волокна)</div>
                                <div className="table_row_right">
                                    <div className="table_row_right_percent twenty">20%</div>
                                </div>
                            </div>
                            <div className="table_row">
                                <div className="table_row_left">мясо (органы)</div>
                                <div className="table_row_right">
                                    <div className="table_row_right_percent twenty">20%</div>
                                </div>
                            </div>
                            <div className="table_row">
                                <div className="table_row_left">пшеница</div>
                                <div className="table_row_right">
                                    <div className="table_row_right_percent ten"><p className="low_percent">10%</p></div>
                                </div>
                            </div>
                            <div className="table_row">
                                <div className="table_row_left">рыба</div>
                                <div className="table_row_right">
                                    <div className="table_row_right_percent twenty">20%</div>
                                </div>
                            </div>
                            <div className="table_row">
                                <div className="table_row_left">молоко</div>
                                <div className="table_row_right">
                                    <div className="table_row_right_percent three"><p className="low_percent">3%</p></div>
                                </div>
                            </div>
                            <div className="table_row">
                                <div className="table_row_left">соя</div>
                                <div className="table_row_right">
                                    <div className="table_row_right_percent thirtyOne">31%</div>
                                </div>
                            </div>
                            <div className="table_row">
                                <div className="table_row_left">дрожжи</div>
                                <div className="table_row_right">
                                    <div className="table_row_right_percent fortyFife">45%</div>
                                </div>
                            </div>
                            <div className="table_row">
                                <div className="table_row_left">кукуруза</div>
                                <div className="table_row_right">
                                    <div className="table_row_right_percent nine"><p className="low_percent">9%</p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Усвояемость белка
                        </div>
                        <div className="table">
                            <div className="table_row">
                                <div className="table_row_left">яйцо</div>
                                <div className="table_row_right">
                                    <div className="table_row_right_percent hundred">100%</div>
                                </div>
                            </div>
                            <div className="table_row">
                                <div className="table_row_left">рис</div>
                                <div className="table_row_right">
                                    <div className="table_row_right_percent seventyTwo">72%</div>
                                </div>
                            </div>
                            <div className="table_row">
                                <div className="table_row_left">мясо (волокна)</div>
                                <div className="table_row_right">
                                    <div className="table_row_right_percent ninetyTwo">92%</div>
                                </div>
                            </div>
                            <div className="table_row">
                                <div className="table_row_left">мясо (органы)</div>
                                <div className="table_row_right">
                                    <div className="table_row_right_percent ninety">90%</div>
                                </div>
                            </div>
                            <div className="table_row">
                                <div className="table_row_left">пшеница</div>
                                <div className="table_row_right">
                                    <div className="table_row_right_percent sixty">60%</div>
                                </div>
                            </div>
                            <div className="table_row">
                                <div className="table_row_left">рыба</div>
                                <div className="table_row_right">
                                    <div className="table_row_right_percent seventyFive">75%</div>
                                </div>
                            </div>
                            <div className="table_row">
                                <div className="table_row_left">молоко</div>
                                <div className="table_row_right">
                                    <div className="table_row_right_percent eightyNine">89%</div>
                                </div>
                            </div>
                            <div className="table_row">
                                <div className="table_row_left">соя</div>
                                <div className="table_row_right">
                                    <div className="table_row_right_percent seventyFive">75%</div>
                                </div>
                            </div>
                            <div className="table_row">
                                <div className="table_row_left">дрожжи</div>
                                <div className="table_row_right">
                                    <div className="table_row_right_percent sixtyThree">63%</div>
                                </div>
                            </div>
                            <div className="table_row">
                                <div className="table_row_left">кукуруза</div>
                                <div className="table_row_right">
                                    <div className="table_row_right_percent fiftyFour">54%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
