import React from 'react'
import DefinitionBlock from '../../../Components/LessonAndTestComponents/DefinitionBlock'

import img1 from "../../../image/lessons/lesson4/step10-1.png";
import img2 from "../../../image/lessons/lesson4/step10-2.png";
import img3 from "../../../image/lessons/lesson4/step10-3.png";
import img4 from "../../../image/lessons/lesson4/step10-4.png";
import img5 from "../../../image/lessons/lesson4/step10-5.png";
import img6 from "../../../image/lessons/lesson4/step10-6.png";

export default class step10 extends React.Component {

    render() {
        return (
            <div className="step10">
                <DefinitionBlock
                    text={`<span class="text_blue">Углеводы</span> — это вещества, молекулы которых состоят из углерода, кислорода и водорода. У всех углеводов есть как ряд общих свойств, так и отличительные особенности.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_border">
                            <img className="flex_el_img" src={img1} alt=""/>
                        </div>
                        <p className="flex_el_text">Добавляются в корма для домашних животных в качестве источников энергии и пищевых волокон.</p>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_border">
                            <img className="flex_el_img" src={img2} alt=""/>
                        </div>
                        <p className="flex_el_text">Не являются незаменимым питательным веществом. Организм кошек и собак может синтезировать глюкозу из аминокислот.</p>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_border">
                            <img className="flex_el_img" src={img3} alt=""/>
                        </div>
                        <p className="flex_el_text">В сухих кормах уровень содержания углеводов, как правило, выше, чем во влажных кормах.</p>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_border">
                            <img className="flex_el_img" src={img4} alt=""/>
                        </div>
                        <p className="flex_el_text">К углеводам относятся сахара, крахмал и клетчатка.</p>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_border">
                            <img className="flex_el_img" src={img5} alt=""/>
                        </div>
                        <p className="flex_el_text">Имеют преимущественно растительное происхождение.</p>
                    </div>
                    <img className="img" src={img6} alt=""/>
                </div>
            </div>
        )
    }
}
