import React from 'react'

import img1 from '../../../image/lessons/lesson15/step5-1.png'


export default class step5 extends React.Component {

    render() {
        return (
            <div className="step5">
                <img src={img1} alt=""/>
            </div>
        )
    }
}
