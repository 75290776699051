import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson4/step2-1.png'



export default class step2 extends React.Component {


    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step2">
                <div className="flex">
                    <div className="flex_el">
                        <TextBlock
                            text={`<span class="text_blue">Жиры — основной источник энергии. При распаде 1 г жиров высвобождается примерно в 2 раза больше энергии по сравнению с белками и углеводами.</span>
                            
                            Жиры содержат незаменимые жирные кислоты и обеспечивают условия для всасывания жирорастворимых витаминов в кишечнике.`}
                        />
                    </div>
                    <div className="flex_el">
                        <div className="imgBox">
                            <img src={img1} alt="" className="img"/>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
