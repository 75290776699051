import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson12/step20-1.png';

export default class step20 extends React.Component {

    render() {

        return (
            <div className="step20">
                <TextBlock
                    text={`Из этого урока вы узнали, как различаются готовые рационы для животных.
                    
                    Промышленные корма учитывают возрастные и физиологические особенности домашних животных, могут <b>использоваться для профилактики заболеваний и поддержания здорового состояния питомца.</b>
                    
                    <b>Сухие и влажные рационы</b> имеют разнообразные вкусы. Влажные корма также различаются по текстурам, что позволяет разнообразить питание домашних животных.
                    
                    Готовые корма делятся <b>на три класса.</b> При этом корма всех классов являются качественными, так как каждый корм <b>выполняет свою задачу и соответствует заданным стандартам.</b>
                    
                    Большинство готовых кормов являются <b>сбалансированными,</b> и животное может питаться ими на протяжении всей жизни. Отдельно выделяют категорию <b>лакомств,</b> которые используются как <b>дополнение к основному рациону</b> при необходимости.`}
                />
                <img className="imgCenter" src={img1} alt=""/>
            </div>
        )
    }
}
