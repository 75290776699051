import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from "../../../image/lessons/lesson1/step7-1.png";


export default class step7 extends React.Component{

    render(){
        return (
            <div className="step7">
                <div className="flex">
                    <div className="flex_el">
                        <TextBlock text={`Первоначальная селекция дикой африканской кошки стала важной по ряду причин:`}/>
                        <ul className="list">
                            <li className="list_item">Среди всех видов диких кошек африканский кот – наименее агрессивный и самый дружелюбный.</li>
                            <li className="list_item">На генетическом уровне кошки еще меньше изменились по сравнению с предками, чем собаки. Именно поэтому они практически не отличаются друг от друга по размеру и внешнему виду.</li>
                        </ul>
                    </div>
                    <div className="flex_el">
                        <img className="img" src={img1} alt=""/>
                    </div>
                </div>

            </div>
        )
    }

}
