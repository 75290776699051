import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import ArrowBack from '../../../Components/LessonAndTestComponents/ArrowBack';

import img1 from '../../../image/lessons/lesson1/step24-1.png';
import img1_1 from '../../../image/lessons/lesson1/step24-1-1.png';
import img2 from '../../../image/lessons/lesson1/step24-2.png';
import img2_1 from '../../../image/lessons/lesson1/step24-2-1.png';
import img3 from '../../../image/lessons/lesson1/step24-3.png';
import img3_1 from '../../../image/lessons/lesson1/step24-3-1.png';
import img4 from '../../../image/lessons/lesson1/step24-4.png';
import img4_1 from '../../../image/lessons/lesson1/step24-4-1.png';
import img5 from '../../../image/lessons/lesson1/step24-5.png';
import img5_1 from '../../../image/lessons/lesson1/step24-5-1.png';
import img6 from '../../../image/lessons/lesson1/step24-6.png';
import img6_1 from '../../../image/lessons/lesson1/step24-6-1.png';
import img7 from '../../../image/lessons/lesson1/step24-7.png';
import img7_1 from '../../../image/lessons/lesson1/step24-7-1.png';
import img8 from '../../../image/lessons/lesson1/step24-8.png';
import img8_1 from '../../../image/lessons/lesson1/step24-8-1.png';
import img9 from '../../../image/lessons/lesson1/step24-9.png';
import img9_1 from '../../../image/lessons/lesson1/step24-9-1.png';
import img10 from '../../../image/lessons/lesson1/step24-10.png';
import img10_1 from '../../../image/lessons/lesson1/step24-10-1.png';
import img11 from '../../../image/lessons/lesson1/step24-11.png';
import img11_1 from '../../../image/lessons/lesson1/step24-11-1.png';
import img12 from '../../../image/lessons/lesson1/step24-12.png';
import img12_1 from '../../../image/lessons/lesson1/step24-12-1.png';
import img13 from '../../../image/lessons/lesson1/step24-13.png';
import img13_1 from '../../../image/lessons/lesson1/step24-13-1.png';
import img14 from '../../../image/lessons/lesson1/step24-14.png';
import img14_1 from '../../../image/lessons/lesson1/step24-14-1.png';
import img15 from '../../../image/lessons/lesson1/step24-15.png';
import img15_1 from '../../../image/lessons/lesson1/step24-15-1.png';
import img16 from '../../../image/lessons/lesson1/step24-16.png';
import img16_1 from '../../../image/lessons/lesson1/step24-16-1.png';


export default class step24 extends React.Component {
    state = {
        flexActive: 0,
        flexs: [1],
        flexHidden: false,
    };

    changeFlex = flexActive => {
        const {flexs} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }
        this.setState({flexActive, flexs, flexHidden: true})
        if (flexs.length === 11) {
            this.props.showNextBtn()
        }
    }

    closeImgDiv = () => {this.setState({flexHidden: false, flexActive: 0})}

    breedData = {
        0: {
            img: '',
            breed: '',
            divList: [''],
            divImg: '',
        },
        1: {
            img: img1,
            breed: 'Британская короткошерстная',
            divList: [
                `Независимая, но в то же время очень нежная`,
                `Наслаждается присутствием людей`,
                `Не требует трудоемкого ухода`,
            ],
            divImg: img1_1,
            divDetail: 'Знаете ли Вы, что британская короткошерстная кошка окраса серебристый табби  стала «лицом» самого популярного корма для кошек в мире <span class="text_purple">WHISKAS<sup>®</sup></span>? Поэтому британских короткошерстных кошек такого окраса зачастую называют кошками окраса «вискас».'
        },
        2: {
            img: img2,
            breed: 'Сиамская',
            divList: [
                `По характеру напоминает собак`,
                `Легко дрессируется`,
                `Искренне доверяет хозяину`,
            ],
            divImg: img2_1,
        },
        3: {
            img: img13,
            breed: 'Шотландская вислоухая (скоттиш фолд)',
            divList: [
                'Терпеливая',
                'Интеллигентная',
                'Незлопамятная'
            ],
            divImg: img13_1,
        },
        4: {
            img: img3,
            breed: 'Сибирская',
            divList: [
                `Большая и сильная кошка`,
                `Имеет уравновешенный характер`,
                `Очень сообразительна`,
                `Хорошо чувствует себя за городом`,
            ],
            divImg: img3_1,
        },
        5: {
            img: img12,
            breed: 'Мейн-кун',
            divList: [
                'Ласковый',
                'Общительный',
                'Спокойный'
            ],
            divImg: img12_1,
        },
        // 4: {
        //     img: img4,
        //     breed: 'Персидская',
        //     divList: [
        //         `Нежная и спокойная`,
        //         `Привязывается к хозяину`,
        //         `Легко уживается с другими кошками и детьми`,
        //     ],
        //     divImg: img4_1,
        // },
        // 5: {
        //     img: img5,
        //     breed: 'Британская длинношерстная',
        //     divList: [
        //         `Ласковый и спокойный характер`,
        //         `Не скучает в одиночестве`,
        //         `С удовольствием бегает и играет`,
        //     ],
        //     divImg: img5_1,
        // },
        6: {
            img: img6,
            breed: 'Русская голубая',
            divList: [
                `Благородный мягкий характер`,
                `Идеальна для жизни в семье`,
                `Была долгое время любима царями и аристократией`,
            ],
            divImg: img6_1,
            divDetail: 'Русская голубая — очень благородная кошка. Поэтому она стала «лицом» популярного бренда кормов супер премиум класса <span class="text_yellow">SHEBA<sup>®</sup></span>'
        },
        7: {
            img: img11,
            breed: 'Бенгальская',
            divList: [
                'Умная',
                'Энергичная',
                'Преданная'
            ],
            divImg: img11_1,
        },
        8: {
            img: img14,
            breed: 'Абиссинская',
            divList: [
                'Активная',
                'Любопытная',
                'Верная'
            ],
            divImg: img14_1,
        },
        // 7: {
        //     img: img7,
        //     breed: 'Сфинкс',
        //     divList: [
        //         `Любит общение`,
        //         `Наслаждается обществом людей и других животных`,
        //         `Чувствителен к холоду`,
        //     ],
        //     divImg: img7_1,
        // },
        9: {
            img: img8,
            breed: 'Канадский сфинкс',
            divList: [
                `Очень любвеобилен и миролюбив`,
                `Легко поддается обучению`,
                `Не боится собак и других животных`,
            ],
            divImg: img8_1,
        },
        // 9: {
        //     img: img9,
        //     breed: 'Турецкая ангора',
        //     divList: [
        //         `Интеллектуальна и любознательна`,
        //         `Ведет активный образ жизни`,
        //         `Любит быть в центре внимания`,
        //     ],
        //     divImg: img9_1,
        // },
        10: {
            img: img10,
            breed: 'Невская маскарадная',
            divList: [
                `Предана своему хозяину`,
                `Игрива и в меру активна`,
                `Дружит с детьми`,
                `Не злопамятна`,
            ],
            divImg: img10_1,
        },
        11: {
            img: img16,
            breed: 'Персидская',
            divList: [
                `Нежная и спокойная`,
                `Привязывается к хозяину`,
                `Легко уживается с другими кошками и детьми`,
            ],
            divImg: img16_1,
        },
        12: {
            img: img15,
            breed: 'Донской сфинкс',
            divList: [
                'Игривая',
                'Тактичная',
                'Дружелюбная'
            ],
            divImg: img15_1,
        },
    }

    render() {
        const {flexActive, flexHidden} = this.state
        return (
            <div className="step24">
                <InfoBlock
                    text={`Нажмите на название породы, чтобы узнать о ней подробнее.
                    
                    Для продолжения, пожалуйста, ознакомьтесь со всей представленной информацией в этом шаге урока.`}
                    hidden={flexHidden === true ? ' hidden' : ''}
                />
                <div className={'flex ' + (flexActive !== 0 && 'active ') +  (flexHidden === true && ' hidden') + (flexActive === '1' || flexActive === '6' ? ' newMargin' : '')}>
                    {Object.keys(this.breedData).filter(id => id > 0).map((item) => {
                        return (
                            <div
                                key={item}
                                className={'flex_el ' + (flexActive === (item) && 'active')}
                                onClick={() => {this.changeFlex(item)}}
                            >
                                <img className="flex_el_img" src={this.breedData[item].img} alt=""/>
                                <p className="flex_el_text">
                                    {this.breedData[item].breed}
                                </p>
                                <p className="flex_el_number">{item}</p>
                            </div>
                        )
                    })}
                </div>
                <div className={'imgDiv ' + (flexActive !== 0 && 'active ') + (flexActive === '1' || flexActive === '6' ? ' newHeight' : '')}>
                    <ArrowBack
                        className={flexActive === 0 ? 'hidden' : ''}
                        returnMenu={this.closeImgDiv}
                    />
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_flex">
                            <img className="imgDiv_img" src={this.breedData[flexActive].divImg} alt=""/>
                            <div className="imgDiv_box">
                                <ul className="list">
                                    {this.breedData[flexActive].divList.map((item, index) => {
                                        return (
                                            <li
                                                key={index}
                                                className="list_item"
                                            >
                                                {item}
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                        {this.breedData[flexActive].divDetail &&
                        <div className={'imgDiv_detail ' + (flexActive === '6' && 'yellow')}>
                            <p className="imgDiv_detail_head">
                                Интересный факт о породе
                            </p>
                            <p
                                className="imgDiv_detail_text"
                                dangerouslySetInnerHTML={{ __html: this.breedData[flexActive].divDetail }}
                            />
                        </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
