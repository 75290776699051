import React from 'react'
import Resume from "../../../Components/LessonAndTestComponents/Resume";

import img1 from "../../../image/lessons/lesson8/step13-1.png";

export default class step13 extends React.Component {

    state = {
        resumeData: [
            `Количество энергии, потребляемой с лакомствами, не должно превышать <b>15% от суточной потребности</b> животного в энергии.`,
            `Следует использовать только лакомства, <b>специально предназначенные</b> для животных.`,
            `В качестве <b>поощрения</b> нужно использовать не только корм, но также игрушки и подвижные игры.`,
            `При применении специализированных лакомств (например, для поддержания здоровья ротовой полости) следует тщательно <b>соблюдать рекомендации</b> по их количеству.`,
        ]
    }

    render() {
        return (
            <div className="step13">
                <Resume
                    text={`Чтобы не нарушить сбалансированный рацион питомца, нужно следить за правильным использованием угощений и соблюдать следующие рекомендации:`}
                    resumeData={this.state.resumeData}
                    img={img1}
                />
            </div>
        )
    }
}
