import React from 'react'
import Resume from '../../../Components/LessonAndTestComponents/Resume'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson10/step10-1.png'


export default class step10 extends React.Component{

    state = {
        resumeData: [
            `Энергетические потребности котят достигают своего пика в возрасте <b>10 недель.</b>`,
            `Котята <b>мужского пола</b> растут быстрее, чем котята женского пола.`,
            `После отучения от грудного вскармливания котят нужно кормить примерно <b>4–5 раз в день.</b>`,
        ]
    }

    render() {
        return (
            <div className="step10">
                <Resume
                    resumeData={this.state.resumeData}
                    img={img1}
                />
                <TextBlock
                    text={`В следующей теме вы узнаете о питании взрослых и пожилых кошек.`}
                />
            </div>
        )
    }
}
