import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import Answer from './dnd/Answer';
import Questions from './dnd/Questions';

import img1 from '../../../image/lessons/lesson19/step10-1.png'
import img2 from "../../../image/lessons/lesson19/step10-2.png";
import img3 from "../../../image/lessons/lesson19/step10-3.png";
import img4 from "../../../image/lessons/lesson19/step10-4.png";
import img1_1 from "../../../image/lessons/lesson19/step10-1-1.png";
import img1_2 from "../../../image/lessons/lesson19/step10-1-2.png";
import img1_3 from "../../../image/lessons/lesson19/step10-1-3.png";
import img2_1 from "../../../image/lessons/lesson19/step10-2-1.png";
import img3_1 from "../../../image/lessons/lesson19/step10-3-1.png";
import img3_2 from "../../../image/lessons/lesson19/step10-3-2.png";
import img3_3 from "../../../image/lessons/lesson19/step10-3-3.png";
import img4_1 from "../../../image/lessons/lesson19/step10-4-1.png";
import img4_2 from "../../../image/lessons/lesson19/step10-4-2.png";


export default class step10 extends React.Component {
    answer1;
    answer2;
    answer3;
    answer4;
    answer5;
    answer6;
    state = {
        // Ресайз
        windowWidth: window.innerWidth,

        // Работа десктопной версии
        answersObj: {1: [], 2: [], 3: [], 4: []},
        questionsObj: {1: [], 2: [], 3: [], 4: []},
        correct: '',
        answerCorrectObj: {1: [], 2: [], 3: [], 4: []},
        testActive: 0,

        //Работа мобильной версии
        answerActive: 0,
        questionActive: 0,
        answersMobile: [],
        questionsMobile: [],
        answersSelected: ['?', '?', '?', '?', '?', '?'],
        correctMobile: '',
        answerCorrectMobile: [],
        showPopup: false
    }

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    //Работа десктопной версии
    setAnswersObj = (answer_el, question_el) => {
        const {answersObj, testActive} = this.state
        let testItem = Object.values(answersObj)[testActive]

        if (testItem.indexOf(question_el) === -1) {
            let answer = []
            testItem[answer_el] = question_el
            testItem.push(answer)
        }
        this.setState({answersObj})

        let questionsObj = {1: [], 2: [], 3: [], 4: []};
        // eslint-disable-next-line
        testItem.map(answer => {

            if (answer.length) {
                Object.values(questionsObj)[testActive].push(answer)
            }

        });
        this.setState({questionsObj})
        if (Object.values(questionsObj)[testActive].length === 6) {
            this.checkAnswer()
        }
    }

    showTestActive = (testActive) => {
        const {answerCorrectObj} = this.state
        Object.values(answerCorrectObj)[testActive].map((item, index) => {
            return (
                item === ''
            )
        })
        this.setState({testActive: testActive, correct: ''})
    }

    checkAnswer = () => {
        const {answerCorrectObj, questionsObj, testActive} = this.state
        // eslint-disable-next-line
        Object.values(questionsObj).map((testItem, index) => {
            // eslint-disable-next-line
            testItem.map((answer, index) => {
                if (answer === String(index + 1)) {
                    Object.values(answerCorrectObj)[testActive].push('correct')
                } else {
                    Object.values(answerCorrectObj)[testActive].push('inCorrect')
                }
            })
        })
        this.setState({
            answerCorrectObj: answerCorrectObj,
        })
        if (Object.values(questionsObj)[testActive].length === 6 && Object.values(answerCorrectObj)[testActive].indexOf('inCorrect') === -1) {
            this.setState({correct: '1'})
        } else {
            this.setState({correct: '0'})
        }

        if (answerCorrectObj[1].length && answerCorrectObj[1].length && answerCorrectObj[3].length && answerCorrectObj[4].length) {
            this.props.showNextBtn()
        }
    };

    testDataDesktop = {
        1: {
            cardHead: `для щенков мелких и миниатюрных пород до 1 года`,
            cardImg: img1,
            question1: `Здоровый рост`,
            question2: `Содержит оптимальное количество кальция и фосфора`,
            question3: `Здоровое развитие мозга`,
            question4: `Способствует поддержанию правильного развития головного мозга, благодаря содержанию ДГК из натуральных источников`,
            question5: `Уменьшенный размер крокеты`,
            question6: `Размер гранул, разработанный специально для мелких и миниатюрных пород`,
            // answers: [img1_1, '?', '?', img1_2, '?', '?', img1_3, '?', '?',],
            icon1: img1_1,
            icon2: img1_2,
            icon3: img1_3,
        },
        2: {
            cardHead: `для щенков средних и крупных пород до 1 года`,
            cardImg: img2,
            question1: `Здоровый рост`,
            question2: `Содержит оптимальное количество кальция и фосфора`,
            question3: `Здоровое развитие мозга`,
            question4: `Способствует поддержанию правильного развития головного мозга, благодаря содержанию ДГК из натуральных источников`,
            question5: `Здоровое развитие мускулатуры`,
            question6: `Оптимальный уровень белка высокого качества`,
            // answers: [img1_1, '?', '?', img1_2, '?', '?', img2_1, '?', '?',],
            icon1: img1_1,
            icon2: img1_2,
            icon3: img2_1,
        },
        3: {
            cardHead: `для взрослых собак мелких и миниатюрных пород старше 1 года`,
            cardImg: img3,
            question1: `Здоровье мочевыделительной системы`,
            question2: `Баланс минералов для поддержания здоровья мочевыделительной системы`,
            question3: `Жизненная энергия`,
            question4: `Содержит повышенные уровни витаминов В1, В2, В6 и железа`,
            question5: `Уменьшенный размер крокеты`,
            question6: `Размер гранул, разработанный специально для мелких и миниатюрных пород`,
            icon1: img3_1,
            icon2: img3_2,
            icon3: img3_3,
        },
        4: {
            cardHead: `для взрослых собак средних и крупных пород старше 1 года`,
            cardImg: img4,
            question1: `Развитие здоровой мускулатуры`,
            question2: `Оптимальный уровень белка высокого качества`,
            question3: `Подвижность суставов`,
            question4: `Содержит натуральный источник глюкозамина`,
            question5: `Жизненная энергия`,
            question6: `Содержит повышенные уровни витаминов В1, В2, В6 и железа`,
            icon1: img4_1,
            icon2: img4_2,
            icon3: img3_3,
        },
    }


    _renderTestDesktop = () => {
        const {answerCorrectObj, correct, testActive, questionsObj} = this.state
        return (
            <div className="test_desktop">
                <div className="cards">
                    {Object.keys(this.testDataDesktop).map((item,index) => {
                        return (
                            <div
                                key={item}
                                className={'cards_el ' + (testActive === index && 'active')}
                                onClick={() => {
                                    this.showTestActive(index);
                                    this.answer1.clearAnswer()
                                    this.answer2.clearAnswer()
                                    this.answer3.clearAnswer()
                                    this.answer4.clearAnswer()
                                    this.answer5.clearAnswer()
                                    this.answer6.clearAnswer()
                                }}
                            >
                                <p className="cards_el_text">
                                    {this.testDataDesktop[item].cardHead}
                                </p>
                                <div className="cards_el_box-img">
                                    <img src={this.testDataDesktop[item].cardImg} alt="" className="cards_el_img"/>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="questions">
                    {Object.values(this.testDataDesktop[testActive + 1]).slice(2, 8).map((item, index) => {
                        return (
                            <div
                                key={index}
                                className={'question_el ' + (testActive > 1 && 'pink ') + (Object.values(questionsObj)[testActive].indexOf(String(index + 1)) >= 0 && ' hidden')}
                            >
                                <Questions targetKey="1" question_el={String(index + 1)} label={item} text={item}/>
                            </div>
                        )
                    })}
                </div>
                <div className="flex">
                    <div className="answers">
                        <div className="answers_icon-box">
                            <img src={this.testDataDesktop[testActive + 1].icon1} alt="" className="answers_icon"/>
                        </div>
                        {Object.values(this.testDataDesktop[testActive + 1]).slice(2, 4).map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className="answers_item"
                                >
                                    <Answer ref={Answer => {
                                        // eslint-disable-next-line
                                        switch (index) {
                                            case 0:
                                                this.answer1 = Answer
                                                break
                                            case 1:
                                                this.answer2 = Answer
                                                break
                                        }
                                    }} {...this.props} targetKey="1" setAnswer={this.setAnswersObj} answer_el={String(index + 1)} text={'?'} count={'?'} answerCorrect={Object.values(answerCorrectObj)[testActive][index]}/>
                                </div>
                            )
                        })}
                        <div className="answers_icon-box">
                            <img src={this.testDataDesktop[testActive + 1].icon2} alt="" className="answers_icon"/>
                        </div>
                        {Object.values(this.testDataDesktop[testActive + 1]).slice(4, 6).map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className="answers_item"
                                >
                                    <Answer ref={Answer => {
                                        // eslint-disable-next-line
                                        switch (index) {
                                            case 0:
                                                this.answer3 = Answer
                                                break
                                            case 1:
                                                this.answer4 = Answer
                                                break
                                        }
                                    }} {...this.props} targetKey="1" setAnswer={this.setAnswersObj} answer_el={String(index + 3)} text={'?'} count={'?'} answerCorrect={Object.values(answerCorrectObj)[testActive][index + 2]}/>
                                </div>
                            )
                        })}
                        <div className="answers_icon-box">
                            <img src={this.testDataDesktop[testActive + 1].icon3} alt="" className="answers_icon"/>
                        </div>
                        {Object.values(this.testDataDesktop[testActive + 1]).slice(6, 8).map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className="answers_item"
                                >
                                    <Answer ref={Answer => {
                                        // eslint-disable-next-line
                                        switch (index) {
                                            case 0:
                                                this.answer5 = Answer
                                                break
                                            case 1:
                                                this.answer6 = Answer
                                                break
                                        }
                                    }}{...this.props} targetKey="1" setAnswer={this.setAnswersObj} answer_el={String(index + 5)} text={'?'} count={'?'} answerCorrect={Object.values(answerCorrectObj)[testActive][index + 4]}/>
                                </div>
                            )
                        })}
                        {/*{Object.values(this.testDataDesktop[testActive + 1])[8].map((item, index) => {
                        if (index === 0 || index === 3 || index === 6) {
                            return (
                                <div
                                    key={index}
                                    className={'answers_icon-box'}
                                >
                                    <img src={item} alt="" className="answers_icon"/>
                                </div>
                            )
                        } else {
                            return (
                                <div
                                    key={index}
                                    className="answers_item"
                                >
                                    <Answer targetKey="1" setAnswer={this.setAnswersObj} answer_el={String(index + 1)} text={'?'} count={'?'} answerCorrect={Object.values(answerCorrectObj)[testActive][index]}/>
                                </div>
                            )
                        }
                    })}*/}
                    </div>
                </div>
                {correct !== '' ?
                    <div className={'info ' + (correct === '0' && 'error')}>
                        <div className="info_head">
                            {correct === '1' ? 'все верно!' : 'К сожалению, ваш ответ не верен.'}
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        )
    }

    //Работа мобильной версии
    questions = [
        `Для кого`,
        `Внешний вид корма`,
        `Вкус и текстура`,
        `Название и логотип`,
        `Возраст`,
        `Ключевые особенности корма`
    ]

    selectAnswer = (answerActive) => {
        document.body.scrollIntoView( true);
        document.body.style.overflow = "hidden";
        this.setState({
            showPopup: true,
            answerActive: answerActive
        })
    }

    selectQuestion = (questionActive) => {
        const {answerActive, answersMobile, questionsMobile, answersSelected} = this.state

        if (answersMobile.indexOf(answerActive) === -1) {
            answersMobile.push(answerActive)
        }
        this.setState({answersMobile})

        if (questionsMobile.indexOf(questionsMobile) === -1) {
            questionsMobile.push(questionActive)
        }
        this.setState({questionsMobile})

        answersSelected.splice(answerActive - 1, 1, this.questions[questionActive - 1])

        document.body.style.overflow = "";
        this.setState({
            showPopup: false,
            questionActive: questionActive,
            answersSelected: answersSelected
        })
    }

    checkAnswerMobile = () => {
        const {answerCorrectMobile, answersSelected} = this.state
        // eslint-disable-next-line
        answersSelected.map((answer, index) => {
            if (answer === this.questions[index]) {
                answerCorrectMobile.push('correct')
            } else {
                answerCorrectMobile.push('inCorrect')
            }
        })
        this.setState({
            answerCorrect: answerCorrectMobile,
        })
        if (answerCorrectMobile.indexOf('?') === -1 && answerCorrectMobile.indexOf('inCorrect') === -1) {
            this.setState({correctMobile: '1'})
        } else {
            this.setState({correctMobile: '0'})
        }
        this.props.showNextBtn()
    }

    closePopup = () => {
        document.body.style.overflow = "";
        this.setState({showPopup: false})
    }

    _renderTestMobile = () => {
        const {showPopup, answerActive, answersMobile, questionsMobile, answersSelected, correctMobile, answerCorrectMobile} = this.state
        return (
            <div className="test_mobile">
                {correctMobile !== '' ?
                    <div className={'info ' + (correctMobile === '0' && 'error')}>
                        <div className="info_head">
                            {correctMobile === '1' ? 'все верно!' : 'К сожалению, ваш ответ не верен.'}
                        </div>
                        <div className="infoText">
                            {correctMobile === '1'
                                ?
                                `Вы правильно расположили основные элементы лицевой стороны упаковки. Далее ознакомьтесь с этикеткой на задней стороне.`
                                :
                                'Попробуйте еще раз расположить основные элементы лицевой стороны упаковки.'
                            }
                        </div>
                    </div>
                    :
                    <button className="btn btn_blue" onClick={() => this.checkAnswerMobile()}>
                        ПРОВЕРИТЬ
                    </button>
                }
                <div className="flex">
                    <div className="flex_img">
                        {this.questions.map((item, index) => {
                            return (
                                <span
                                    key={index}
                                    className={'point ' + answerCorrectMobile[index]}
                                >
                                    {index + 1}
                                </span>
                            )
                        })
                        }
                        <img className="img" src={img1} alt=""/>
                    </div>
                </div>
                <div className="answers">
                    {this.questions.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className={'answers_item ' + (answersMobile.indexOf(index + 1) >=0 ? 'selected ' : '') + answerCorrectMobile[index]}
                                onClick={() => {this.selectAnswer(index + 1)}}
                            >
                                {answersSelected[index]}
                                <span className={'answers_number ' + (answersMobile.indexOf(index + 1) >=0 ? 'selected ' : '') + answerCorrectMobile[index]}>{index + 1}</span>
                            </div>
                        )
                    })

                    }
                </div>
                {showPopup &&
                <div className="popup">
                    <div className="popup_wrp">
                        <div
                            className="popup_closed"
                            onClick={() => {this.closePopup()}}
                        />
                        <div className="popup_flex">
                            <div className="point">
                                {answerActive}
                            </div>
                            <p className="popup_text">
                                Выберите вариант ответа:
                            </p>
                            <div className="questions">
                                {this.questions.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={'questions_item ' + (questionsMobile.indexOf(index + 1) >=0 ? 'selected ' : '')}
                                            onClick={() => {this.selectQuestion(index + 1)}}
                                        >
                                            {item}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }

    render() {
        const {windowWidth} = this.state
        return (
            <div className="step10">
                <TextBlock
                    text={`На лицевой стороне упаковки указывается базовая информация о продукте.`}
                />
                <InfoBlock
                    text={`Нажмите на продукт и сопоставьте индивидуальные особенности состава с полезными свойствами данного продукта.`}
                />
                {windowWidth >= 768 ?
                    <div className="test">
                        {this._renderTestDesktop()}
                    </div>
                    :
                    <div className="test">
                        {this._renderTestMobile()}
                    </div>
                }
            </div>
        )
    }
}
