import React from 'react'

import img1 from "../../../image/lessons/lesson7/step26-1.png";

export default class step26 extends React.Component {

    render() {
        return (
            <div className="step26">
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Для питомцев
                        </div>
                        <div className="flex_el_box">
                            <ul className="list">
                                <li className="list_item">Меньшая калорийность. Профилактика набора лишнего веса и ожирения.</li>
                                <li className="list_item">Легко разгрызать и глотать. Это особенно важно для котят и щенков, а также для старых животных.</li>
                                <li className="list_item">Высокое содержание мясных ингредиентов.</li>
                                <li className="list_item">За счет высокого содержания жидкости увеличивает объем и снижает тем самым концентрацию мочи, что способствует профилактике мочекаменной болезни. Актуально для кошек и собак мелких пород.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Для хозяев
                        </div>
                        <div className="flex_el_box">
                            <ul className="list">
                                <li className="list_item">Большой выбор вкусов и текстур дает возможность разнообразить рацион питомца.</li>
                                <li className="list_item">Высокие вкусовые качества. Легко угодить привередливым питомцам.</li>
                                <li className="list_item">Закрытый пауч не нужно хранить в холодильнике.</li>
                                <li className="list_item">Паучи предназначены для разового кормления.</li>
                            </ul>
                        </div>
                    </div>
                    <img src={img1} alt="" className="flex_el_img"/>
                </div>
            </div>
        )
    }
}
