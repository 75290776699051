import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson22/step4-1.png'
import img2 from '../../../image/lessons/lesson22/step4-2.png'

export default class step4 extends React.Component {

    render() {
        return (
            <div className="step4">
                <img src={img1} alt="" className="imgCenter"/>
                <TextBlock
                    text={`<b>DREAMIES™ —</b> это <b>особое сочетание</b> хрустящей оболочки снаружи и нежного паштета внутри подушечки.`}
                />
                <img src={img2} alt="" className="imgCenter"/>
                <TextBlock
                    text={`Такое сочетание позволяет кошке не только с удовольствием разгрызать хрустящую подушечку, но и наслаждаться мягкой начинкой.

                    Благодаря удачной комбинации текстур, DREAMIES™ завоевал особое признание среди котов и кошек.`}
                />
            </div>
        )
    }
}
