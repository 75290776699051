import React from 'react'

import img1 from '../../../image/lessons/lesson16/step27-1.png'


export default class step27 extends React.Component {


    render() {
        return (
            <div className="step27">
                <img src={img1} className="imgCenter" alt=""/>
            </div>
        )
    }
}
