import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson21/step2-1.png";

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <TextBlock
                    text={`Как у типичных ночных животных, у кошек особенно развиты те органы чувств, которые помогают охотиться и спасают от внешних врагов.`}
                />
                <div className="flex">
                    <TextBlock
                        text={`Кошки зорко видят в темноте и способны воспринимать широкий звуковой диапазон. И хотя большинство кошек живут сегодня в безопасности и домашнем уюте, они сохранили физиологические особенности своих диких предков.`}
                    />
                    <img src={img1} alt="" className="flex_img"/>
                </div>
            </div>
        )
    }
}
