import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from "../../../image/lessons/lesson8/step26-1.png";

export default class step26 extends React.Component {

    render() {
        return (
            <div className="step26">
                <TextBlock
                    text={`Постепенный перевод имеет следующие <span class="text_blue">преимущества:</span>`}
                />
                <ul className="list">
                    <li className="list_item">животное привыкает к новым вкусам и текстурам;</li>
                    <li className="list_item">пищеварительная система адаптируется к новым ингредиентам;</li>
                    <li className="list_item">увеличивается вероятность принятия животным нового корма.</li>
                </ul>
                <div className="flex">
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                    <div className="flex_el">
                        <TextBlock
                            text={`<span class="text_blue">Другие рекомендации по переводу на новый рацион:</span>`}
                        />
                        <ul className="list">
                            <li className="list_item">добавить в корм лакомства, которые питомец любит, но в маленьких количествах;</li>
                            <li className="list_item">немного подогреть корм для усиления аромата.</li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
