import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <div className="block">
                    <TextBlock
                        text={`Режим самоизоляции влияет на наше взаимодействие, в большей степени, с собаками. Именно поэтому для начала мы подробнее поговорим о них и о том, как сделать совместное пребывание дома наиболее комфортным.

                            А затем вспомним важные советы по обеспечению благоприятной среды дома для вашей кошки.`}
                    />
                </div>
            </div>
        )
    }
}
