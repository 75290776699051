import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson15/step7-1.png'
import img1xs from '../../../image/lessons/lesson15/step7-1xs.png'
import img2 from '../../../image/lessons/lesson15/step7-2.png'
import img2xs from '../../../image/lessons/lesson15/step7-2xs.png'
import img3 from '../../../image/lessons/lesson15/step7-3.png'
import img4 from '../../../image/lessons/lesson15/step7-4.png'


export default class step7 extends React.Component {

    render() {
        return (
            <div className="step7">
                <TextBlock
                    text={`На основе полученной информации, рассчитайте размер суточной порции для этой собаки.`}
                />

                <div className="block">
                    <div className="block_head">
                        1. Увеличивается количество кошек <b>старше 7 лет.</b>
                    </div>
                    <div className="block_box">
                        <div className="block_box_el">
                            <picture>
                                <source srcSet={img1xs} media="(max-width: 767px)"/>
                                <img src={img1} alt="" className="block_box_el_img"/>
                            </picture>
                        </div>
                        <div className="block_box_el">
                            <div className="block_box_el_description">
                                <div className="block_box_el_point dark-blue"/>
                                <div className="block_box_el_text">возраст старше 7 лет</div>
                            </div>
                            <div className="block_box_el_description">
                                <div className="block_box_el_point blue"/>
                                <div className="block_box_el_text">возраст моложе 7 лет</div>
                            </div>
                            <div className="block_box_el_description">
                                <div className="block_box_el_point green">
                                    <img src={img4} alt="" className="block_box_el_point_img"/>
                                </div>
                                <div className="block_box_el_text">самый активный рост популяции</div>
                            </div>
                            <img src={img3} alt="" className="img"/>
                        </div>
                    </div>
                </div>
                <div className="block">
                    <div className="block_head">
                        2. Увеличивается количество <b>стерилизованных\кастрированных</b> животных.
                    </div>
                    <div className="block_box">
                        <div className="block_box_el">
                            <picture>
                                <source srcSet={img2xs} media="(max-width: 767px)"/>
                                <img src={img2} alt="" className="block_box_el_img"/>
                            </picture>
                        </div>
                        <div className="block_box_el">
                            <div className="block_box_el_description">
                                <div className="block_box_el_point dark-blue"/>
                                <div className="block_box_el_text">стерил./кастрированные</div>
                            </div>
                            <div className="block_box_el_description">
                                <div className="block_box_el_point blue"/>
                                <div className="block_box_el_text">не стерил./ не кастрированные животные</div>
                            </div>
                            <div className="block_box_el_description">
                                <div className="block_box_el_point green">
                                    <img src={img4} alt="" className="block_box_el_point_img"/>
                                </div>
                                <div className="block_box_el_text">самый активный рост популяции</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
