import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

import img1 from '../../../image/lessons/lesson32/step25-1.png';
import img2 from '../../../image/lessons/lesson32/step25-2.png';
import img3 from '../../../image/lessons/lesson32/step25-3.png';


export default class step25 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        pointActive: 0,
        points: [0],
        showPopup: false,
    };

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }


    clickPoint = pointActive => {
        const {points} = this.state
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }


        this.setState({pointActive, points})

        if (points.length === 3) {
            this.props.showNextBtn();
        }
    }

    render() {
        const {pointActive, windowWidth, points} = this.state
        return (
            <div className="step25">
                <div className="info">
                    <div className="info_el">
                        <TextBlock
                            text={`<b>В рационах PEDIGREE<sup>®</sup> поддерживается оптимальный баланс кальция и фосфора для здорового развития зубов у щенков и их укрепления у взрослых собак</b>`}
                        />
                    </div>
                    <div className="info_el">
                        <img src={img3} alt="" className="imgCenter"/>
                    </div>
                </div>
                <InfoBlock
                    text={`Нажмите на активные элементы, чтобы узнать больше о роли питания в поддержании здоровья зубов`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <img
                            src={img1} alt=""
                            className={'flex_el_img ' + (pointActive === 1 && windowWidth > 767 && 'active ') + (points.indexOf(1) >= 0 && windowWidth <= 767 && ' active')}
                            onClick={() => {this.clickPoint(1)}}
                        />
                        <div className={'flex_el_block ' + (pointActive === 1 && windowWidth > 767 && 'active ') + (points.indexOf(1) >= 0 && windowWidth <= 767 && ' active')}>
                            <div className="flex_el_block_head">
                                Для щенков
                            </div>
                            <div className="flex_el_block_text">
                                Для нормального формирования зубов очень важно поступление с рационом <b>достаточного количества Ca и Р</b>
                            </div>
                            <div className="flex_el_block_head">
                                Последствия недостатка кальция и фосфора
                            </div>
                            <div className="flex_el_block_text">
                                Недостаток и нарушение их баланса приводит к <b>запоздалой смене зубов и их аномальному росту</b>
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <img
                            src={img2} alt=""
                            className={'flex_el_img ' + (pointActive === 2 && windowWidth > 767 && 'active ') + (points.indexOf(2) >= 0 && windowWidth <= 767 && ' active')}
                            onClick={() => {this.clickPoint(2)}}
                        />
                        <div className={'flex_el_block ' + (pointActive === 2 && windowWidth > 767 && 'active ') + (points.indexOf(2) >= 0 && windowWidth <= 767 && ' active')}>
                            <div className="flex_el_block_head">
                                Для взрослых собак
                            </div>
                            <div className="flex_el_block_text">
                                <b>Ca и Р</b> важны для сохранения целостности зуба. Эмаль у собак очень тонкая и подвержена стиранию
                            </div>
                            <div className="flex_el_block_head">
                                Последствия недостатка кальция и фосфора
                            </div>
                            <div className="flex_el_block_text">
                                При нарушении целостности эмали происходит накопление налета в месте дефекта, а впоследствии - образование зубного камня
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
