import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';
import DefinitionBlock from '../../../Components/LessonAndTestComponents/DefinitionBlock';

import img1 from "../../../image/lessons/lesson4/step6-1.png";
import img2 from "../../../image/lessons/lesson4/step6-2.png";
import img3 from "../../../image/lessons/lesson4/step6-3.png";
import img4 from "../../../image/lessons/lesson4/step6-4.png";

export default class step6 extends React.Component {

    state = {
        flexData:
            [
                'короткоцепочечные жирные кислоты',
                'насыщенные длинноцепочечные жирные кислоты',
                'незаменимые жирные кислоты',
                'полиненасыщенные длинноцепочечные жирные кислоты'
            ],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData, flexHidden} = this.state
        return (
            <div className="step6">
                <DefinitionBlock
                    text={`<span class="text_blue">Жирные кислоты</span> — основной компонент жиров. Они состоят из углеродной цепи, которая может иметь разную длину и химическую структуру.`}
                    hidden={flexHidden === true ? ' hidden' : ''}
                />
                <InfoBlock
                    text={`Нажмите на блок, чтобы получить дополнительную информацию.`}
                    hidden={flexHidden === true ? ' hidden' : ''}
                />
                <div className={'head ' + (flexHidden === true ? 'hidden' : '')}>
                    Жирные кислоты делятся на 4 вида:
                </div>
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Энергия для активной жизни.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Энергия (поддержание), хранение в жировой ткани.
                            
                            Используются исключительно для выработки энергии в организме.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img2} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Гормональные вещества.
                            
                            Жирные кислоты называются незаменимыми, если они не вырабатываются в организме.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img3} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Энергия + структура мембраны.
                            
                            Полиненасыщенные жирные кислоты (ПНЖК) играют более сложную роль.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img4} alt=""/>
                        </div>
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
