import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson2/step10-1.png";

export default class step10 extends React.Component {

    render() {
        return (
            <div className="step10">
                <div className="advice">
                    <b>Энергетическая ценность</b> — это количество энергии в килокалориях, которое животное получает при употреблении 100 г. корма.
                </div>
                <div className="flex">
                    <div className="flex_el">
                        <TextBlock
                            text={`Энергетическая ценность корма всегда указана на упаковке в ккал/100 г, и под ней подразумевается количество обменной энергии.`}
                        />
                        <img src={img1} alt="" className="flex_el_img_mobile"/>
                        <TextBlock
                            text={`<span class="text_blue">На энергетическую ценность напрямую влияет состав корма.</span> Его также можно найти на задней стороне упаковки.`}
                        />
                    </div>
                    <div className="flex_el">
                        <img src={img1} alt="" className="img"/>
                    </div>
                </div>
            </div>
        )
    }
}
