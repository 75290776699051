import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson6/step9-1.png";
import img2 from "../../../image/lessons/lesson6/step9-2.png";

export default class step9 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит и избыток'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step9">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Хлор (Cl) — кислотно-щелочной баланс.</b>
                           
                            Наиболее распространенный отрицательно заряженный ион в межклеточной жидкости животных.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_flex">
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img2} alt=""/>
                            </div>
                            <TextBlock
                                text={`Хлор важен для поддержания концентрации межклеточной жидкости и играет роль в поддержании кислотно-щелочного баланса.`}
                            />
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`В большинстве пищевых продуктов хлор содержится в ограниченных количествах. По этой причине рацион должен быть дополнен хлоридсодержащими солями, например, хлоридом натрия (поваренная соль).`}
                        />
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Симптомы <b>дефицита</b> хлора включают:`}
                        />
                        <ul className="list">
                            <li className="list_item">слабость;</li>
                            <li className="list_item">задержку роста;</li>
                            <li className="list_item">те же симптомы, что наблюдаются при дефиците калия.</li>
                        </ul>
                        <TextBlock
                            text={`<b>Избыток</b> хлора может привести к:`}
                        />
                        <ul className="list">
                            <li className="list_item">изменению уровня кальция и калия в крови;</li>
                            <li className="list_item">метаболическому ацидозу.</li>
                        </ul>
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
