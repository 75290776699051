import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson2/step12-1.png";

export default class step12 extends React.Component {


    render() {
        return (
            <div className="step12">
                <TextBlock
                    text={`Энергетическая ценность различных питательных веществ.`}
                />
                <div className="units">
                    <p className="units_el">
                        1 грамм жиров = <span className="units_el_blue">9.4 ккал</span> валовой энергии
                    </p>
                    <p className="units_el">
                        1 грамм белков = <span className="units_el_blue">5.7 ккал</span> валовой энергии
                    </p>
                    <p className="units_el">
                        1 грамм углеводов = <span className="units_el_blue">4.1 ккал</span> валовой энергии
                    </p>
                </div>
                <div className="flex">
                    <div className="flex_el">
                        <TextBlock
                            text={`Видно, что <span class="text_blue">жиры производят в два раза больше килокалорий,</span> чем белки и углеводы, что делает их более энергетически плотным питательным веществом.`}
                        />
                    </div>
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                </div>
                <TextBlock
                    text={`В готовых кормах содержание жиров и белков указано на упаковке, а углеводы рассчитываются по формуле:`}
                />
                <div className="formula">
                    % УГЛЕВОДОВ = 100 – (% БЕЛКОВ + % ЖИРОВ + % ВОДЫ + % ЗОЛЫ + % ВОЛОКОН)
                </div>
            </div>
        )
    }
}
