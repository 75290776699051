import React from 'react'
import './style.sass'

import logo from '../../image/logo-auth-perekrestok.png'
import Inputs from '../../Components/Inputs'
import SiteFooter from '../../Components/SiteFooter'
import {Link} from "react-router-dom";
import Api from '../../Service/Api'
import {withRouter} from 'react-router-dom';
import Checkbox from "../../Components/Checkbox";

class Perekrestok extends React.Component {

    state = {
        type: 'auth',
        email: '',
        emailError: false,
        emailErrorText: false,
        password: '',
        passwordError: false,
        passwordErrorText: false,
        agreement: false
    }

    Api = new Api()

    componentDidMount() {
    }

    changeFormType = (type) => {
        this.setState({type})
    }

    changeAgreement = () => {
        const {agreement} = this.state
        this.setState({
            agreement: !agreement
        })
    }

    updateState = (type, value, index) => {
        // eslint-disable-next-line
        switch (type) {
            case 'email' : {
                this.setState({
                    email: value,
                    emailError: false,
                    emailErrorText: false
                });
                break;
            }
            case 'password' : {
                this.setState({
                    password: value,
                    passwordError: false,
                    passwordErrorText: false
                })
                break;
            }
        }
    }

    sendDate = () => {
        const {
            email,
            password,
            type,
        } = this.state
        if (!this.valid_email(email)) {
            this.setState({emailError: true})
        } else if (!password.length) {
            this.setState({passwordError: true})
        } else {
            if (type === 'auth') {
                this.Api.login(email, password)
                    .then((res) => {
                        if (res.status === 200) {
                            this.props.changeType();
                        } else {
                            Object.keys(res.error).map(key => {
                                // eslint-disable-next-line
                                this.state[key + 'Error'] = true;
                                // eslint-disable-next-line
                                this.state[key + 'ErrorText'] = res.error[key];
                                this.setState({errors: new Date()})
                                // if (key === 'password') {
                                //     this.setState({
                                //         passwordError: true,
                                //         passwordErrorText: res.error[key],
                                //     })
                                // }
                                return false
                            })
                        }
                    })
            } else {
                this.Api.signUpFree(email, password, 'perekrestok')
                    .then((res) => {
                        if (res === true) {
                            this.props.changeType();
                        } else {
                            Object.keys(res.errors).map(key => {
                                // eslint-disable-next-line
                                this.state[key + 'Error'] = true;
                                // eslint-disable-next-line
                                this.state[key + 'ErrorText'] = res.errors[key];
                                this.setState({errors: new Date()})
                                return false
                            })
                        }
                    })
            }
            // (type === 'auth' ? this.Api.login(email, password) : this.Api.signUpFree(email, password, 'pyaterochka'))
            // // this.Api.login(email, password)
            //     .then((res) => {
            //         if (res.status === 200) {
            //             this.props.changeType();
            //         } else {
            //             Object.keys(res.error).map(key => {
            //                 // eslint-disable-next-line
            //                 this.state[key + 'Error'] = true;
            //                 // eslint-disable-next-line
            //                 this.state[key + 'ErrorText'] = res.error[key];
            //                 this.setState({errors: new Date()})
            //                 // if (key === 'password') {
            //                 //     this.setState({
            //                 //         passwordError: true,
            //                 //         passwordErrorText: res.error[key],
            //                 //     })
            //                 // }
            //                 return false
            //             })
            //         }
            //     })
        }
    }

    valid_email = (el) => {
        let reg = /^[a-z-._0-9]+@[a-z-_0-9]+\.[a-z0-9]{2,10}$/i;
        return reg.test(el)
    }

    render() {
        const {
            type,
            emailError,
            emailErrorText,
            passwordError,
            passwordErrorText,
            agreement
        } = this.state
        return (
            <div className="Perekrestok">
                <div className="wrp">
                    <form action="" className="form">
                        <div className="logo">
                            <img src={logo} alt=""/>
                        </div>
                        <div className="head">
                            <b>Добро пожаловать!</b> <br/>
                            Для входа в обучающую программу заполните, пожалуйста, поля:
                        </div>
                        <div className="form_type">
                            <div
                                className={'form_type_el ' + (type === 'auth' && 'active')}
                                onClick={() => (this.changeFormType('auth'))}
                            >
                                Вход
                            </div>
                            <div
                                className={'form_type_el ' + (type === 'registration' && 'active')}
                                onClick={() => (this.changeFormType('registration'))}
                            >
                                Регистрация
                            </div>
                        </div>
                        <Inputs
                            name={'email'}
                            placeholder={'E-mail'}
                            onChange={this.updateState}
                            type={'email'}
                            className={emailError && 'error'}
                            errorText={emailErrorText}
                        />
                        <Inputs
                            name={'password'}
                            placeholder={'Пароль'}
                            onChange={this.updateState}
                            className={passwordError && 'error'}
                            type={'password'}
                            errorText={passwordErrorText}

                        />
                        {type === 'registration' &&
                            <Checkbox
                                active={agreement}
                                text={'Я подтверждаю, что мне исполнилось 18 лет, я ознакомился/ознакомилась с <a class="agreement_link" href="/agreement.pdf" target="_blank" rel="noopener noreferrer">пользовательским соглашением</a>, политикой конфиденциальности и политикой обработки персональных данных.'}
                                onClick={this.changeAgreement}
                            />
                        }
                        <div className="buttons">
                            <button
                                type='button'
                                className="btn btn_transparent_pink"
                                onClick={() => {
                                    this.sendDate()
                                }}
                                disabled={agreement || type === 'auth' ? '' : 'disabled'}
                            >
                                {type === 'registration' ? 'Зарегистрироваться' : 'Войти'}
                            </button>
                        </div>
                    </form>
                </div>
                <SiteFooter/>
            </div>
        )
    }

}

export default withRouter(Perekrestok);
