import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from "../../../Components/LessonAndTestComponents/OnClickBlock";

import img1 from '../../../image/lessons/lesson4/step8-1.png';
import img2 from "../../../image/lessons/lesson4/step8-2.png";

export default class step8 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит и избыток'],
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step8">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Омега-6 жирные кислоты — здоровая кожа и шерсть, репродуктивная функция.</b>
                           
                            Арахидоновая и линолевая кислоты — это жирные кислоты, принадлежащие семейству омега-6 ПНЖК.

                            Организм собак может синтезировать арахидоновую кислоту из линолевой, а организм кошек — нет, поэтому арахидоновая кислота — это необходимый компонент в рационе кошек.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Омега-6 жирные кислоты необходимы для нормального процесса репродукции.
                            
                            Они участвуют в синтезе простагландинов — гормоноподобных веществ, регулирующих целый ряд репродуктивных процессов, включая овуляцию и роды.`}
                        />
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Арахидоновая кислота содержится в животных жирах:`}
                        />
                        <div className="imgDiv_wrap_flex">
                            <div className="box">
                                <ul className="list">
                                    <li className="list_item">говяжий жир;</li>
                                    <li className="list_item">подкожный жир домашней птицы.</li>
                                </ul>
                                <TextBlock
                                    text={`Линолевая кислота содержится в растительных жирах, например, в подсолнечном масле.`}
                                />
                            </div>
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img2} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Дефицит омега-6 жирных кислот может привести к:`}
                        />
                        <ul className="list">
                            <li className="list_item">нарушению репродуктивной функции;</li>
                            <li className="list_item">сухости, раздражению и шелушению кожи;</li>
                            <li className="list_item">тусклости шерстного покрова.</li>
                        </ul>
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
