import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickTest from "../../../Components/LessonAndTestComponents/OnClickTest";
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";

import img1 from "../../../image/lessons/lesson7/step5-1.png";


export default class step5 extends React.Component {

    state = {
        answersData: [`1-2 раза в день`, `4-6 раз в день`, `7-20 раз в день`]
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step5">
                <TextBlock
                    text={`Как вы думаете, как часто едят кошки в течение дня?`}
                />
                <InfoBlock
                    text={`Выберите подходящий вариант и нажмите «Проверить».`}
                />
                <OnClickTest
                    ansewrsData={this.state.answersData}
                    type={'block'}
                    rightAnswers={[3]}
                    showNextBtn={this.showNextBtn}
                    img={img1}
                    correctText={`Желудок кошек не способен сильно растягиваться, поэтому они едят маленькими порциями в течение суток.`}
                />
            </div>
        )
    }
}
