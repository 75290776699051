import React from 'react'
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";

import img1 from '../../../image/lessons/lesson23/step9-1.png';
import img2 from '../../../image/lessons/lesson23/step9-2.png';

export default class step9 extends React.Component {

    state = {
        pointActive: 0,
        points: [],
    }

    showInfo = (pointActive) => {
        const {points} = this.state;
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        this.setState({points: points, pointActive: pointActive})

        if (points.length === 1) {
            this.props.showNextBtn();
        }
    }

    render() {
        const {points, pointActive} = this.state;
        return (
            <div className="step9">
                <InfoBlock
                    text={`Нажмите на активные элементы, чтобы познакомиться с ассортиментом NATURE’S TABLE™.`}
                />
                <div className="flex">
                    <div className={'flex_el ' + (points.indexOf(1) >= 0 && 'active')}>
                        <div
                            className="flex_el_btn"
                            onClick={() => {this.showInfo(1)}}
                        >
                            Сухие рационы
                        </div>
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                    <img src={img2} alt="" className={'flex_img ' + (pointActive > 0 && 'active')}/>
                </div>
            </div>
        )
    }
}
