import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import Answer from './dnd/Answer';
import Questions from './dnd/Questions';

import img1 from '../../../image/lessons/lesson14/step9-1.png'
import img2 from '../../../image/lessons/lesson14/step9-2.png'
import img3 from '../../../image/lessons/lesson14/step9-3.png'
import img4 from '../../../image/lessons/lesson14/step9-4.png'

export default class step9 extends React.Component {

    state = {
        // Ресайз
        windowWidth: window.innerWidth,

        // Работа десктопной версии
        answers: [],
        questions: [],
        correct: '',
        answerCorrect: [],
        rightsAnswers: [1, 2, 3, 5, 4, 6, 7, 8],

        //Работа мобильной версии
        answerActive: 0,
        questionActive: 0,
        answersMobile: [],
        questionsMobile: [],
        answersSelected: ['?', '?', '?', '?', '?', '?', '?', '?'],
        correctMobile: '',
        answerCorrectMobile: [],
        showPopup: false
    }

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    //Работа десктопной версии
    setAnswer = (answer_el, question_el) => {
        const {answers} = this.state

        if (answers.indexOf(question_el) === -1) {
            let answer = []
            answers[answer_el] = question_el
            answers.push(answer)
        }
        this.setState({answers})

        let questions = [];
        // eslint-disable-next-line
        answers.map(answer => {

            if (answer.length) {
                questions.push(answer)
            }

        });
        this.setState({questions})
    };

    checkAnswer = () => {
        const {answerCorrect, questions, rightsAnswers} = this.state
        console.log(questions, rightsAnswers)
        // eslint-disable-next-line
        questions.map((answer, index) => {
            if (index < 2) {
                if (+answer === rightsAnswers[0] || +answer === rightsAnswers[1]) {
                    answerCorrect.push('correct')
                } else {
                    answerCorrect.push('inCorrect')
                }
            } else if (index < 4) {
                if (+answer === rightsAnswers[2] || +answer === rightsAnswers[3] || +answer === rightsAnswers[5]) {
                    answerCorrect.push('correct')
                } else {
                    answerCorrect.push('inCorrect')
                }
            } else if (index < 6) {
                if (+answer === rightsAnswers[4] || +answer === rightsAnswers[5] || +answer === rightsAnswers[3]) {
                    answerCorrect.push('correct')
                } else {
                    answerCorrect.push('inCorrect')
                }
            } else if (index < 8) {
                if (+answer === rightsAnswers[6] || +answer === rightsAnswers[7]) {
                    answerCorrect.push('correct')
                } else {
                    answerCorrect.push('inCorrect')
                }
            }
            // if (answer === String(index + 1)) {
            //     answerCorrect.push('correct')
            // } else {
            //     answerCorrect.push('inCorrect')
            // }
        })
        this.setState({
            answerCorrect: answerCorrect,
        })
        if (questions.length === 8 && answerCorrect.indexOf('inCorrect') === -1) {
            this.setState({correct: '1'})
        } else {
            this.setState({correct: '0'})
        }
        this.props.showNextBtn()
    };

    _renderTestDesktop = () => {
        const {answerCorrect, correct, questions} = this.state
        return (
            <div className="test_desktop">
                <div className="questions">
                    <div className={'question_el ' + (questions.indexOf('1') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'1'} label={'Высокоусвояемые ингредиенты'} text={'Высокоусвояемые ингредиенты'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('2') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'2'} label={'Диетическая клетчатка'} text={'Диетическая клетчатка'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('3') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'3'} label={'Линолевая кислота'} text={'Линолевая кислота'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('4') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'4'} label={'Витамин Е'} text={'Витамин Е'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('5') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'5'} label={'Цинк'} text={'Цинк'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('6') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'6'} label={'Цинк'} text={'Цинк'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('7') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'7'} label={'Оптимальный уровень кальция и фосфора'} text={'Оптимальный уровень кальция и фосфора'}/>
                    </div>
                    <div className={'question_el ' + (questions.indexOf('8') >= 0 && 'hidden')}>
                        <Questions targetKey="1" question_el={'8'} label={'Пористая текстура сухого корма'} text={'Пористая текстура сухого корма'}/>
                    </div>
                </div>
                <div className="flex">
                    <div className="flex_el">
                        <div className="answers">
                            <div className="property">
                                Здоровое пищеварение
                                <img src={img1} alt="" className="property_img"/>
                                <span className="property_arrow">
                                    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.29289 18.7071C7.68342 19.0976 8.31658 19.0976 8.70711 18.7071L15.0711 12.3431C15.4616 11.9526 15.4616 11.3195 15.0711 10.9289C14.6805 10.5384 14.0474 10.5384 13.6569 10.9289L8 16.5858L2.34315 10.9289C1.95262 10.5384 1.31946 10.5384 0.928932 10.9289C0.538407 11.3195 0.538407 11.9526 0.928932 12.3431L7.29289 18.7071ZM7 -4.3711e-08L7 18L9 18L9 4.3711e-08L7 -4.3711e-08Z" fill="#005EAD"/>
                                    </svg>
                                </span>
                                <span className="property_plus">+</span>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'1'} text={'?'} count={'?'} answerCorrect={answerCorrect[0]}/>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'2'} text={'1'} count={'?'} answerCorrect={answerCorrect[1]}/>
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="answers">
                            <div className="property">
                                Здоровье кожи и шерсти
                                <img src={img2} alt="" className="property_img"/>
                                <span className="property_arrow">
                                    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.29289 18.7071C7.68342 19.0976 8.31658 19.0976 8.70711 18.7071L15.0711 12.3431C15.4616 11.9526 15.4616 11.3195 15.0711 10.9289C14.6805 10.5384 14.0474 10.5384 13.6569 10.9289L8 16.5858L2.34315 10.9289C1.95262 10.5384 1.31946 10.5384 0.928932 10.9289C0.538407 11.3195 0.538407 11.9526 0.928932 12.3431L7.29289 18.7071ZM7 -4.3711e-08L7 18L9 18L9 4.3711e-08L7 -4.3711e-08Z" fill="#005EAD"/>
                                    </svg>
                                </span>
                                <span className="property_plus">+</span>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'3'} text={'2'} count={'?'} answerCorrect={answerCorrect[2]}/>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'4'} text={'3'} count={'?'} answerCorrect={answerCorrect[3]}/>
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="answers">
                            <div className="property">
                                Иммунитет
                                <img src={img3} alt="" className="property_img"/>
                                <span className="property_arrow">
                                    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.29289 18.7071C7.68342 19.0976 8.31658 19.0976 8.70711 18.7071L15.0711 12.3431C15.4616 11.9526 15.4616 11.3195 15.0711 10.9289C14.6805 10.5384 14.0474 10.5384 13.6569 10.9289L8 16.5858L2.34315 10.9289C1.95262 10.5384 1.31946 10.5384 0.928932 10.9289C0.538407 11.3195 0.538407 11.9526 0.928932 12.3431L7.29289 18.7071ZM7 -4.3711e-08L7 18L9 18L9 4.3711e-08L7 -4.3711e-08Z" fill="#005EAD"/>
                                    </svg>
                                </span>
                                <span className="property_plus">+</span>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'5'} text={'?'} count={'?'} answerCorrect={answerCorrect[4]}/>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'6'} text={'?'} count={'?'} answerCorrect={answerCorrect[5]}/>
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="answers">
                            <div className="property">
                                Здоровые зубы
                                <img src={img4} alt="" className="property_img"/>
                                <span className="property_arrow">
                                    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.29289 18.7071C7.68342 19.0976 8.31658 19.0976 8.70711 18.7071L15.0711 12.3431C15.4616 11.9526 15.4616 11.3195 15.0711 10.9289C14.6805 10.5384 14.0474 10.5384 13.6569 10.9289L8 16.5858L2.34315 10.9289C1.95262 10.5384 1.31946 10.5384 0.928932 10.9289C0.538407 11.3195 0.538407 11.9526 0.928932 12.3431L7.29289 18.7071ZM7 -4.3711e-08L7 18L9 18L9 4.3711e-08L7 -4.3711e-08Z" fill="#005EAD"/>
                                    </svg>
                                </span>
                                <span className="property_plus">+</span>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'7'} text={'?'} count={'?'} answerCorrect={answerCorrect[6]}/>
                            </div>
                            <div className="answers_item">
                                <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'8'} text={'?'} count={'?'} answerCorrect={answerCorrect[7]}/>
                            </div>
                        </div>
                    </div>
                </div>
                {correct !== '' ?
                    <div className={'info ' + (correct === '0' && 'error')}>
                        <div className="info_head">
                            {correct === '1' ? 'все верно!' : 'К сожалению, ваш ответ неверен.'}
                        </div>
                        <div className="infoText">
                            Здоровое пищеварение обеспечивают высокоусвояемые ингредиенты и диетическая клетчатка, здоровье кожи и шерсти обеспечивает линолевая кислота и цинк, иммунитет обеспечивают витамин Е и цинк, а здоровые зубы обеспечивают оптимальный уровень кальция, фосфора и пористая текстура сухого корма.
                        </div>
                    </div>
                    :
                    <button className="btn btn_blue" onClick={() => this.checkAnswer()}>
                        Узнать правильный ответ
                    </button>
                }
            </div>
        )
    }

    //Работа мобильной версии
    questions = [
        `Высокоусвояемые ингредиенты`,
        `Диетическая клетчатка`,
        `Линолевая кислота`,
        `Цинк`,
        `Витамин Е`,
        `Цинк`,
        `Оптимальный уровень кальция и фосфора`,
        `Пористая текстура сухого корма`,
    ]

    selectAnswer = (answerActive) => {
        document.body.scrollIntoView( true);
        document.body.style.overflow = "hidden";
        this.setState({
            showPopup: true,
            answerActive: answerActive
        })
    }

    selectQuestion = (questionActive) => {
        const {answerActive, answersMobile, questionsMobile, answersSelected} = this.state

        if (answersMobile.indexOf(answerActive) === -1) {
            answersMobile.push(answerActive)
        }
        this.setState({answersMobile})

        if (questionsMobile.indexOf(questionsMobile) === -1) {
            questionsMobile.push(questionActive)
        }
        this.setState({questionsMobile})

        answersSelected.splice(answerActive - 1, 1, this.questions[questionActive - 1])

        document.body.style.overflow = "";
        this.setState({
            showPopup: false,
            questionActive: questionActive,
            answersSelected: answersSelected
        })
    }

    checkAnswerMobile = () => {
        const {answerCorrectMobile, answersSelected} = this.state
        // eslint-disable-next-line
        answersSelected.map((answer, index) => {
            if (index < 2) {
                if (answer === this.questions[0] || answer === this.questions[1]) {
                    answerCorrectMobile.push('correct')
                } else {
                    answerCorrectMobile.push('inCorrect')
                }
            } else if (index < 4) {
                if (answer === this.questions[2] || answer === this.questions[3]) {
                    answerCorrectMobile.push('correct')
                } else {
                    answerCorrectMobile.push('inCorrect')
                }
            } else if (index < 6) {
                if (answer === this.questions[4] || answer === this.questions[5]) {
                    answerCorrectMobile.push('correct')
                } else {
                    answerCorrectMobile.push('inCorrect')
                }
            } else if (index < 8) {
                if (answer === this.questions[6] || answer === this.questions[7]) {
                    answerCorrectMobile.push('correct')
                } else {
                    answerCorrectMobile.push('inCorrect')
                }
            }
            // if (answer === this.questions[index]) {
            //     answerCorrectMobile.push('correct')
            // } else {
            //     answerCorrectMobile.push('inCorrect')
            // }
        })
        this.setState({
            answerCorrect: answerCorrectMobile,
        })
        if (answerCorrectMobile.indexOf('?') === -1 && answerCorrectMobile.indexOf('inCorrect') === -1) {
            this.setState({correctMobile: '1'})
        } else {
            this.setState({correctMobile: '0'})
        }
        this.props.showNextBtn()
    }

    closePopup = () => {
        document.body.style.overflow = "";
        this.setState({showPopup: false})
    }

    _renderTestMobile = () => {
        const {showPopup, answersMobile, questionsMobile, answersSelected, correctMobile, answerCorrectMobile} = this.state
        return (
            <div className="test_mobile">
                {correctMobile !== '' ?
                    <div className={'info ' + (correctMobile === '0' && 'error')}>
                        <div className="info_head">
                            {correctMobile === '1' ? 'все верно!' : 'К сожалению, ваш ответ неверен.'}
                        </div>
                        <div className="infoText">
                            Здоровое пищеварение обеспечивают высокоусвояемые ингредиенты и диетическая клетчатка, здоровье кожи и шерсти обеспечивает линолевая кислота и цинк, иммунитет обеспечивают витамин Е и цинк, а здоровые зубы обеспечивают оптимальный уровень кальция, фосфора и пористая текстура сухого корма.
                        </div>
                    </div>
                    :
                    <button className="btn btn_blue" onClick={() => this.checkAnswerMobile()}>
                        Узнать правильный ответ
                    </button>
                }
                <div className="answers">
                    <div className="answers_box">
                        <div className="property">
                            Здоровое пищеварение
                            <img src={img1} alt="" className="property_img"/>
                            <span className="property_arrow">
                                    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.29289 18.7071C7.68342 19.0976 8.31658 19.0976 8.70711 18.7071L15.0711 12.3431C15.4616 11.9526 15.4616 11.3195 15.0711 10.9289C14.6805 10.5384 14.0474 10.5384 13.6569 10.9289L8 16.5858L2.34315 10.9289C1.95262 10.5384 1.31946 10.5384 0.928932 10.9289C0.538407 11.3195 0.538407 11.9526 0.928932 12.3431L7.29289 18.7071ZM7 -4.3711e-08L7 18L9 18L9 4.3711e-08L7 -4.3711e-08Z" fill="#005EAD"/>
                                    </svg>
                                </span>
                            <span className="property_plus">+</span>
                        </div>
                        <div
                            className={'answers_item ' + (answersMobile.indexOf(1) >=0 ? 'selected ' : '') + answerCorrectMobile[0]}
                            onClick={() => {this.selectAnswer(1)}}
                        >
                            {answersSelected[0]}
                        </div>
                        <div
                            className={'answers_item ' + (answersMobile.indexOf(2) >=0 ? 'selected ' : '') + answerCorrectMobile[1]}
                            onClick={() => {this.selectAnswer(2)}}
                        >
                            {answersSelected[1]}
                        </div>
                    </div>
                    <div className="answers_box">
                        <div className="property">
                            Здоровье кожи и шерсти
                            <img src={img2} alt="" className="property_img"/>
                            <span className="property_arrow">
                                    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.29289 18.7071C7.68342 19.0976 8.31658 19.0976 8.70711 18.7071L15.0711 12.3431C15.4616 11.9526 15.4616 11.3195 15.0711 10.9289C14.6805 10.5384 14.0474 10.5384 13.6569 10.9289L8 16.5858L2.34315 10.9289C1.95262 10.5384 1.31946 10.5384 0.928932 10.9289C0.538407 11.3195 0.538407 11.9526 0.928932 12.3431L7.29289 18.7071ZM7 -4.3711e-08L7 18L9 18L9 4.3711e-08L7 -4.3711e-08Z" fill="#005EAD"/>
                                    </svg>
                                </span>
                            <span className="property_plus">+</span>
                        </div>
                        <div
                            className={'answers_item ' + (answersMobile.indexOf(3) >=0 ? 'selected ' : '') + answerCorrectMobile[2]}
                            onClick={() => {this.selectAnswer(3)}}
                        >
                            {answersSelected[2]}
                        </div>
                        <div
                            className={'answers_item ' + (answersMobile.indexOf(4) >=0 ? 'selected ' : '') + answerCorrectMobile[3]}
                            onClick={() => {this.selectAnswer(4)}}
                        >
                            {answersSelected[3]}
                        </div>
                    </div>
                    <div className="answers_box">
                        <div className="property">
                            Иммунитет
                            <img src={img3} alt="" className="property_img"/>
                            <span className="property_arrow">
                                    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.29289 18.7071C7.68342 19.0976 8.31658 19.0976 8.70711 18.7071L15.0711 12.3431C15.4616 11.9526 15.4616 11.3195 15.0711 10.9289C14.6805 10.5384 14.0474 10.5384 13.6569 10.9289L8 16.5858L2.34315 10.9289C1.95262 10.5384 1.31946 10.5384 0.928932 10.9289C0.538407 11.3195 0.538407 11.9526 0.928932 12.3431L7.29289 18.7071ZM7 -4.3711e-08L7 18L9 18L9 4.3711e-08L7 -4.3711e-08Z" fill="#005EAD"/>
                                    </svg>
                                </span>
                            <span className="property_plus">+</span>
                        </div>
                        <div
                            className={'answers_item ' + (answersMobile.indexOf(5) >=0 ? 'selected ' : '') + answerCorrectMobile[4]}
                            onClick={() => {this.selectAnswer(5)}}
                        >
                            {answersSelected[4]}
                        </div>
                        <div
                            className={'answers_item ' + (answersMobile.indexOf(6) >=0 ? 'selected ' : '') + answerCorrectMobile[5]}
                            onClick={() => {this.selectAnswer(6)}}
                        >
                            {answersSelected[5]}
                        </div>
                    </div>
                    <div className="answers_box">
                        <div className="property">
                            Здоровые зубы
                            <img src={img4} alt="" className="property_img"/>
                            <span className="property_arrow">
                                    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.29289 18.7071C7.68342 19.0976 8.31658 19.0976 8.70711 18.7071L15.0711 12.3431C15.4616 11.9526 15.4616 11.3195 15.0711 10.9289C14.6805 10.5384 14.0474 10.5384 13.6569 10.9289L8 16.5858L2.34315 10.9289C1.95262 10.5384 1.31946 10.5384 0.928932 10.9289C0.538407 11.3195 0.538407 11.9526 0.928932 12.3431L7.29289 18.7071ZM7 -4.3711e-08L7 18L9 18L9 4.3711e-08L7 -4.3711e-08Z" fill="#005EAD"/>
                                    </svg>
                                </span>
                            <span className="property_plus">+</span>
                        </div>
                        <div
                            className={'answers_item ' + (answersMobile.indexOf(7) >=0 ? 'selected ' : '') + answerCorrectMobile[6]}
                            onClick={() => {this.selectAnswer(7)}}
                        >
                            {answersSelected[6]}
                        </div>
                        <div
                            className={'answers_item ' + (answersMobile.indexOf(8) >=0 ? 'selected ' : '') + answerCorrectMobile[7]}
                            onClick={() => {this.selectAnswer(8)}}
                        >
                            {answersSelected[7]}
                        </div>
                    </div>
                    {/*{this.questions.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className={'answers_item ' + (answersMobile.indexOf(index + 1) >=0 ? 'selected ' : '') + answerCorrectMobile[index]}
                                onClick={() => {this.selectAnswer(index + 1)}}
                            >
                                {answersSelected[index]}
                            </div>
                        )
                    })

                    }*/}
                </div>
                {showPopup &&
                <div className="popup">
                    <div className="popup_wrp">
                        <div
                            className="popup_closed"
                            onClick={() => {this.closePopup()}}
                        />
                        <div className="popup_flex">
                            <div className="questions">
                                {this.questions.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={'questions_item ' + (questionsMobile.indexOf(index + 1) >=0 ? 'selected ' : '')}
                                            onClick={() => {this.selectQuestion(index + 1)}}
                                        >
                                            {item}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }

    render() {
        const {windowWidth} = this.state
        return (
            <div className="step9">
                <TextBlock
                    text={`Предлагаем закрепить материал и вспомнить, из каких компонентов состоит формула каждой из функциональных потребностей животного.`}
                />
                <InfoBlock
                    text={`Перетащите слагаемые элементы к каждой из потребностей, затем нажмите кнопку «Узнать правильный ответ», чтобы проверить результаты.`}
                />
                {windowWidth >= 768 ?
                    <div className="test">
                        {this._renderTestDesktop()}
                    </div>
                    :
                    <div className="test">
                        {this._renderTestMobile()}
                    </div>
                }
            </div>
        )
    }
}
