import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';

export default class step1 extends React.Component {

    planData = [
        `Концепция натурального питания`,
        `Состав рационов NATURE’S TABLE™`,
        `Ассортимент рационов NATURE’S TABLE™ для кошек`,
        `Ассортимент рационов NATURE’S TABLE™ для собак`,
    ]

    render() {
        return (
            <div className="step1">
                <div className="head">План урока</div>

                <TextBlock
                    text={`В этом уроке рассматриваются следующие вопросы:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'brown'}
                />

            </div>
        )
    }
}
