import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import StatisticsBlock from "../../../Components/LessonAndTestComponents/StatisticsBlock";

import img1 from '../../../image/lessons/lesson15/step19-1.png'
import img1xs from '../../../image/lessons/lesson15/step19-1xs.png'
import img2 from "../../../image/lessons/lesson15/step19-2.png"


export default class step6 extends React.Component {


    render() {
        return (
            <div className="step19">
                <TextBlock
                    text={`<b>54%</b> калорий, потребляемых популяцией кошек, приходятся на корма промышленного производства.

                    <b>13%</b> калорий, потребляемых популяцией собак, приходятся на корма промышленного производства.`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} alt="" className="img imgCenter"/>
                </picture>
                <StatisticsBlock
                    img={img2}
                    text={'Получается, что большинство питомцев России не накормлены кормами промышленного производства!'}
                />
            </div>
        )
    }
}
