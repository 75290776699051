import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';

export default class step1 extends React.Component {

    planData = [
        `Sheba<sup>®</sup> сопротивление бесполезно!`,
        `Чего хотят кошки и почему Sheba<sup>®</sup> – это именно то, что им нужно`,
        `Ключевые особенности обновленных блюд Sheba<sup>®</sup>`,
        `Разнообразие блюд Sheba<sup>®</sup>`,
    ]

    render() {
        return (
            <div className="step1">
                <div className="head">План урока</div>

                <TextBlock
                    text={`В этом уроке рассматриваются следующие вопросы:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'brown'}
                />

            </div>
        )
    }
}
