import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

import img1 from "../../../image/lessons/lesson7/step9-1.png";

export default class step9 extends React.Component {

    render() {
        return (
            <div className="step9">
                <InfoBlock
                    text={`Рассмотрите схему пищеварительного тракта собаки. Цифры на схеме соответствуют номеру факта.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <ol className="list-numeral">
                            <li className="list-numeral_item"><span className="list-numeral_text">Обоняние собак в 10 000 раз чувствительнее, чем у человека.</span></li>
                            <li className="list-numeral_item"><span className="list-numeral_text">У собак 42 зуба, предназначенные для разгрызания, разрывания и перемалывания пищи. Эмаль зубов собаки в 5 раз тоньше, чем у зубов человека.</span></li>
                            <li className="list-numeral_item"><span className="list-numeral_text">Меньше вкусовых рецепторов, чем у человека.</span></li>
                            <li className="list-numeral_item"><span className="list-numeral_text">Выработка слюнной амилазы ограничена.</span></li>
                            <li className="list-numeral_item"><span className="list-numeral_text">Очень растяжимый желудок, способный переварить большое количество пищи.</span></li>
                            <li className="list-numeral_item"><span className="list-numeral_text">Желудочный сок более кислый, чем у человека. Это способствует перевариванию костей и уничтожению патогенных бактерий.</span></li>
                            <li className="list-numeral_item"><span className="list-numeral_text">Время кишечного транзита — 12-30 часов (намного быстрее, чем у человека).</span></li>
                            <li className="list-numeral_item"><span className="list-numeral_text">Бактериальная ферментация происходит в толстом кишечнике.</span></li>
                        </ol>
                    </div>
                    <div className="flex_el">
                        <img src={img1} alt="" className="img"/>
                    </div>
                </div>
            </div>
        )
    }
}
