import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";
import DefinitionBlock from "../../../Components/LessonAndTestComponents/DefinitionBlock";

import img1 from "../../../image/lessons/lesson2/step6-1.png";
import img2 from "../../../image/lessons/lesson2/step6-2.png";
import img3 from "../../../image/lessons/lesson2/step6-3.png";
import img4 from "../../../image/lessons/lesson2/step6-4.png";



export default class step6 extends React.Component {

    render() {
        return (
            <div className="step6">
                <TextBlock
                    text={`Энергия вырабатывается в процессе пищеварения, когда пища расщепляется на мелкие элементы.`}
                />
                <div className="head">
                    4 этапа пищеварительного процесса
                </div>
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_border">
                            <img className="flex_el_img" src={img1} alt=""/>
                        </div>
                        <p className="flex_el_text">Пища попадает в пищевод</p>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_border">
                            <img className="flex_el_img" src={img2} alt=""/>
                        </div>
                        <p className="flex_el_text">Проходит по пищеварительному тракту</p>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_border">
                            <img className="flex_el_img" src={img3} alt=""/>
                        </div>
                        <p className="flex_el_text">Расщепляется на простые элементы</p>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_border">
                            <img className="flex_el_img" src={img4} alt=""/>
                        </div>
                        <p className="flex_el_text">Элементы усваиваются организмом</p>
                    </div>
                </div>
                <DefinitionBlock
                    text={`В диетологии энергию обычно выражают в <span class="text_blue">килокалориях</span> (ккал). 1 ккал это количество тепла, необходимое для нагрева 1 кг воды на 1 градус Цельсия. 1 килокалория = 1000 калорий.`}
                />
                <TextBlock
                    text={`Вода не является источником энергии. Чем больше содержание воды в составе корма, тем ниже концентрация энергии. Тем не менее, вода необходима для жизнедеятельности организма собак и кошек, так как является средой, в которой протекают все процессы обмена веществ.`}
                />
            </div>
        )
    }
}
