import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import Popup from "../../../Components/Popup";

import img1 from '../../../image/lessons/lesson20/step5-1.png'


export default class step5 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        pointActive: 0,
        points: [0],
        showPopup: false,
    };

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }


    clickPoint = pointActive => {
        const {points, windowWidth, showPopup} = this.state
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }
        if (windowWidth < 767) {

            if (showPopup) {
                this.setState({
                    pointActive: 0,
                    showPopup: false,
                })
            } else {
                this.setState({
                    pointActive: pointActive,
                    showPopup: true,
                    points: points
                })
            }

        } else {
            this.setState({pointActive, points})
        }

        this.props.showNextBtn();
    }

    closePopup = () => {
        this.setState({pointActive: 0, showPopup: false})
    }

    render() {
        const {showPopup, pointActive} = this.state;
        return (
            <div className="step5">
                <InfoBlock
                    text={`Нажмите на этикетку «100% высококачественные ингредиенты» и узнайте, какие ингредиенты FEDIAF считает натуральными.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <TextBlock
                            text={`В основе влажных рационов WHISKAS<sup>®</sup> - <b>100% натуральные ингредиенты*</b> (мясо и субпродукты, а также злаки), которые соответствуют критериям натуральности <b>FEDIAF</b> (Европейская федерация производителей кормов для домашних животных).<br>* Натуральные ингредиенты: мясо и субпродукты`}
                        />
                    </div>
                    <div className="flex_el">
                        <div className={'flex_el_block ' + (pointActive === 1 && 'active')}>
                            <div className="flex_el_block_closed" onClick={this.closePopup}/>
                            <div className="flex_el_block_head">
                                FEDIAF считает ингредиент натуральным, если он одновременно соответствует трём критериям:
                            </div>
                            <ul className="list">
                                <li className="list_item">Имеет природное происхождение (получен из растений, животных, микроорганизмов и минералов);</li>
                                <li className="list_item">К нему ничего не было добавлено в процессе хранения, т.е. в его природный состав ничего не было привнесено;</li>
                                <li className="list_item">В процессе производства он был подвергнут только физическим методам обработки (например, помол, высушивание, температурная обработка).</li>
                            </ul>
                            <div className="flex_el_block_head bottom">
                                Натуральные ингредиенты Whiskas<sup>®</sup> соотвествуют всем требованиям FEDIAF
                            </div>
                        </div>
                        <div className="flex_el_img-button" onClick={() => {this.clickPoint(1)}}/>
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                </div>
                {showPopup &&
                <Popup
                    closePopup={this.closePopup}
                >
                    <div className="Popup_head">
                        FEDIAF считает ингредиент натуральным, если он одновременно соответствует трём критериям:
                    </div>
                    <ul className="list">
                        <li className="list_item">Упругая кожа</li>
                        <li className="list_item">Насыщенный выраженный цвет шерсти</li>
                        <li className="list_item">Блеск шерсти</li>
                        <li className="list_item">Отсутствие перхоти</li>
                    </ul>
                    <div className="Popup_head bottom">
                        Натуральные ингридиенты Whiskas<sup>®</sup> соотвествуют всем требованиям FEDIAF
                    </div>
                    <button
                        className="btn btn_blue"
                        onClick={() => {this.clickPoint(0)}}
                    >
                        продолжить
                    </button>
                </Popup>
                }
            </div>
        )
    }
}
