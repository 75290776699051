import React from 'react'
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";
import Popup from "../../../Components/Popup";

import img1 from "../../../image/lessons/lesson19/step9-1.png";
import img2 from "../../../image/lessons/lesson19/step9-2.png";
import img3 from "../../../image/lessons/lesson19/step9-3.png";
import img4 from "../../../image/lessons/lesson19/step9-4.png";
import img_popup1_1 from "../../../image/lessons/lesson19/step9-1-1.png";
import img_popup1_2 from "../../../image/lessons/lesson19/step9-1-2.png";
import img_popup1_3 from "../../../image/lessons/lesson19/step9-1-3.png";
import img_popup2_1 from "../../../image/lessons/lesson19/step9-2-1.png";
import img_popup3_1 from "../../../image/lessons/lesson19/step9-3-1.png";
import img_popup3_2 from "../../../image/lessons/lesson19/step9-3-2.png";
import img_popup3_3 from "../../../image/lessons/lesson19/step9-3-3.png";
import img_popup4_1 from "../../../image/lessons/lesson19/step9-4-1.png";
import img_popup4_2 from "../../../image/lessons/lesson19/step9-4-2.png";

export default class step9 extends React.Component {

    state = {
        popupActive: 0,
        showPopup: false,
        points: [],
    }

    showPopup = (popupActive) => {
        const {points} = this.state;
        if (points.indexOf(popupActive) === -1) {
            points.push(popupActive)
        }

        this.setState({
            popupActive: popupActive,
            showPopup: true,
            points: points
        })

        if (points.length === 4) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    popupData = {
        1: {
            img: img1,
            head: 'Для щенков мелких и миниатюрных пород до 1 года',
            properties1: 'Здоровый рост',
            properties2: 'Здоровое развитие мозга',
            properties3: 'Уменьшенный размер крокеты',
            icon1: img_popup1_1,
            icon2: img_popup1_2,
            icon3: img_popup1_3,
            text1: 'Содержит оптимальное соотношение кальция и фосфора для поддержания здорового роста щенка',
            text2: 'Способствует поддержанию правильного развития головного мозга щенка благодаря содержанию ДГК (DHA) из натуральных источников (ДГК содержится в молоке матери)',
            text3: 'Специально разработанный для щенков мелких и миниатюрных пород',
        },
        2: {
            img: img2,
            head: 'Для взрослых собак мелких и миниатюрных пород старше 1 года',
            properties1: 'Здоровье мочевыделительной системы',
            properties2: 'Жизненная энергия',
            properties3: 'Уменьшенный размер крокеты',
            icon1: img_popup3_1,
            icon2: img_popup3_2,
            icon3: img_popup3_3,
            text1: 'Специальная формула для поддержания здоровья мочевыделительной системы.',
            text2: 'Cодержит повышенные уровни витаминов В1, В2, В6 и железа, способствующие поддержанию активности собаки.',
            text3: 'Уменьшенный размер крокеты, разработанный специально для собак мелких и миниатюрных пород.',
        },
        3: {
            img: img3,
            head: 'Для щенков средних и крупных пород до 1 года',
            properties1: 'Здоровый рост',
            properties2: 'Здоровое развитие мозга',
            properties3: 'Развитие здоровой мускулатуры',
            icon1: img_popup1_1,
            icon2: img_popup1_2,
            icon3: img_popup2_1,
            text1: 'Содержит оптимальное соотношение кальция и фосфора для поддержания здорового роста щенка',
            text2: 'Способствует поддержанию правильного развития головного мозга щенка, благодаря содержанию ДГК (DHA) из натуральных источников (ДГК содержится в молоке матери)',
            text3: 'Оптимальный уровень белка высокого качества для поддержания развития сильной и здоровой мускулатуры',
        },
        4: {
            img: img4,
            head: 'Для взрослых собак средних и крупных пород старше 1 года',
            properties1: 'Здоровая мускулатура',
            properties2: 'Подвижность суставов',
            properties3: 'Жизненная энергия',
            icon1: img_popup4_1,
            icon2: img_popup4_2,
            icon3: img_popup3_2,
            text1: 'Cодержит белки высокого качества для поддержания сильной и здоровой мускулатуры.',
            text2: 'Cодержит натуральный источник глюкозамина для поддержания здоровья суставов, что способствует хорошей подвижности собаки.',
            text3: 'Cодержит повышенные уровни витаминов В1, В2, В6 и железа, способствующие поддержанию активности собаки.',
        },
    }

    render() {
        const {showPopup, popupActive} = this.state
        return (
            <div className="step9">
                <InfoBlock
                    text={`Кроме общей формулы «5 слагаемых здоровья» каждый рацион PERFECT FIT™ для собак обладает индивидуальными особенностями. Выберете рацион и познакомьтесь с ними.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div
                            className="flex_el_content"
                            onClick={() => {this.showPopup(1)}}
                        >
                            <p className="flex_el_text">
                                Для щенков мелких и миниатюрных пород до 1 года
                            </p>
                            <img src={img1} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div
                            className="flex_el_content pink"
                            onClick={() => {this.showPopup(2)}}
                        >
                            <p className="flex_el_text">
                                Для взрослых собак мелких и миниатюрных пород старше 1 года
                            </p>
                            <img src={img2} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div
                            className="flex_el_content"
                            onClick={() => {this.showPopup(3)}}
                        >
                            <p className="flex_el_text">
                                Для щенков средних и крупных пород до 1 года
                            </p>
                            <img src={img3} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div
                            className="flex_el_content pink"
                            onClick={() => {this.showPopup(4)}}
                        >
                            <p className="flex_el_text">
                                Для взрослых собак средних и крупных пород старше 1 года
                            </p>
                            <img src={img4} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                </div>
                {showPopup &&
                    <Popup
                        closePopup={this.closePopup}
                    >
                        <div className="info">
                            <img src={this.popupData[popupActive].img} alt="" className="info_img"/>
                            <div className="info_el">
                                {/*<img src={this.popupData[popupActive].img} alt="" className="info_img tablet"/>*/}
                                <div className={'info_head ' + (popupActive === 2 || popupActive === 4 ? 'pink' : '')}>
                                    {this.popupData[popupActive].head}
                                </div>
                                <div className="info_list">
                                    <div className="info_item">
                                        <p className="info_properties">
                                            {this.popupData[popupActive].properties1}
                                        </p>
                                        <div className="info_icon-box">
                                            <img src={this.popupData[popupActive].icon1} alt="" className="info_icon"/>
                                        </div>
                                    </div>
                                    <div className="info_item">
                                        <p className="info_properties">
                                            {this.popupData[popupActive].properties2}
                                        </p>
                                        <div className="info_icon-box">
                                            <img src={this.popupData[popupActive].icon2} alt="" className="info_icon"/>
                                        </div>
                                    </div>
                                    <div className="info_item">
                                        <p className="info_properties">
                                            {this.popupData[popupActive].properties3}
                                        </p>
                                        <div className="info_icon-box">
                                            <img src={this.popupData[popupActive].icon3} alt="" className="info_icon"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'info_el ' + (popupActive === 2 || popupActive === 4 ? 'pink' : '')}>
                                <div className="info_text top">
                                    В дополнение к формуле <b>PERFECT FIT™ «TOTAL 5»</b> данный рацион обеспечивает следующие преимущества для {popupActive < 3 ? 'собак мелких и миниатюрных пород' : 'собаки'}:
                                </div>
                                <div className="info_subhead">
                                    {this.popupData[popupActive].properties1}
                                </div>
                                <div className="info_text">
                                    {this.popupData[popupActive].text1}
                                </div>
                                <div className="info_subhead">
                                    {this.popupData[popupActive].properties2}
                                </div>
                                <div className="info_text">
                                    {this.popupData[popupActive].text2}
                                </div>
                                <div className="info_subhead">
                                    {this.popupData[popupActive].properties3}
                                </div>
                                <div className="info_text">
                                    {this.popupData[popupActive].text3}
                                </div>
                            </div>
                        </div>
                    </Popup>
                }
            </div>
        )
    }
}
