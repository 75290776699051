import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson24/step16-1.png";
import img2 from "../../../image/lessons/lesson24/step16-2.png";
export default class step16 extends React.Component {

    render() {
        return (
            <div className="step16">
                <div className="head">
                    Sheba<sup>®</sup> Creamy
                </div>
                <TextBlock
                    text={`Угостите Вашу любимицу лакомством SHEBA<sup>®</sup> Creamy из собственных рук или подайте в мисочке как отдельное блюдо. Пленительный вкус SHEBA<sup>®</sup> Creamy с говядиной напомнит кошке о том, как сильно Вы ее любите.
                    
                    <span class="text_sheba">Ключевые особенности:</span>`}
                />
                <div className="advantages">
                    <div className="advantages_el">
                        Нежный крем из кусочков мяса
                    </div>
                    <div className="advantages_plus">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.22939 13.425V8.925H0.704395V5.475H5.22939V0.975H8.7794V5.475H13.3044V8.925H8.7794V13.425H5.22939Z" fill="#AC8748"/>
                        </svg>
                    </div>
                    <div className="advantages_el">
                        В удобной упаковке
                    </div>
                </div>
                <div className="flex">
                    <img src={img1} alt="" className="flex_img"/>
                    <img src={img2} alt="" className="flex_img"/>
                </div>
            </div>
        )
    }
}
