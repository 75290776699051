import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickTest from "../../../Components/LessonAndTestComponents/OnClickTest";
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";

export default class step5 extends React.Component {

    state = {
        answersData:
            [
                `Минерального происхождения, например, известь`,
                `Растительные – котовник кошачий, валериана, аспарагус, мята, гвоздика, мимоза`,
                `Цитрусовые, например, апельсины, лайм`,
                `Запах воды`,
                `Сладкие ароматы – ваниль, корица, яблоки`,
                `Животного происхождения – феромоны, мясо, печень и другие субпродукты`,
            ]
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step5">
                <TextBlock
                    text={`Обоняние является одним из самых древних и примитивных чувств. Интересно, что оно тесно связано с центром управления эмоциями. И этим можно объяснить мощное, часто подсознательное влияние запахов на состояние и поведение кошек.`}
                />
                <InfoBlock
                    text={`Выберите наиболее приятные для кошек запахи.`}
                />
                <OnClickTest
                    ansewrsData={this.state.answersData}
                    type={'block'}
                    rightAnswers={[1, 2, 6]}
                    showNextBtn={this.showNextBtn}
                    quantity={33.25}
                    correctText={`Наиболее привлекательны для кошек запахи минерального, растительного и животного происхождения`}
                />
            </div>
        )
    }
}
