import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson8/step12-1.png";
import img2 from "../../../image/lessons/lesson8/step12-2.png";
import img3 from "../../../image/lessons/lesson8/step12-3.png";
import img4 from "../../../image/lessons/lesson8/step12-4.png";

export default class step12 extends React.Component {

    state = {
        flexData: ['для занятия на время', 'функциональный уход', 'проявление заботы'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: true})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData, flexHidden} = this.state
        return (
            <div className="step12">
                <TextBlock
                    text={`Хозяева угощают своих питомцев по разным причинам.`}
                />
                <InfoBlock
                    text={`Нажмите на кнопки, чтобы узнать подробнее о причинах угощений.`}
                />
                <div className="OnClickBlock">
                    <div className="flex false">
                        <div className="flex_el" style={{width: '30%'}}>
                            <div className="flex_el_wrp false">для занятия на время</div>
                        </div>
                        <div className="flex_el" style={{width: '30%'}}>
                            <div className="flex_el_wrp false">функциональный уход</div>
                        </div>
                        <div className="flex_el" style={{width: '30%'}}>
                            <div className="flex_el_wrp false">проявление заботы</div>
                        </div>
                    </div>
                </div>
{/*                <OnClickBlock*/}
{/*                    flexData={flexData}*/}
{/*                    hideElement={this.hideElement}*/}
{/*                    showNextBtn={this.showNextBtn}*/}
{/*                >*/}
{/*                    <div className="imgDiv_wrap">*/}
{/*                        <TextBlock*/}
{/*                            text={`С этой целью обычно используются твердые лакомства, для разгрызания которых нужно длительное время. Питомцу интересно играть с таким лакомством, и он может провести с ним большое количество времени.`}*/}
{/*                        />*/}
{/*                        <div className="imgDiv_wrap_flex">*/}
{/*                            <TextBlock*/}
{/*                                text={`<span class="text_error">Ошибка:</span> часто люди дают питомцам кости или хрящи. Это небезопасно и вредно для здоровья животного!*/}
{/*                                */}
{/*                                <span class="text_correct">Правильное решение:</span> специально изготовленные лакомства, которые не только доставляют питомцу удовольствие, но и безопасны для здоровья. Например, изделия в виде косточек для собак PEDIGREE<sup>®</sup> Jumbone™ с твердой оболочкой и мягкой начинкой.`}*/}
{/*                            />*/}
{/*                            <div className="imgBox">*/}
{/*                                <img className="imgDiv_wrap_img" src={img2} alt=""/>*/}
{/*                            </div>*/}
{/*                        </div>*/}
{/*                    </div>*/}
{/*                    <div className="imgDiv_wrap">*/}
{/*                        <TextBlock*/}
{/*                            text={`Некоторые лакомства являются функциональными, т.е. оказывают положительное влияние на здоровье животных — например, на гигиену зубов или укрепление суставов.`}*/}
{/*                        />*/}
{/*                        <div className="imgDiv_wrap_flex">*/}
{/*                            <TextBlock*/}
{/*                                text={`<span class="text_error">Ошибка:</span> некоторые хозяева считают, что деревянные палки полезно грызть собакам, чтобы поддерживать гигиену зубов. Это не так! Палки небезопасны для собак: они могут поранить язык или десна, и при этом не способствуют очищению зубов от налета.*/}
{/*                                */}
{/*                                <span class="text_correct">Правильное решение:</span> следует кормить специализированными лакомствами. Например, палочки для ухода за зубами DENTASTIX™ от PEDIGREE<sup>®</sup> являются не только вкусным, но и полезным лакомством. Благодаря X-образной форме и особой текстуре они помогают эффективно очищать зубы и массировать десны собаки.   */}
{/*Кроме того, DENTASTIX™ от PEDIGREE<sup>®</sup> содержит активные компоненты, препятствующие отложению зубного налета и замедляющие формирование зубного камня.`}*/}
{/*                            />*/}
{/*                            <div className="imgBox">*/}
{/*                                <img className="imgDiv_wrap_img" src={img3} alt=""/>*/}
{/*                            </div>*/}
{/*                        </div>*/}
{/*                    </div>*/}
{/*                    <div className="imgDiv_wrap">*/}
{/*                        <TextBlock*/}
{/*                            text={`Хозяева любят баловать своих питомцев и давать им лакомства в дополнение к основному рациону. Так они проявляют дополнительную заботу и становятся ближе со своим любимцем.`}*/}
{/*                        />*/}
{/*                        <div className="imgDiv_wrap_flex">*/}
{/*                            <TextBlock*/}
{/*                                text={`<span class="text_error">Ошибка:</span> люди используют еду со стола в качестве угощения. Так делать нельзя! Человеческая еда слишком калорийна для животных. Более того, она может оказаться токсичной и вызвать проблемы с пищеварением.*/}
{/*                                */}
{/*                                <span class="text_correct">Правильное решение:</span> покупать готовые лакомства, разработанные с учетом потребностей животных. Например, кошек можно угощать подушечками DREAMIES™, а собак — лакомствами Rodeo™ от PEDIGREE<sup>®</sup>.`}*/}
{/*                            />*/}
{/*                            <div className="imgBox">*/}
{/*                                <img className="imgDiv_wrap_img" src={img4} alt=""/>*/}
{/*                            </div>*/}
{/*                        </div>*/}
{/*                    </div>*/}
{/*                </OnClickBlock>*/}
                <img src={img1} alt="" className={'imgCenter ' + (flexHidden && 'hidden')}/>
            </div>
        )
    }
}
