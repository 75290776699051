import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson7/step13-1.png";
import img2 from "../../../image/lessons/lesson7/step13-2.png";

export default class step13 extends React.Component {

    render() {
        return (
            <div className="step13">
                <TextBlock
                    text={`На основе исследований WALTHAM™ Research были сделаны следующие выводы о пищевых предпочтениях кошек и собак.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Для кошек
                        </div>
                        <div className="flex_el_box">
                            <TextBlock
                                text={`Кошки очень привередливы к пище, они предпочитают <b>еду комнатной температуры или соответствующую температуре тела</b>. Это объясняется генетическими привычками: в дикой природе кошки всегда едят только что пойманную жертву.`}
                            />
                            <img src={img1} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Для собак
                        </div>
                        <div className="flex_el_box">
                            <TextBlock
                                text={`Собаки менее прихотливы к еде. <b>Температура корма не влияет значительно на вкусовые предпочтения собак</b> в силу их особенностей. Поскольку в дикой природе волки и собаки часто охотятся стаями, иногда питаются падалью, а также склонны запасать пищу, то они привыкли есть как теплую, так и холодную пищу.`}
                            />
                            <img src={img2} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
