import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson5/step13-1.png";
import img2 from "../../../image/lessons/lesson5/step13-2.png";

export default class step13 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит и избыток'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step13">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Витамин В6 (пиридоксин) — важен для здоровья.</b>
                           
                            Пиридоксин был обнаружен в середине XX столетия, но многие его функции в организме все еще изучаются.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`В качестве кофермента пиридоксин играет огромную роль в различных метаболических процессах:`}
                        />
                        <ul className="list">
                            <li className="list_item">синтез гемоглобина;</li>
                            <li className="list_item">использование гликогена;</li>
                            <li className="list_item">метаболизм жирных кислот;</li>
                            <li className="list_item">синтез и разрушение аминокислот.</li>
                        </ul>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`В основном, пиридоксин содержится в:`}
                        />
                        <div className="imgDiv_wrap_flex">
                            <ul className="list">
                                <li className="list_item">дрожжах;</li>
                                <li className="list_item">ростках пшеницы;</li>
                                <li className="list_item">мясе.</li>
                            </ul>
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img2} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Дефицит витамина В6 может вызвать:`}
                        />
                        <ul className="list">
                            <li className="list_item">нарушения нервной системы;</li>
                            <li className="list_item">нарушения кровеносной системы;</li>
                            <li className="list_item">повреждения кожи.</li>
                        </ul>
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
