import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson5/step17-1.png";

export default class step17 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит и избыток'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step17">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Холин — важен для работы печени.</b>
                           
                            Холин не является витамином в истинном смысле этого слова, поскольку в небольшом количестве он может синтезироваться в печени животных.
                            
                            Однако этого количества недостаточно для удовлетворения ежедневной потребности организма, поэтому необходимо его поступление с пищей.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Холин выполняет важные функции:`}
                        />
                        <ul className="list">
                            <li className="list_item">является компонентом фосфолипидов;</li>
                            <li className="list_item">защищает кожу от обезвоживания;</li>
                            <li className="list_item">в сочетании с фосфором становится лецитином и формирует часть клеточных мембран и липопротеинов крови;</li>
                            <li className="list_item">является компонентом ацетилхолина, участвующего в передаче нервных импульсов.</li>
                        </ul>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Большое количество холина содержат:`}
                        />
                        <ul className="list">
                            <li className="list_item">мясо;</li>
                            <li className="list_item">печень;</li>
                            <li className="list_item">сердце;</li>
                            <li className="list_item">яйца;</li>
                            <li className="list_item">соя.</li>
                        </ul>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Дефицит холина вызывает патологическое накопление жирных кислот в печени.`}
                        />
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
