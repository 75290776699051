import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson3/step4-1.png";

export default class step4 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData, flexHidden} = this.state
        return (
            <div className="step4">
                <InfoBlock
                    text={`Для продолжения, пожалуйста, ознакомьтесь со всей представленной информацией в этом шаге урока.`}
                    hidden={flexHidden === true ? ' hidden' : ''}
                />
               <OnClickBlock
                   flexData={flexData}
                   hideElement={this.hideElement}
                   showNextBtn={this.showNextBtn}
               >
                   <div className="imgDiv_wrap">
                       <TextBlock
                           text={`<b>Аминокислоты — это строительные элементы белков и их производных.</b>
                           
                            Белки состоят в общей сложности из 20 разных аминокислот, из них <b>10 являются незаменимыми</b> для кошек и собак. Это означает, что они не вырабатываются в организме и должны поступать с пищей. Кроме этого, для кошки незаменимым является также таурин — особая серосодержащая аминокислота, которая не входит в перечень 20 основных аминокислот.
                            
                            Также две аминокислоты являются условно заменимыми - тирозин и цистеин. При дефиците их поступления с пищей они могут синтезироваться в организме из других аминокислот.`}
                       />
                   </div>
                   <div className="imgDiv_wrap">
                       <TextBlock
                           text={`Аминокислоты необходимы для нормального функционирования организма.
                            
                            При недостаточности незаменимых аминокислот у щенков и котят ухудшается скорость роста и общее состояние здоровья, а у взрослых животных могут нарушиться такие процессы, как выведение азотсодержащих отходов и синтез гемоглобина.
                            
                            Более того, белки — это основной структурный компонент шерсти, поэтому они очень важны для здоровья кожи и шерсти.`}
                       />
                   </div>
                   <div className="imgDiv_wrap">
                       <TextBlock
                           text={`Все пищевые белки являются источником определенного набора аминокислот.
                            
                            Белки высокой биологической ценности характеризуются хорошей перевариваемостью и высоким уровнем содержания незаменимых аминокислот.
                            
                            К таким белкам относятся:`}
                       />
                       <div className="imgDiv_wrap_flex">
                           <ul className="list">
                               <li className="list_item">яичный белок;</li>
                               <li className="list_item">мясо (включая субпродукты);</li>
                               <li className="list_item">курица;</li>
                               <li className="list_item">рыба;</li>
                               <li className="list_item">глютен злаковых культур.</li>
                           </ul>
                           <div className="imgBox">
                               <img className="imgDiv_wrap_img" src={img1} alt=""/>
                           </div>
                       </div>
                   </div>
                   <div className="imgDiv_wrap">
                       <TextBlock
                           text={`Отсутствие в рационе любой из незаменимых аминокислот приводит к прекращению синтеза незаменимых белков.

                            В этих условиях начинается разрушение тканей организма животного для обеспечения необходимыми аминокислотами, что приводит к серьезным проблемам со здоровьем.`}
                       />
                   </div>
               </OnClickBlock>
            </div>
        )
    }
}
