import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson32/step48-1.png';


export default class step48 extends React.Component{

    render() {
        return (
            <div className="step48">
                <TextBlock
                    text={`Лакомство по уходу за зубами <span class="text_red">PEDIGREE<sup>®</sup> DENTASTIX™</span> является наиболее удобным и эффективным способом ухода за зубами собаки.`}
                />
                <div className="head">
                    Благодаря тройному действию, PEDIGREE<sup>®</sup> DENTASTIX™:
                </div>
                <ul>
                    <li>Уменьшает образования зубного камня до 80%</li>
                    <li>Очищает труднодоступные зубы</li>
                    <li>Поддерживает здоровье десен</li>
                </ul>
                <div className="img-box">
                    <img className="img" src={img1} alt=""/>
                </div>
            </div>
        )
    }
}
