import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from "../../../image/lessons/lesson20/step6-1.png";

export default class step6 extends React.Component {

    render() {
        return (
            <div className="step6">
               <div className="flex">
                   <div className="flex_el">
                       <img src={img1} alt="" className="flex_el_img"/>
                   </div>
                   <div className="flex_el">
                       <TextBlock
                           text={`Рецептура рационов WHISKAS<sup>®</sup> одобрена экспертами <b>Ассоциации практикующих ветеринарных врачей.</b>`}
                       />
                   </div>
               </div>
            </div>
        )
    }
}
