import React from 'react'
import './style.sass'
import logo from '../../image/PPClub-logo-blue.png'
import LinkPreview1 from '../../image/link-preview-1.jpg'
import LinkPreview2 from '../../image/link-preview-2.png'
import LinkPreview3 from '../../image/link-preview-3.png'
import LinkPreview4 from '../../image/link-preview-4.png'
import VideoBlock from "../../Components/LessonAndTestComponents/VideoBlock";
import LinkPreview from "../../Components/LinkPreview";
import PerfectFit from "../../image/LeftSideBar/PerfectFit.png";

export default class PPCVideo extends React.Component {

    componentDidMount() {
        // this.setState({preloader: false})
    }

    render() {
        return (
            <div className="PPCVideo">
                {/*<img className="PPCVideo_logo" src={logo} alt=""/>*/}
                <div className="PPCVideo_text-block">
                    <p className="PPCVideo_text">
                        В данном разделе вы можете ознакомиться с видеолекциями, которые помогут вам еще лучше понять своих питомцев и найти к ним правильный подход.
                    </p>
                </div>
                <h2 className="PPCVideo_title">
                    Список видеолекций
                </h2>
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_wrp">
                            {/*<LinkPreview*/}
                            {/*    link={'#'}*/}
                            {/*    img={LinkPreview3}*/}
                            {/*    alt={'Ссылка на Видео'}*/}
                            {/*/>*/}
                            <VideoBlock
                                src="https://www.youtube.com/embed/eVu2oXyTFjE"
                                title="https://www.youtube.com/watch?v=eVu2oXyTFjE&feature=emb_logo"
                            />
                            <p className="flex_el_text">Вебинар «Благополучие домашних животных в новогодние праздники»</p>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_wrp">
                            {/*<LinkPreview*/}
                            {/*    link={'#'}*/}
                            {/*    img={LinkPreview3}*/}
                            {/*    alt={'Ссылка на Видео'}*/}
                            {/*/>*/}
                            <VideoBlock
                                src="https://www.youtube.com/embed/L32FH6xYAEg"
                                title="https://www.youtube.com/watch?v=L32FH6xYAEg&feature=emb_logo"
                            />
                            <p className="flex_el_text">ВЕБИНАР «ДЕНЬ КОШЕК. КАК СДЕЛАТЬ ИХ ЖИЗНЬ КОМФОРТНОЙ, СЧАСТЛИВОЙ И ЗДОРОВОЙ»</p>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_wrp">
                            {/*<LinkPreview*/}
                            {/*    link={'#'}*/}
                            {/*    img={LinkPreview3}*/}
                            {/*    alt={'Ссылка на Видео'}*/}
                            {/*/>*/}
                            <VideoBlock
                                src="https://www.youtube.com/embed/1fbCO2oEnB4"
                                title="https://www.youtube.com/watch?v=1fbCO2oEnB4&feature=emb_logo"
                            />
                            <p className="flex_el_text">ВЕБИНАР «ДАЧНЫЙ СЕЗОН: ИММУНИТЕТ ДОМАШНИХ ПИТОМЦЕВ И НЕ ТОЛЬКО»</p>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_wrp">
                            {/*<LinkPreview*/}
                            {/*    link={'#'}*/}
                            {/*    img={LinkPreview3}*/}
                            {/*    alt={'Ссылка на Видео'}*/}
                            {/*/>*/}
                            <VideoBlock
                                src="https://www.youtube.com/embed/EJBDffeQni0"
                                title="https://www.youtube.com/watch?v=EJBDffeQni0&feature=emb_logo"
                            />
                            <p className="flex_el_text">ВЕБИНАР «ВСЕМИРНЫЙ ДЕНЬ БЕЗДОМНЫХ ЖИВОТНЫХ»</p>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_wrp">
                            {/*<LinkPreview*/}
                            {/*    link={'#'}*/}
                            {/*    img={LinkPreview3}*/}
                            {/*    alt={'Ссылка на Видео'}*/}
                            {/*/>*/}
                            <VideoBlock
                                src="https://www.youtube.com/embed/WFher-Nte7w"
                                title="https://www.youtube.com/watch?v=WFher-Nte7w&feature=emb_logo"
                            />
                            <p className="flex_el_text">ВЕБИНАР «Всемирный день защиты животных: Аллергия на питомцев»</p>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_wrp">
                            {/*<LinkPreview*/}
                            {/*    link={'#'}*/}
                            {/*    img={LinkPreview3}*/}
                            {/*    alt={'Ссылка на Видео'}*/}
                            {/*/>*/}
                            <VideoBlock
                                src="https://www.youtube.com/embed/ew5TO52Bv2Y"
                                title="https://www.youtube.com/watch?v=ew5TO52Bv2Y&feature=emb_logo"
                            />
                            <p className="flex_el_text">ВЕБИНАР «ВСЁ О PET-FRIENDLY ОФИСАХ»</p>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_wrp">
                            {/*<LinkPreview*/}
                            {/*    link={'#'}*/}
                            {/*    img={LinkPreview3}*/}
                            {/*    alt={'Ссылка на Видео'}*/}
                            {/*/>*/}
                            <VideoBlock
                                src="https://www.youtube.com/embed/cDfknm1bNk4"
                                title="https://www.youtube.com/watch?v=cDfknm1bNk4git&feature=emb_logo"
                            />
                            <p className="flex_el_text">Международный день собак</p>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_wrp">
                            {/*<LinkPreview*/}
                            {/*    link={'#'}*/}
                            {/*    img={LinkPreview3}*/}
                            {/*    alt={'Ссылка на Видео'}*/}
                            {/*/>*/}
                            <VideoBlock
                                src="https://www.youtube.com/embed/H1m0UOiyXBU"
                                title="https://www.youtube.com/watch?v=H1m0UOiyXBU&feature=emb_logo"
                            />
                            <p className="flex_el_text">Вебинар «Как поддерживать здоровье зубов наших любимцев»</p>
                            <a className="flex_el_text_small" href="https://mydrive.effem.com/:v:/g/personal/ksenia_shumitskaya_effem_com/EaAdRh2ZZFVJtAhomds-OE0BJTBsa0NyGcBC64DeUcgF8A?e=cebnoZ">Ссылка на&nbsp;запись на&nbsp;Sharepoint (при&nbsp;проблемах с&nbsp;Youtube)</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
