import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import Answer from './dnd/Answer';
import Questions from './dnd/Questions';

export default class step2 extends React.Component {

    state = {
        // Ресайз
        windowWidth: window.innerWidth,

        // Работа десктопной версии
        answers: ['2', '3', '6', '1', '4', '5'], //Массив правильных ответов
        correct: '',
        answerCorrect: [],
        answersSelectedDesktop: ['?', '?', '?', '?', '?', '?'], // Массив выбираемых значений

        //Работа мобильной версии
        answerActive: 0,
        questionActive: 0,
        answersMobile: [],
        questionsMobile: [],
        answersSelected: ['?', '?', '?', '?', '?', '?', '?', '?', '?'], // Массив выбираемых значений
        correctMobile: '',
        answerCorrectMobile: [],
        showPopup: false
    }


    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    //Работа десктопной версии
    setAnswer = (answer_el, question_el) => {
        const {answersSelectedDesktop} = this.state

        answersSelectedDesktop.splice(answer_el - 1, 1, question_el)
        this.setState({answersSelectedDesktop})
    };

    checkAnswer = () => {
        const {answerCorrect, answersSelectedDesktop, answers} = this.state
        // eslint-disable-next-line
        answersSelectedDesktop.map((answer, index) => {
            if (index < 3) {
                if (answer === answers[1] || answer === answers[2] || answer === answers[0]) {
                    answerCorrect.push('correct')
                } else {
                    answerCorrect.push('inCorrect')
                }
            } else if (index < 6) {
                if (answer === answers[5] || answer === answers[3] || answer === answers[4]) {
                    answerCorrect.push('correct')
                } else {
                    answerCorrect.push('inCorrect')
                }
            }
        })
        this.setState({
            answerCorrect: answerCorrect,
        })
        if (answersSelectedDesktop.indexOf('?') === -1 && answerCorrect.indexOf('inCorrect') === -1) {
            this.setState({correct: '1'})
        } else {
            this.setState({correct: '0'})
        }
        this.props.showNextBtn()
    };

    _renderTestDesktop = () => {
        const {answerCorrect, correct, answersSelectedDesktop} = this.state
        return (
            <div className="test_desktop">
                <div className="test_wrp">
                    <div className="questions">
                        <div className={'question_el ' + (answersSelectedDesktop.indexOf('1') >= 0 && 'hidden')}>
                            <Questions targetKey="1" question_el={'1'} label={'Могут есть как свежепойманную жертву, так и питаться падалью.'} text={'Могут есть как свежепойманную жертву, так и питаться падалью.'}/>
                        </div>
                        <div className={'question_el ' + (answersSelectedDesktop.indexOf('2') >= 0 && 'hidden')}>
                            <Questions targetKey="1" question_el={'2'} label={'Охотятся, даже если не голодны.'} text={'Охотятся, даже если не голодны.'}/>
                        </div>
                        <div className={'question_el ' + (answersSelectedDesktop.indexOf('3') >= 0 && 'hidden')}>
                            <Questions targetKey="1" question_el={'3'} label={'Нуждаются в богатом белками рационе.'} text={'Нуждаются в богатом белками рационе.'}/>
                        </div>
                        <div className={'question_el ' + (answersSelectedDesktop.indexOf('4') >= 0 && 'hidden')}>
                            <Questions targetKey="1" question_el={'4'} label={'Предпочитают пищу с высоким содержанием жира.'} text={'Предпочитают пищу с высоким содержанием жира.'}/>
                        </div>
                        <div className={'question_el ' + (answersSelectedDesktop.indexOf('5') >= 0 && 'hidden')}>
                            <Questions targetKey="1" question_el={'5'} label={'Охотятся, когда испытывают чувство голода.'} text={'Охотятся, когда испытывают чувство голода.'}/>
                        </div>
                        <div className={'question_el ' + (answersSelectedDesktop.indexOf('6') >= 0 && 'hidden')}>
                            <Questions targetKey="1" question_el={'6'} label={'Обычно едят только что пойманную жертву.'} text={'Обычно едят только что пойманную жертву.'}/>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="answers">
                            <div className="product">
                                <h3 className="head">Дикие кошки</h3>
                                <div className="product_box">
                                    <div className="answers_box">
                                        <div className="answers_item">
                                            <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'1'} count={'?'} answerCorrect={answerCorrect[0]}/>
                                        </div>
                                    </div>
                                    <div className="answers_box">
                                        <div className="answers_item">
                                            <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'2'} count={'?'} answerCorrect={answerCorrect[1]}/>
                                        </div>
                                    </div>
                                    <div className="answers_box">
                                        <div className="answers_item">
                                            <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'3'} count={'?'} answerCorrect={answerCorrect[2]}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="product">
                                <h3 className="head">Волки</h3>
                                <div className="product_box">
                                    <div className="answers_box">
                                        <div className="answers_item">
                                            <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'4'} count={'?'} answerCorrect={answerCorrect[3]}/>
                                        </div>
                                    </div>
                                    <div className="answers_box">
                                        <div className="answers_item">
                                            <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'5'} count={'?'} answerCorrect={answerCorrect[4]}/>
                                        </div>
                                    </div>
                                    <div className="answers_box">
                                        <div className="answers_item">
                                            <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'6'} count={'?'} answerCorrect={answerCorrect[5]}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {correct !== '' ?
                    <div className={'info ' + (correct === '0' && 'error')}>
                        <div className="info_head">
                            {correct === '1' ? 'все верно!' : 'К сожалению, ваш ответ не верен.'}
                        </div>
                        <div className="infoText">
                            Далее предлагаем ознакомиться с материалом более подробно.
                        </div>
                    </div>
                    :
                    <button className="btn btn_blue" onClick={() => this.checkAnswer()}>
                        ПРОВЕРИТЬ
                    </button>
                }
            </div>
        )
    }

    //Работа мобильной версии
    questions = [
        'Могут есть как свежепойманную жертву, так и питаться падалью.',
        'Охотятся, даже если не голодны.',
        'Нуждаются в богатом белками рационе.',
        'Предпочитают пищу с высоким содержанием жира.',
        'Охотятся, когда испытывают чувство голода.',
        'Обычно едят только что пойманную жертву.',
    ]

    popupData = {
        1: {
            head: 'Дикие кошки'
        },
        2: {
            head: 'Дикие кошки'
        },
        3: {
            head: 'Дикие кошки'
        },
        4: {
            head: 'Волки'
        },
        5: {
            head: 'Волки'
        },
        6: {
            head: 'Волки'
        },
    }

    selectAnswer = (answerActive) => {
        document.body.scrollIntoView( true);
        document.body.style.overflow = "hidden";
        this.setState({
            showPopup: true,
            answerActive: answerActive
        })
    }

    selectQuestion = (questionActive) => {
        const {answerActive, answersMobile, questionsMobile, answersSelected} = this.state

        if (answersMobile.indexOf(answerActive) === -1) {
            answersMobile.push(answerActive)
        }
        this.setState({answersMobile})

        if (questionsMobile.indexOf(questionsMobile) === -1) {
            questionsMobile.push(questionActive)
        }
        this.setState({questionsMobile})

        answersSelected.splice(answerActive - 1, 1, this.questions[questionActive - 1])

        document.body.style.overflow = "";
        this.setState({
            showPopup: false,
            questionActive: questionActive,
            answersSelected: answersSelected
        })
    }

    checkAnswerMobile = () => {
        const {answerCorrectMobile, answersSelected} = this.state
        // eslint-disable-next-line
        answersSelected.map((answer, index) => {
            if (index < 3) {
                if (answer === this.questions[1] || answer === this.questions[2] || answer === this.questions[5]) {
                    answerCorrectMobile.push('correct')
                } else {
                    answerCorrectMobile.push('inCorrect')
                }
            } else if (index < 6) {
                if (answer === this.questions[0] || answer === this.questions[3] || answer === this.questions[4]) {
                    answerCorrectMobile.push('correct')
                } else {
                    answerCorrectMobile.push('inCorrect')
                }
            }
        })
        this.setState({
            answerCorrect: answerCorrectMobile,
        })
        if (answerCorrectMobile.indexOf('?') === -1 && answerCorrectMobile.indexOf('inCorrect') === -1) {
            this.setState({correctMobile: '1'})
        } else {
            this.setState({correctMobile: '0'})
        }
        this.props.showNextBtn()
    }

    closePopup = () => {
        document.body.style.overflow = "";
        this.setState({showPopup: false})
    }

    _renderTestMobile = () => {
        const {showPopup, answerActive, answersMobile, questionsMobile, answersSelected, correctMobile, answerCorrectMobile} = this.state
        return (
            <div className="test_mobile">
                {correctMobile !== '' ?
                    <div className={'info ' + (correctMobile === '0' && 'error')}>
                        <div className="info_head">
                            {correctMobile === '1' ? 'все верно!' : 'К сожалению, ваш ответ не верен.'}
                        </div>
                        <div className="infoText">
                            Далее предлагаем ознакомиться с материалом более подробно.
                        </div>
                    </div>
                    :
                    <button className="btn btn_blue" onClick={() => this.checkAnswerMobile()}>
                        ПРОВЕРИТЬ
                    </button>
                }
                <div className="answers">
                    <div className="flex">
                        <div className="flex_el">
                            <h3 className="flex_el_head">Дикие кошки</h3>
                            <div className="flex_el_box">
                                <div className={'answers_item ' + (answersMobile.indexOf(1) >=0 ? 'selected ' : '') + answerCorrectMobile[0]} onClick={() => {this.selectAnswer(1)}}>
                                    {answersSelected[0]}
                                </div>
                                <div className={'answers_item ' + (answersMobile.indexOf(2) >=0 ? 'selected ' : '') + answerCorrectMobile[1]} onClick={() => {this.selectAnswer(2)}}>
                                    {answersSelected[1]}
                                </div>
                                <div className={'answers_item ' + (answersMobile.indexOf(3) >=0 ? 'selected ' : '') + answerCorrectMobile[2]} onClick={() => {this.selectAnswer(3)}}>
                                    {answersSelected[2]}
                                </div>
                            </div>
                        </div>
                        <div className="flex_el">
                            <h3 className="flex_el_head">Волки</h3>
                            <div className="flex_el_box">
                                <div className={'answers_item ' + (answersMobile.indexOf(4) >=0 ? 'selected ' : '') + answerCorrectMobile[3]} onClick={() => {this.selectAnswer(4)}}>
                                    {answersSelected[3]}
                                </div>
                                <div className={'answers_item ' + (answersMobile.indexOf(5) >=0 ? 'selected ' : '') + answerCorrectMobile[4]} onClick={() => {this.selectAnswer(5)}}>
                                    {answersSelected[4]}
                                </div>
                                <div className={'answers_item ' + (answersMobile.indexOf(6) >=0 ? 'selected ' : '') + answerCorrectMobile[5]} onClick={() => {this.selectAnswer(6)}}>
                                    {answersSelected[5]}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showPopup &&
                <div className="popup">
                    <div className="popup_wrp">
                        <div
                            className="popup_closed"
                            onClick={() => {this.closePopup()}}
                        />
                        <div className="popup_flex">
                            <p className="popup_text">
                                {this.popupData[answerActive].head}
                            </p>
                            <div className="questions">
                                {this.questions.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={'questions_item '  + (questionsMobile.indexOf(index + 1) >=0 ? 'selected ' : '')}
                                            onClick={() => {this.selectQuestion(index + 1)}}
                                        >
                                            {item}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }

    render() {
        const {windowWidth} = this.state
        return (
            <div className="step2">
                <TextBlock
                    text={`Чтобы хорошо разбираться в пищевых потребностях домашних собак и кошек, нужно знать особенности питания их предков — волков и диких кошек.
                    
                    Попробуйте установить, в чем заключаются отличия между их пищевым поведением.`}
                />
                <InfoBlock
                    text={`Перетащите перечисленные ниже характеристики к волкам или диким кошкам, а затем нажмите «Проверить».`}
                />
                {windowWidth >= 768 ?
                    <div className="test">
                        {this._renderTestDesktop()}
                    </div>
                    :
                    <div className="test">
                        {this._renderTestMobile()}
                    </div>
                }
            </div>
        )
    }
}
