import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson22/step2-1.png'

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <TextBlock
                    text={`Почти все владельцы кошек <b>регулярно угощают</b> своих питомцев какой-либо едой между основными приёмами пищи с целью побаловать кошку, выразить свою любовь или просто привнести разнообразие в ежедневный рацион кошки.

                     В основном в качестве угощения используется <b>еда со стола</b>, которая не полезна для кошек, а иногда и <b>вредна.</b>`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
