import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from "../../../image/lessons/lesson10/step7-1.jpg";


export default class step7 extends React.Component{

    render(){
        return (
            <div className="step7">
                <TextBlock
                    text={`У котят маленький и слабо растяжимый желудок, поэтому они едят <b>небольшими порциями, но часто</b>. Разработаны научно обоснованные рекомендации по кормлению котят в период роста.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <TextBlock text={`Корм для котят <span class="text_blue">должен обладать следующими качествами:</span>`}/>
                        <ul className="list">
                            <li className="list_item">хорошая усвояемость;</li>
                            <li className="list_item">высокая вкусовая привлекательность;</li>
                            <li className="list_item">высокая энергетическая и питательная ценность, соответствующая потребностям котенка.</li>
                        </ul>
                        <TextBlock text={`У взрослых кошек потребности в белке высоки, а у котят они еще больше. Как и щенкам, котятам нужен <b>дополнительный белок</b> для обеспечения роста организма.`}/>
                    </div>
                    <div className="flex_el">
                        <img className="flex_el_img" src={img1} alt=""/>
                    </div>
                </div>

            </div>
        )
    }

}
