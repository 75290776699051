import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

import img1 from '../../../image/lessons/lesson16/step10-1.png'
import img1xs from '../../../image/lessons/lesson16/step10-1xs.png'
import img2 from '../../../image/lessons/lesson16/step10-2.png'
import img2xs from '../../../image/lessons/lesson16/step10-2xs.png'
import img3 from '../../../image/lessons/lesson16/step10-3.png'
import img3xs from '../../../image/lessons/lesson16/step10-3xs.png'
import img4 from '../../../image/lessons/lesson16/step10-4.png'
import img4xs from '../../../image/lessons/lesson16/step10-4xs.png'
import img5 from '../../../image/lessons/lesson16/step10-5.png'
import img5xs from '../../../image/lessons/lesson16/step10-5xs.png'
import img6 from '../../../image/lessons/lesson16/step10-6.png'
import img6xs from '../../../image/lessons/lesson16/step10-6xs.png'


export default class step10 extends React.Component {

    state = {
        pointActive: 0,
        points: [],
    };

    pointClick = (pointActive) => {
        const {points} = this.state;
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        this.setState({points: points})

        if (points.length === 5) {
            this.props.showNextBtn();
        }
    }

    render() {
        const {points} = this.state
        return (
            <div className="step10">
                <TextBlock
                    text={`На маршруте нашего покупателя есть <span class="text_blue">несколько форматов магазинов</span>, где он покупает корм для своего питомца.`}
                />
                <InfoBlock
                    text={`Нажмите на знаки вопросов, чтобы узнать подробнее.`}
                />
                <div className="box">
                    <div className="box_img">
                        <div className="box_point" onClick={() => {this.pointClick(1)}}>
                            <picture>
                                <source srcSet={img1xs} media="(max-width: 767px)"/>
                                <img src={img1} className={'box_point_img ' + (points.indexOf(1) >=0 && 'active')} alt=""/>
                            </picture>
                        </div>
                        <div className="box_point" onClick={() => {this.pointClick(2)}}>
                            <picture>
                                <source srcSet={img2xs} media="(max-width: 767px)"/>
                                <img src={img2} className={'box_point_img ' + (points.indexOf(2) >=0 && 'active')} alt=""/>
                            </picture>
                        </div>
                        <div className="box_point" onClick={() => {this.pointClick(3)}}>
                            <picture>
                                <source srcSet={img3xs} media="(max-width: 767px)"/>
                                <img src={img3} className={'box_point_img ' + (points.indexOf(3) >=0 && 'active')} alt=""/>
                            </picture>
                        </div>
                        <div className="box_point" onClick={() => {this.pointClick(4)}}>
                            <picture>
                                <source srcSet={img4xs} media="(max-width: 767px)"/>
                                <img src={img4} className={'box_point_img ' + (points.indexOf(4) >=0 && 'active')} alt=""/>
                            </picture>
                        </div>
                        <div className="box_point" onClick={() => {this.pointClick(5)}}>
                            <picture>
                                <source srcSet={img5xs} media="(max-width: 767px)"/>
                                <img src={img5} className={'box_point_img ' + (points.indexOf(5) >=0 && 'active')} alt=""/>
                            </picture>
                        </div>
                        <picture>
                            <source srcSet={img6xs} media="(max-width: 767px)"/>
                            <img src={img6} className="img" alt=""/>
                        </picture>
                    </div>
                </div>
                <div className={'info ' + (points.length === 5 && 'show')}>
                    В среднем в течение года наш покупатель приобретает корм в <span className="info_big">2,5</span> форматах магазинов.
                </div>
            </div>
        )
    }
}
