import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import Popup from "../../../Components/Popup";

import img1 from "../../../image/lessons/lesson21/step9-1.png";

export default class step9 extends React.Component {

    state = {
        popupActive: 0,
        showPopup: false,
        points: [],
    }

    showPopup = (popupActive) => {
        const {points} = this.state;
        if (points.indexOf(popupActive) === -1) {
            points.push(popupActive)
        }

        this.setState({
            popupActive: popupActive,
            showPopup: true,
            points: points
        })

        if (points.length === 3) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    popupData = {
        1: {
            head: `Вкус Umami`,
            text: `Кошки распознают вкус umami. Этот вкус был открыт в начале ХХ века японскими исследователями и в настоящее время продолжает интенсивно изучаться. Его называют еще «мясным» или «бульонным». Он возникает в присутствии глутаминовой кислоты (аминокислота) и некоторых нуклеотидов. Вкус umami влияет на формирование вкусовых предпочтений, усиливает интенсивность других вкусов.`
        },
        2: {
            head: `Вкус воды`,
            text: `У кошек есть рецепторы вкуса воды. Кошки очень чувствительны к изменениям качества воды. Они предпочитают исключительно чистую воду. Именно поэтому кошки часто пьют воду из-под крана или из больших ёмкостей, где вода дольше не застаивается.`,
        },
        3: {
            head: `Чувствительность к аминокислотам`,
            text: `У кошек есть специальные рецепторы, обладающие высокой чувствительностью к аминокислотам — даже одна молекула может вызвать их раздражение. Кошка способна к специализированному и тщательному анализу пищи с точки зрения её аминокислотного состава. Кроме того, кошки способны отличать мясо разного качества, например, свежее и несвежее, жирное и постное и т.д. Вещества, которые накапливаются в теле дичи после смерти, оказывают тормозящее действие на рецепторы аминокислот у кошек и регулируют потребление несвежего мяса (падали). Немаловажное значение для определения свежести мяса имеют и рецепторы «кислого» вкуса, которые также активируются при изменении рН-среды.`,
        },
    }

    render() {
        const {showPopup, popupActive} = this.state
        return (
            <div className="step9">
                <TextBlock
                    text={`Важно знать, что кошкам свойственно долгое время помнить вкус. При выборе пищи они руководствуются прошлым опытом.`}
                />
                <div className="info">
                    <img src={img1} alt="" className="info_img"/>
                    <TextBlock
                        text={`Кошки достаточно быстро устанавливают связь между потребляемым веществом и последствиями для организма. После употребления пищи, которая привела к пищевым расстройствам, кошка будет отказываться от неё в дальнейшем.
                        
                        И напротив, если потребление пищи с определённым вкусом и запахом доставляло кошке удовольствие и не имело последствий в виде нарушения работы внутренних органов, корм и далее будет с охотой поедаться.`}
                    />
                </div>
                <InfoBlock
                    text={`Нажмите на ключевые элементы, чтобы больше узнать о них`}
                />
                <div className="flex">
                    <div className="flex_el" onClick={() => {this.showPopup(1)}}>
                        {this.popupData[1].head}
                    </div>
                    <div className="flex_el" onClick={() => {this.showPopup(2)}}>
                        {this.popupData[2].head}
                    </div>
                    <div className="flex_el" onClick={() => {this.showPopup(3)}}>
                        {this.popupData[3].head}
                    </div>
                </div>
                {showPopup &&
                <Popup
                    closePopup={this.closePopup}
                >
                    <div className="head">
                        {this.popupData[popupActive].head}
                    </div>
                    <TextBlock
                        text={this.popupData[popupActive].text}
                    />
                </Popup>
                }
            </div>
        )
    }
}
