import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

import img1 from '../../../image/lessons/lesson9/step26-1.jpg'
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";


export default class step5 extends React.Component {

    state = {
        popupActive: 0,
        popupShow: false,
        flexHidden: false,
        points: [],
    };

    showPopup = popupActive => {
        const {points} = this.state;
        if (points.indexOf(popupActive) === -1) {
            points.push(popupActive)
        }

        if (points.length === 4) {
            this.setState({popupActive: 0, popupShow: false})
        }

        this.setState({
            popupShow: true,
            popupActive: popupActive,
            points: points
        })

        if (popupActive > 4) {
            this.setState({popupActive: 0, popupShow: false})
        }

        if (points.length === 4) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({popupShow: false})
    }

    popupWrp = {
        1: `Потребление <b>энергии</b> сокращается на 20%`,
        2: `<b>Пищеварение</b> пожилых собак становится более чувствительным`,
        3: `Снижается <b>уровень активности</b>, собаки больше спят`,
        4: `Уменьшается <b>мышечная</b> масса`
    }

    render() {
        const {popupShow, popupActive} = this.state
        return (
            <div className="step26">
                <TextBlock
                    text={`В процессе старения потребности собак меняются.`}
                />
                <InfoBlock
                    text={`Нажмите на вопросительные знаки, чтобы узнать, как меняется организм собак.`}
                />
                <div className="imgDiv">
                    {Object.keys(this.popupWrp).map(item => {
                        return (
                            <div
                                key={item}
                                className="point"
                                onClick={() => {this.showPopup(Number(item))}}
                            >
                                ?
                            </div>
                        )
                    })
                    }
                    <img src={img1} alt="" className="imgCenter"/>
                </div>
                {popupShow &&
                    <PopupInStep
                        closePopup={this.closePopup}
                        popupText={this.popupWrp[popupActive]}
                        changePoint={() => this.showPopup(popupActive + 1)}
                    />
                }
            </div>
        )
    }
}
