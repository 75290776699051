import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson13/step9-1.png'
import img2 from '../../../image/lessons/lesson13/step9-2.png'
import img3 from '../../../image/lessons/lesson13/step9-3.png'

export default class step9 extends React.Component {


    render() {
        return (
            <div className="step9">
                <TextBlock
                    text={`Сухой продукт в основном состоит из <b>мяса</b> и <b>субпродуктов в дегидратированном (то есть высушенном) виде.</b> Рассмотрите таблицу, где описаны основные источники сырья в сухом продукте.`}
                />
                <div className="flex flex_header">
                    <div className="flex_el_header">
                        <div className="attention">
                            Тип сырья
                        </div>
                    </div>
                    <div className="flex_el_header">
                        <div className="attention">
                            Сырой материал
                        </div>
                    </div>
                    <div className="flex_el_header">
                        <div className="attention">
                            Функция
                        </div>
                    </div>
                </div>
                <div className="flex">
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Тип сырья:
                        </h3>
                        <div className="flex_wrp">
                            <TextBlock
                                text={`Мясо`}
                            />
                            <img className="img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Сырой материал:
                        </h3>
                        <div className="flex_wrp">
                            <TextBlock
                                text={`Мука из птицы
                            Говяжья мука
                            Куриные субпродукты
                            Мука из тунца
                            Мука из ягненка
                            Мука из кролика
                            Мука из лосося
                            Мука из креветок`}
                            />
                        </div>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Функция:
                        </h3>
                        <div className="flex_wrp">
                            <TextBlock
                                text={`Источник животного белка и незаменимых аминокислот`}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex">
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Тип сырья:
                        </h3>
                        <div className="flex_wrp">
                            <TextBlock
                                text={`Злаки`}
                            />
                            <img className="img" src={img2} alt=""/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Сырой материал:
                        </h3>
                        <div className="flex_wrp">
                            <TextBlock
                                text={`Мука из пшеницы
                            Мука из риса
                            Мука из кукурузы`}
                            />
                        </div>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Функция:
                        </h3>
                        <div className="flex_wrp">
                            <TextBlock
                                text={`Источники легко усвояемых углеводов и клетчатки`}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex">
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Тип сырья:
                        </h3>
                        <div className="flex_wrp">
                            <TextBlock
                                text={`Масла и жиры`}
                            />
                            <img className="img" src={img3} alt=""/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Сырой материал:
                        </h3>
                        <div className="flex_wrp">
                            <TextBlock
                                text={`Подсолнечное масло
                            Оливковое масло
                            Рыбий жир
                            Животный жир`}
                            />
                        </div>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Функция:
                        </h3>
                        <div className="flex_wrp">
                            <TextBlock
                                text={`Источник энергии, жирных кислот, жирорастворимых витаминов`}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
