import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import ArrowBack from '../../../Components/LessonAndTestComponents/ArrowBack';

import img1 from '../../../image/lessons/lesson1/step16-1.png';
import img1_1 from '../../../image/lessons/lesson1/step16-1-1.png';
import img2 from '../../../image/lessons/lesson1/step16-2.png';
import img2_1 from '../../../image/lessons/lesson1/step16-2-1.png';
import img3 from '../../../image/lessons/lesson1/step16-3.png';
import img3_1 from '../../../image/lessons/lesson1/step16-3-1.png';
import img4 from '../../../image/lessons/lesson1/step16-4.png';
import img4_1 from '../../../image/lessons/lesson1/step16-4-1.png';
import img5 from '../../../image/lessons/lesson1/step16-5.png';
import img5_1 from '../../../image/lessons/lesson1/step16-5-1.png';
import img6 from '../../../image/lessons/lesson1/step16-6.png';
import img6_1 from '../../../image/lessons/lesson1/step16-6-1.png';
import img7 from '../../../image/lessons/lesson1/step16-7.png';
import img7_1 from '../../../image/lessons/lesson1/step16-7-1.png';
import img8 from '../../../image/lessons/lesson1/step16-8.png';
import img8_1 from '../../../image/lessons/lesson1/step16-8-1.png';
import img9 from '../../../image/lessons/lesson1/step16-9.png';
import img9_1 from '../../../image/lessons/lesson1/step16-9-1.png';
import img10 from '../../../image/lessons/lesson1/step16-10.png';
import img10_1 from '../../../image/lessons/lesson1/step16-10-1.png';


export default class step16 extends React.Component {
    state = {
        flexActive: 0,
        flexs: [1],
        flexHidden: false,
    };

    changeFlex = flexActive => {
        const {flexs} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }
        this.setState({flexActive, flexs, flexHidden: true})
        if (flexs.length === 11) {
            this.props.showNextBtn()
        }
    }

    closeImgDiv = () => {this.setState({flexHidden: false, flexActive: 0})}

    breedData = {
        0: {
            img: '',
            breed: '',
            divText: ``,
            divImg: '',
        },
        1: {
            img: img1,
            breed: 'Пастушьи и скотогонные',
            divText: `Собаки этой группы были выведены с целью выпаса скота. В домашних условиях поведение этих собак также напоминает «пастуха»: они любят, чтобы все были на виду и обращали на них внимание.
            
            <b>Основные породы:</b> практически все разновидности овчарок, колли.`,
            divImg: img1_1,
        },
        2: {
            img: img2,
            breed: 'Пинчеры и шнауцеры',
            divText: `Самая разнообразная породная группа. Поведенческие особенности этих собак разнятся: от добрых ньюфаундлендов до грозных кавказских овчарок.
            
            <b>Породы:</b> немецкие доги, бульмастиффы, боксеры, доберманы, ротвейлеры, сенбернары.`,
            divImg: img2_1,
        },
        3: {
            img: img3,
            breed: 'Терьеры',
            divText: `К этой группе относятся породы, в названии которых присутствует слово «терьер». Очень подвижные и любознательные собаки. Их предки были отличными охотниками на крыс, енотов, лисиц и даже кабанов. Терьеры подходят активным людям, любящим спорт и прогулки на воздухе.`,
            divImg: img3_1,
        },
        4: {
            img: img4,
            breed: 'Таксы',
            divText: `Назначение этих собак – охота на норного зверя. Находясь на природе, таксы любят непрерывно что-то копать.
            
            Они обладают упрямым характером, но в то же время очень умны и преданы хозяину.`,
            divImg: img4_1,
        },
        5: {
            img: img5,
            breed: 'Шпицы и породы примитивного типа',
            divText: `К этой группе относятся лайки, шпицы и северные ездовые собаки. Примитивными эти породы называются потому, что были выведены без вмешательства человека.
            
            <b>Популярные породы:</b> акита, чау-чау, аляскинский маламут, все лайки и шпицы.`,
            divImg: img5_1,
        },
        6: {
            img: img6,
            breed: 'Гончие и родословные породы',
            divText: `Собаки этой группы служат для загонной охоты и для преследования дичи по следу крови. Их можно назвать рабочими собаками охотничьего назначения.
            
            <b>Наиболее распространенные породы:</b> бассет-хаунд, бигль, далматин.`,
            divImg: img6_1,
        },
        7: {
            img: img7,
            breed: 'Легавые',
            divText: `Эти породы собак были выведены для охоты с ружьем на дичь. Отличаются острым обонянием и спокойным характером.
            
            <b>Популярные породы:</b> ирландский сеттер, сеттер гордон, английский сеттер, английский пойнтер, немецкий курцхаар.`,
            divImg: img7_1,
        },
        8: {
            img: img8,
            breed: 'Ретриверы, спаниели и водные собаки',
            divText: `Это охотничьи собаки, предназначенные для охоты на дичь, в том числе водоплавающую. Часто используются как поводыри. В семье они не агрессивны, терпеливы и общительны.
            
            <b>Породы:</b> коккер-спаниель, золотистый ретривер, лабрадор-ретривер.`,
            divImg: img8_1,
        },
        9: {
            img: img9,
            breed: 'Борзые',
            divText: `Эти собаки издавна использовались охотниками, чтобы загонять дикого зверя. Им необходимо много бегать на открытом пространстве.
            
            <b>Породы:</b> русская псовая, афганская борзая, ирландский волкодав, уиппет.`,
            divImg: img9_1,
        },
        10: {
            img: img10,
            breed: 'Декоративные и собаки-компаньоны',
            divText: `Собаки этой группы не имеют специальных функций. Их заводят в качестве друзей и компаньонов человека.
            
            <b>Породы:</b> чихуахуа, французский бульдог, пекинес, мопс, мальтийская болонка, той-терьер, пудели, гриффоны, японский хин.`,
            divImg: img10_1,
        },
    }

    render() {
        const {flexActive, flexHidden} = this.state
        return (
            <div className="step16">
                <TextBlock
                    text={`Все собаки делятся на 10 породных групп согласно классификации FСI в зависимости от их исторически сложившихся функций, которые они выполняют для человека.`}
                    hidden={flexHidden === true ? 'hidden' : ''}
                />
                <InfoBlock
                    text={`Нажмите на группу, чтобы получить более подробную информацию и узнать о самых популярных породах этой группы.
                    
                    Для продолжения, пожалуйста, ознакомьтесь со всей представленной информацией в этом шаге урока.`}
                    hidden={flexHidden === true ? ' hidden' : ''}
                />
                <div className={'flex ' + (flexActive !== 0 && 'active ') +  (flexHidden === true && ' hidden')}>
                    {Object.keys(this.breedData).filter(id => id > 0).map((item) => {
                        return (
                            <div
                                key={item}
                                className={'flex_el ' + (flexActive === (item) && 'active')}
                                onClick={() => {this.changeFlex(item)}}
                            >
                                <img className="flex_el_img" src={this.breedData[item].img} alt=""/>
                                <p className="flex_el_text">
                                    {this.breedData[item].breed}
                                </p>
                            </div>
                        )
                    })}
                </div>
                <div className={'imgDiv ' + (flexActive !== 0 && 'active')}>
                    <ArrowBack
                        className={flexActive === 0 ? 'hidden' : ''}
                        returnMenu={this.closeImgDiv}
                    />
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_flex">
                            <TextBlock
                                text={this.breedData[flexActive].divText}
                            />
                            <img className="imgDiv_img" src={this.breedData[flexActive].divImg} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
