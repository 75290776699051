import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from "../../../image/lessons/lesson20/step15-1.png";
import img2 from "../../../image/lessons/lesson20/step15-2.png";
import img3 from "../../../image/lessons/lesson20/step15-3.png";

export default class step28 extends React.Component {

    render() {
        return (
            <div className="step28">
                <TextBlock
                    text={`WHISKAS<sup>®</sup> для стерилизованных кошек – это <b>сбалансированный корм</b>, предназначенный для кормления кошки в течение всей жизни с момента стерилизации:`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="advantages">
                            <div className="advantages_number">1</div>
                            <div className="advantages_text">
                                <b>Учитывает особые потребности</b> стерилизованных кошек;
                            </div>
                        </div>
                        <div className="advantages">
                            <div className="advantages_number">2</div>
                            <div className="advantages_text">
                                <b>Поддержание здоровой массы тела</b>: содержит пониженное количество жира и калорий (по сравнению со стандартным рационом WHISKAS<sup>®</sup> для взрослых кошек) для предотвращения набора излишнего веса стерилизованных кошек и котов:
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                </div>
                <img src={img2} alt="" className="imgCenter"/>
                <div className="advantages">
                    <div className="advantages_number">3</div>
                    <div className="advantages_text">
                        <b>Специальный баланс минералов для здоровья мочевыделительной системы:</b>
                    </div>
                </div>
                <img src={img3} alt="" className="imgCenter"/>
            </div>
        )
    }
}
