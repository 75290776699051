import React from "react";
import "./style.scss"

const Button = (props) => {
    return(
        <div className="login__button">
            <button type="button" onClick={props.onClick}>{props.title}</button>
        </div>
    )
}

export default Button