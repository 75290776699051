import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

export default class step6 extends React.Component {

    state = {
        flexData:
            [
                'абсорбируемые',
                'перевариваемые',
                'ферментируемые',
                'неферментируемые'
            ],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData, flexHidden} = this.state
        return (
            <div className="step6">
                <InfoBlock
                    text={`Нажмите на блок, чтобы получить дополнительную информацию.`}
                    hidden={flexHidden === true ? ' hidden' : ''}
                />
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Немедленно усваиваются организмом.
                            
                            Пример: <b>глюкоза</b> — энергетический ресурс для работы мозга и нервной системы, а также красных кровяных клеток.`}
                        />
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Под действием ферментов расщепляются на абсорбируемые углеводы. К перевариваемым углеводам относится <b>крахмал.</b>`}
                        />
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Перерабатываются бактериями в кишечнике и могут расщепляться на углеводы, усваиваемые организмом.
                            
                            В качестве примера можно привести <b>пектин.</b>`}
                        />
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Также называются <b>клетчаткой.</b> Эта категория углеводов проходит через организм в непереваренном виде и выполняет функцию наполнения содержимого кишечника и стимуляции нормальной моторики желудочно-кишечного тракта.`}
                        />
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
