import React from 'react'

import img1 from "../../../image/lessons/lesson20/step19-1.png";

export default class step19 extends React.Component {

    render() {
        return (
            <div className="step19">
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Для питомцев
                        </div>
                        <div className="flex_el_box">
                            <ul className="list">
                                <li className="list_item">Высокая энергетическая ценность. Сухой корм примерно в 4 раза более калорийный, чем влажный, поэтому порции имеют меньший размер.</li>
                                <li className="list_item">Улучшает гигиену ротовой полости за счет механической чистки зубов.</li>
                                <li className="list_item">Поддерживает моторику желудочно-кишечного тракта за счет высокого содержания клетчатки.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Для хозяев
                        </div>
                        <div className="flex_el_box">
                            <ul className="list">
                                <li className="list_item">Экономия. За счет маленьких порций корм расходуется медленнее и покупать его можно реже.</li>
                                <li className="list_item">Длительный срок годности.</li>
                                <li className="list_item">Удобно хранить и использовать.</li>
                            </ul>
                        </div>
                    </div>
                    <img src={img1} alt="" className="flex_el_img"/>
                </div>
            </div>
        )
    }
}
