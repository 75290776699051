import React from 'react'
import Resume from "../../../Components/LessonAndTestComponents/Resume";

import img1 from "../../../image/lessons/lesson2/step7-1.png";


export default class step7 extends React.Component {


    state = {
        resumeData: [
            `<b>Питательные вещества</b> — это материалы, необходимые для роста и восстановления организма, а также для обеспечения организма энергией.`,
            `Три главных источника энергии: <b>белки, жиры, углеводы.</b>`,
            `Энергия <b>обеспечивает работу всех клеток</b> организма.`,
            `Сбалансированный рацион обеспечивает организм животных <b>незаменимыми питательными веществами.</b>`,
        ]
    }

    render() {
        return (
            <div className="step7">
                <Resume
                    resumeData={this.state.resumeData}
                    img={img1}
                />
            </div>
        )
    }
}
