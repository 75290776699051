import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson13/step7-1.png'


export default class step7 extends React.Component {

    render() {
        return (
            <div className="step7">
                <TextBlock
                    text={`Сырью, из которого производят корма для животных, уделяется особое внимание. Отбор и анализ сырья состоит из нескольких этапов.`}
                />
                <img className="img" src={img1} alt=""/>
            </div>
        )
    }
}
