import React from "react";
import "./style.sass"
import DropDownPanel from "../../Components/DropDownPanel"

const faq = [
    {
        question: "Как начисляются баллы? (за уроки, за тесты, за время прохождения тестов, за повышение ранга)",
        answer: "За прохождение каждого урока вы получаете определенную сумму баллов. Информацию об этом вы можете найти во вкладке «Мое обучение». Прохождение тестов также дает вам дополнительные баллы, которые зависят от количества правильных ответов и от времени прохождения теста. Чем быстрее и правильнее вы решаете тест, тем больше баллов вы получаете.\n" +
            "Важно! Баллы за время прохождения теста начисляются только в том случае, если у вас 8 и более правильных ответов. Будьте внимательны!"
    },
    {
        question: "Как начисляются баллы? (за уроки, за тесты, за время прохождения тестов, за повышение ранга)",
        answer: "За прохождение каждого урока вы получаете определенную сумму баллов. Информацию об этом вы можете найти во вкладке «Мое обучение». Прохождение тестов также дает вам дополнительные баллы, которые зависят от количества правильных ответов и от времени прохождения теста. Чем быстрее и правильнее вы решаете тест, тем больше баллов вы получаете.\n" +
            "Важно! Баллы за время прохождения теста начисляются только в том случае, если у вас 8 и более правильных ответов. Будьте внимательны!"
    },
    {
        question: "Как присваиваются статусы?",
        answer: [`Статус присваивается в соответствии с заработанными в процессе обучения баллами и, в зависимости от их количества, пользователю присваивается один из следующих статусов: `,<br/>,
            `Новичок - 0 баллов `, <br/>,
            `Специалист - 50 баллов `, <br/>,
            `Профессионал - 100 баллов `, <br/>,
            `Мастер - 200 баллов `, <br/>,
            `Знаток - 500 баллов `, <br/>,
            `Мудрец - 800 баллов `, <br/>,
            `Гуру - 1200 баллов `]
    },
]

export default function Faq() {

    return (
        <div>
            {faq.map((item, index) => {
                return (
                    <DropDownPanel
                        key={index}
                        number={index + 1}
                        question={item.question}
                        answer={item.answer}
                    />
                )
            })}
        </div>
    )
}

