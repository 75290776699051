import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";

import img1 from '../../../image/lessons/lesson20/step3-1.png'

export default class step3 extends React.Component {

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step3">
                <TextBlock
                    text={`Рецептура рационов WHISKAS<sup>®</sup> основана на результатах многолетних исследований международного научно-исследовательского института Waltham. Научно-исследовательский институт Waltham изучает вопросы питания домашних животных с 1963 года.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <TextBlock
                            text={`<b>За это время научно-исследовательский институт Waltham совершил ряд важных научных открытий, ценность которых очевидна и сегодня:</b>`}
                        />
                        <ul className="list">
                            <li className="list_item">
                                Например, именно в научно-исследовательском институте Waltham был определен необходимый уровень таурина во влажных и сухих рационах для кошек;
                            </li>
                            <li className="list_item">
                                Другой пример – в научно-исследовательском институте Waltham было открыто, что кошки не имеют рецепторов сладкого вкуса сахаров.
                            </li>
                        </ul>
                    </div>
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                </div>
                <InfoBlock
                    text={`Нажмите далее, чтобы посмотреть видеоролик про международный научно-исследовательский институт Waltham.`}
                />
            </div>
        )
    }
}
