import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";

import img1 from "../../../image/lessons/lesson20/step8-1.png";

export default class step8 extends React.Component {

    render() {
        return (
            <div className="step8">
                <TextBlock
                    text={`Выделяют 3 стадии жизни кошек. WHISKAS<sup>®</sup> заботится о кошке на каждой стадии ее жизни.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
                <InfoBlock
                    text={`На каждой стадии жизни у кошки есть свои особенности и потребности в питании. Давайте разберемся, какие существуют риски для здоровья, если кормить кошку, не учитывая ее стадии жизни. Нажмите продолжить, чтобы узнать.`}
                />
            </div>
        )
    }
}
