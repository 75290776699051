import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson5/step10-1.png";
import img2 from "../../../image/lessons/lesson5/step10-2.png";

export default class step10 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит и избыток'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step10">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Витамин В2 (рибофлавин) — энергообразование.</b>
                           
                            Рибофлавин был обнаружен в 1937 году, но его важная роль в предотвращении многих болезней была полностью изучена только в 1980-е годы.
                            
                            Этот водорастворимый витамин очень чувствителен к свету.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Витамин В2 выполняет следующие функции:`}
                        />
                        <ul className="list">
                            <li className="list_item">поддержание здоровья кожи и шерсти;</li>
                            <li className="list_item">участие в образовании энергии вместе с витамином B3.</li>
                        </ul>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Витамин В2 очень распространен в природе и содержится в:`}
                        />
                        <div className="imgDiv_wrap_flex">
                            <ul className="list">
                                <li className="list_item">дрожжах;</li>
                                <li className="list_item">печени;</li>
                                <li className="list_item">яйцах.</li>
                            </ul>
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img2} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Дефицит витамина В2 может вызвать изменения кожи живота и участков вокруг глаз.`}
                        />
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
