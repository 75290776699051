import React from 'react'
import './style.sass'

export default class MainHeader extends React.Component{
    render() {
        return (
            <div className={`MainHeader ${this.props.headerClass}`}>
                {this.props.header &&
                    <h2 className="header">{this.props.header}</h2>
                }
                {this.props.subheader &&
                    <h3 className="subheader">{this.props.subheader}</h3>
                }
                {this.props.questionName &&
                    <div className={'testHead ' + this.props.testHeadClass}>
                        <span className="testName">Вопрос {this.props.count}</span>
                        <div className="answer">
                            правильные ответы: <span>{this.props.answerCorrect}/{this.props.questionCount}</span>
                        </div>
                    </div>
                }
                {this.props.text &&
                    <div className="text">
                        {this.props.text}
                    </div>
                }
            </div>
        )
    }
}
