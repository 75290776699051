import React from 'react'
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";

export default class step17 extends React.Component {


    render() {
        return (
            <div className="step17">
                <div className="box">
                    <VideoBlock
                        src={'https://www.youtube.com/embed/_NuDVOVDLhc'}
                        title={'https://www.youtube.com/watch?v=_NuDVOVDLhc&feature=emb_logo'}
                    />
                </div>
            </div>
        )
    }
}
