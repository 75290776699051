import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson6/step5-1.png";
import img2 from "../../../image/lessons/lesson6/step5-2.png";
import img3 from "../../../image/lessons/lesson6/step5-3.png";

export default class step5 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит и избыток'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step5">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Фосфор (Р) — структура костей, компонент клеточной мембраны.</b>
                           
                            Слово фосфор означает «несущий свет».
                            
                            Вещество было открыто в 1669 году немецким алхимиком, который выделил фосфор в виде испарений, светящихся в темноте.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_flex">
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img2} alt=""/>
                            </div>
                            <div className="imgDiv_wrap_flex_box">
                                <TextBlock
                                    text={`Фосфор играет важную роль в организме:`}
                                />
                                <ul className="list">
                                    <li className="list_item">является структурным компонентом молекул ДНК и РНК;</li>
                                    <li className="list_item">обеспечивает прочность костей и зубов;</li>
                                    <li className="list_item">является компонентом клеточной мембраны;</li>
                                    <li className="list_item">участвует в энергообразовании.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_flex">
                            <TextBlock
                                text={`Фосфор содержится в костях млекопитающих, птиц и рыб.`}
                            />
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img3} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Дефицит фосфора может привести к:`}
                        />
                        <ul className="list">
                            <li className="list_item">замедлению роста;</li>
                            <li className="list_item">плохому аппетиту;</li>
                            <li className="list_item">деформации костей.</li>
                        </ul>
                        <TextBlock
                            text={`В рационах для правильного роста и развития важно поддерживать определенное соотношение кальция и фосфора (обычно это 1:1).`}
                        />
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
