import React from 'react'
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";
import Popup from "../../../Components/Popup";
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";

import img1 from '../../../image/lessons/lesson32/step45-1.png';
import img2 from '../../../image/lessons/lesson32/step45-2.png';
import img3 from '../../../image/lessons/lesson32/step45-3.png';
import img4 from '../../../image/lessons/lesson32/step45-4.png';
import img5 from '../../../image/lessons/lesson32/step45-5.png';
import img6 from '../../../image/lessons/lesson32/step45-6.png';
import img7 from '../../../image/lessons/lesson32/step45-7.png';
import img8 from '../../../image/lessons/lesson32/step45-8.png';
import img9 from '../../../image/lessons/lesson32/step45-9.png';
import img10 from '../../../image/lessons/lesson32/step45-10.png';
import img11 from '../../../image/lessons/lesson32/step45-11.png';
import img12 from '../../../image/lessons/lesson32/step45-12.png';
import img13 from '../../../image/lessons/lesson32/step45-13.png';
import img15 from '../../../image/lessons/lesson32/step45-15.png';
import img16 from '../../../image/lessons/lesson32/step45-16.png';
import img17 from '../../../image/lessons/lesson32/step45-17.png';
import img18 from '../../../image/lessons/lesson32/step45-18.png';
import img19 from '../../../image/lessons/lesson32/step45-19.png';
import img20 from '../../../image/lessons/lesson32/step45-20.png';
import img21 from '../../../image/lessons/lesson32/step45-21.png';
import img22 from '../../../image/lessons/lesson32/step45-22.png';
import img_popup2_1 from '../../../image/lessons/lesson32/step45-popup2-1.png';
import img_popup2_2 from '../../../image/lessons/lesson32/step45-popup2-2.png';
import img_popup2_3 from '../../../image/lessons/lesson32/step45-popup2-3.png';
import img_popup2_4 from '../../../image/lessons/lesson32/step45-popup2-4.png';
import img_popup3_1 from '../../../image/lessons/lesson32/step45-popup3-1.png';
import img_popup3_2 from '../../../image/lessons/lesson32/step45-popup3-2.png';
import img_popup3_3 from '../../../image/lessons/lesson32/step45-popup3-3.png';
import img_popup3_4 from '../../../image/lessons/lesson32/step45-popup3-4.png';
import img_popup4_1 from '../../../image/lessons/lesson32/step45-popup4-1.png';
import img_popup4_2 from '../../../image/lessons/lesson32/step45-popup4-2.png';
import img_popup5_1 from '../../../image/lessons/lesson32/step45-popup5-1.png';
import img_popup5_2 from '../../../image/lessons/lesson32/step45-popup5-2.png';
import img_popup6_1 from '../../../image/lessons/lesson32/step45-popup6-1.png';
import img_popup6_2 from '../../../image/lessons/lesson32/step45-popup6-2.png';
import img_popup7_1 from '../../../image/lessons/lesson32/step45-popup7-1.png';
import img_popup7_2 from '../../../image/lessons/lesson32/step45-popup7-2.png';

export default class step45 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        flexActive: 0,
        popupActive: 0,
        showPopup: false,
        showVideo: false,
        points: [],
    };

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize)
    }

    //Отработка клика по кругам
    changeFlex = flexActive => {
        const {windowWidth} = this.state
        if (windowWidth < 767) {
            document.body.scrollIntoView( true);
            document.body.style.overflow = "hidden";
            this.setState({flexActive})
        } else {
            this.setState({flexActive})
        }
    }

    //Показ popup
    showPopup = popupActive => {
        const {points} = this.state
        if (points.indexOf(popupActive) === -1) {
            points.push(popupActive)
        }

        if (popupActive === 1) {
            this.setState({popupActive: popupActive, showPopup: true, showVideo: true})
        } else {
            this.setState({popupActive: popupActive, showPopup: true})
        }

        if (points.length === 7) {
            this.props.showNextBtn();
        }
    }

    //Переключение на видео в popup
    showVideo = () => {
        const {showVideo} = this.state
        this.setState({showVideo: !showVideo})
    }

    //Закрыть popup
    closePopup = () => {this.setState({showPopup: false, popupActive: 0, showVideo: false})}

    //Закрыть content на моблике
    closeContent = () => {
        document.body.style.overflow = "";
        this.setState({flexActive: 0})
    }

    //Данные для popup
    popupData = {
        1: {
            head: `PEDIGREE<sup>®</sup> DENTASTIX™`,
            video: 'https://www.youtube.com/embed/2ry_113w3eQ',
            title: 'https://www.youtube.com/watch?v=2ry_113w3eQ&feature=emb_logo',
            iconFlex: img15
        },
        2: {
            head: `PEDIGREE<sup>®</sup> Tasty Bites™ – ароматные кусочки <span class="Popup_subhead">Для щенков</span>`,
            productImg: img_popup2_1,
            icon1: img_popup2_2,
            text1: `Особая текстура мягких <span class="Popup_content_red">полувлажных ароматных кусочков,</span> небольшой размер которых <span class="Popup_content_red">идеально подходит, чтобы угощать питомца в любое время малыми порциями</span>`,
            icon2: img_popup2_3,
            text2: `Подходит для щенков в возрасте <span class="Popup_content_red">от 4 месяцев</span>`,
            icon3: img_popup2_4,
            text3: `Pedigree<sup>®</sup> Tasty Bites™ для щенков содержит <span class="Popup_content_red">кальций</span> для здоровья костей и зубов`,
            list: false,
            video: 'https://www.youtube.com/embed/MOvmDZLPyEM',
            title: 'https://www.youtube.com/watch?v=MOvmDZLPyEM',
            iconFlex: img16
        },
        3: {
            head: `PEDIGREE<sup>®</sup> Tasty Bites™ – ароматные кусочки <span class="Popup_subhead">Для взрослых собак</span>`,
            productImg: img_popup3_1,
            icon1: img_popup3_2,
            text1: `Особая текстура мягких <span class="Popup_content_red">полувлажных ароматных кусочков,</span> небольшой размер которых <span class="Popup_content_red">идеально подходит, чтобы угощать питомца в любое время малыми порциями</span>`,
            icon2: img_popup3_3,
            text2: `Представлены двумя вкусами: <span class="Popup_content_red">с говядиной и с курицей</span>`,
            icon3: false,
            text3: ``,
            list: `Pedigree<sup>®</sup> Tasty Bites™ содержит:`,
            video: 'https://www.youtube.com/embed/ZsQCKOnyTAg',
            title: 'https://www.youtube.com/watch?v=ZsQCKOnyTAg',
            iconFlex: img16
        },
        4: {
            head: `PEDIGREE<sup>®</sup> Rodeo™`,
            productImg: img_popup4_1,
            icon1: img_popup4_2,
            text1: `Лакомство в форме мясных косичек.
            
            Благодаря <span class="Popup_content_red">полувлажной текстуре и высокой вкусовой привлекательности</span> легко разжевывается и съедается с удовольствием!`,
            icon2: false,
            text2: ``,
            icon3: false,
            text3: ``,
            list: `Pedigree<sup>®</sup> Rodeo™ содержит:`,
            video: 'https://www.youtube.com/embed/XDcE6lAOQPw',
            title: 'https://www.youtube.com/watch?v=XDcE6lAOQPw',
            iconFlex: img16,
        },
        5: {
            head: `PEDIGREE<sup>®</sup> Markies™`,
            productImg: img_popup5_1,
            icon1: img_popup5_2,
            text1: `Особая текстура <span class="Popup_content_red">мясного печенья в виде рулетиков с мозговой косточкой</span>`,
            icon2: false,
            text2: ``,
            icon3: false,
            text3: ``,
            list: 'Pedigree<sup>®</sup> Markies™ содержит:',
            video: 'https://www.youtube.com/embed/zC_PaEaHkhw',
            title: 'https://www.youtube.com/watch?v=zC_PaEaHkhw',
            iconFlex: img16,
        },
        6: {
            head: `PEDIGREE<sup>®</sup> Biscrok™`,
            productImg: img_popup6_1,
            icon1: img_popup6_2,
            text1: `Особая <span class="Popup_content_red">хрустящая текстура бисквитных косточек</span>`,
            icon2: img_popup3_3,
            text2: `В одной упаковке – <span class="Popup_content_red">ассорти из нескольких вкусов: с курицей, с говядиной с бараниной</span>`,
            icon3: false,
            text3: ``,
            list: 'Pedigree<sup>®</sup> Biscrok содержит:',
            video: 'https://www.youtube.com/embed/FkHsTYWiFew',
            title: 'https://www.youtube.com/watch?v=FkHsTYWiFew',
            iconFlex: img16,
        },
        7: {
            head: `PEDIGREE<sup>®</sup> Jumbone™ Mini<span class="Popup_subhead">(с говядиной и птицей)</span>`,
            productImg: img_popup7_1,
            icon1: img_popup7_2,
            text1: `Особая <span class="Popup_content_red">хрустящая текстура бисквитных косточек</span>`,
            icon2: false,
            text2: ``,
            icon3: false,
            text3: ``,
            list: 'Pedigree<sup>®</sup> Jumbone™ содержит:',
            video: 'https://www.youtube.com/embed/tOUSjgpiJNA',
            title: 'https://www.youtube.com/watch?v=tOUSjgpiJNA',
            iconFlex: img17
        },
    }

    render() {
        const {flexActive, showPopup, popupActive, showVideo, windowWidth} = this.state
        return (
            <div className="step45">
                <InfoBlock
                    text={`Нажмите на изображения, чтобы познакомиться с лакомствами PEDIGREE<sup>®</sup>`}
                />

                <div className="flex">
                    <div className="flex_el">
                        <div
                            className={'flex_el_content ' + (flexActive === 0 ? '' : flexActive === 1 ? 'active' : 'inactive')}
                            onClick={() => {this.changeFlex(1)}}
                        >
                            <div className="flex_el_head">
                                Для поддержания здоровья зубов
                            </div>
                            <div className="flex_el_img-box">
                                <img src={img1} alt="" className="flex_el_img"/>
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div
                            className={'flex_el_content ' + (flexActive === 0 ? '' : flexActive === 2 ? 'active' : 'inactive')}
                            onClick={() => {this.changeFlex(2)}}
                        >
                            <div className="flex_el_head">
                                Для угощения и&nbsp;поощрения во время тренировки
                            </div>
                            <div className="flex_el_img-box">
                                <img src={img2} alt="" className="flex_el_img"/>
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div
                            className={'flex_el_content ' + (flexActive === 0 ? '' : flexActive === 3 ? 'active' : 'inactive')}
                            onClick={() => {this.changeFlex(3)}}
                        >
                            <div className="flex_el_head">
                                Для занятия собаки на время
                            </div>
                            <div className="flex_el_img-box">
                                <img src={img3} alt="" className="flex_el_img"/>
                            </div>
                        </div>
                    </div>
                </div>

                {flexActive > 0 &&
                <div className={'content ' + (flexActive === 2 && 'blue middle ') + (flexActive === 3 && ' right')}>

                    {flexActive === 1 &&
                    <div className={'content_box stix'}>
                        {windowWidth < 767 &&
                            <div className="content_close" onClick={this.closeContent}/>
                        }
                        {windowWidth < 767 &&
                        <img className="content_icon" src={img15} alt=""/>
                        }
                        <div className="content_header">
                            PEDIGREE<sup>®</sup> DENTASTIX™
                        </div>
                        <div className="content_subhead">
                            (Лакомство по уходу за зубами)
                        </div>
                        <div className="content_list">
                            <div className="content_item">
                                <div className="content_item_head">
                                    Для собак<br/>
                                    малых пород<br/>
                                    (весом от 5 до 10 кг)
                                </div>
                                <img src={img4} alt="" className="content_item_img"/>
                                <div className="content_item_description">
                                    3 палочки – 45 г<br/>
                                    7 палочек – 110 г
                                </div>
                            </div>
                            <div className="content_item">
                                <div className="content_item_head">
                                    Для собак<br/>
                                    средних пород<br/>
                                    (весом от 10 до 25 кг)
                                </div>
                                <img src={img5} alt="" className="content_item_img"/>
                                <div className="content_item_description">
                                    3 палочки – 77 г<br/>
                                    7 палочек – 180 г
                                </div>
                            </div>
                            <div className="content_item">
                                <div className="content_item_head">
                                    Для собак<br/>
                                    крупных пород<br/>
                                    (весом более 25 кг)
                                </div>
                                <img src={img6} alt="" className="content_item_img"/>
                                <div className="content_item_description">
                                    7 палочек – 270 г
                                </div>
                            </div>
                            <div className="content_item">
                                <div className="content_item_head">
                                    «Жевательные кусочки»<br/>
                                    со вкусом говядины<br/>
                                    для собак<br/>
                                    средних и крупных пород<br/>
                                    более 15 кг
                                </div>
                                <img src={img18} alt="" className="content_item_img"/>
                                {/*<div className="content_item_description">*/}
                                {/*    7 палочек – 270 г*/}
                                {/*</div>*/}
                            </div>
                            <div className="content_item">
                                <div className="content_item_head">
                                    «Жевательные кусочки»<br/>
                                    со вкусом говядины<br/>
                                    для собак<br/>
                                    маленьких пород<br/>
                                    от 5 до 15 кг
                                </div>
                                <img src={img19} alt="" className="content_item_img"/>
                                {/*<div className="content_item_description">*/}
                                {/*    7 палочек – 270 г*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className="play" onClick={() => {this.showPopup(1)}}>
                            <div className="play_text">
                                смотреть<br/>
                                видеоролик
                            </div>
                            <div className="play_button">
                                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="30" cy="30" r="29" fill="white" stroke="#BA0D0E" strokeWidth="2"/>
                                    <path d="M24 20L40 30L24 40V20Z" fill="#BA0D0E"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    }
                    {flexActive === 2 &&
                    <div className={'content_box'}>
                        {windowWidth < 767 &&
                        <div className="content_close" onClick={this.closeContent}/>
                        }
                        {windowWidth < 767 &&
                        <img className="content_icon" src={img16} alt=""/>
                        }
                        <div className="content_list top">
                            <div className="content_item">
                                <div className="content_header">
                                    Tasty Minis™
                                    <img src={img22} alt=""/>
                                </div>
                                <div className="content_subhead">
                                    (Ароматные кусочки и хрустящие подушечки)
                                </div>
                                <div className="content_item_container">
                                    <div className="content_item_box" onClick={() => {this.showPopup(3)}}>
                                        <img src={img7} alt="" className="content_item_img"/>
                                        <div className="content_item_description">
                                            130 г
                                        </div>
                                    </div>
                                    <div className="content_item_box" onClick={() => {this.showPopup(2)}}>
                                        <img src={img8} alt="" className="content_item_img"/>
                                        <div className="content_item_description">
                                            125 г
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content_item" onClick={() => {this.showPopup(3)}}>
                                <div className="content_item_container">
                                    <div className="content_item_box">
                                        <div className="content_header">
                                            Schmackos™
                                            <img src={img22} alt=""/>
                                        </div>
                                        <div className="content_subhead">
                                            (Мясное лакомство из говядины)
                                        </div>
                                        <img src={img20} alt="" className="content_item_img"/>
                                        <div className="content_item_description">
                                            33 г
                                        </div>
                                    </div>
                                    <div className="content_item_box">
                                        <div className="content_header">
                                            Ranchos™
                                            <img src={img22} alt=""/>
                                        </div>
                                        <div className="content_subhead">
                                            (Мясное лакомство c говядиной)
                                        </div>
                                        <img src={img21} alt="" className="content_item_img"/>
                                        <div className="content_item_description">
                                            58 г
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="content_item" onClick={() => {this.showPopup(3)}}>*/}
                            {/*    <div className="content_item_box">*/}
                            {/*        <img src={img9} alt="" className="content_item_img"/>*/}
                            {/*        <div className="content_item_description">*/}
                            {/*            130 г*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        <div className="content_list bottom">
                            <div className="content_item" onClick={() => {this.showPopup(4)}}>
                                <div className="content_item_box">
                                    <div className="content_item_header">
                                        Rodeo™
                                        <img src={img22} alt=""/>
                                    </div>
                                    <div className="content_item_description">
                                        (Мясные косички)
                                    </div>
                                    <img src={img10} alt="" className="content_item_img"/>
                                    <div className="content_item_description">
                                        4 палочки – 70 г
                                    </div>
                                </div>
                            </div>
                            <div className="content_item" onClick={() => {this.showPopup(5)}}>
                                <div className="content_item_box">
                                    <div className="content_item_header">
                                        Markies™
                                        <img src={img22} alt=""/>
                                    </div>
                                    <div className="content_item_description">
                                        (Мясное печенье)
                                    </div>
                                    <img src={img11} alt="" className="content_item_img"/>
                                    <div className="content_item_description">
                                        150 г
                                    </div>
                                </div>
                            </div>
                            <div className="content_item" onClick={() => {this.showPopup(6)}}>
                                <div className="content_item_box">
                                    <div className="content_item_header">
                                        Biscrok™
                                        <img src={img22} alt=""/>
                                    </div>
                                    <div className="content_item_description">
                                        (Бисквитные<br/>
                                        косточки асссорти)
                                    </div>
                                    <img src={img12} alt="" className="content_item_img"/>
                                    <div className="content_item_description">
                                        200 г
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    {flexActive === 3 &&
                    <div className={'content_box jumbone'}>
                        {windowWidth < 767 &&
                        <div className="content_close" onClick={this.closeContent}/>
                        }
                        {windowWidth < 767 &&
                        <img className="content_icon" src={img17} alt=""/>
                        }
                        <div className="content_header">
                            Jumbone™ Mini (Лакомая кость)
                        </div>
                        <div className="content_subhead">
                            Для собак миниатюрных пород
                        </div>
                        <div className="play" onClick={() => {this.showPopup(7)}}>
                            <div className="play_text">
                                смотреть<br/>
                                видеоролик
                            </div>
                            <div className="play_button">
                                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="30" cy="30" r="29" fill="white" stroke="#BA0D0E" strokeWidth="2"/>
                                    <path d="M24 20L40 30L24 40V20Z" fill="#BA0D0E"/>
                                </svg>
                            </div>
                        </div>
                        <div className="content_list">
                            <div className="content_item">
                                <img src={img13} alt="" className="content_item_img"/>
                                <div className="content_item_description">
                                    4 палочки – 180 г
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
                }
                {showPopup &&
                    <Popup
                        closePopup={this.closePopup}
                    >
                        {windowWidth < 767 &&
                        <img className="Popup_icon" src={this.popupData[popupActive].iconFlex} alt=""/>
                        }
                        <div
                            className="Popup_head"
                            dangerouslySetInnerHTML={{__html: this.popupData[popupActive].head}}
                        >
                        </div>
                        {!showVideo
                            ?
                            <div className="Popup_content">
                                <div className="Popup_content_item">
                                    <img src={this.popupData[popupActive].productImg} alt="" className="Popup_product-img"/>
                                </div>
                                <div className="Popup_content_item">
                                    <div className="Popup_content_description">
                                        <img src={this.popupData[popupActive].icon1} alt="" className="Popup_content_icon"/>
                                        <p
                                            className="Popup_content_text"
                                            dangerouslySetInnerHTML={{__html: this.popupData[popupActive].text1}}
                                        >
                                        </p>
                                    </div>
                                    {this.popupData[popupActive].icon2 &&
                                    <div className="Popup_content_description">
                                        <img src={this.popupData[popupActive].icon2} alt="" className="Popup_content_icon"/>
                                        <p
                                            className="Popup_content_text"
                                            dangerouslySetInnerHTML={{__html: this.popupData[popupActive].text2}}
                                        >
                                        </p>
                                    </div>
                                    }
                                    {this.popupData[popupActive].icon3 &&
                                    <div className="Popup_content_description">
                                        <img src={this.popupData[popupActive].icon3} alt="" className="Popup_content_icon"/>
                                        <p
                                            className="Popup_content_text"
                                            dangerouslySetInnerHTML={{__html: this.popupData[popupActive].text3}}
                                        >
                                        </p>
                                    </div>
                                    }
                                    {this.popupData[popupActive].list &&
                                    <div className="Popup_content_description list">
                                        <img src={img_popup3_4} alt="" className="Popup_content_icon"/>
                                        <div className="Popup_content_list-box">
                                            <p
                                                className="Popup_content_text"
                                                dangerouslySetInnerHTML={{__html: this.popupData[popupActive].list}}
                                            >
                                            </p>
                                            <ul>
                                                <li>
                                                    <span className="Popup_content_description_red">ВИТАМИНЫ</span> – для поддержания естественной защиты
                                                </li>
                                                <li>
                                                    <span className="Popup_content_description_red">МИНЕРАЛЫ</span> – содержат кальций для поддержания здоровья костей
                                                </li>
                                                <li>
                                                    <span className="Popup_content_description_red">ОМЕГА-3</span> – для поддержания формы и активной жизни
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    }
                                </div>
                                <div className="play" onClick={this.showVideo}>
                                    <div className="play_text">
                                        смотреть<br/>
                                        видеоролик
                                    </div>
                                    <div className="play_button">
                                        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="30" cy="30" r="29" fill="white" stroke="#BA0D0E" strokeWidth="2"/>
                                            <path d="M24 20L40 30L24 40V20Z" fill="#BA0D0E"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className={'Popup_content ' + (showVideo && 'block')}>
                                <VideoBlock
                                    src={this.popupData[popupActive].video}
                                    title={this.popupData[popupActive].title}
                                />
                                {flexActive !== 1 &&
                                    <div className="play" onClick={this.showVideo}>
                                        <div className="play_text">
                                            смотреть<br/>
                                            описание
                                        </div>
                                        <div className="play_button">
                                            <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="30" cy="30" r="29" fill="white" stroke="#BA0D0E" strokeWidth="2"/>
                                                <rect x="20" y="22" width="20" height="3" fill="#BA0D0E"/>
                                                <rect x="20" y="29" width="20" height="3" fill="#BA0D0E"/>
                                                <rect x="20" y="36" width="20" height="3" fill="#BA0D0E"/>
                                            </svg>
                                        </div>
                                    </div>
                                }

                            </div>
                        }
                    </Popup>
                }
            </div>
        )
    }

}
