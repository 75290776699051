import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from "../../../Components/LessonAndTestComponents/OnClickBlock";

import img1 from '../../../image/lessons/lesson3/step20-1.png';

export default class step20 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step20">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Аминокислоты с разветвленными цепями — незаменимые аминокислоты для синтеза мышечного белка.</b>

                            <b>Лейцин, изолейцин и валин</b> относятся к аминокислотам с разветвленными цепями в составе семейства незаменимых аминокислот, следовательно, они обязательно должны присутствовать в рационе животных.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Лейцин, изолейцин и валин стимулируют синтез белков и замедляют процесс их распада в мышцах. Аминокислоты с разветвленными цепями помогают наращивать мышечную массу и поддерживают мышцы в рабочем состоянии.`}
                        />
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Лейцин, изолейцин и валин содержатся в мясе, включая говядину, баранину и мясо домашней птицы.`}
                        />
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Дефицит лейцина, изолейцина и валина может привести к потере веса и заторможенности реакций животного. А дефицит изолейцина — к огрубению кожного покрова и нарушению координации.`}
                        />
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
