import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson9/step12-1.jpg'


export default class step12 extends React.Component {


    render() {
        return (
            <div className="step12">
                <div className="flex">
                    <div className="ul">
                        <div className="li">
                            <div className="number">1</div>
                            <div className="text">
                                Беременность у собак длится в среднем <b>9 недель</b> (63 дня).
                            </div>
                        </div>
                        <div className="li">
                            <div className="number">2</div>
                            <div className="text">
                                Энергетические потребности беременных собак <b>возрастают</b> во второй половине беременности.
                            </div>
                        </div>
                        <div className="li">
                            <div className="number">3</div>
                            <div className="text">
                                Идеальный корм для беременных и кормящих собак — <b>калорийный, с высокими вкусовыми качествами и хорошо перевариваемый.</b> Под эти параметры подходит корм для щенков.
                            </div>
                        </div>
                        <div className="li">
                            <div className="number">4</div>
                            <div className="text">
                                В период лактации собаке требуется <b>наибольшее количество</b> питательных веществ.
                            </div>
                        </div>
                    </div>
                    <img src={img1} alt=""/>
                </div>
                <TextBlock
                    text={`В следующей теме вы узнаете о том, как растет и развивается щенок.`}
                />
            </div>
        )
    }
}
