import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson17/step10-1.png'

export default class step10 extends React.Component {


    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step10">
                <TextBlock
                    text={`Поскольку <b>собаки могут сильно отличаться размерами</b>, производители выпускают готовые корма для миниатюрных (до 5 кг), мелких (до 10 кг), средних (до 25 кг) и крупных (более 25 кг) собак.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
                <TextBlock
                    text={`Собаки разных размеров обладают особыми питательными потребностями, которые обязательно должны быть учтены в корме.`}
                />
                <div className="head">
                    ОСОБЕННОСТИ МЕЛКИХ И МИНИАТЮРНЫХ СОБАК
                </div>
                <TextBlock
                    text={`Эти корма обладают <b>сбалансированным составом</b>, учитывающим особенности собак небольшого размера (высокое содержание энергии, особый состав витаминов и минералов). Кроме этого, эти <b>корма имеют уменьшенный размер кусочков и гранул</b> для удобства поедания именно мелкими собаками.`}
                />
                <div className="head">
                    ОСОБЕННОСТИ КРУПНЫХ СОБАК
                </div>
                <TextBlock
                    text={`В таких кормах обычно несколько <b>меньше калорий</b>, чтобы избежать набора лишнего веса, и добавлены специальные полезные <b>компоненты для здоровья суставов</b>. Имеют более крупный <b>размер кусочков и гранул</b> для более удобного поедания.`}
                />
                <div className="head">
                    КОРМА ДЛЯ СРЕДНИХ СОБАК
                </div>
                <TextBlock
                    text={`Имеют универсальный сбалансированный состав, который подходит собакам всех размерных групп.`}
                />
            </div>
        )
    }
}
