import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import DefinitionBlock from '../../../Components/LessonAndTestComponents/DefinitionBlock';

import img1 from '../../../image/lessons/lesson3/step3-1.png'

export default class step3 extends React.Component {

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step3">
                <div className="flex">
                    <div className="flex_el">
                        <div className="head">
                            Структура белка
                        </div>
                        <TextBlock
                            text={`Белки представляют собой высокомолекулярные органические вещества, состоящие из аминокислот, соединённых в цепочку пептидной связью. От порядка расположения аминокислот зависит функция того или иного белка в организме.`}
                        />
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_imgBox">
                            <img src={img1} alt="" className="img"/>
                        </div>
                    </div>
                </div>
                <DefinitionBlock
                    text={`<span class="text_blue">Полипептид</span> — линейная цепочка аминокислот. Белок содержит по крайней мере один длинный полипептид.`}
                    text2={`<span class="text_blue">Пептидная связь</span> соединяет отдельные аминокислоты вместе.`}
                />
            </div>
        )
    }
}
