import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step3 extends React.Component {

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step3">
                <TextBlock
                    text={`Витамины делятся на две большие группы. Каждый витамин отвечает за определенные функции в организме.`}
                />
                <div className="data">
                    <div className={'data_el'}>
                        <div className="data_el_head">Жирорастворимые витамины</div>
                        <div className="data_tablet">
                            <div className="data_tablet_column left">
                                <div className="data_tablet_item">А</div>
                                <div className="data_tablet_item">D</div>
                                <div className="data_tablet_item">E</div>
                                <div className="data_tablet_item">K</div>
                            </div>
                            <div className="data_tablet_column right">
                                <div className="data_tablet_item">Зрение, кожа</div>
                                <div className="data_tablet_item">Обмен кальция и фосфора</div>
                                <div className="data_tablet_item">Антиоксидант</div>
                                <div className="data_tablet_item">Свертывание крови</div>
                            </div>
                        </div>
                        <div className="text desktop">
                            <div className="text_head">
                                Основная разница между этими двумя группами витаминов заключается в следующем:
                            </div>
                            <div className="list">
                                <li className="list_item">
                                    <span className="list_item_blue">Жирорастворимые витамины</span> обладают способностью накапливаться в организме, поэтому их избыточное потребление может иметь токсический эффект.
                                </li>
                                <li className="list_item">
                                    <span className="list_item_blue">Водорастворимые витамины</span>, напротив, не накапливаются в организме, поэтому их избыток невозможен.
                                </li>
                            </div>
                        </div>
                    </div>
                    <div className={'data_el'}>
                        <div className="data_el_head">Водорастворимые витамины</div>
                        <div className="data_tablet">
                            <div className="data_tablet_column left">
                                <div className="data_tablet_item">B1</div>
                                <div className="data_tablet_item">B2</div>
                                <div className="data_tablet_item">B3</div>
                                <div className="data_tablet_item">B5</div>
                                <div className="data_tablet_item">B6</div>
                                <div className="data_tablet_item">B7</div>
                                <div className="data_tablet_item">B9</div>
                                <div className="data_tablet_item">B12</div>
                                <div className="data_tablet_item">Холин</div>
                                <div className="data_tablet_item">C</div>
                            </div>
                            <div className="data_tablet_column right">
                                <div className="data_tablet_item">Нервная система</div>
                                <div className="data_tablet_item">Кожа</div>
                                <div className="data_tablet_item">Кожа, клеточная энергия</div>
                                <div className="data_tablet_item">Рост, кожа</div>
                                <div className="data_tablet_item">Клеточная энергия</div>
                                <div className="data_tablet_item">Кожа, шерсть</div>
                                <div className="data_tablet_item">Формирование клеток крови</div>
                                <div className="data_tablet_item">Формирование клеток крови</div>
                                <div className="data_tablet_item">Формирование клеточных мембран, защита кожи от обезвоживания</div>
                                <div className="data_tablet_item">Антиоксидант крови</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text mobile">
                    <div className="text_head">
                        Основная разница между этими двумя группами витаминов заключается в следующем:
                    </div>
                    <div className="list">
                        <li className="list_item">
                            <span className="list_item_blue">Жирорастворимые витамины</span> обладают способностью накапливаться в организме. Поэтому их избыточное потребление может иметь токсический эффект.
                        </li>
                        <li className="list_item">
                            <span className="list_item_blue">Водорастворимые витамины</span>, напротив, не накапливаются в организме, поэтому их избыток невозможен.
                        </li>
                    </div>
                </div>
            </div>
        )
    }
}
