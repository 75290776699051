import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";
import RangeSlider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'

import img1 from "../../../image/lessons/lesson21/step11-1.png";

export default class step11 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        value: 0,
        correct: '',
    }

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    handleChange = value => {
        this.setState({
            value: value
        })
    };

    checkClick = () => {
        const {value} = this.state
        if (value > 95) {
            this.setState({correct: '1'})
        } else {
            this.setState({correct: '0'})
        }
        this.props.showNextBtn()
    }

    render() {
        const {value, correct, windowWidth} = this.state
        return (
            <div className="step11">
                <TextBlock
                    text={`Уши кошки могут двигаться <b>независимо</b> друг от друга, что помогает ей исключительно точно определять местонахождение источника звука.
                    
                    Кошки могут обнаруживать мельчайшие отклонения в звуке любой интенсивности, они улавливают различия всего лишь <b>в 1/10 тона</b>. Такая способность помогает им определить тип и размер добычи, издающей лишь незначительный шум.`}
                />
                <InfoBlock
                    text={`Попробуйте определить слуховой диапазон кошки.`}
                />
                <div className="flex">
                    <div className={'flex_el ' + (correct === '' ? '' : correct === '1' ? ' correct' : ' inCorrect')}>
                        <div className="arrow"/>
                        <div className="range">
                            <div className="range_inner">
                                <div className={'range_el ' + (value > 2 && 'active ') + (correct !== '' && ' answer')}/>
                                <div className={'range_el ' + (value > 18 && 'active ') + (correct !== '' && ' answer')}/>
                                <div className={'range_el ' + (value > 23 && 'active ') + (correct !== '' && ' answer')}/>
                                <div className={'range_el ' + (value > 31 && 'active ') + (correct !== '' && ' answer')}/>
                                <div className={'range_el ' + (value > 46 && 'active ') + (correct !== '' && ' answer')}/>
                                <div className={'range_el ' + (value > 64 && 'active ') + (correct !== '' && ' answer')}/>
                            </div>
                        </div>
                        <div className='RangeSlider'>
                            <RangeSlider
                                min={0}
                                max={100}
                                value={value}
                                tooltip={false}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                </div>
                {correct !== '' ?
                    <div className={'info ' + (correct === '0' && 'error')}>
                        <div className="info_head">
                            {correct === '1' ? 'Все верно!' : 'К сожалению, ваш ответ не верен.'}
                        </div>
                        <div className="infoText">
                            Для сравнения, человек воспринимает звуки в диапазоне от 16Гц до 20кГц, собака – от 15Гц до 40кГц, кошка же от 45 Гц до 65кГц.
                        </div>
                    </div>
                    :
                    <button className="btn btn_blue" onClick={() => this.checkClick()}>
                        {windowWidth <= 767 ? 'Ответ' : 'Узнать правильный ответ'}
                    </button>
                }
            </div>
        )
    }
}
