import React from 'react'
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

export default class step6 extends React.Component {

    render() {
        return (
            <div className="step6">
                <TextBlock
                    text={`Предлагаем ознакомиться с обучающими видео`}
                />
                <VideoBlock
                    src={'https://www.youtube.com/embed/UQHlGc24OLU'}
                    title={'https://www.youtube.com/watch?v=UQHlGc24OLU&feature=emb_logo'}
                />
                <TextBlock
                    text={`<span class="text_blue">Приучение собаки к прекращению нежелательных действий</span>`}
                />
            </div>
        )
    }
}
