import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson8/step17-1.png';
import img2 from '../../../image/lessons/lesson8/step17-2.png';
import img3 from '../../../image/lessons/lesson8/step17-3.png';
import img4 from '../../../image/lessons/lesson8/step17-4.png';
import img5 from '../../../image/lessons/lesson8/step17-5.png';

export default class step17 extends React.Component {

    infoData = {
        1: {
            img: img1,
            text: `Легко заметны ребра, позвоночник, лопатки и кости таза у короткошерстных собак). 
            Явно снижена мышечная масса. 
            Жировые отложения в области ребер не пальпируются.`
        },
        2: {
            img: img2,
            text: `Легко заметны ребра, позвоночник, лопатки и кости таза.
            Отчетливое сужение в области живота (талия).
            Минимальные жировые отложения в области живота.`
        },
        3: {
            img: img3,
            text: `Ребра и позвоночник незаметны, но легко пальпируются.
            Отчетливое сужение в области живота (талия).
            Незначительные отложения жира в области живота.`
        },
        4: {
            img: img4,
            text: `Ребра и позвоночник пальпируются с трудом.
            Сужение в области живота (талия) отсутствует.
            Заметно отвисший живот.`
        },
        5: {
            img: img5,
            text: `Значительные жировые отложения в области грудной клетки, позвоночника и живота.
            Сильно отвисший живот.`
        },
    }
    render() {
        return (
            <div className="step17">
                <TextBlock
                    text={`Вес животного оценивается по пятибалльной шкале, где 1 балл означает истощенное телосложение, а 5 баллов — ожирение. 3 балла соответствуют идеальной упитанности.
                    
                    При оценке упитанности нужно обратить внимание, насколько выступают грудная клетка и позвоночник.`}
                />
                <div className="head">
                    <div className="head_el left">Градация</div>
                    <div className="head_el right">Критерии</div>
                </div>
                {Object.values(this.infoData).map((item, index) => {
                    return (
                        <div key={index} className="info">
                            <div className="info_el left">
                                <img src={item.img} alt="" className="info_el_img"/>
                            </div>
                            <div className="info_el right">
                                {item.text}
                            </div>
                        </div>
                    )
                })
                }
            </div>
        )
    }
}
