import React from 'react'
import './style.sass'


export default class MainButton extends React.Component {
    _renderBtns() {
        const {type, showNextStep, showPrevStep, showPrevBtn, showNextBtn, showPopap, checkAnswer, nextQuestion, customBtnText, showPopapAfterLesson, disabled} = this.props;

        // eslint-disable-next-line
        switch (type) {
            case 'take a lesson' : {
                return (
                    <div className="MainButton">
                        <button
                            className="btn btn_blue btn_center"
                            onClick={showNextStep}
                        >
                            Пройти урок
                        </button>
                    </div>

                )
            }
            case 'next step' : {
                return (
                    <div className="MainButton">
                        <button
                            className="btn btn_whiteBlueBorder"
                            onClick={showPrevStep}
                        >
                            Вернуться
                        </button>
                        <button
                            className={"btn btn_blue " + (showNextBtn ? '' : 'dn') }
                            onClick={showNextStep}
                        >
                            Продолжить
                        </button>
                    </div>
                )
            }
            case 'show popap' : {
                return (
                    <div className="MainButton">
                        <button
                            className="btn btn_whiteBlueBorder"
                            onClick={showPrevStep}
                        >
                            Вернуться
                        </button>
                        <button
                            className={"btn btn_blue " + (showNextBtn ? '' : 'dn') }
                            onClick={showPopap}
                        >
                            Продолжить
                        </button>
                    </div>
                )
            }
            case 'check answer' : {
                return (
                    <div className="MainButton">
                        {showPrevBtn &&
                            <button
                                className="btn btn_whiteBlueBorder"
                                onClick={showPrevStep}
                            >
                                Вернуться
                            </button>
                        }
                        <button
                            disabled={disabled}
                            className={"btn btn_blue " + (!showPrevBtn && 'btn_right')}
                            onClick={checkAnswer}
                        >
                            Проверить ответ
                        </button>
                    </div>
                )
            }
            case 'next question' : {
                return (
                    <div className="MainButton">
                        {showPrevBtn &&
                        <button
                            className="btn btn_whiteBlueBorder"
                            onClick={showPrevStep}
                        >
                            Вернуться
                        </button>
                        }
                        <button
                            disabled={disabled}
                            className={"btn btn_blue " + (!showPrevBtn && 'btn_right')}
                            onClick={nextQuestion}
                        >
                            Продолжить
                        </button>
                    </div>
                )
            }
            case 'end test' : {
                return (
                    <div className="MainButton">
                        <button
                            className="btn btn_blue btn_right"
                            onClick={nextQuestion}
                        >
                            Завершить тест
                        </button>
                    </div>
                )
            }
            case 'continue lesson' : {
                return (
                    <div className="MainButton">
                        {showPrevBtn &&
                        <button
                            className="btn btn_whiteBlueBorder"
                            onClick={showPrevStep}
                        >
                            Вернуться
                        </button>
                        }
                        <button
                            className={"btn btn_blue " + (!showPrevBtn && 'btn_right')}
                            onClick={showNextStep}
                        >
                            Продолжить
                        </button>
                    </div>
                )
            }
            case 'end lesson' : {
                return (
                    <div className="MainButton">
                        {showPrevBtn &&
                        <button
                            className="btn btn_whiteBlueBorder"
                            onClick={showPrevStep}
                        >
                            Вернуться
                        </button>
                        }
                        <button
                            className={"btn btn_blue " + (showNextBtn ? '' : 'dn') + (showPrevBtn ? '' : ' btn_right')}
                            onClick={showPopapAfterLesson}
                        >
                            Завершить урок
                        </button>
                    </div>
                )
            }
            case 'custom' : {
                return (
                    <div className="MainButton">
                        <button
                            className="btn btn_blue btn_center"
                            onClick={showNextStep}
                        >
                            {customBtnText}
                        </button>
                    </div>
                )
            }
            case 'clear' : {
                return null;
            }
        }
    }

    render() {
        return (
            this._renderBtns()
        )
    }
}
