import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from "../../../Components/LessonAndTestComponents/OnClickBlock";

import img1 from '../../../image/lessons/lesson3/step17-1.png';
import img2 from "../../../image/lessons/lesson3/step17-2.png";

export default class step17 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step17">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Аргинин — незаменимая аминокислота, отвечающая за рост и выработку мочевины.</b>
                           
                            Аргинин необходим для синтеза мочевины из аммиака. При отсутствии аргинина у кошек проявляются симптомы интоксикации аммиаком: рвота, повышенное слюноотделение и нарушение нервной системы.

                            При отсутствии немедленного лечения недостаток аргинина может привести к смерти  в течение нескольких часов.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Аргинин отвечает за следующие функции в организме:`}
                        />
                        <ul className="list">
                            <li className="list_item">выделение аммиака;</li>
                            <li className="list_item">регулирование тонуса кровеносных сосудов;</li>
                            <li className="list_item">выработка некоторых гормонов.</li>
                        </ul>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Основными источниками аргинина являются:`}
                        />
                        <ul className="list">
                            <li className="list_item">мясо и субпродукты;</li>
                            <li className="list_item">желатин.</li>
                        </ul>
                        <TextBlock
                            text={`Учитывая, что аммиак получается в результате распада белков, чем больше белка содержится в рационе, тем выше потребность животного в аргинине.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img2} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Отсутствие аргинина в рационе вызывает:`}
                        />
                        <ul className="list">
                            <li className="list_item">рвоту;</li>
                            <li className="list_item">мышечные спазмы;</li>
                            <li className="list_item">повышенное слюноотделение;</li>
                            <li className="list_item">развитие катаракты (в долгосрочном периоде);</li>
                            <li className="list_item">возможен летальный исход.</li>
                        </ul>
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
