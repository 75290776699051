import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from "../../../Components/LessonAndTestComponents/OnClickBlock";

import img1 from '../../../image/lessons/lesson3/step18-1.png';

export default class step18 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step18">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Лизин — незаменимая аминокислота для синтеза всех белков в организме.</b>

                            Контроль содержания лизина в рационе очень важен, так как при неправильном составлении рациона риск дефицита лизина выше всего.
                        
                            Лизин чувствителен к теплу, и в процессе приготовления корма он вступает в химическую реакцию с сахарами, которая отвечает за аромат и вкусовые качества корма.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Лизин является незаменимой аминокислотой, участвующей в синтезе белков.`}
                        />
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Источниками лизина являются:`}
                        />
                        <ul className="list">
                            <li className="list_item">мясные продукты, в частности — мышечные волокна;</li>
                            <li className="list_item">соевые белки.</li>
                        </ul>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Недостаток лизина приводит:`}
                        />
                        <ul className="list">
                            <li className="list_item">к ухудшению аппетита;</li>
                            <li className="list_item">к потере веса.</li>
                        </ul>
                        <TextBlock
                            text={`Избыток лизина в рационе щенков может вызвать симптомы дефицита аргинина.`}
                        />
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
