import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson24/step6-1.png'

export default class step6 extends React.Component {

    render() {
        return (
            <div className="step6">
                <TextBlock
                    text={`Блюда Sheba<sup>®</sup> получили очень высокие оценки в исследовании восприятия продукта владельцами кошек:`}
                />
                <img src={img1} alt="" className="imgCenter"/>
                <div className="info">
                    По пяти перечисленным критериям, а также по общему впечатлению от продукта, Sheba<sup>®</sup> имеет очень высокие оценки от потребителей
                </div>
            </div>
        )
    }
}
