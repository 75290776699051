import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from "../../../image/lessons/lesson18/step6-1.png";

export default class step6 extends React.Component {

    render() {
        return (
            <div className="step6">
                <TextBlock
                    text={`<span class="text_dark-blue">Отложение зубного камня приводит к пародонтиту и потере зубов.</span>
                    
                    <b>Пародонтит</b> — воспаление тканей, окружающих зубы.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
                <TextBlock
                    text={`<b>Основной способ профилактики пародонтита</b> — ежедневная чистка зубного налёта.`}
                />
            </div>
        )
    }
}
