import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson32/step41-1.png'


export default class step41 extends React.Component {


    render() {
        return (
            <div className="step41">
                <div className="head">
                    4. Высокая частота заболеваний ротовой полости
                </div>
                <TextBlock
                    text={`<b>Чем меньше масса тела, тем больше риск развития заболеваний пародонта.</b> Мелкие породы собак чаще, чем крупные, страдают заболеваниями ротовой полости. Участки пародонтопатии одинакового размера более выражены у животных с меньшей поверхностью зубов и десен.`}
                />
                {/*<div className="text">*/}
                {/*    Чем меньше масса тела, тем больше риск развития заболеваний пародонта. Мелкие породы собак чаще, чем крупные, страдают заболеваниями ротовой полости. Участки пародонтопатии одинакового размера более выражены у животных с меньшей поверхностью зубов и десен.*/}
                {/*</div>*/}
                <div className="author">
                    DeBowes и др., 1996
                </div>
                <div className="flex">
                    <img src={img1} alt="" className="left"/>
                    <ul>
                        <li>серьезный гингивит</li>
                        <li>изъязвление слизистой</li>
                        <li>обильное отложение зубного камня на большинстве зубов</li>
                        <li>недостающие зубы</li>
                        <li>расшатанные зубы</li>
                        <li>галитоз</li>
                    </ul>
                </div>
            </div>
        )
    }
}
