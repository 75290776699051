import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson8/step15-1.png";


export default class step15 extends React.Component {

    render() {
        return (
            <div className="step15">
                <TextBlock
                    text={`На этой диаграмме показано распределение собак по весу среди общей популяции в России.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
                <TextBlock
                    text={`Среди собак сохраняются практически такие же тенденции, как и у кошек. Разница в количестве собак с избыточным весом между крупными и небольшими городами менее значительная, чем у кошек, так как собаки даже в больших городах регулярно выгуливаются, а значит, получают некоторую физическую нагрузку.`}
                />
            </div>
        )
    }
}
