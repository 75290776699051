import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson16/step23-1.png'

export default class step23 extends React.Component {

    render() {
        return (
            <div className="step23">
                <TextBlock
                    text={`В среднем, время в категории от момента входа до момента выхода с покупкой занимает <span class="text_blue">менее 1 минуты!</span>`}
                />
                <img className="imgCenter" src={img1} alt=""/>
            </div>
        )
    }
}
