import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson6/step12-1.png";
import img2 from "../../../image/lessons/lesson6/step12-2.png";
import img3 from "../../../image/lessons/lesson6/step12-3.png";

export default class step12 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит и избыток'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step12">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Цинк (Zn) — здоровье кожи и шерсти, репродуктивная функция.</b>
                           
                            Представляет собой переходный металл, который встречается во всем организме. Он присутствует в большинстве тканей в относительно низких концентрациях.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <div className="imgDiv_wrap_flex">
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img2} alt=""/>
                            </div>
                            <div className="imgDiv_wrap_flex_box">
                                <ul className="list">
                                    <li className="list_item">Ферменты, содержащие цинк, участвуют в делении клеток, метаболизме углеводов и протеина, образуют структуру мембраны.</li>
                                    <li className="list_item">Цинк необходим для нормального функционирования репродуктивной системы.</li>
                                    <li className="list_item">Он играет важнейшую роль в процессах регенерации кожи, роста волос, секреции сальных желез.</li>
                                    <li className="list_item">Цинк участвует в синтезе коллагена и кератина.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Источники цинка:`}
                        />
                        <div className="imgDiv_wrap_flex">
                            <ul className="list">
                                <li className="list_item">крупяные продукты из цельного зерна;</li>
                                <li className="list_item">мясо;</li>
                                <li className="list_item">минеральные соли: сульфат цинка и оксид цинка.</li>
                            </ul>
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img3} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Дефицит</b> цинка может привести к замедлению роста и повреждениям подушечек лап, проблемам с кожей и шерстью.
                            
                            Его <b>избыток</b> может быть причиной судорог у кошек. Метаболизм цинка тесно взаимосвязан со всасыванием и утилизацией железа и меди. При условии нормального содержания в рационе этих двух минералов, концентрация цинка даже в несколько раз превышающая минимальную потребность, не окажет негативного влияния на организм.`}
                        />
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
