import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickBlock from '../../../Components/LessonAndTestComponents/OnClickBlock';

import img1 from "../../../image/lessons/lesson6/step11-1.png";
import img2 from "../../../image/lessons/lesson6/step11-2.png";

export default class step11 extends React.Component {

    state = {
        flexData: ['Описание', 'Функции', 'Источники', 'Дефицит и избыток'],
        flexHidden: false
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step11">
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Железо (Fe) — транспорт кислорода.</b>
                           
                            Наиболее распространенный микроэлемент в организме, составляющий около 0,005% от его общей массы.`}
                        />
                        <div className="imgBox">
                            <img className="imgDiv_wrap_img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <ul className="list">
                            <li className="list_item">Является компонентом <b>гемоглобина</b> — сложного белка, который переносит кислород по всему организму в составе эритроцитов.</li>
                            <li className="list_item">Также железо — компонент <b>миоглобина</b>, который отвечает за ту же функцию в мышцах.</li>
                            <li className="list_item">Участвует во множестве ферментативных функций, особенно в клеточном дыхании.</li>
                        </ul>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`Основные природные источники железа:`}
                        />
                        <div className="imgDiv_wrap_flex">
                            <div className="box">
                                <ul className="list">
                                    <li className="list_item">печень;</li>
                                    <li className="list_item">мясо;</li>
                                    <li className="list_item">рыба;</li>
                                    <li className="list_item">брокколи;</li>
                                    <li className="list_item">шпинат.</li>
                                </ul>
                            </div>
                            <div className="imgBox">
                                <img className="imgDiv_wrap_img" src={img2} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Дефицит</b> железа может привести к:`}
                        />
                        <ul className="list">
                            <li className="list_item">замедлению роста;</li>
                            <li className="list_item">бледности слизистой оболочки;</li>
                            <li className="list_item">диарее;</li>
                            <li className="list_item">анемии.</li>
                        </ul>
                        <TextBlock
                            text={`<b>Избыток</b> железа вызывает:`}
                        />
                        <ul className="list">
                            <li className="list_item">критический дефицит марганца, меди и цинка;</li>
                            <li className="list_item">рвоту и диарею.</li>
                        </ul>
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
