import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'

import img1 from '../../../image/lessons/lesson8/step2-1.png'
import img2 from '../../../image/lessons/lesson8/step2-2.png'


export default class step2 extends React.Component {


    render() {
        return (
            <div className="step2">
                <TextBlock
                    text={`Вы уже знаете из предыдущих уроков, что корм должен соответствовать жизненной стадии питомца и предоставляться в количестве, которое полностью удовлетворило бы потребности его организма в энергии и питательных веществах.`}
                />
                <InfoBlock
                    text={`Сравните энергетическую ценность сухого и влажного корма.`}
                />

                <div className="flex">
                    <div className="flex_el">
                        <span>СУХОЙ КОРМ</span>
                        330-420 ккал / 100 г
                        <img src={img1} alt=""/>
                    </div>
                    <div className="flex_el">
                        <span>ВЛАЖНЫЙ КОРМ</span>
                        60-100 ккал / 100 г
                        <img src={img2} alt=""/>
                    </div>
                </div>
            </div>
        )
    }
}
