import React from 'react'

import img1 from '../../../image/lessons/lesson15/step20-1.png';

export default class step20 extends React.Component {

    render() {
        return (
            <div className="step20">
                <img src={img1} alt="" className="imgCenter"/>
                <div className="block">
                    <div className="block_description">
                        <div className="block_description_point green"/>
                        <div className="block_description_text">Москва / Санкт-Петербург</div>
                    </div>
                    <div className="block_description">
                        <div className="block_description_point blue"/>
                        <div className="block_description_text">Города с населением менее 500 тыс. человек</div>
                    </div>
                    <div className="block_description">
                        <div className="block_description_point black"/>
                        <div className="block_description_text">Города с населением более 500 тыс. человек</div>
                    </div>
                    <div className="block_description">
                        <div className="block_description_point red"/>
                        <div className="block_description_text">Сельские поселения</div>
                    </div>
                </div>
            </div>
        )
    }
}
