import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson1/step13-1.png";
import img2 from "../../../image/lessons/lesson1/step13-2.png";

export default class step13 extends React.Component {

    render() {
        return (
            <div className="step13">
                <TextBlock
                    text={`У каждой размерной группы собак есть свои особенности питания и ухода в силу того, что размер определяет энергетические потребности.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <img className="flex_el_img" src={img1} alt=""/>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_el_head">Маленькие собаки</h3>
                        <p className="flex_el_text">
                            <b>Маленьких собак нельзя перекармливать: им необходимо много энергии, так как они более активны, чем крупные. Именно поэтому корм для них является более калорийным.</b> А еще в специализированных кормах для мелких собак уменьшен размер гранул для более удобного поглощения пищи. Один из таких кормов – <span className="flex_el_yellow">PEDIGREE<sup>®</sup> для взрослых собак миниатюрных пород.</span>
                        </p>
                    </div>
                </div>
                <div className="flex">
                    <div className="flex_el">
                        <img className="flex_el_img" src={img2} alt=""/>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_el_head">Большие собаки</h3>
                        <p className="flex_el_text">
                            <b>У больших собак часто возникают проблемы с суставами.</b> Поэтому очень важно кормить их специальным кормом, например, <span className="flex_el_yellow">PEDIGREE<sup>®</sup> для взрослых собак крупных пород</span>, в котором содержатся жирные кислоты Омега-3 и Омега-6, а также хондропротекторы – вещества, укрепляющие хрящевую ткань сустава.
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}
