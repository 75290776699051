import React from 'react'

export default class step15 extends React.Component {


    render() {
        return (
            <div className="step15">
                <div className="block">
                    <div className="block_text">
                        Что такое «Покрытие калорий» и как его рассчитать?
                    </div>
                </div>
            </div>
        )
    }
}
