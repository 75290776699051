import React from 'react'
import OnClickBlock from "../../../Components/LessonAndTestComponents/OnClickBlock";
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";

import img1 from "../../../image/lessons/lesson20/step16-1.png";
import img2 from "../../../image/lessons/lesson20/step16-2.png";
import img3 from "../../../image/lessons/lesson20/step16-3.png";
import img4 from "../../../image/lessons/lesson20/step16-4.png";

export default class step16 extends React.Component {

    state = {
        flexData: [
            'Обеспечение оптимального баланса питательных веществ',
            'Поддержка здоровья',
            'Поддержка разнообразия вкусов и текстур'
        ],
        flexHidden: false,
    }

    hideElement = () => {
        this.setState({flexHidden: !this.state.flexHidden})
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step16">
                <InfoBlock
                    text={`Нажмите на ключевые потребности кошек старше 7 лет и узнайте больше о каждой из них, а также Вы узнаете решения, которые предлагают рационы WHISKAS<sup>®</sup>.`}
                />
                <div className="head">
                    <div className="head_text">
                        Ключевые потребности в питании кошек старше 7 лет
                    </div>
                    <img src={img1} alt="" className="head_img"/>
                </div>
                <OnClickBlock
                    flexData={flexData}
                    hideElement={this.hideElement}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Поддержка долгой и здоровой жизни</b>
                           
                            У кошки после 7 лет уже могут появляться <b>признаки старения</b>, хотя владельцы нередко не воспринимают таких кошек как стареющих.
                            
                            <b>Решение WHISKAS<sup>®</sup></b>

                            Повышение качества и продолжительности жизни <b>за счет оптимального для стареющих кошек баланса белков и жиров.</b>`}
                        />
                        <img className="imgCenter" src={img2} alt=""/>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Поддержка жизненной энергии</b>
                           
                            После 7 лет у кошки <b>снижается общая активность и способность к усвоению пищи.</b>
                            
                            <b>Решение WHISKAS<sup>®</sup></b>

                            <b>Отборные ингредиенты и специальный витаминно-минеральный комплекс</b> поддерживают жизненную энергию кошек.`}
                        />
                        <img className="imgCenter" src={img3} alt=""/>
                    </div>
                    <div className="imgDiv_wrap">
                        <TextBlock
                            text={`<b>Поддержка аппетита</b>
                           
                            У кошек старше 7 лет нередко <b>пропадает интерес к пище</b>. Они отказываются от привычной еды, либо едят неохотно и с трудом.`}
                        />
                        <div className="imgDiv_wrap_flex">
                            <div className="imgDiv_wrap_flex_el">
                                <TextBlock
                                    text={`<b>Решение WHISKAS<sup>®</sup></b>`}
                                />
                                <ul className="list">
                                    <li className="list_item">Высокая <b>вкусовая привлекательность</b>;</li>
                                    <li className="list_item"><b>Разнообразие текстур и вкусов.</b></li>
                                </ul>
                            </div>
                            <div className="imgDiv_wrap_flex_el">
                                <img className="imgDiv_wrap_flex_img" src={img4} alt=""/>
                            </div>
                        </div>
                    </div>
                </OnClickBlock>
            </div>
        )
    }
}
