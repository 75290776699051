import React from 'react'
import VideoBlock from '../../../Components/LessonAndTestComponents/VideoBlock'

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <div className="box">
                    <VideoBlock
                        src={'https://www.youtube.com/embed/igd61QAB0mI'}
                        title={'https://www.youtube.com/watch?time_continue=59&v=igd61QAB0mI&feature=emb_logo'}
                    />
                </div>
            </div>
        )
    }
}
