import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import Answer from './dnd/Answer';
import Questions from './dnd/Questions';
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";



export default class step16 extends React.Component {

    state = {
        answers: [],
        questions: [],
        correct: '',
        answerCorrect: []
    }

    setAnswer = (answer_el, question_el) => {
        const {answers} = this.state

        if (answers.indexOf(question_el) === -1) {
            let answer = []
            answers[answer_el] = question_el
            answers.push(answer)
        }
        this.setState({answers})

        let questions = [];
        // eslint-disable-next-line
        answers.map(answer => {

            if (answer.length) {
                questions.push(answer)
            }

        });
        this.setState({questions})
    };

    checkAnswer = () => {
        const {answerCorrect, questions} = this.state
        // eslint-disable-next-line
        questions.map((answer, index) => {
            if (answer === String(index + 1)) {
                answerCorrect.push('correct')
            } else {
                answerCorrect.push('inCorrect')
            }
        })
        this.setState({
            answerCorrect: answerCorrect,
        })
        if (questions.length === 7 && answerCorrect.indexOf('inCorrect') === -1) {
            this.setState({correct: '1'})
        } else {
            this.setState({correct: '0'})
        }
        this.props.showNextBtn()
    };

    render() {
        const {answerCorrect, correct, questions} = this.state
        return (
            <div className="step16">
                <TextBlock
                    text={`Предлагаем закрепить знания и вспомнить, как выглядит схема производства влажного продукта.`}
                />
                <InfoBlock
                    text={`Расставьте блоки в соответствии со схемой, которую вы изучили ранее.`}
                />
                <div className="test">
                    <div className="answers">
                        <div className="flex_left">
                            <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'1'} text={'?'} count={'?'} answerCorrect={answerCorrect[0]}/>
                            <div className="flex_wrp">
                                <div className="flex_wrp_left">
                                    <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'2'} text={'1'} count={'?'} answerCorrect={answerCorrect[1]}/>
                                    <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'3'} text={'2'} count={'?'} answerCorrect={answerCorrect[2]}/>
                                    <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'4'} text={'3'} count={'?'} answerCorrect={answerCorrect[3]}/>
                                    <span className="arrow"></span>
                                    <span className="arrow"></span>
                                    <span className="arrow"></span>
                                    <span className="arrow arrow_right"></span>
                                </div>
                                <div className="flex_wrp_right">
                                    <div className="answer_hidden">
                                        <div className="answer"></div>
                                    </div>
                                    <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'5'} text={'?'} count={'?'} answerCorrect={answerCorrect[4]}/>
                                    <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'6'} text={'?'} count={'?'} answerCorrect={answerCorrect[5]}/>
                                    <span className="arrow arrow_angle"></span>
                                    <span className="arrow arrow_long"></span>
                                    <span className="arrow arrow_right"></span>
                                </div>

                            </div>
                        </div>
                        <div className="flex_right">
                            <Answer targetKey="1" setAnswer={this.setAnswer} answer_el={'7'} text={'?'} count={'?'} answerCorrect={answerCorrect[6]}/>
                        </div>
                    </div>
                    <div className="questions">
                        <div className={'question_el ' + (questions.indexOf('1') >= 0 && 'hidden')}>
                            <Questions targetKey="1" question_el={'1'} label={'Сырьё'} text={'Сырьё'}/>
                        </div>
                        <div className={'question_el ' + (questions.indexOf('2') >= 0 && 'hidden')}>
                            <Questions targetKey="1" question_el={'2'} label={'Перемешивание и измельчение'} text={'Перемешивание и измельчение'}/>
                        </div>
                        <div className={'question_el ' + (questions.indexOf('3') >= 0 && 'hidden')}>
                            <Questions targetKey="1" question_el={'3'} label={'Приготовление кусочков'} text={'Приготовление кусочков'}/>
                        </div>
                        <div className={'question_el ' + (questions.indexOf('4') >= 0 && 'hidden')}>
                            <Questions targetKey="1" question_el={'4'} label={'Наполнение упаковки готовым продуктом'} text={'Наполнение упаковки готовым продуктом'}/>
                        </div>
                        <div className={'question_el ' + (questions.indexOf('5') >= 0 && 'hidden')}>
                            <Questions targetKey="1" question_el={'5'} label={'Приготовление соуса'} text={'Приготовление соуса'}/>
                        </div>
                        <div className={'question_el ' + (questions.indexOf('6') >= 0 && 'hidden')}>
                            <Questions targetKey="1" question_el={'6'} label={'Стерилизация'} text={'Стерилизация'}/>
                        </div>
                        <div className={'question_el ' + (questions.indexOf('7') >= 0 && 'hidden')}>
                            <Questions targetKey="1" question_el={'7'} label={'Готовый продукт'} text={'Готовый продукт'}/>
                        </div>
                    </div>
                </div>
                {correct !== '' ?
                    <div className={'info ' + (correct === '0' && 'error')}>
                        <div className="info_head">
                            {correct === '1' ? 'все верно!' : 'К сожалению, ваш ответ не верен.'}
                        </div>
                    </div>
                    :
                    <button className="btn btn_blue" onClick={() => this.checkAnswer()}>
                        ПРОВЕРИТЬ
                    </button>
                }
            </div>
        )
    }
}
