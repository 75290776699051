import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";
import PlanInFirstStep from "../../../Components/LessonAndTestComponents/PlanInFirstStep";

export default class step1 extends React.Component {
    planData = [
        `Как готовые корма могут заботиться о здоровье животных`,
        `Какие функциональные аспекты являются самыми важными`,
        `Какие ингредиенты в готовых кормах добавляются для улучшения здоровья животных`,
    ]

    render() {
        return (
            <div className="step1">
                <div className="head">План урока</div>

                <TextBlock
                    text={`Вы изучите:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'blue'}
                />

            </div>
        )
    }
}
