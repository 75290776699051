import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

import img1 from '../../../image/lessons/lesson7/step18-1.png'


export default class step18 extends React.Component {


    render() {
        return (
            <div className="step18">
                <TextBlock
                    text={`Как вы знаете, готовый корм для животных отвечает <b>всем потребностям в питательных веществах</b>. В домашней еде недостаточно макро- и микронутриентов, что может негативно сказаться на здоровье питомца в долгосрочном периоде.`}
                />
                <InfoBlock
                    text={`Изучите таблицу, чтобы понять, почему домашняя еда не является сбалансированным рационом для собак и кошек.`}
                />
                <div className="img-box">
                    <img src={img1} alt="" className="img"/>
                </div>
            </div>
        )
    }
}
