import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson16/step8-1.png';

export default class step8 extends React.Component {

    render() {
        return (
            <div className="step8">
                <TextBlock
                    text={`А Вы знаете, чем покупатель кормов для домашних животных важен для торговых сетей?`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
