import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";

import img1 from '../../../image/lessons/lesson23/step6-1.png';
import img2 from '../../../image/lessons/lesson23/step6-2.png';
import img3 from '../../../image/lessons/lesson23/step6-3.png';
import img4 from '../../../image/lessons/lesson23/step6-4.png';
import img5 from '../../../image/lessons/lesson23/step6-5.png';
import img6 from '../../../image/lessons/lesson23/step6-6.png';
import img7 from '../../../image/lessons/lesson23/step6-7.png';
import img8 from '../../../image/lessons/lesson23/step6-8.png';
import img9 from '../../../image/lessons/lesson23/step6-9.png';
import img10 from '../../../image/lessons/lesson23/step6-10.png';
import img11 from '../../../image/lessons/lesson23/step6-11.png';
import img12 from '../../../image/lessons/lesson23/step6-12.png';
import img13 from '../../../image/lessons/lesson23/step6-13.png';
import img14 from '../../../image/lessons/lesson23/step6-14.png';
import img15 from '../../../image/lessons/lesson23/step6-15.png';
import img16 from '../../../image/lessons/lesson23/step6-16.png';

export default class step6 extends React.Component {

    state = {
        pointActive: 0,
        points: [],
    }

    showInfo = (pointActive) => {
        const {points} = this.state;
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        this.setState({points: points, pointActive: pointActive})

        if (points.length === 16) {
            this.props.showNextBtn();
        }
    }

    flexData = {
        1: {
            img: img1,
            head: `Курица`,
            text: `Источник животного белка с высокой усвояемостью`
        },
        2: {
            img: img2,
            head: `Говядина`,
            text: `Богатый источник белка и незаменимых аминокислот`
        },
        3: {
            img: img3,
            head: `Индейка`,
            text: `Источник животного белка с высокой усвояемостью`
        },
        4: {
            img: img4,
            head: `Лосось`,
            text: `Богатый источник белка и омега-3 жирных кислот`
        },
        5: {
            img: img5,
            head: `Кукуруза`,
            text: `Природный источник углеводов, энергии и минералов`
        },
        6: {
            img: img6,
            head: `Пшеница`,
            text: `Источник углеводов и клетчатки для пищеварения`
        },
        7: {
            img: img7,
            head: `Рис`,
            text: `Легкоусвояемые углеводы, минералы и витамины группы В`
        },
        8: {
            img: img8,
            head: `Куриное яйцо`,
            text: `Легкоусвояемый источник животного белка`
        },
        9: {
            img: img9,
            head: `Масла и жиры `,
            text: `Источники энергии, Омега-3,6 кислот, витаминов А, D, E`
        },
        10: {
            img: img10,
            head: `Бульон`,
            text: `Высокая естественная вкусовая привлекательность`
        },
        11: {
            img: img11,
            head: `Дрожжи`,
            text: `Богатый источник аминокислот и витаминов группы В`
        },
        12: {
            img: img12,
            head: `Мякоть сахарной свеклы`,
            text: `Волокна для пищеварения и микрофлоры`
        },
        13: {
            img: img13,
            head: `Морковь`,
            text: `Богатый источник бета-каротина`
        },
        14: {
            img: img14,
            head: `Горошек`,
            text: `Источник белка, витаминов и минералов`
        },
        15: {
            img: img15,
            head: `Шпинат`,
            text: `Богат микроэлементами (особенно железом) и витаминами`
        },
        16: {
            img: img16,
            head: `Цикорий`,
            text: `Природный пребиотик для микрофлоры кишечника`
        },


    }

    render() {
        const {points} = this.state;
        return (
            <div className="step6">
                <div className="head">
                    NATURE’S TABLE™. Натурально и полезно.
                </div>
                <TextBlock
                    text={`NATURE’S TABLE™ содержит натуральные ингредиенты высокого качества, которые мы получаем от проверенных поставщиков. Вся польза природы – для ваших питомцев, чтобы они радовали вас здоровьем каждый день.`}
                />
                <InfoBlock
                    text={`Нажмите на активные элементы, чтобы узнать больше о каждом ингредиенте NATURE’S TABLE™.`}
                />
                <div className="flex">
                    {Object.keys(this.flexData).map(item => {
                        return (
                            <div
                                key={item}
                                className={'flex_el ' +(points.indexOf(item) >=0 && 'active')}
                                onClick={() => {this.showInfo(item)}}
                            >
                                <div className="flex_el_img-box">
                                    <img src={this.flexData[item].img} alt="" className="flex_el_img"/>
                                </div>
                                <div className="flex_el_box">
                                    <div className="flex_el_box_head">
                                        {this.flexData[item].head}
                                    </div>
                                    <div className="flex_el_box_text">
                                        {this.flexData[item].text}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}
