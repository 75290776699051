import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson9/step25-1.jpg'
import List from "../../../Components/LessonAndTestComponents/List";


export default class step5 extends React.Component {

    ListData = [
        `почечная недостаточность;`,
        `артрит;`,
        `болезнь сердца;`,
        `появление лишнего веса;`,
        `проблемы с зубами.`
    ]


    render() {
        return (
            <div className="step25">
                <TextBlock
                    text={`С возрастом собаке требуется дополнительный уход.
                    
                    Собака считается стареющей, когда она достигает середины ожидаемой продолжительности жизни. Пожилыми называют тех собак, чей возраст достиг ¾ от ожидаемой продолжительности жизни. 
Продолжительность жизни у собак крупных пород меньше, чем у собак мелких пород, поэтому они стареют раньше. У стареющих собак могут наблюдаться проблемы со здоровьем. <span class="text_blue">Самые распространенные заболевания собак, связанные с пожилым возрастом:</span>`}
                />
                <div className="flex">
                    <List ListData={this.ListData}/>
                    <img className="img" src={img1} alt=""/>
                </div>
                {/*<div className="attention">*/}
                {/*    <p className="attention_text">Щенок растет примерно в 12 раз быстрее ребенка. Поэтому необходимо следить за его питанием и соблюдать режим кормления. Первый год жизни собаки — самый важный для ее дальнейшего развития!</p>*/}
                {/*</div>*/}
            </div>
        )
    }
}
