import React from 'react'
import DefinitionBlock from "../../../Components/LessonAndTestComponents/DefinitionBlock";

import img1 from "../../../image/lessons/lesson2/step9-1.png";


export default class step9 extends React.Component {


    render() {
        return (
            <div className="step9">
                <DefinitionBlock
                    text={`<span class="text_blue">Валовая энергия (ВЭ)</span> — это количество энергии, высвобождаемое при полном сжигании корма в калориметрической бомбе. Это вся энергия, которая может содержаться в корме.`}
                    text2={`<span class="text_blue">Усвояемая энергия (УЭ)</span> — энергия, которую организм усваивает из пищи. Это валовая энергия минус потери энергии с фекалиями. УЭ = ВЭ — потеря энергии с фекалиями.`}
                    text3={`<span class="text_blue">Обменная или метаболическая энергия  (ОЭ)</span> — энергия, участвующая в обмене веществ. Это усвояемая энергия минус потери энергии с мочой и газами. ОЭ = УЭ — потеря энергии с мочой и газами.`}
                />
                <img src={img1} alt="" className="img"/>
            </div>
        )
    }
}
