import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson7/step15-1.png";

export default class step15 extends React.Component {

    render() {
        return (
            <div className="step15">
                <TextBlock
                    text={`<b>Сбалансированный рацион</b> содержит все необходимые организму питательные вещества в правильных пропорциях и удовлетворяет ежедневные энергетические потребности животного на определенной жизненной стадии.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Критерии сбалансированного питания:
                        </div>
                        <ul className="list">
                            <li className="list_item">Количество каждого питательного вещества должно соответствовать оптимальной норме.</li>
                            <li className="list_item">Каждое питательное вещество добавляется в правильном соотношении с пищевой ценностью корма и с другими веществами.</li>
                            <li className="list_item">Питательные вещества должны хорошо усваиваться.</li>
                        </ul>
                    </div>
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                </div>
                <div className="advice">
                    Собакам и кошкам требуется около 40 необходимых питательных веществ, каждое из которых должно содержаться в правильном количестве и правильной форме.
                </div>
            </div>
        )
    }
}
