import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickTest from "../../../Components/LessonAndTestComponents/OnClickTest";

import img1 from "../../../image/lessons/lesson2/step11-1.png";
import img2 from "../../../image/lessons/lesson2/step11-2.png";
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";


export default class step11 extends React.Component {

    state = {
        answersData: [`10 г`, `25 г`, `22 г`, `17 г`, `нет верного ответа`]
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step11">
                <TextBlock
                    text={`Проверьте свое понимание: рассчитайте оптимальный размер порции для кошки.`}
                />
                <InfoBlock
                    text={`Сделайте расчеты и выберите ответ, который у вас получился.`}
                />
                <div className="catAliсe">
                    <div className="catAlice_el">
                        <TextBlock
                            text={`Это кошка Алиса. Ее энергетические потребности составляют 200 ккал в сутки. Ее кормят сухим рационом PERFECT FIT™ для взрослых кошек.
                            
                            Энергетическая ценность корма: 
                            390 ккал/100 г.`}
                        />
                        <img src={img1} alt="" className="catAlice_mobile"/>
                        <TextBlock
                            text={`<span class="text_blue">Каким будет оптимальный размер порции для этой кошки, если ее кормят 3 раза в день?</span>`}
                        />
                    </div>
                    <div className="catAlice_el">
                        <img src={img1} alt="" className="catAlice_el_img"/>
                    </div>
                </div>
                <OnClickTest
                    ansewrsData={this.state.answersData}
                    type={'block'}
                    rightAnswers={[4]}
                    showNextBtn={this.showNextBtn}
                    correctText={`Сначала узнаем, сколько граммов корма необходимо кошке ежедневно. Для этого нужно разделить ее суточные энергетические потребности на калорийность корма:
                    <b>(200 / 390 х 100) = 51 г</b> (с округлением).
                    
                    Теперь разделим суточную норму на количество приемов пищи: <b>51 / 3 = 17 г</b> (с округлением).
                    
                    Таким образом, оптимальный размер порции 
                    <b>для этой кошки составляет 17 г.</b>`}
                >
                    <img className="correctImg" src={img2} alt=""/>
                </OnClickTest>
            </div>
        )
    }
}
