import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson1/step4-1.png'
import img1xs from '../../../image/lessons/lesson1/step4-1xs.png'

export default class step4 extends React.Component {

    render() {
        return (
            <div className="step4">
                <TextBlock
                    text={`Процесс одомашнивания азиатского волка был долгим и сложным. Ключевым фактором в этом процессе стала селекция, позволившая развить необходимые человеку повадки у собак и отучить их от нежелательных привычек.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <picture className="flex_el_box">
                            <source srcSet={img1xs} media="(max-width: 767px)"/>
                            <img className="flex_el_img" src={img1} alt=""/>
                        </picture>
                    </div>
                    <div className="flex_el">
                        <TextBlock
                            text={`Как правило, волки боятся людей и агрессивно настроены по отношению к ним. Только 5% волков чувствуют себя комфортно в присутствии людей.
                            
                            С помощью селекции этой малой доли и ее разведения нашим предкам удалось вывести животных с повадками, присущими домашним животным.
                            
                            ДНК собак остается схожей с их предками: генетическая структура отличается на 0.2% от генетической структуры волков.`}
                        />
                    </div>
                </div>
                <div className="definitions">
                    <div className="definitions_head">
                        Определения
                    </div>
                    <div className="definitions_box">
                        <div className="definitions_item">
                            <p className="definitions_text">
                                <span className="definitions_word">Селекция</span><span className="definitions_description">– это выведение новых пород животных путем искусственного отбора.</span>
                            </p>
                        </div>
                        <div className="definitions_item">
                            <p className="definitions_text">
                                <span className="definitions_word">Одомашнивание</span><span className="definitions_description"> – это процесс изменения диких животных, при котором они на протяжении многих лет содержатся человеком генетически изолированно от их дикой формы (т.е. не имеют возможности скрещиваться с другими дикими животными).</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
