import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson16/step5-1.png'
import img1xs from '../../../image/lessons/lesson16/step5-1xs.png'


export default class step5 extends React.Component {


    render() {
        return (
            <div className="step5">
                <TextBlock
                    text={`При этом семьи с домашними животными обычно большие
                    (более 4-х человек).`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} className="imgCenter" alt=""/>
                </picture>
                <div className="text">
                    * Все семьи России с домашними животными.
                </div>
            </div>
        )
    }
}
